import React, { Component } from "react";
import moment from "moment";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Skeleton } from 'primereact/skeleton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { VehicleSrvice } from "../../service/VehicleService";
import { mapKey } from "../../utilities/constant";
import { minToHrMinSec, secondsToHms, minToHrMin } from "../../utilities/utillFunction";
import { dummy_data } from "../../utilities/staticData";

const map_start_icon = '<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64"><path style="fill:#01010100; stroke:none;" d="M0 0L0 64L64 64L64 0L0 0z"/><path style="fill:#00A42F; stroke:none;" d="M31 60L33 60C38.9485 49.275 51.7089 35.8509 51.8164 23C51.9872 2.56881 22.1996 -3.31481 13.9699 15C7.39209 29.6386 23.7505 48.1572 31 60z"/><path style="fill:#f73a3a; stroke:none;" d="M21 6L22 7L21 6M42 6L43 7L42 6z"/><path style="fill:#FFFFFF; stroke:none;" d="M28.1088 16.0278C20.0658 20.5369 26.7819 33.892 34.956 29.8056C44.4446 25.0619 37.288 10.8817 28.1088 16.0278z"/><path style="fill:#ff0101; stroke:none;" d="M24 25L25 26L24 25M39 25L40 26L39 25M49 31L50 32L49 31M48 33L49 34L48 33M47 35L48 36L47 35z"/><path style="fill:#f73a3a; stroke:none;" d="M19 40L20 41L19 40z"/><path style="fill:#ff0101; stroke:none;" d="M43 42L44 43L43 42z"/><path style="fill:#e3641d; stroke:none;" d="M22 45L23 46L22 45z"/><path style="fill:#ff0101; stroke:none;" d="M38 50L39 51L38 50z"/><path style="fill:#e3641d; stroke:none;" d="M33 58L34 59L33 58z"/></svg>';

const map_end_icon = '<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64"><path style="fill:#01010100; stroke:none;" d="M0 0L0 64L64 64L64 0L0 0z"/><path style="fill:#F14003; stroke:none;" d="M31 60L33 60C38.9485 49.275 51.7089 35.8509 51.8164 23C51.9872 2.56881 22.1996 -3.31481 13.9699 15C7.39209 29.6386 23.7505 48.1572 31 60z"/><path style="fill:#f73a3a; stroke:none;" d="M21 6L22 7L21 6M42 6L43 7L42 6z"/><path style="fill:#FFFFFF; stroke:none;" d="M28.1088 16.0278C20.0658 20.5369 26.7819 33.892 34.956 29.8056C44.4446 25.0619 37.288 10.8817 28.1088 16.0278z"/><path style="fill:#ff0101; stroke:none;" d="M24 25L25 26L24 25M39 25L40 26L39 25M49 31L50 32L49 31M48 33L49 34L48 33M47 35L48 36L47 35z"/><path style="fill:#f73a3a; stroke:none;" d="M19 40L20 41L19 40z"/><path style="fill:#ff0101; stroke:none;" d="M43 42L44 43L43 42z"/><path style="fill:#e3641d; stroke:none;" d="M22 45L23 46L22 45z"/><path style="fill:#ff0101; stroke:none;" d="M38 50L39 51L38 50z"/><path style="fill:#e3641d; stroke:none;" d="M33 58L34 59L33 58z"/></svg>';

class VehicleReportComponent extends Component {
    mapRef = React.createRef();
    constructor(props) {
        super(props);

        this.state = {
            reportData: null,
            stoppagePoints: null,
            geoArray: null,
            selectedThingName: null,
            date4: new Date(),
        };

        this.maxDate = new Date();

        this.vehicleService = new VehicleSrvice();
        this.handStoppage = this.handStoppage.bind(this);
        this.handleduration = this.handleduration.bind(this);
        this.handleIdling = this.handleIdling.bind(this);

        addLocale("es", {
            firstDayOfWeek: 1,
            dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
            dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
            dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
            monthNames: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
            monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
            today: "Hoy",
            clear: "Limpiar",
        });
    }
    componentDidMount = () => {
        var toDay = new Date();
        var preDate = toDay.setDate(toDay.getDate() - 1);
        preDate = new Date(preDate).toLocaleDateString("en-US");
        preDate = new Date(preDate).toString();
        // console.log(preDate);
        let form_date = moment(preDate).startOf("day").valueOf();
        let to_date = moment(preDate).endOf("day").valueOf();
        this.initReport("pm9yoq9v7z", form_date, to_date, "support_nextqore_com_1");
    };

    getStrengthMarker = (H, map, station, contentString, defaultLayers, icon, ui, size) => {
        let group = new H.map.Group();
        map.addObject(group);
        // let ui = H.ui.UI.createDefault(map, defaultLayers);
        let marker = new H.map.Marker(station, icon, size);
        let bubble;

        marker.addEventListener(
            "tap",
            function (evt) {
                bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
                    content: evt.target.getData(),
                });
                ui.addBubble(bubble);
            },
            false
        );
        // marker.addEventListener(
        // 	'pointerleave',
        // 	function (evt) {
        // 		bubble.close();
        // 	},
        // 	false
        // );
        marker.setData(contentString);

        group.addObject(marker);
        return marker;
    };

    initReport = (serviceId, form_date, to_date, thingName) => {
        this.vehicleService.getVehicleReport(serviceId, form_date, to_date, thingName).then((renponse) => {
            let vehicles;
            // if(renponse.responseCode === 500) {
            //     vehicles = dummy_data.responseData.data;
            // } else {
            //     vehicles = renponse.responseData.data;
            // }
            vehicles = dummy_data.responseData.data;
            let index = vehicles.findIndex((item) => item.thingName === this.props.thingName);
            if (index > -1) {
                const vehicle = vehicles[index];
                this.handleRoute(vehicle);
            }
        });
    };

    handleRoute = (data) => {
        let geoMapArray = [];
        let declutterPoints = data["Route Points"] ? data["Route Points"] : [];

        for (let index = 0; index < declutterPoints.length; index++) {
            let lat = Number(typeof declutterPoints[index].latitude === "object" ? Number(declutterPoints[index].latitude.double) : declutterPoints[index].latitude),
                lon = Number(declutterPoints[index].longitude?.double ? declutterPoints[index].longitude?.double : declutterPoints[index].longitude);
            geoMapArray.push({
                lat: lat,
                lng: lon,
                type: declutterPoints[index].type ? declutterPoints[index].type : "",
                time: declutterPoints[index].ts,
            });
        }
        let stoppege_point = data["Stoppage Points"];
        stoppege_point.forEach((item, index) => {
            let geoPoint = `${item.latitude},${item.longitude}`;
            this.vehicleService.getLatLongDetails(geoPoint).then((res) => {
                item.index = index + 1;
                item.adderss = res.items[0].address.label;
            });
        });

        this.setState(
            {
                reportData: data,
                stoppagePoints: stoppege_point,
                geoArray: geoMapArray,
                selectedThingName: this.props.thingName,
            },
            () => {
                this.getVehicleMap(this.state.geoArray);
            }
        );
    };

    getVehicleMap = (stations) => {
        if (!stations || !stations.length) {
            return;
        }
        let H = window.H;
        let scheduledIcon = new H.map.Icon("/images/Scheduled.svg", {
            size: { w: 15, h: 15 },
        });
        let unScheduledIcon = new H.map.Icon("/images/Unscheduled.svg", {
            size: { w: 15, h: 15 },
        });
        let startIcon = new H.map.Icon(map_start_icon, { size: { w: 32, h: 32 } });
        let endIcon = new H.map.Icon(map_end_icon, { size: { w: 32, h: 32 } });

        const platform = new H.service.Platform({
            apikey: mapKey,
        });
        const defaultLayers = platform.createDefaultLayers();

        const map = new H.Map(this.mapRef.current, defaultLayers.vector.normal.map, {
            center: { lat: stations[0].lat, lng: stations[0].lng },
            zoom: 12,
            pixelRatio: window.devicePixelRatio || 1,
        });

        window.addEventListener("resize", () => map.getViewPort().resize());

        let ui = H.ui.UI.createDefault(map, defaultLayers);
        new H.mapevents.Behavior(new H.mapevents.MapEvents(map));

        let markerLabel = 1;
        let markers = [];
        for (let i = 0; i < stations.length; i++) {
            let mark;
            let contentString =
                '<div id="vehicle-content">' +
                '<div id="vehicle-info">' +
                `<div id=${stations[i].type === "undesignated" ? "fc-red" : "fc-green"} >` +
                `${i === 0 ? "Start" : i === stations.length - 1 ? "End" : stations[i].type}` +
                "</div>" +
                '<div id="info">' +
                `${i === 0 ? "Start Time" : i === stations.length - 1 ? "End Time" : "Time"} : ` +
                `${stations[i].time ? stations[i].time.split(" ")[1] : ""}` +
                "</div>" +
                `${!(i === 0 || i === stations.length - 1) ? '<div id="info">' + `Stop Point Number : ` + `${markerLabel}` + " </div>" : ""}` +
                "</div>" +
                "</div>";

            if (i === 0) {
                mark = this.getStrengthMarker(H, map, stations[i], contentString, defaultLayers, { icon: startIcon }, ui, { h: 10, w: 10 });
            } else if (i === stations.length - 1) {
                mark = this.getStrengthMarker(H, map, stations[i], contentString, defaultLayers, { icon: endIcon }, ui, { h: 10, w: 10 });
            } else if ((i != stations.length - 1 || 0) && stations[i].type === "undesignated") {
                mark = this.getStrengthMarker(H, map, stations[i], contentString, defaultLayers, { icon: unScheduledIcon }, ui, { h: 10, w: 10 });
                markerLabel += 1;
            } else if ((i != stations.length - 1 || 0) && stations[i].type === "designated") {
                mark = this.getStrengthMarker(H, map, stations[i], contentString, defaultLayers, { icon: scheduledIcon }, ui, { h: 10, w: 10 });
                markerLabel += 1;
            }
            markers.push(mark);
        }
        //this.setState({ markers });
        for (let index = 0; index < stations.length - 1; index++) {
            this.addRouteFromAtoB(platform, stations[index].lat + "," + stations[index].lng, stations[index + 1].lat + "," + stations[index + 1].lng, H, map);
        }
    };

    addRouteFromAtoB = (platform, origin, destination, H, map) => {
        this.calculateRouteFromAtoB(platform, origin, destination, H, map).then((route) => {
            this.addRouteShapeToMap(route, H, map);
        });
    };

    calculateRouteFromAtoB(platform, origin, destination, H, map) {
        return new Promise((resolve, reject) => {
            var router = platform.getRoutingService(null, 8),
                routeRequestParams = {
                    routingMode: "fast",
                    transportMode: "car",
                    origin: origin,
                    destination: destination,
                    return: "polyline,summary",
                };

            router.calculateRoute(routeRequestParams, (res, err) => {
                if (err) reject();
                resolve(res.routes[0]);
            });
        });
    }

    addRouteShapeToMap(route, H, map) {
        route.sections.forEach((section) => {
            let linestring = H.geo.LineString.fromFlexiblePolyline(section.polyline);
            let polyline = new H.map.Polyline(linestring, {
                style: {
                    lineWidth: 5,
                    strokeColor: "rgba(10, 3, 241, 1)",
                },
            });
            map.addObject(polyline);
        });
    }

    handStoppage = (item) => {
        return item.adderss;
    };
    handleduration = (item) => {
        return minToHrMinSec(item.stoppageDuration);
    };
    handleIdling = (item) => {
        return item.ignitionOnTime ? secondsToHms(item.ignitionOnTime) : "00:00:00";
    };

    handleDateChenge = (e) => {
        let form_date = moment(e.value).startOf("day").valueOf();
        let to_date = moment(e.value).startOf("day").valueOf();
        this.setState({ date4: e.value }, () => {
            this.initReport("pm9yoq9v7z", form_date, to_date, "support_nextqore_com_1");
        });
    };

    render = () =>
        this.state.reportData ? (
            <div className="grid">
                <div className="col-12">
                    <div className="flex justify-content-between">
                        <p className="px-2 m-0" onClick={this.props.reportBack}>
                            <span className="pi pi-arrow-left"></span>Back
                        </p>
                        <p className="px-2">
                            Vehicle ID: <span className="text-blue-700">{this.state.reportData.thingName}</span>
                        </p>
                    </div>
                </div>
                <div className="col-12">
                    <Calendar id="minmax" value={this.state.date4} onChange={(e) => this.handleDateChenge(e)} maxDate={this.maxDate} showIcon readOnlyInput />
                </div>
                <div className="col-12 lg:col-4">
                    <div className="card mb-3 vehicle_info">
                        <p className="text-900">
                            Start Time:
                            <span> {moment(this.state.reportData["Start Time"], "YYYY-MM-DD HH:mm:ss").format("ddd, hA")}</span>
                        </p>
                        <p className="text-900">
                            End Time:
                            <span> {moment(this.state.reportData["End Time"], "YYYY-MM-DD HH:mm:ss").format("ddd, hA")}</span>
                        </p>
                        <p className="text-900">
                            Total Travel Time:
                            <span> {this.state.reportData["Total Travel Time"]}</span>
                        </p>
                        <p className="text-900">
                            Distance Travelled:
                            <span> {this.state.reportData["Total Distance Travelled"] + " km/hr"}</span>
                        </p>
                        <p className="text-900">
                            Stoppage Point:
                            <span> {this.state.reportData["Stoppage Points"].length}</span>
                        </p>
                        <p className="text-900">
                            Total Stoppage Period:
                            <span> {minToHrMin(this.state.reportData["Total Stoppage Time"])}</span>
                        </p>
                    </div>
                </div>
                <div className="col-12 lg:col-8">
                    <div className="card p-0">
                        <div ref={this.mapRef} style={{ width: "100%", height: "280px", overflow: "hidden" }}></div>
                    </div>
                </div>
                <div className="col-12">
                    <Accordion>
                        <AccordionTab header="Stoppage Details">
                            <div className="grid">
                                <div className="col-12 lg:col-4">
                                    <div className="card mb-3 vehicle_info">
                                        <p className="text-900">
                                            Total Designated Stoppages:
                                            <span className="text-green-600"> {this.state.reportData["Designated Stoppage Points"].length}</span>
                                        </p>
                                        <p className="text-900">
                                            Total Undesignated Stoppages:
                                            <span className="text-pink-600"> {this.state.reportData["UnDesignated Stoppage Points"].length}</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 lg:col-8">
                                    <div className="card">
                                        <DataTable value={this.state.stoppagePoints} responsiveLayout="scroll">
                                            {/* <Column header="Sl.No." field="index"></Column> */}
                                            <Column header="Stoppage" body={this.handStoppage}></Column>
                                            <Column header="Stoppage Time" field="ts"></Column>
                                            <Column header="Stoppage Duration" body={this.handleduration}></Column>
                                            <Column header="Idling Duration" body={this.handleIdling}></Column>
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </AccordionTab>
                        <AccordionTab header="Performance and Diagnostics">
                            <p>
                                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
                                aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Consectetur, adipisci velit, sed quia non numquam eius modi.
                            </p>
                        </AccordionTab>
                    </Accordion>
                </div>
            </div>
        ) : (
            <div className="grid">
                <div className="col-12 lg:col-4">
                    <Skeleton height="322px" className="mb-2"></Skeleton>
                </div>
                <div className="col-12 lg:col-8">
                    <Skeleton height="322px" className="mb-2"></Skeleton>
                </div>
                <div className="col-12">
                    <Skeleton height="50px" className="mb-2"></Skeleton>
                    <Skeleton height="50px" className="mb-2"></Skeleton>
                </div>
            </div>
        );
}

export default VehicleReportComponent;