
import { Route } from 'react-router-dom';
import ThingsList from './pages/waterQuality/wsThings';
import Calibration from './pages/waterQuality/calibration';
import VehicleListComponent from './pages/vehicleTraker/VehicleListComponent';
import FuelReport from './pages/fuelMonitoring/FuelReport';
import WaterQuality from './pages/waterQuality/WaterQuality';
import VehicleOBDList from './pages/vehicleOBDMonitoring/VehicleOBDList';
// import VehicleOBDHistory from './pages/vehicleOBDMonitoring/VehicleOBDHistory';
const AuthorizedRoutes = () => {
    return (
        <>
            <Route path="/wqThings" component={ThingsList} />
            <Route path="/calibration" component={Calibration} />
            <Route path="/dashboard" render={() => <VehicleListComponent />} />
            <Route path="/vehicle-traking" render={() => <VehicleListComponent />} />
            <Route path="/vehicle-tracking-obd" render={() => <VehicleOBDList />} />
            <Route path="/fuel-monitoring" component={FuelReport} />
            <Route path="/water-quality-dashboard" render={() => <WaterQuality />} />
        </>
    )
}

export default AuthorizedRoutes;
