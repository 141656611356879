export function convertValue(value, type, outputMetrix){
    var res = "";
    if(outputMetrix === "MKS"){
        switch(type){
            case "temperature":
                res = value > 0 ? value : value;
                break;   
            case "speed": 
                res = value > 0 ? value : value;
            break;
            case "distance":
                res = value > 0 ? value : value;
                break;
            case "number":
                res = value > 0 ? value : value;
                break;
            case "percent":
                res = value > 0 ? value : value;
                break;
            default: 
                res = value;
                break;
        }
    }else if(outputMetrix === "FPS" ) {
        switch(type){
            case "temperature":
                res = value > 0 ? ((value * 9/5) +32).toFixed(1) : value;
                break;   
            case "speed": 
                res = value > 0 ? (value / 1.609).toFixed(1) : value;
            break;
            case "distance":
                res = value > 0 ? (value / 1.609).toFixed(1) : value;
                break;
            case "number":
                res = value > 0 ? value : value;
                break;
            case "percent":
                res = value > 0 ? value : value;
                break;
            default: 
                res = value;
                break;
        }
    }
    return res;
}