const httpConstants = {
    METHOD_TYPE: {
        POST: 'POST',
        PATCH: 'PATCH',
        PUT: 'PUT',
        GET: 'GET',
        DELETE: 'DELETE',
    },
    CONTENT_TYPE: {
        APPLICATION_JSON: 'application/json',
        MULTIPART_FORM_DATA: 'multipart/form-data',
        APPLICATION_FORM_URLENCODED: 'application/x-www-form-urlencoded',
        IMAGE_PNG: 'image/png'
    },
    DEVICE_TYPE: {
        WEB: 'web'
    },
    API_END_POINT: {
        GET_USER_PERMISSIONS: '/get-user-permission',
        GET_SERVICES_MENU: '/get-menu-option'
    },
    combinatorValue: [
        { name: "AND", code: "$and" },
        { name: "OR", code: "$or" },
    ],
    parameterValues: [
        { name: 'Tower Type', code: 'towerDetails.towerConfig.towerType' },
        { name: 'Tower Height', code: 'towerDetails.towerHeight.heightfromBase' },
        { name: 'Tenancy Count', code: 'towerDetails.tenancyCount' },
        { name: 'State', code: 'siteDetails.location.state' },
        { name: 'City', code: 'siteDetails.location.city' },
        { name: 'Zipcode', code: 'siteDetails.location.zipcode' },
        { name: 'Wind Zone', code: 'siteDetails.environmental.windZone' },
        { name: 'Seismic Zone', code: 'siteDetails.environmental.seismicZone' },
        { name: 'Tower Load(Wind Load adjusted)', code: 'towerDetails.capacity.usedLoadCapacityWithWindLoad' },
    ],
    operator_Value: [
        { name: "Equal To", code: "$eq" },
        { name: "Greater Than", code: "$gt" },
        { name: "Less Than", code: "$lt" },
        { name: "Contains", code: "$in" },
        { name: "Greater Than Equal", code: "$gte" },
        { name: "Less Than Equal", code: "$lte" },
    ],
    for_alpha_numeric_operator: [
        { name: "Equal To", code: "$eq" },
        { name: "Contains", code: "$in" },
    ],
    for_numeric_operator: [
        { name: "Equal To", code: "$eq" },
        { name: "Greater Than", code: "$gt" },
        { name: "Less Than", code: "$lt" },
        { name: "Greater Than Equal", code: "$gte" },
        { name: "Less Than Equal", code: "$lte" },
    ],
    tower_types: [
        { name: "GBT", code: "GBT" },
        { name: "RTT", code: "RTT" },
        { name: "GBM", code: "GBM" },
        { name: "RTP", code: "RTP" },
    ]
};

const pHValues = [
    { action: "calibph4", name: "pH 4" },
    { action: "calibph7", name: "pH 7" },
    { action: "calibph10", name: "pH 10" }
];

const ecValues = [
    { action: "calibec1413", name: "EC 1413 uS" },
    { action: "calibec12880", name: "EC 12880 us" },
];

module.exports = {
    httpConstants,
    pHValues,
    ecValues
}