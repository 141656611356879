import React, { Component } from "react";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Image } from 'primereact/image';
import { Ripple } from 'primereact/ripple';
import { VehicleSrvice } from "../../service/VehicleService";
import { mapKey } from "../../utilities/constant";
import { getMax, getMin } from "../../utilities/utillFunction";
import moment from 'moment';

const map_start_icon = '<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64"><path style="fill:#01010100; stroke:none;" d="M0 0L0 64L64 64L64 0L0 0z"/><path style="fill:#00A42F; stroke:none;" d="M31 60L33 60C38.9485 49.275 51.7089 35.8509 51.8164 23C51.9872 2.56881 22.1996 -3.31481 13.9699 15C7.39209 29.6386 23.7505 48.1572 31 60z"/><path style="fill:#f73a3a; stroke:none;" d="M21 6L22 7L21 6M42 6L43 7L42 6z"/><path style="fill:#FFFFFF; stroke:none;" d="M28.1088 16.0278C20.0658 20.5369 26.7819 33.892 34.956 29.8056C44.4446 25.0619 37.288 10.8817 28.1088 16.0278z"/><path style="fill:#ff0101; stroke:none;" d="M24 25L25 26L24 25M39 25L40 26L39 25M49 31L50 32L49 31M48 33L49 34L48 33M47 35L48 36L47 35z"/><path style="fill:#f73a3a; stroke:none;" d="M19 40L20 41L19 40z"/><path style="fill:#ff0101; stroke:none;" d="M43 42L44 43L43 42z"/><path style="fill:#e3641d; stroke:none;" d="M22 45L23 46L22 45z"/><path style="fill:#ff0101; stroke:none;" d="M38 50L39 51L38 50z"/><path style="fill:#e3641d; stroke:none;" d="M33 58L34 59L33 58z"/></svg>';

const map_end_icon = '<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64"><path style="fill:#01010100; stroke:none;" d="M0 0L0 64L64 64L64 0L0 0z"/><path style="fill:#F14003; stroke:none;" d="M31 60L33 60C38.9485 49.275 51.7089 35.8509 51.8164 23C51.9872 2.56881 22.1996 -3.31481 13.9699 15C7.39209 29.6386 23.7505 48.1572 31 60z"/><path style="fill:#f73a3a; stroke:none;" d="M21 6L22 7L21 6M42 6L43 7L42 6z"/><path style="fill:#FFFFFF; stroke:none;" d="M28.1088 16.0278C20.0658 20.5369 26.7819 33.892 34.956 29.8056C44.4446 25.0619 37.288 10.8817 28.1088 16.0278z"/><path style="fill:#ff0101; stroke:none;" d="M24 25L25 26L24 25M39 25L40 26L39 25M49 31L50 32L49 31M48 33L49 34L48 33M47 35L48 36L47 35z"/><path style="fill:#f73a3a; stroke:none;" d="M19 40L20 41L19 40z"/><path style="fill:#ff0101; stroke:none;" d="M43 42L44 43L43 42z"/><path style="fill:#e3641d; stroke:none;" d="M22 45L23 46L22 45z"/><path style="fill:#ff0101; stroke:none;" d="M38 50L39 51L38 50z"/><path style="fill:#e3641d; stroke:none;" d="M33 58L34 59L33 58z"/></svg>';

class VehicleLiveDetailsComponent extends Component {
    mapRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            vehicleDetails: null
        }
        this.vehicleService = new VehicleSrvice();
        this.panelHeaderTemplate = this.panelHeaderTemplate.bind(this);
    }

    panelHeaderTemplate = (options) => {
        const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
        const className = `${options.className} justify-content-between`;
        const titleClassName = `${options.titleClassName} pl-1`;

        return (
            <div className={className}>
                <span className={titleClassName}>
                    Traking Basic Details
                </span>
                <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        )
    }

    componentDidMount = () => {
        const data = this.props.vehicle
        const origin = `${this.props.vehicle.locations[0].lat},${this.props.vehicle.locations[0].lng}`;
        const destination = `${this.props.vehicle.locations[this.props.vehicle.locations.length - 1].lat},${this.props.vehicle.locations[this.props.vehicle.locations.length - 1].lng}`;
        Promise.all([
            this.vehicleService.getLatLongDetails(origin), 
            this.vehicleService.getLatLongDetails(destination)
        ]).then(response => {
            data.startAddress = response[0] ? response[0].items[0].address : '-';
            data.endAddress = response[1] ? response[1].items[0].address : '-';
            this.setState({
                vehicleDetails: data
            }, () => {
                console.log(this.state.vehicleDetails);
                this.getVehicleRealTimeDetailedMap(data);
            })
        }); 
    }

    getStrengthMarker = (H, map, station, contentString, defaultLayers, icon, ui, size) => {
		let group = new H.map.Group();
		map.addObject(group);
		// let ui = H.ui.UI.createDefault(map, defaultLayers);
		let marker = new H.map.Marker(station, icon, size);
		let bubble;

		marker.addEventListener(
			'tap',
			function (evt) {
				bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
					content: evt.target.getData(),
				});
				ui.addBubble(bubble);
			},
			false
		);
		// marker.addEventListener(
		// 	'pointerleave',
		// 	function (evt) {
		// 		bubble.close();
		// 	},
		// 	false
		// );
		marker.setData(contentString);

		group.addObject(marker);
		return marker;
	}

    getVehicleRealTimeDetailedMap = (data) => {
        const H = window.H;
        const platform = new H.service.Platform({
            apikey: mapKey
        });
        const defaultLayers = platform.createDefaultLayers();

        const startLocation = data.locations[0];
		const endLocation = data.locations[data.locations.length - 1];

        const map = new H.Map(
            this.mapRef.current, 
            defaultLayers.vector.normal.map, 
            {
                center: { lat: endLocation.lat, lng: endLocation.lng },
                zoom: 12,
                pixelRatio: window.devicePixelRatio || 1
            }
        );

        let wayPoints = [];
		for (let i = 0; i < data.locations.length; i++) {
			wayPoints.push(
				`via=${data.locations[i].lat},${data.locations[i].lng}`
			);
		}
        
        window.addEventListener('resize', () => map.getViewPort().resize());
        new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
        const ui = H.ui.UI.createDefault(map, defaultLayers);

        let markers = [];
        const startIcon = new H.map.Icon(map_start_icon);
		const endIcon = new H.map.Icon(map_end_icon);

        for (let i = 0; i < data.locations.length; i++) {
			let mark;
			let contentString = '';
			if (i === 0) {
				contentString =
					'<div id="vehicle-content">' +
					'<div id="vehicle-info">' +
					'<div id="info">' +
					`${'Start Address'}: ` +
					`${data.startAddress.label}` +
					' </div>' +
					'<div id="info">' +
					`${'Speed'}: ` +
					`${data.locations[i].speed}` +
					' </div>' +
					'</div>' +
					'</div>';
                
				mark = this.getStrengthMarker(
					H,
					map,
					data.locations[i],
					contentString,
					defaultLayers,
					{ icon: startIcon },
					ui,
					{ h: 10, w: 10 }
				);
			} else if (i === data.locations.length - 1) {
				contentString =
					'<div id="vehicle-content">' +
					'<div id="vehicle-info">' +
					'<div id="info">' +
					`${'End Address'}: ` +
					`${data.endAddress.label}` +
					' </div>' +
					'<div id="info">' +
					`${'Speed'}: ` +
					`${data.locations[i].speed}` +
					' </div>' +
					'</div>' +
					'</div>';

				mark = this.getStrengthMarker(
					H,
					map,
					data.locations[i],
					contentString,
					defaultLayers,
					{ icon: endIcon },
					ui,
					{ h: 10, w: 10 }
				);
			}

			markers.push(mark);
		}

        let distance = 0;
        this.vehicleService.getRoute(`${startLocation.lat},${startLocation.lng}`, `${endLocation.lat},${endLocation.lng}`, wayPoints.join('&')).then((response) => {
            if(response.routes[0] && response.routes[0].sections && response.routes[0].sections.length) {
                response.routes[0].sections.forEach(s => { distance = distance + s.summary.length; })
            }
            this.addRouteShapeToMap(response.routes[0], H, map);
            this.addManueversToMap(response.routes[0], H, map);
            data.totalDistance = distance ? (distance / 1000).toFixed(1) : 0;
            data.totalTime = moment(data.locations[data.locations.length - 1].ts).diff(moment(data.locations[0].ts), 'hours');
        });

        this.setState({ vehicleDetails: data });
    }

    addRouteShapeToMap(route, H, map) {
		route.sections.forEach((section) => {
			let linestring = H.geo.LineString.fromFlexiblePolyline(
				section.polyline
			);
			let polyline = new H.map.Polyline(linestring, {
				style: {
					lineWidth: 5,
					strokeColor: 'rgba(10, 3, 241, 1)',
				},
			});
			map.addObject(polyline);
		})
	}

    addManueversToMap = (route, H, map) => {
		var svgMarkup = '<svg width="18" height="18" ' + 'xmlns="http://www.w3.org/2000/svg">' + '<circle cx="6" cy="6" r="5" '+'fill="#1b468d" stroke="white" stroke-width="1" />' + '</svg>',
		  	dotIcon = new H.map.Icon(svgMarkup, { anchor: { x: 6, y: 6 } }),
		  	group = new H.map.Group(), i;
	
		route.sections.forEach((section) => {
		  	let poly = H.geo.LineString.fromFlexiblePolyline(section.polyline).getLatLngAltArray();
	
			let actions = section.actions;
			// Add a marker for each maneuver
			for (i = 0; i < actions.length; i += 1) {
				let action = actions[i];
				var marker = new H.map.Marker({
				lat: poly[action.offset * 3],
				lng: poly[action.offset * 3 + 1]
				},
				{ icon: dotIcon });
				group.addObject(marker);
			}
		
			group.addEventListener('tap', function (evt) {
				this.map.setCenter(evt.target.getGeometry());
				this.openBubble(evt.target.getGeometry(), evt.target.instruction);
			}, false);
		
			// Add the maneuvers group to the map
			map.addObject(group);
		});
	}

    render = () => {
        return (
            <div className="grid">
                <div className="col-12 md:col-6 lg:col-12">
                    {this.state.vehicleDetails ? (
                        <>
                            <div className="grid">
                                <div className="col-12">
                                    <div className="flex justify-content-between">
                                        <p className="px-2 m-0"
                                            onClick={this.props.reportBack}>
                                            <span className="pi pi-arrow-left"></span>Back
                                        </p>
                                        <p className="px-2">Vehicle ID: <span className="text-blue-700">{this.state.vehicleDetails.thingName}</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="grid mb-3">
                                <div className="col-12 lg:col-4">
                                    <div className="card vehicle_info">
                                        <p className="font-semibold text-900">
                                            Last Location: <br/>
											<div style={{color: 'var(--blue-600)'}}> {this.state.vehicleDetails.endAddress.label}</div>
										</p>
										<p className="font-semibold text-900">
                                            Ignition Status: 
                                            {
                                                this.state.vehicleDetails.locations[this.state.vehicleDetails.locations.length -1].ignition === 1 ? (<span className="text-green-600">ON</span>) : (<span className="text-pink-600">OFF</span>)
                                            }
										</p>
                                        <p className="font-semibold text-900">
                                            Total Distance Travelled: 
											<span> {this.state.vehicleDetails.totalDistance ? this.state.vehicleDetails.totalDistance + " Km" : "--"}</span>
										</p>
                                        <p className="font-semibold text-900">
                                            Total Time travelled: 
											<span> {this.state.vehicleDetails.totalTime ? this.state.vehicleDetails.totalTime + " Hr" : "--"}</span>
										</p>
                                        <p className="font-semibold text-900">
                                            Max Speed: 
											<span> {getMax(this.state.vehicleDetails.locations, "speed") + " Km/Hr"}</span>
										</p>
                                        <p className="font-semibold text-900">
                                            Avg Speed: 
											<span>
                                                {
                                                   this.state.vehicleDetails.totalDistance &&  this.state.vehicleDetails.totalTime ? ((this.state.vehicleDetails.totalDistance / this.state.vehicleDetails.totalTime).toFixed(2) + " Km/Hr") : '--'
                                                }
                                            </span>
										</p>
                                    </div>
                                </div>
                                <div className="col-12 lg:col-8">
                                    <div className="card p-0 mb-3">
                                        <div
                                            ref={this.mapRef}
                                            style={{
                                                width: "100%",
                                                height: "280px",
                                                overflow: "hidden",
                                            }}
                                        ></div>
                                    </div>
                                    
                                </div>
                            </div>
                            <Accordion >
                                <AccordionTab header="Vehicle Information">
                                    <div className="grid">
                                        <div className="col-12 lg:col-4">
                                            <div className="card" style={{position: 'relative'}}>
                                                <Image src="images/demo_vehicle.jpg" alt="Image" width="100%" preview />
                                                <div className="overlay_information">
                                                    <p className="text-100 flex-1">Name: <br/><span className="text-blue-600">{this.state.vehicleDetails.thingName}</span></p>
                                                    <p className="text-100 flex-1">Make: <br/><span className="text-blue-600">{this.state.vehicleDetails.make}</span></p>
                                                    <p className="text-100 flex-1">Model:<br/> <span className="text-blue-600">{this.state.vehicleDetails.model}</span></p>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </AccordionTab>
                                <AccordionTab header="Performance and Diagnostics">
                                    <p>
                                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
                                        aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Consectetur, adipisci velit, sed quia non numquam eius modi.
                                    </p>
                                </AccordionTab>
                            </Accordion>
                        </>
                    ) : null}
                </div>
            </div>
        );
    }
}

export default VehicleLiveDetailsComponent;