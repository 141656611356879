import React, { Component } from "react";
import { VehicleSrvice } from "../../service/VehicleService";
import { mapKey } from "../../utilities/constant";
import VehicleDetailsComponent from "./VehicleDetailsComponent";

const svgMarkup = '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"><path style="fill:#01010100; stroke:none;" d="M0 0L0 32L32 32L32 0L0 0z"/><path style="fill:#e84d3d; stroke:none;" d="M15 26L17 26C19.6885 21.918 23.6112 17.9434 24.5918 13C26.4889 3.43692 13.1676 -2.78313 8.17901 6.1088C4.21305 13.1779 11.2793 20.6202 15 26z"/><path style="fill:#ffffff; stroke:none;" d="M15.2593 8.05941C12.4857 8.60038 13.1027 13.3731 15.9954 12.963C19.1715 12.5127 18.4131 7.44428 15.2593 8.05941z"/><path style="fill:#f48221; stroke:none;" d="M24 7L25 8L24 7M10 19L11 20L10 19M19 22L20 23L19 22z"/><path style="fill:#c0a7a5; stroke:none;" d="M9 23L10 24L9 23z"/><path style="fill:#bcc5c800; stroke:none;" d="M17 27L15 27C12.816 23.4744 3.67966 22.9556 6.17593 27.6242C8.18558 31.3828 25.24 31.7858 26.0787 26.9051C27.0422 21.2976 18.5828 24.1389 17 27z"/><path style="fill:#c0a7a5; stroke:none;" d="M13 23L14 24L13 23M18 23L19 24L18 23z"/><path style="fill:#f48221; stroke:none;" d="M14 24L15 25L14 24M17 24L18 25L17 24z"/><path style="fill:#c0a7a500; stroke:none;" d="M14 25L15 26L14 25M4 26L5 27L4 26M15.6667 26.3333L16.3333 26.6667L15.6667 26.3333M27 26L28 27L27 26z"/></svg>';

class VehicleListComponent extends Component {
    mapRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            map: null,
            markers: null, 
            position: [
                { lat: 37.773905, lng: -122.422912 },
                { lat: 37.773331, lng: -122.424003 },
                { lat: 37.773403, lng: -122.423583 },
                { lat: 37.772031, lng: -122.422367 }
            ],
            vehicles: null,
            detailsReport: false,
            vehicleDetails: null
        };
        this.vehicleService = new VehicleSrvice();
    }
    componentDidMount = () => {
        this.vehicleService.getAllCurrentVehicle('pm9yoq9v7z').then((response) => {
            if(response.success) {
                let vehicles = response.responseData;
                let currentPosition = [];

                vehicles.forEach(item => {
                    currentPosition.push({
                        lat: item.locations[item.locations.length - 1].lat,
                        lng: item.locations[item.locations.length - 1].lng,
                        name: item.thingName,
                        make: item.make,
                        model: item.model,
                        ts: item.locations[item.locations.length - 1].ts,
                        ignition: item.locations[item.locations.length - 1].ignition,
                        speed: item.locations[item.locations.length - 1].speed
                    });
                });

                const H = window.H;
                const platform = new H.service.Platform({
                    apikey: mapKey
                });
                const defaultLayers = platform.createDefaultLayers();
                const map = new H.Map(
                    this.mapRef.current,
                    defaultLayers.vector.normal.map,
                    {
                        center: {lat: this.state.position[0].lat, lng: this.state.position[0].lng}, 
                        zoom: 5,
                        pixelRatio: window.devicePixelRatio || 1
                    }
                );
                const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
                const ui = H.ui.UI.createDefault(map, defaultLayers);
                window.addEventListener('resize', () => map.getViewPort().resize());

                let markers = [];
                for (let i = 0; i < currentPosition.length; i++) {
                    let mark;
                    let station = {lat: currentPosition[i].lat , lng: currentPosition[i].lng, ts: currentPosition[i].ts, speed: currentPosition[i].speed, ignition: currentPosition[i].ignition};
                    let contentString =
                        '<div id="vehicle-content">' +
                        '<div id="vehicle-info">' +
                        '<div id="info">' +
                        `${'Vehicle Name'}: ` +
                        `${currentPosition[i].name}` +
                        ' </div>' +
                        '<div id="info">' +
                        `${'Speed'}: ` +
                        `${currentPosition[i].speed} km/hr` +
                        ' </div>' +
                        '</div>' +
                        '</div>';

                        mark = this.getStrengthMarker(
                            H,
                            map,
                            station,
                            contentString,
                            defaultLayers,
                            ui,
                            { h: 10, w: 10 }
                        );

                    markers.push(mark);
                }    

                this.setState({map, position: currentPosition, vehicles, markers}, () => {
                    map.setCenter({lat: this.state.position[0].lat, lng: this.state.position[0].lng});
                });
            }
        })
        
    }


    getStrengthMarker = (H, map, station, contentString, defaultLayers, ui, size) => {
		let group = new H.map.Group();
		map.addObject(group);
        let bearsIcon = new H.map.Icon(svgMarkup);
		let marker = new H.map.Marker(station, {icon: bearsIcon}, size);
		let bubble;

		marker.addEventListener(
			'tap',
			function (evt) {
				bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
					content: evt.target.getData(),
				});
				ui.addBubble(bubble);
			},
			false
		);
		marker.setData(contentString);
		group.addObject(marker);
		return marker;
	}

    hendelVehicleClick = (item) => {
        this.state.map.setCenter({lat: item.locations[item.locations.length - 1].lat, lng: item.locations[item.locations.length - 1].lng});
        this.state.map.setZoom(8);
    }

    showVehicleDetail = (item) => {
        this.setState({detailsReport: true, vehicleDetails: item});
    }

    reportBack = () => {
        this.setState({detailsReport: false, vehicleDetails: null});
    }

    render = () => {
        return(
                this.state.detailsReport ? (
                    <VehicleDetailsComponent vehicle={this.state.vehicleDetails} reportBack={this.reportBack} />
                ) : (
                    <div className="grid grid-nogutter flex-row-reverse">
                        <div className="col-12">
                            <h3 style={{margin: '0', textAlign: 'center', padding: '10px 0'}}>Live Vehicle Report</h3>
                        </div>
                        <div className="col-12 md:col-6 lg:col-8">
                            <div className="card p-0">
                                <div
                                    ref={this.mapRef}
                                    className="list_map_grid"
                                ></div>
                            </div>
                        </div>
                        <div className="col-12 md:col-6 lg:col-4">
                            <div className="vehicle_list_grid">
                                {
                                    this.state.vehicles ? (
                                        this.state.vehicles.map(item => (
                                            <div className="card report_opt_card mb-3" 
                                                key={item.thingName} 
                                                onPointerEnter={ () => this.hendelVehicleClick(item) }
                                                onPointerLeave={ () => this.state.map.setZoom(5) }
                                                onClick={ () => this.showVehicleDetail(item) } 
                                            >
                                                <div className="flex">
                                                    <div className="card_ioc_grid flex justify-content-center align-items-center mr-2">
                                                        <i className="pi pi-car" style={{ fontSize: '1.7em' }}></i>
                                                    </div>
                                                    <div className="flex-1">
                                                        <p className="font-medium mb-0">{item.thingName}</p>
                                                        <p className="text-md font-medium mb-0">
                                                            Make: <span className="text-blue-500 mr-3">{item.make}</span>
                                                            Model: <span className="text-blue-500 mr-3">{item.model}</span>
                                                        </p>
                                                        <p className="text-md font-medium mb-0">
                                                            Ignition: <span className="text-green-500 mr-3">
                                                                {
                                                                    item.locations[item.locations.length - 1].ignition === 1 ? 'ON' : 'OFF'
                                                                }
                                                            </span>
                                                            Speed: <span className="text-green-500">{item.locations[item.locations.length - 1].speed} Km/h</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
            ) 
        )
    }
}

export default VehicleListComponent;