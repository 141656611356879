import React, { useContext, useEffect, useState } from 'react';
import { classNames } from 'primereact/utils';
import { useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { RTLContext } from './App';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
const poolData = {
    UserPoolId: process.env.REACT_APP_USER_POOL_ID,
    ClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENTID
}
const userPool = new CognitoUserPool(poolData)
const AppTopbar = (props) => {
    let nqpermissions = JSON.parse(sessionStorage.getItem('permissions'));
    const [isAdmin, setIsAdmin] = useState(false);
    const isRTL = useContext(RTLContext);
    const history = useHistory();

    useEffect(() => {
        if (nqpermissions && nqpermissions.length) {
            let userPersmissions = nqpermissions[0].permissions.find(p => { return p === "UserManagement.WRITE" })
            if (userPersmissions) {
                setIsAdmin(true);
            }
        }
    }, [])

    useEffect(() => {
        // Fixed for 6.1.0
        /*if (props.searchActive) {
            searchPanel.current.element.focus();
        }*/
    }, [props.searchActive])

    const goLogin = () => {
        history.push('/login');
    }

    const signout = async () => {
        cognitoSignout();

        sessionStorage.removeItem("token");
        sessionStorage.removeItem("username");
        sessionStorage.removeItem("email");
        sessionStorage.removeItem("serviceId");
        sessionStorage.removeItem('menu');
        goLogin();
    }

    const cognitoSignout = () => {
        let userData = {
            Username: sessionStorage.getItem('email'),
            Pool: userPool,
        };

        let cognitoUser = new CognitoUser(userData);
        cognitoUser.signOut();
    }

    const onServiceChange = () => {
        history.push("/services");
    }

    const onAdminActivityClick = () => {
        window.open(process.env.REACT_APP_PREVIOUS_WEBAPP_URL, "_blank", 'noopener,noreferrer')
    }

    return (
        <div className="layout-topbar shadow-4">
            <div className="layout-topbar-left">
                <button type="button" style={{ cursor: 'pointer' }} className="layout-topbar-logo p-link" onClick={() => history.push('/')}>
                    <img id="app-logo" src={process.env.PUBLIC_URL + "/assets/layout/images/logo-light.png"} alt="ultima-layout" style={{ height: '2.25rem' }} />
                </button>
                <button type="button" className="layout-menu-button shadow-6 p-link" onClick={props.onMenuButtonClick}>
                    <i className="pi pi-chevron-right"></i>
                </button>
                <button type="button" className="layout-topbar-mobile-button p-link">
                    <i className="pi pi-ellipsis-v fs-large" onClick={props.onMobileTopbarButtonClick}></i>
                </button>
            </div>

            <div className={classNames('layout-topbar-right', { 'layout-topbar-mobile-active': props.mobileTopbarActive })}>
                {/* <div className="layout-topbar-actions-left"> */}
                {/* <MegaMenu model={model} className="layout-megamenu" /> */}
                {/* </div> */}
                <div className=" ml-auto layout-topbar-actions-right">
                    <ul className="layout-topbar-items">

                        <li className="layout-topbar-item">
                            <button className="layout-topbar-action flex flex-row justify-content-center align-items-center px-2 rounded-circle p-link" onClick={(event) => props.onTopbarItemClick({ originalEvent: event, item: 'profile' })}>
                                <i className="pi pi-user fs-large"></i>
                            </button>

                            <CSSTransition classNames="p-toggleable" timeout={{ enter: 1000, exit: 450 }} in={props.activeTopbarItem === 'profile'} unmountOnExit>
                                <ul className="layout-topbar-action-panel shadow-6" style={{ borderTop: "1px solid #e4e4e4", padding: "0" }}>
                                    <li className="layout-topbar-action-item" style={{ borderBottom: "1px solid #e4e4e4" }}>
                                        <div className="flex flex-row p-link">
                                            <div className='col-12 '>
                                                <div className='font-bold text-center'>{sessionStorage.getItem('username')}</div>
                                                <div className='text-center'>{sessionStorage.getItem('email')}</div>
                                            </div>


                                        </div>
                                    </li>

                                    <li className="layout-topbar-action-item">
                                        <button className="flex flex-row align-items-center p-link" onClick={onServiceChange}>
                                            <i className="fa-solid fa-arrows-spin mr-2"></i>
                                            <span>Change Service</span>
                                        </button>
                                    </li>
                                    {
                                        isAdmin ?

                                            <li className="layout-topbar-action-item">
                                                <button className="flex flex-row align-items-center p-link" onClick={onAdminActivityClick}>
                                                    <i className="fa-regular fa-user mr-2"></i>
                                                    <span>Admin Activity</span>
                                                </button>
                                            </li>
                                            : null
                                    }
                                    <li className="layout-topbar-action-item" onClick={signout}>
                                        <button className="flex flex-row align-items-center p-link">
                                            <i className={classNames('pi pi-power-off', { 'mr-2': !isRTL, 'ml-2': isRTL })}></i>
                                            <span>Logout</span>
                                        </button>
                                    </li>
                                </ul>
                            </CSSTransition>
                        </li>
                    </ul>
                </div>
            </div>
        </div >
    );

}

export default AppTopbar;
