import React, { Component } from "react";
import { TabView, TabPanel } from 'primereact/tabview';
import VehicleLiveDetailsComponent from "./VehicleLiveDetailsComponent";
import VehicleReportComponent from "./VehicleReportComponent";

class VehicleDetailsComponent extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            name: null
        }
    }

    componentDidMount = () => {
        this.setState({name: this.props.vehicle.thingName});
    }

    render = () => {
        return (
            <div className="grid">
                <div className="col-12">
                    <TabView>
                        <TabPanel header="Live">
                            <VehicleLiveDetailsComponent vehicle={this.props.vehicle} reportBack={this.props.reportBack} />
                        </TabPanel>
                        <TabPanel header="History">
                            <VehicleReportComponent thingName={this.state.name} reportBack={this.props.reportBack} />
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        );
    }
}

export default VehicleDetailsComponent;