import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';
import { CognitoUserPool, AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';

const poolData = {
	UserPoolId: process.env.REACT_APP_USER_POOL_ID,
	ClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENTID
}

const userPool = new CognitoUserPool(poolData)

const Login = () => {
	// const dispatch = useDispatch();
	const [email, setEmail] = useState(null);
	const [password, setPassword] = useState(null);
	const [errMsg, setErrMsg] = useState(null);
	const [disableBtn, setBtnDisabled] = useState(false);
	const [passwordType, setPasswordType] = useState("password");

	const history = useHistory();

	const userLogin = async (e) => {
		setBtnDisabled(true);
		let loginResponse = await cognitoLogin();
		if (!loginResponse || !loginResponse.accessToken || !loginResponse.idToken || !loginResponse.idToken.payload.sub || !loginResponse.idToken.payload.email)
			return;
		sessionStorage.setItem("token", loginResponse.idToken.jwtToken);
		sessionStorage.setItem("username", loginResponse.idToken.payload.name);
		sessionStorage.setItem("email", loginResponse.idToken.payload.email);
		// history.push("/wqThings");
		// window.open("http://localhost:3002/towers/portfolioanalysis?token=" +loginResponse.idToken.jwtToken + "&serviceId=lj0f975vfe&mobile=true", "_blank")
		history.push("/services");
	}
	const cognitoLogin = async () => {
		let loginDetails = {
			Username: email,
			Password: password
		}
		let authenticationDetails = new AuthenticationDetails(loginDetails)

		let userData = {
			Username: email,
			Pool: userPool,
		};

		let cognitoUser = new CognitoUser(userData);

		return await new Promise((resolve, reject) => {
			cognitoUser.authenticateUser(authenticationDetails, {
				onSuccess: data => {
					resolve(data);
					setBtnDisabled(true);
				},
				onFailure: err => {
					reject(err.message);

					if (err.message) {
						setErrMsg(err.message);
						setBtnDisabled(false);
					}
				}
			})
		})
	}

	const togglePassword = (e) => {
		if (passwordType === "password") {
			setPasswordType("text")
			return;
		}
		setPasswordType("password")
	}

	return (
		<div className="pages-body flex flex-column login-img">
			<div className="align-self-center mt-auto mb-auto">
				<div className="pages-panel card flex flex-column">
					<div className="Row-fixing">
						<img alt="" src="./images/nexLogo.svg" className="logo-fixing" /><br /><br />
					</div>
					<div className="pages-detail mb-6 px-6">Sign in to your Nextqore account</div>
					<form onSubmit={userLogin}>
						<div className="input-panel flex flex-column px-3">
							<div className="p-inputgroup">
								<span className="p-inputgroup-addon">
									<i className="pi pi-envelope"></i>
								</span>
								<span className="p-float-label">
									<InputText type="text" id="inputgroup1" onChange={(e) => setEmail(e.target.value)} />
								</span>
							</div>
							<div className="p-inputgroup mt-3 mb-3">
								<span className="p-inputgroup-addon">
									<i className="pi pi-lock"></i>
								</span>
								<span className="p-float-label">
									<InputText style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }} type={passwordType} id="inputgroup2" onChange={(e) => setPassword(e.target.value)} />

									<div className="p-inputgroup-addon" onClick={togglePassword}>
										{passwordType === "password" ? <i className="pi pi-eye-slash"></i> : <i className="pi pi-eye"></i>}
									</div>
								</span>
							</div>

							{/* <div>
								<div className='blue-text mb-3' style={{float: "right", color: "#0c6291"}} onClick={() => onforgotPassword()}>Forgot Password ?</div>
							</div> */}
							<div className="errorstyle">{errMsg}</div>

						</div>
						<Button className="login-button mb-3 px-3" label="LOGIN" disabled={disableBtn} onClick={userLogin}></Button>
					</form>
				</div>
			</div>
		</div>
	)
}

export default Login;
