export function getMax(arr, key) {
    const values = arr.map(object => {
        return object[key];
      });

      return Math.max(...values)
}

export function getMin(arr, key) {
    const values = arr.map(object => {
        return object[key];
      });

      return Math.min(...values)
}

export function minToHrMinSec(n) {
  let num = n;
  let hours = (num / 60);
  let rhours = Math.floor(hours);
  let minutes = (hours - rhours) * 60;
  let rminutes = Math.round(minutes);

  let hDisplay = rhours > 9 ? rhours + ":" : "0" + rhours + ":"
  let mDisplay = rminutes > 9 ? rminutes + ":" : "0" + rminutes + ":"
  let sDisplay = Math.floor(n * 60 % 60) > 9 ? Math.floor(n * 60 % 60) : "0" + Math.floor(n * 60 % 60)

  return hDisplay + mDisplay + sDisplay;
}

export function minToHrMin(n) {
  let num = n;
  let hours = (num / 60);
  let rhours = Math.floor(hours);
  let minutes = (hours - rhours) * 60;
  let rminutes = Math.round(minutes);
  return rhours + "hr " + rminutes + "min";
}

export function secondsToHms(sec) {
  if (!Number(sec)) {
      return 0
  }
  sec = Number(sec);
  let h = Math.floor(sec / 3600);
  let m = Math.floor(sec % 3600 / 60);
  let s = Math.floor(sec % 3600 % 60);

  let hDisplay = h > 9 ? h + ":" : "0" + h + ":"
  let mDisplay = m > 9 ? m + ":" : "0" + m + ":"
  let sDisplay = s > 9 ? s : "0" + s

  return hDisplay + mDisplay + sDisplay;
}

export function checkIfInSameMinute(ts1, ts2) {
  ts1 = new Date(ts1)
  ts2 = new Date(ts2)
  
  return ts1.getMinutes() === ts2.getMinutes()
}