import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';
import dbService from '../../service/dbService';
const ThingsList = () => {
    const [things, setThings] = useState(null);
    const [selThing, selectThing] = useState(null);
    const history = useHistory();

    useEffect(() => {
        getThingsForService();
    }, [])


    const getThingsForService = async () => {
        dbService.getNqItems().then((resp) => {
            if (resp && resp.length) {
                if (resp.length === 1) selectThing(resp[0].thingName);
                else setThings(resp);
            }
        }).catch(e => {
            console.error(e);
        });
    }

    const onThingClick = async (thing) => {
        selectThing(thing.thingName);
    }

    const onProbeSelection = (e) => {
        history.push('/calibration?thing=' + selThing + '&probe=' + e)
    }

    return (
        <div className='col-12'>
            {selThing ?
                <div>
                    <div className='col-12' style={{ marginTop: "4rem" }}>
                        <div className='card'>
                            <h5 className='mb-3 text-2xl'>Select the probe to calibrate</h5>

                            <div className='col-12' style={{ marginTop: "4rem" }}>
                                <div className='grid'>
                                    <div className='col-6'><Button label="pH" className="mr-2 mb-2 blue-bg" value="pH" style={{ padding: "1rem 3rem 1rem 3rem" }} onClick={() => onProbeSelection("pH")}></Button></div>
                                    <div className='col-6'><Button label="EC/TDS" className="mr-2 mb-2 blue-bg" value='EC/TDS' style={{ padding: "1rem 3rem 1rem 3rem" }} onClick={(e) => onProbeSelection("EC")}></Button></div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                :
                things && things.length ?
                    things.map((t, i) => {
                        return (
                            <div key={i} className="card" onClick={() => onThingClick(t)}>
                                <div className="col-12" style={{ marginRight: "5rem" }}>
                                    <label >Thing : </label>

                                    <label >{t.thingName}</label>
                                </div>
                            </div>
                        );
                    })
                    : null
            }

        </div>
    )
}

export default ThingsList;
