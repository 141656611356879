import React, { Component } from "react";
import { Accordion, AccordionTab } from 'primereact/accordion';
import FuelTrend from "./components/FuelTrend";
import DgBatteryTrend from "./components/DgBatteryTrend";
import FuelConsumption from "./components/FuelConsumption";
import Session from "./components/Session";
import FuellingEvent from "./components/FuellingEvent";

class FuelHistoricalReport extends Component {
    constructor(props) {
        super(props);
    }

    render = () => {
        return (
            <div className="grid">
                <div className="col-12">
                    <Accordion activeIndex={0}>
                        <AccordionTab header="Fuel Consumption Report">
                            <FuelConsumption />
                        </AccordionTab>
                        <AccordionTab header="Session Report">
                            <Session />
                        </AccordionTab>
                        <AccordionTab header="Fuelling Events">
                            <FuellingEvent />
                        </AccordionTab>
                        <AccordionTab header="Fuel Trend">
                            <FuelTrend />
                        </AccordionTab>
                        <AccordionTab header="DG Battery Trend">
                            <DgBatteryTrend />
                        </AccordionTab>
                    </Accordion>
                </div>
            </div>
        );
    }
}

export default FuelHistoricalReport;