import axios from "axios";

async function performAction(thingName,sensorType, status) {
    let url = process.env.REACT_APP_PARSER_URL + "/wqa";
    let data = {
        requestId: "654329",
        mode: "Automatic",
        action: status,
        sensorType: sensorType,
        thingName: thingName,
        serviceId: sessionStorage.getItem('serviceId')
    };
    let token =  sessionStorage.getItem('token') || null;

    const res = await axios.post(url,data, {headers: {"Authorization": "Bearer " + token }});
    return res.data;
}

export default {
    performAction,
}