import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { useLocation } from 'react-router-dom';
import { ecValues, pHValues } from '../../lib/constants';
import parserService from '../../service/parser';
import { useHistory } from 'react-router-dom';
const Calibration = () => {
    const location = new URLSearchParams(useLocation().search);
    const history = useHistory();
    const [errMsg, setErrMsg] = useState(null);
    const [value, setValue] = useState(null);
    const [action, setAction] = useState(null);
    const [currentValue, setCurrentValue] = useState(null);
    document.body.style.backgroundColor = 'white';

    const setDropdownValue = (e) => {
        setValue(e);
        setAction(e.action);
    }

    const handleCalibrationClick = () => {
        if(!action) return setErrMsg("Please select the value for calibaration");
        performCalibration();

    }
    const performCalibration = async() => {
        let probe = location.get("probe") === "pH" ? location.get("probe").toLowerCase() : "tds";
        let resp = await parserService.performAction(location.get("thing"), probe, action);
        if(resp.msg) setErrMsg(resp.msg);
        history.push('/wqThings');
    }

    const readValue = async() => {
        let value = await parserService.performAction(location.get("thing"), location.get("probe").toLowerCase(), "status");
        if(value.data){
            let valueArr = value.data.split(" ");
            if(valueArr.length) {
                setCurrentValue(valueArr[2]/100);
            }
        }
    }

    return (
        <div className='col-12 background-white' style={{ marginTop: "1rem" }}>
            <div className='col-12 lg:col-4 md:col-6'>
                <span className='mb-3 text-xl'>{"Select standard value " + location.get("probe") + " for calibartion"}</span>
            </div>
            <div className='col-12'>
                <Dropdown className='col-12 lg:col-4 md:col-6' value={value} onChange={(e) => setDropdownValue(e.value)} options={location.get("probe") === "pH" ? pHValues : ecValues} optionLabel="name" placeholder={"Select " + location.get("probe") + " value"} />
            </div>
            <div className='col-12 lg:col-4 md:col-6 grid'>
                <div className='col-8'>
                    <div className='col-12'>
                        <span className='mb-3 text-2xl'>{"Current " + location.get("probe") + " value:"}</span>

                    </div>
                    <div className='col-12'>
                        <Button label="Read" className="mr-2 mb-2 blue-bg" style={{ padding: "1rem 5rem 1rem 5rem" }} onClick={() => readValue()}></Button>
                    </div>
                </div>
                <div className='col-4'>
                    <div className='col-12'>
                        <Button label={currentValue} className="p-button-outlined mr-2 mb-2" style={{ fontSize: "2rem", padding: "1.6rem", color: "#0c6291" }} />
                    </div>

                </div>
            </div>

            <div className='col-12 lg:col-4 md:col-6'>
                <img alt="" src={location.get("probe") === "pH" ? "./images/ph_image.png" : "./images/ec_image.png"} style={{ width: "100%", height: "35vh" }} />

            </div>

            <div className='col-12 lg:col-4 md:col-6' style={{ textAlign: "center" }}>
                <Button label="Proceed For Calibration" className="mr-2 mb-2 blue-bg" onClick={() => handleCalibrationClick()}style={{ fontSize: "1,3rem", padding: "1.6rem", color: "#0c6291" }} />
            </div>
            <div className='col-12 lg:col-4 md:col-6' style={{ textAlign: "center" }}>
                <span style={{color:"red"}}>{errMsg}</span>
            </div>

        </div>

    )
}

export default Calibration;
