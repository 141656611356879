import axios from 'axios';

async function getNqItems() {
    let url = 'https://wg8cp34rch.execute-api.us-east-1.amazonaws.com/apigwdocdb/'+ sessionStorage.getItem('serviceId') + '/nq-items' ;
    const res = await axios.post(url, {"projection": {_id: 0, thingName: 1, serviceId: 1}});
    return res.data;
}

export default {
    getNqItems,
}