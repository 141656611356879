import React, { Component } from "react";
import moment from "moment";
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { FuelSrvice } from "../../../service/FuelService";

const serviceID = '3v5cgrvyo0';
class FuelConsumption extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateRange: null,
            things: null, 
            selectedThing: null, 
            fuelConsumption: null
        };
        this.fuelService = new FuelSrvice();
        this.hendelDate = this.hendelDate.bind(this);
    }
    
    componentDidMount = () => {
        let current_date = moment().format("YYYY-MM-DD");
        let previous_date = moment().subtract(6, "days").format("YYYY-MM-DD");
        this.fuelService.getThings(serviceID).then(res => {

            let things = [];
            res.responseData.forEach(item => {
                things.push({name: item, code: item });
            });

            this.setState({ 
                things: things, 
                selectedThing: things 
            }, () => this.createReport(this.state.selectedThing, current_date, previous_date));
        })
    }

    createReport = (things, current_date, previous_date) => {
        let thingArr = [];
        things.forEach(item => {
            thingArr.push({"thingName": item.name})
        });
        const payload = {
            "filter": {
                "$or": thingArr,
                "date": {"$gte": previous_date,"$lte": current_date}
            }
        };
        this.fuelService.getQuickSight(serviceID, payload).then(res => {
            this.setState({ fuelConsumption: res, dateRange: [new Date(previous_date), new Date(current_date)] })
        });
    }

    handelDateChange = (e) => {
        this.setState({ dateRange: e.value }, () => {
            if (this.state.dateRange[1] !== null) {
                this.createReport(this.state.selectedThing, moment(this.state.dateRange[1]).format("YYYY-MM-DD"), moment(this.state.dateRange[0]).format("YYYY-MM-DD"));
            }
        });
    }

    handelThingChange = (e) => {
        let current_date = moment(this.state.dateRange[1]).format("YYYY-MM-DD");
        let previous_date = moment(this.state.dateRange[0]).format("YYYY-MM-DD");
        this.setState({ selectedThing: e.value }, () => this.createReport(this.state.selectedThing, current_date, previous_date))
    }

    hendelDate = (item) => moment(item.date).format("MMM D, YYYY");

    render = () => {
        let headerGroup = (<ColumnGroup>
            <Row>
                <Column header="Date" className="bg-primary"></Column>
                <Column header="Site ID" className="bg-primary"></Column>
                <Column header="Site Name" className="bg-primary"></Column>
                <Column header="Total Run Hours" className="bg-primary"></Column>
                <Column header="Opening Fuel Level" className="bg-primary"></Column>
                <Column header="Fueling Amount" className="bg-primary"></Column>
                <Column header="Fuel Consumed" className="bg-primary"></Column>
                <Column header="De-Fueling Amount" className="bg-primary"></Column>
                <Column header="Closing Fuel Level" className="bg-primary"></Column>
            </Row>
        </ColumnGroup>);
        return (
            <div className="grid">
                <div className="col-12 lg:col-3">
                    <Calendar id="range" value={this.state.dateRange} onChange={(e) => this.handelDateChange(e)} selectionMode="range" readOnlyInput className="w-full" />
                </div>
                <div className="col-12 lg:col-3">
                    <MultiSelect
                        className="w-full"
                        value={this.state.selectedThing}
                        options={this.state.things}
                        onChange={(e) => this.handelThingChange(e)}
                        optionLabel="name"
                        placeholder="Select a Site"
                        // maxSelectedLabels={3}
                    />
                </div>
                <div className="col-12" style={{maxHeight: '300px', overflowY: 'auto'}}>
                    {this.state.fuelConsumption ? (
                        <DataTable headerColumnGroup={headerGroup} value={this.state.fuelConsumption} responsiveLayout="scroll" showGridlines stripedRows>
                            <Column 
                                className="white-space-nowrap" 
                                body={this.hendelDate}></Column>
                            <Column 
                                field="siteId" 
                                className="white-space-nowrap" 
                                ></Column>
                            <Column 
                                field="siteName" 
                                className="white-space-nowrap" 
                                ></Column>
                            <Column 
                                field="totalRunDuration" 
                                className="white-space-nowrap" 
                                ></Column>
                            <Column  
                                field="openingFuelLevel" 
                                className="white-space-nowrap" 
                                ></Column>
                            <Column 
                                field="fuellingAmount" 
                                className="white-space-nowrap" 
                                ></Column>
                            <Column 
                                field="fuelConsumed" 
                                className="white-space-nowrap text-right" 
                                ></Column>
                            <Column 
                                field="deFuellingAmount" 
                                className="white-space-nowrap text-right" 
                                ></Column>
                            <Column 
                                field="closingFuelLevel" 
                                className="white-space-nowrap text-right" 
                                ></Column>
                        </DataTable>
                    ) : null}
                </div>
            </div>
        );
    }
}

export default FuelConsumption;