import React, { Component } from "react";
import moment from "moment";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { Skeleton } from 'primereact/skeleton';
import { InputSwitch } from 'primereact/inputswitch';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import { minToHrMinSec, secondsToHms, minToHrMin } from "../../utilities/utillFunction";
import { mapKey } from "../../utilities/constant";
import { VehicleSrvice } from "../../service/VehicleService";
import { convertValue } from "../../utilities/unitConversion";

const map_start_icon = '<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64"><path style="fill:#01010100; stroke:none;" d="M0 0L0 64L64 64L64 0L0 0z"/><path style="fill:#00A42F; stroke:none;" d="M31 60L33 60C38.9485 49.275 51.7089 35.8509 51.8164 23C51.9872 2.56881 22.1996 -3.31481 13.9699 15C7.39209 29.6386 23.7505 48.1572 31 60z"/><path style="fill:#f73a3a; stroke:none;" d="M21 6L22 7L21 6M42 6L43 7L42 6z"/><path style="fill:#FFFFFF; stroke:none;" d="M28.1088 16.0278C20.0658 20.5369 26.7819 33.892 34.956 29.8056C44.4446 25.0619 37.288 10.8817 28.1088 16.0278z"/><path style="fill:#ff0101; stroke:none;" d="M24 25L25 26L24 25M39 25L40 26L39 25M49 31L50 32L49 31M48 33L49 34L48 33M47 35L48 36L47 35z"/><path style="fill:#f73a3a; stroke:none;" d="M19 40L20 41L19 40z"/><path style="fill:#ff0101; stroke:none;" d="M43 42L44 43L43 42z"/><path style="fill:#e3641d; stroke:none;" d="M22 45L23 46L22 45z"/><path style="fill:#ff0101; stroke:none;" d="M38 50L39 51L38 50z"/><path style="fill:#e3641d; stroke:none;" d="M33 58L34 59L33 58z"/></svg>';

const map_end_icon = '<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64"><path style="fill:#01010100; stroke:none;" d="M0 0L0 64L64 64L64 0L0 0z"/><path style="fill:#F14003; stroke:none;" d="M31 60L33 60C38.9485 49.275 51.7089 35.8509 51.8164 23C51.9872 2.56881 22.1996 -3.31481 13.9699 15C7.39209 29.6386 23.7505 48.1572 31 60z"/><path style="fill:#f73a3a; stroke:none;" d="M21 6L22 7L21 6M42 6L43 7L42 6z"/><path style="fill:#FFFFFF; stroke:none;" d="M28.1088 16.0278C20.0658 20.5369 26.7819 33.892 34.956 29.8056C44.4446 25.0619 37.288 10.8817 28.1088 16.0278z"/><path style="fill:#ff0101; stroke:none;" d="M24 25L25 26L24 25M39 25L40 26L39 25M49 31L50 32L49 31M48 33L49 34L48 33M47 35L48 36L47 35z"/><path style="fill:#f73a3a; stroke:none;" d="M19 40L20 41L19 40z"/><path style="fill:#ff0101; stroke:none;" d="M43 42L44 43L43 42z"/><path style="fill:#e3641d; stroke:none;" d="M22 45L23 46L22 45z"/><path style="fill:#ff0101; stroke:none;" d="M38 50L39 51L38 50z"/><path style="fill:#e3641d; stroke:none;" d="M33 58L34 59L33 58z"/></svg>';

const serviceId = 'u8u2x7hxm9';
class VehicleOBDHistory extends Component {
    mapRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            make: this.props.make,
            model: this.props.model,
            thingName: this.props.name,
            obdData: null,
            obdParams: null,
            vehicleData: null,
            reportData: null,
            stoppagePoints: null,
            geoArray: null,
            selectedThingName: null,
            chartOBD: null,
            staticOBD: null,
            unitSystem: process.env.REACT_APP_DISPLAY_TYPE,
            date4: new Date(moment().subtract(1, "days").format("YYYY-MM-DD")),
            checked1: false,
            isError: false,
        };
        this.vehicleService = new VehicleSrvice();
        this.handStoppage = this.handStoppage.bind(this);
        this.handleduration = this.handleduration.bind(this);
        this.handleIdling = this.handleIdling.bind(this);
        this.handelts = this.handelts.bind(this);

        this.maxDate = new Date(moment().subtract(1, "days").format("YYYY-MM-DD"));
        addLocale("es", {
            firstDayOfWeek: 1,
            dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
            dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
            dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
            monthNames: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
            monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
            today: "Hoy",
            clear: "Limpiar",
        });
    }

    componentDidMount = () => {

        let day = moment(this.state.date4).format("DD-MM-YYYY");
        let form_date = moment(this.state.date4).startOf("day").format("YYYY-MM-DD HH:mm:ss");
        let to_date = moment(this.state.date4).endOf("day").format("YYYY-MM-DD HH:mm:ss");

        this.initReport(day, form_date, to_date);
    };

    initReport = (day, form_date, to_date) => {
        let query = {
            filter: {
                date: day,
                thingName: this.state.thingName,
            },
        };
        let query2 = {
            filter: {
                make: this.state.make,
                model: this.state.model,
            },
        };

        Promise.all([this.vehicleService.getObdHistory(serviceId, query), this.vehicleService.getVehicleParams(query2)]).then((res) => {
            if(res && res[0].length > 0 && res[1].length > 0) {
                this.setState({isError: false});
                let vehicleData = res[0][0];
                let obdParams = res[1][0].obdparam;
                let projection = { _id: 0 };

                obdParams.forEach((item) => {
                    projection[item.param] = 1;
                });
                
                let query3 = {
                    filter: { thingName: "empire_ford_1", ts: { $gte: form_date, $lte: to_date } },
                    projection: projection,
                };
                this.vehicleService.getObdDataHistory(serviceId, query3).then((res) => {
                    this.setState({ vehicleData, obdParams, obdData: res }, () => {
                        this.handleRoute(this.state.vehicleData);
                        this.handelOBD(this.state.obdParams, this.state.obdData);
                    });
                });
            } else {
                this.setState({isError: true});
            }
        });
    }

    handelOBD = (obdParm, data) => {
        let staticData = {
            number_of_dtc: "",
            distance_traveled_milon: "",
            distance_since_codes_clear: "",
            time_since_codes_cleared: "",
            fault_codes: "",
        };
        staticData.number_of_dtc = data[data.length - 1].numberofdtc;
        staticData.distance_traveled_milon = data[data.length - 1].distancetraveledmilon;
        staticData.distance_since_codes_clear = data[data.length - 1].distancesincecodesclear;
        staticData.time_since_codes_cleared = data[data.length - 1].timesincecodescleared;
        staticData.fault_codes = data[data.length - 1].faultcodes;

        let obdArr = obdParm.filter((item) => item.param !== "numberofdtc" && item.param !== "distancetraveledmilon" && item.param !== "distancesincecodesclear" && item.param !== "timesincecodescleared" && item.param !== "faultcodes" && item.param !== "timestamp");

        let grandArr = [];
        obdArr.forEach((item) => {
            let temp;
            temp = data.map((elm) => {
                let value = parseFloat(convertValue(elm[item.param], item.displayType, this.state.unitSystem));
                // console.log(value);
                return [moment(elm.timestamp).valueOf(), value];
            });
            grandArr.push({
                title: item.displayName,
                type: item.displayType,
                seq: item.displaySeq,
                data: temp,
            });
        });
        grandArr.sort((a, b) => a.seq - b.seq);
        let chartOBD = grandArr.map((item) => {
            return {
                chart: {
                    zoomType: "x",
                    backgroundColor: "transparent",
                    height: (9 / 16 * 100) + '%',
                },
                title: {
                    text: item.title,
                },
                accessibility: {
                    screenReaderSection: {
                        beforeChartFormat: "<{headingTagName}>{chartTitle}</{headingTagName}><div>{chartSubtitle}</div><div>{chartLongdesc}</div><div>{xAxisDescription}</div><div>{yAxisDescription}</div>",
                    },
                },
                legend: {
                    align: "center",
                    verticalAlign: "bottom",
                    x: 0,
                    y: 0,
                    floating: false,
                    backgroundColor: "transparent",
                    borderColor: "#CCC",
                    borderWidth: 0,
                    shadow: false,
                },
                tooltip: {
                    pointFormat:
                        item.type === "temperature"
                            ? this.state.unitSystem === "FPS"
                                ? "{series.name}: {point.y} \xB0F"
                                : "{series.name}: {point.y} \xB0C."
                            : item.type === "distance"
                            ? this.state.unitSystem === "FPS"
                                ? "{series.name}: {point.y} miles"
                                : "{series.name}: {point.y} km"
                            : item.type === "percent"
                            ? "{series.name}: {point.y} %"
                            : item.type === "speed"
                            ? this.state.unitSystem === "FPS"
                                ? "{series.name}: {point.y} mph"
                                : "{series.name}: {point.y} kmph"
                            : "{series.name}: {point.y}",
                    valueDecimals: 2,
                },
                xAxis: {
                    type: "datetime",
                },
                yAxis: {
                    min: 0,
                    title: false,
                    stackLabels: {
                        enabled: false,
                    },
                    labels: {
                        style: {
                            color: "#86969F",
                        },
                    },
                },
                plotOptions: {
                    series: {
                        label: {
                            connectorAllowed: false,
                        },
                        pointStart: 0,
                        borderRadius: 5,
                        pointWidth: 9,
                        borderWidth: 0,
                    },
                },
                series: [
                    {
                        name: item.title,
                        data: item.data,
                        color: "#0884F1",
                    },
                ],
            };
        });
        this.setState({ chartOBD, staticOBD: staticData });
    };

    getStrengthMarker = (H, map, station, contentString, defaultLayers, icon, ui, size) => {
        let group = new H.map.Group();
        map.addObject(group);
        // let ui = H.ui.UI.createDefault(map, defaultLayers);
        let marker = new H.map.Marker(station, icon, size);
        let bubble;

        marker.addEventListener(
            "tap",
            function (evt) {
                bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
                    content: evt.target.getData(),
                });
                ui.addBubble(bubble);
            },
            false
        );
        // marker.addEventListener(
        // 	'pointerleave',
        // 	function (evt) {
        // 		bubble.close();
        // 	},
        // 	false
        // );
        marker.setData(contentString);

        group.addObject(marker);
        return marker;
    };

    handleRoute = (data) => {
        let geoMapArray = [];
        let declutterPoints = data["stoppagePoints"] ? data["stoppagePoints"] : [];

        for (let index = 0; index < declutterPoints.length; index++) {
            let lat = Number(typeof declutterPoints[index].latitude === "object" ? Number(declutterPoints[index].latitude.double) : declutterPoints[index].latitude),
                lon = Number(declutterPoints[index].longitude?.double ? declutterPoints[index].longitude?.double : declutterPoints[index].longitude);
            geoMapArray.push({
                lat: lat,
                lng: lon,
                type: "undesignated",
                time: declutterPoints[index].ts,
            });
        }
        let stoppege_point = data["stoppagePoints"];
        stoppege_point.forEach((item, index) => {
            let geoPoint = `${item.latitude},${item.longitude}`;
            this.vehicleService.getLatLongDetails(geoPoint).then((res) => {
                item.index = index + 1;
                item.adderss = res.items[0].address.label;
            });
        });

        this.setState(
            {
                reportData: data,
                stoppagePoints: stoppege_point,
                geoArray: geoMapArray,
                selectedThingName: this.props.thingName,
            },
            () => {
                this.getVehicleMap(this.state.geoArray);
            }
        );
    };

    getVehicleMap = (stations) => {
        if (!stations || !stations.length) {
            return;
        }
        let H = window.H;
        let scheduledIcon = new H.map.Icon("/images/Scheduled.svg", {
            size: { w: 15, h: 15 },
        });
        let unScheduledIcon = new H.map.Icon("/images/Unscheduled.svg", {
            size: { w: 15, h: 15 },
        });
        let startIcon = new H.map.Icon(map_start_icon, { size: { w: 32, h: 32 } });
        let endIcon = new H.map.Icon(map_end_icon, { size: { w: 32, h: 32 } });

        const platform = new H.service.Platform({
            apikey: mapKey,
        });
        const defaultLayers = platform.createDefaultLayers();

        const map = new H.Map(this.mapRef.current, defaultLayers.vector.normal.map, {
            center: { lat: stations[0].lat, lng: stations[0].lng },
            zoom: 9,
            pixelRatio: window.devicePixelRatio || 1,
        });

        window.addEventListener("resize", () => map.getViewPort().resize());

        let ui = H.ui.UI.createDefault(map, defaultLayers);
        new H.mapevents.Behavior(new H.mapevents.MapEvents(map));

        let markerLabel = 1;
        let markers = [];
        for (let i = 0; i < stations.length; i++) {
            let mark;
            let contentString =
                '<div id="vehicle-content">' +
                '<div id="vehicle-info">' +
                `<div id=${stations[i].type === "undesignated" ? "fc-red" : "fc-green"} >` +
                `${i === 0 ? "Start" : i === stations.length - 1 ? "End" : stations[i].type}` +
                "</div>" +
                '<div id="info">' +
                `${i === 0 ? "Start Time" : i === stations.length - 1 ? "End Time" : "Time"} : ` +
                `${stations[i].time ? stations[i].time.split(" ")[1] : ""}` +
                "</div>" +
                `${!(i === 0 || i === stations.length - 1) ? '<div id="info">' + `Stop Point Number : ` + `${markerLabel}` + " </div>" : ""}` +
                "</div>" +
                "</div>";

            if (i === 0) {
                mark = this.getStrengthMarker(H, map, stations[i], contentString, defaultLayers, { icon: startIcon }, ui, { h: 10, w: 10 });
            } else if (i === stations.length - 1) {
                mark = this.getStrengthMarker(H, map, stations[i], contentString, defaultLayers, { icon: endIcon }, ui, { h: 10, w: 10 });
            } else if ((i != stations.length - 1 || 0) && stations[i].type === "undesignated") {
                mark = this.getStrengthMarker(H, map, stations[i], contentString, defaultLayers, { icon: unScheduledIcon }, ui, { h: 10, w: 10 });
                markerLabel += 1;
            } else if ((i != stations.length - 1 || 0) && stations[i].type === "designated") {
                mark = this.getStrengthMarker(H, map, stations[i], contentString, defaultLayers, { icon: scheduledIcon }, ui, { h: 10, w: 10 });
                markerLabel += 1;
            }
            markers.push(mark);
        }
        //this.setState({ markers });
        for (let index = 0; index < stations.length - 1; index++) {
            this.addRouteFromAtoB(platform, stations[index].lat + "," + stations[index].lng, stations[index + 1].lat + "," + stations[index + 1].lng, H, map);
        }
    };

    addRouteFromAtoB = (platform, origin, destination, H, map) => {
        this.calculateRouteFromAtoB(platform, origin, destination, H, map).then((route) => {
            this.addRouteShapeToMap(route, H, map);
        });
    };

    calculateRouteFromAtoB(platform, origin, destination, H, map) {
        return new Promise((resolve, reject) => {
            var router = platform.getRoutingService(null, 8),
                routeRequestParams = {
                    routingMode: "fast",
                    transportMode: "car",
                    origin: origin,
                    destination: destination,
                    return: "polyline,summary",
                };

            router.calculateRoute(routeRequestParams, (res, err) => {
                if (err) reject();
                resolve(res.routes[0]);
            });
        });
    }

    addRouteShapeToMap(route, H, map) {
        route.sections.forEach((section) => {
            let linestring = H.geo.LineString.fromFlexiblePolyline(section.polyline);
            let polyline = new H.map.Polyline(linestring, {
                style: {
                    lineWidth: 5,
                    strokeColor: "rgba(10, 3, 241, 1)",
                },
            });
            map.addObject(polyline);
        });
    }

    handStoppage = (item) => {
        return item.adderss;
    };
    handleduration = (item) => {
        return minToHrMinSec(item.stoppageDuration);
    };
    handleIdling = (item) => {
        return item.ignitionOnTime ? secondsToHms(item.ignitionOnTime) : "00:00:00";
    };
    handelts = (item) => {
        return moment(item.ts).format("HH:mm:ss");
    }

    handleDateChenge = (e) => {
        let day = moment(e.value).format("DD-MM-YYYY");
        let form_date = moment(e.value).startOf("day").format("YYYY-MM-DD HH:mm:ss");
        let to_date = moment(e.value).endOf("day").format("YYYY-MM-DD HH:mm:ss");
        this.setState({ date4: e.value }, () => {
            // console.log('form_date: ', form_date);
            // console.log('to_date: ', to_date);
            // console.log('day: ', day);
            this.initReport(day, form_date, to_date);
        });
    };

    handelSwitch = (value) => {
        let unit = process.env.REACT_APP_DISPLAY_TYPE;
        let day = moment(this.state.date4).format("DD-MM-YYYY");
        let form_date = moment(this.state.date4).startOf("day").format("YYYY-MM-DD HH:mm:ss");
        let to_date = moment(this.state.date4).endOf("day").format("YYYY-MM-DD HH:mm:ss");
        if(value === true) {
            unit = 'MKS';
        }
        this.setState({ checked1: value, unitSystem: unit }, () => this.initReport(day, form_date, to_date))
    }

    render = () =>{
        const leftContents = (
            <React.Fragment>
                <Button icon="pi pi-arrow-left" className="mr-2" onClick={this.props.reportBack} />
                <Calendar id="minmax" value={this.state.date4} onChange={(e) => this.handleDateChenge(e)} maxDate={this.maxDate} showIcon readOnlyInput style={{width: '150px'}} />
            </React.Fragment>
        );
    
        const rightContents = (
            <React.Fragment>
                <div className="text-right">
                    <p className="font-bold capitalize mb-1">
                        Vehicle: <span className="text-blue-700">{this.state.make} {this.state.model}</span>
                    </p>
                    <div className="font-bold">
                        <span className="mr-1">FPS</span> 
                        <InputSwitch checked={this.state.checked1} onChange={(e) => this.handelSwitch(e.value)} /> 
                        <span className="ml-1">MKS</span>
                    </div>
                </div>
            </React.Fragment>
        );
        let headerGroup = (<ColumnGroup>
            <Row>
                <Column header="Stoppage" className="bg-primary"></Column>
                <Column header="Time" className="bg-primary"></Column>
                <Column header="Duration" className="bg-primary"></Column>
                <Column header="Idling Duration" className="bg-primary"></Column>
            </Row>
        </ColumnGroup>);
        if(this.state.isError) {
            return (
                <div className="grid">
                    <div className="col-12">
                        <Toolbar left={leftContents} right={rightContents} />
                    </div>
                    <div className="col-12">
                        <div className="card flex flex-column align-items-center justify-content-center" style={{height: '60vh'}}>
                            <span className="pi pi-search" style={{fontSize: '3rem'}}></span>
                            <h3>No Data Found</h3>
                        </div>
                    </div>
                </div>
            )
        } else if(this.state.reportData) {
            return (<>
                <div className="grid">
                    <div className="col-12">
                        <Toolbar left={leftContents} right={rightContents} />
                    </div>
                    <div className="col-6">
                        
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 lg:col-4">
                        <div className="card mb-3 vehicle_info">
                            <p className="font-bold text-900">
                                Start Time:
                                <span> {moment(this.state.reportData["startTime"], "YYYY-MM-DD HH:mm:ss").format("ddd, h:mm A")}</span>
                            </p>
                            <p className="font-bold text-900">
                                End Time:
                                <span> {moment(this.state.reportData["endTime"], "YYYY-MM-DD HH:mm:ss").format("ddd, h:mm A")}</span>
                            </p>
                            <p className="font-bold text-900">
                                Total Travel Time:
                                <span> {this.state.reportData["totalTravelTime"]}</span>
                            </p>
                            <p className="font-bold text-900">
                                Distance Travelled:
                                <span>
                                    {convertValue(this.state.reportData["totalDistanceTravelled"], "distance", this.state.unitSystem)}
                                    {this.state.unitSystem === "FPS" ? " miles" : " Km"}
                                </span>
                            </p>
                            <p className="font-bold text-900">
                                Stoppages:
                                <span> {this.state.reportData["stoppagePoints"].length}</span>
                            </p>
                            <p className="font-bold text-900">
                                Total Stoppage Period:
                                <span> {minToHrMin(this.state.reportData["totalStoppageTime"])}</span>
                            </p>
                        </div>
                    </div>
                    <div className="col-12 lg:col-8">
                        <div className="card p-0">
                            <div ref={this.mapRef} style={{ width: "100%", height: "280px", overflow: "hidden" }}></div>
                        </div>
                    </div>
                    <div className="col-12">
                        <Accordion>
                            <AccordionTab header="Stoppage Details">
                                <div className="grid">
                                    <div className="col-12 lg:col-8">
                                        <div className="card">
                                            <DataTable headerColumnGroup={headerGroup} value={this.state.stoppagePoints} responsiveLayout="scroll">
                                                {/* <Column header="Sl.No." field="index"></Column> */}
                                                <Column header="Stoppage" body={this.handStoppage}></Column>
                                                <Column header="Stoppage Time" body={this.handelts}></Column>
                                                <Column header="Stoppage Duration" body={this.handleduration}></Column>
                                                <Column header="Idling Duration" body={this.handleIdling}></Column>
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                            </AccordionTab>
                            <AccordionTab header="Performance and Diagnostics">
                                <div className="grid">
                                    {this.state.staticOBD ? (
                                        <>
                                            {
                                                this.state.staticOBD.number_of_dtc !== undefined ? (
                                                    <div className="col-12 lg:col-4">
                                                        <div className="card">
                                                            <div className="flex align-items-center w-100">
                                                                <div className="text-base font-bold text-blue-800">Number Of DTC</div>
                                                                <div className="flex-1 text-base font-bold text-right">{this.state.staticOBD.number_of_dtc}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null
                                            }
                                            {
                                                this.state.staticOBD.distance_traveled_milon !== undefined ? (
                                                    <div className="col-12 lg:col-4">
                                                        <div className="card">
                                                            <div className="flex align-items-center w-100">
                                                                <div className="text-base font-bold text-blue-800">Distance Since MIL On</div>
                                                                <div className="flex-1 text-base font-bold text-right">{this.state.staticOBD.distance_traveled_milon}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null
                                            }
                                            {
                                                this.state.staticOBD.distance_since_codes_clear !== undefined ? (
                                                    <div className="col-12 lg:col-4">
                                                        <div className="card">
                                                            <div className="flex align-items-center w-100">
                                                                <div className="text-base font-bold text-blue-800">Distance Since Codes Clear</div>
                                                                <div className="flex-1 text-base font-bold text-right">{this.state.staticOBD.distance_since_codes_clear}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null
                                            }
                                            {
                                                this.state.staticOBD.time_since_codes_cleared !== undefined ? (
                                                    <div className="col-12 lg:col-4">
                                                        <div className="card">
                                                            <div className="flex align-items-center w-100">
                                                                <div className="text-base font-bold text-blue-800">Time Since Codes Cleared</div>
                                                                <div className="flex-1 text-base font-bold text-right">{this.state.staticOBD.time_since_codes_cleared}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null
                                            }
                                            {
                                                this.state.staticOBD.fault_codes !== undefined ? (
                                                    <div className="col-12 lg:col-4">
                                                        <div className="card">
                                                            <div className="flex align-items-center w-100">
                                                                <div className="text-base font-bold text-blue-800">Fault Codes</div>
                                                                <div className="flex-1 text-base font-bold text-right">{this.state.staticOBD.fault_codes}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null
                                            }
                                        </>
                                    ) : null}
                                    {this.state.chartOBD.map((item, index) => (
                                        <div className="col-12 lg:col-12" key={"chart" + index}>
                                            <HighchartsReact highcharts={Highcharts} options={item} />
                                        </div>
                                    ))}
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                </div>
            </>)
        } else {
            return (
                <div className="grid">
                    <div className="col-12 lg:col-4">
                        <Skeleton height="322px" className="mb-2"></Skeleton>
                    </div>
                    <div className="col-12 lg:col-8">
                        <Skeleton height="322px" className="mb-2"></Skeleton>
                    </div>
                    <div className="col-12">
                        <Skeleton height="50px" className="mb-2"></Skeleton>
                        <Skeleton height="50px" className="mb-2"></Skeleton>
                    </div>
                </div>
            )
        }
    }
        
}

export default VehicleOBDHistory;