export const dummy_data = {
	"responseData": {
		"type": 10,
		"data": [
			{
				"Declutter Point": [
					{
						"iccid1": 8995016202,
						"iccid2": 1091240044,
						"totalodometer": 445595209,
						"axisx": 65494,
						"axisy": 24,
						"axisz": 65531,
						"gsmsignal": 5,
						"speed": 0,
						"externalvoltage": 12.717,
						"gnssstatus": 1,
						"batterylevel": 97,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 13013252,
						"sleepmode": 0,
						"gsmcellid": 41303,
						"gsmareacode": 8015,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 207,
						"codecId": 8,
						"numOfData1": 2,
						"crc": 4,
						"numOfData2": 2,
						"timestamp": "2022-09-20 18:19:20",
						"priority": 0,
						"longitude": 96.128665,
						"latitude": 22.27123,
						"altitude": 82,
						"angle": 303,
						"satellites": 15,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 00:49:20",
						"thingName": "ltpk_7h_5745",
						"batchnumber": "2004",
						"city": "madaya",
						"make": "ford",
						"manufacturingdate": "26-Jun-1905",
						"model": "ford-ranger",
						"state": "mandalay-region",
						"type": "pick-up-4x4",
						"zipcode": "05121"
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240044,
						"totalodometer": 445620692,
						"axisx": 4,
						"axisy": 15,
						"axisz": 65526,
						"gsmsignal": 5,
						"speed": 0,
						"externalvoltage": 12.848,
						"gnssstatus": 1,
						"batterylevel": 97,
						"gnsspdop": 0.7000000000000001,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 13038735,
						"sleepmode": 0,
						"gsmcellid": 41303,
						"gsmareacode": 8015,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 52911,
						"numOfData2": 1,
						"timestamp": "2022-09-21 17:23:08",
						"priority": 0,
						"longitude": 96.1288333,
						"latitude": 22.2710633,
						"altitude": 65,
						"angle": 252,
						"satellites": 14,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 23:53:08",
						"thingName": "ltpk_7h_5745",
						"batchnumber": "2004",
						"city": "madaya",
						"make": "ford",
						"manufacturingdate": "26-Jun-1905",
						"model": "ford-ranger",
						"state": "mandalay-region",
						"type": "pick-up-4x4",
						"zipcode": "05121"
					}
				],
				"state": "mandalay-region",
				"city": "madaya",
				"pincode": "05121",
				"siteName": "",
				"siteId": "",
				"make": "ford",
				"model": "ford-ranger",
				"size": "",
				"thingName": "ltpk_7h_5745",
				"UnDesignated Stoppage Points": [
					{
						"iccid1": 8995016202,
						"iccid2": 1091240044,
						"totalodometer": 445595209,
						"axisx": 65491,
						"axisy": 29,
						"axisz": 65531,
						"gsmsignal": 5,
						"speed": 0,
						"externalvoltage": 12.717,
						"gnssstatus": 1,
						"batterylevel": 97,
						"gnsspdop": 0.7000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 13013252,
						"sleepmode": 0,
						"gsmcellid": 41303,
						"gsmareacode": 8015,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 12278,
						"numOfData2": 1,
						"timestamp": "2022-09-20 18:49:20",
						"priority": 0,
						"longitude": 96.128665,
						"latitude": 22.27123,
						"altitude": 82,
						"angle": 303,
						"satellites": 11,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 01:19:20",
						"thingName": "ltpk_7h_5745",
						"batchnumber": "2004",
						"city": "madaya",
						"make": "ford",
						"manufacturingdate": "26-Jun-1905",
						"model": "ford-ranger",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05121",
						"stoppageDuration": 832.1666666666666,
						"ignitionOnTime": 49921
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240044,
						"totalodometer": 445615379,
						"axisx": 35,
						"axisy": 57,
						"axisz": 24,
						"gsmsignal": 5,
						"speed": 6,
						"externalvoltage": 14.171000000000001,
						"gnssstatus": 1,
						"batterylevel": 96,
						"gnsspdop": 0.5,
						"gnsshdop": 0.4,
						"tripodometer": 13033422,
						"sleepmode": 0,
						"gsmcellid": 41303,
						"gsmareacode": 8015,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 21425,
						"numOfData2": 1,
						"timestamp": "2022-09-21 09:32:28",
						"priority": 0,
						"longitude": 96.1291166,
						"latitude": 22.2711183,
						"altitude": 91,
						"angle": 288,
						"satellites": 12,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 16:02:28",
						"thingName": "ltpk_7h_5745",
						"batchnumber": "2004",
						"city": "madaya",
						"make": "ford",
						"manufacturingdate": "26-Jun-1905",
						"model": "ford-ranger",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05121",
						"stoppageDuration": 128.58333333333334,
						"ignitionOnTime": 7715
					}
				],
				"Designated Stoppage Points": [],
				"Start Time": "2022-09-21 00:49:20",
				"End Time": "2022-09-21 23:53:08",
				"Stoppage Points": [
					{
						"iccid1": 8995016202,
						"iccid2": 1091240044,
						"totalodometer": 445595209,
						"axisx": 65491,
						"axisy": 29,
						"axisz": 65531,
						"gsmsignal": 5,
						"speed": 0,
						"externalvoltage": 12.717,
						"gnssstatus": 1,
						"batterylevel": 97,
						"gnsspdop": 0.7000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 13013252,
						"sleepmode": 0,
						"gsmcellid": 41303,
						"gsmareacode": 8015,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 12278,
						"numOfData2": 1,
						"timestamp": "2022-09-20 18:49:20",
						"priority": 0,
						"longitude": 96.128665,
						"latitude": 22.27123,
						"altitude": 82,
						"angle": 303,
						"satellites": 11,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 01:19:20",
						"thingName": "ltpk_7h_5745",
						"batchnumber": "2004",
						"city": "madaya",
						"make": "ford",
						"manufacturingdate": "26-Jun-1905",
						"model": "ford-ranger",
						"state": "mandalay-region",
						"type": "pick-up-4x4",
						"zipcode": "05121",
						"stoppageDuration": 832.1666666666666,
						"ignitionOnTime": 49921
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240044,
						"totalodometer": 445615379,
						"axisx": 35,
						"axisy": 57,
						"axisz": 24,
						"gsmsignal": 5,
						"speed": 6,
						"externalvoltage": 14.171000000000001,
						"gnssstatus": 1,
						"batterylevel": 96,
						"gnsspdop": 0.5,
						"gnsshdop": 0.4,
						"tripodometer": 13033422,
						"sleepmode": 0,
						"gsmcellid": 41303,
						"gsmareacode": 8015,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 21425,
						"numOfData2": 1,
						"timestamp": "2022-09-21 09:32:28",
						"priority": 0,
						"longitude": 96.1291166,
						"latitude": 22.2711183,
						"altitude": 91,
						"angle": 288,
						"satellites": 12,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 16:02:28",
						"thingName": "ltpk_7h_5745",
						"batchnumber": "2004",
						"city": "madaya",
						"make": "ford",
						"manufacturingdate": "26-Jun-1905",
						"model": "ford-ranger",
						"state": "mandalay-region",
						"type": "pick-up-4x4",
						"zipcode": "05121",
						"stoppageDuration": 128.58333333333334,
						"ignitionOnTime": 7715
					}
				],
				"Total Stoppage Time": 960.75,
				"Total Travel Time": "7hr 3min 3sec",
				"Route Points": [
					{
						"iccid1": 8995016202,
						"iccid2": 1091240044,
						"totalodometer": 445595209,
						"axisx": 65494,
						"axisy": 24,
						"axisz": 65531,
						"gsmsignal": 5,
						"speed": 0,
						"externalvoltage": 12.717,
						"gnssstatus": 1,
						"batterylevel": 97,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 13013252,
						"sleepmode": 0,
						"gsmcellid": 41303,
						"gsmareacode": 8015,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 207,
						"codecId": 8,
						"numOfData1": 2,
						"crc": 4,
						"numOfData2": 2,
						"timestamp": "2022-09-20 18:19:20",
						"priority": 0,
						"longitude": 96.128665,
						"latitude": 22.27123,
						"altitude": 82,
						"angle": 303,
						"satellites": 15,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 00:49:20",
						"thingName": "ltpk_7h_5745",
						"batchnumber": "2004",
						"city": "madaya",
						"make": "ford",
						"manufacturingdate": "26-Jun-1905",
						"model": "ford-ranger",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05121"
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240044,
						"totalodometer": 445595209,
						"axisx": 65491,
						"axisy": 29,
						"axisz": 65531,
						"gsmsignal": 5,
						"speed": 0,
						"externalvoltage": 12.717,
						"gnssstatus": 1,
						"batterylevel": 97,
						"gnsspdop": 0.7000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 13013252,
						"sleepmode": 0,
						"gsmcellid": 41303,
						"gsmareacode": 8015,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 12278,
						"numOfData2": 1,
						"timestamp": "2022-09-20 18:49:20",
						"priority": 0,
						"longitude": 96.128665,
						"latitude": 22.27123,
						"altitude": 82,
						"angle": 303,
						"satellites": 11,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 01:19:20",
						"thingName": "ltpk_7h_5745",
						"batchnumber": "2004",
						"city": "madaya",
						"make": "ford",
						"manufacturingdate": "26-Jun-1905",
						"model": "ford-ranger",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05121",
						"stoppageDuration": 832.1666666666666,
						"ignitionOnTime": 49921
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240044,
						"totalodometer": 445615379,
						"axisx": 35,
						"axisy": 57,
						"axisz": 24,
						"gsmsignal": 5,
						"speed": 6,
						"externalvoltage": 14.171000000000001,
						"gnssstatus": 1,
						"batterylevel": 96,
						"gnsspdop": 0.5,
						"gnsshdop": 0.4,
						"tripodometer": 13033422,
						"sleepmode": 0,
						"gsmcellid": 41303,
						"gsmareacode": 8015,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 21425,
						"numOfData2": 1,
						"timestamp": "2022-09-21 09:32:28",
						"priority": 0,
						"longitude": 96.1291166,
						"latitude": 22.2711183,
						"altitude": 91,
						"angle": 288,
						"satellites": 12,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 16:02:28",
						"thingName": "ltpk_7h_5745",
						"batchnumber": "2004",
						"city": "madaya",
						"make": "ford",
						"manufacturingdate": "26-Jun-1905",
						"model": "ford-ranger",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05121",
						"stoppageDuration": 128.58333333333334,
						"ignitionOnTime": 7715
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240044,
						"totalodometer": 445620692,
						"axisx": 4,
						"axisy": 15,
						"axisz": 65526,
						"gsmsignal": 5,
						"speed": 0,
						"externalvoltage": 12.848,
						"gnssstatus": 1,
						"batterylevel": 97,
						"gnsspdop": 0.7000000000000001,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 13038735,
						"sleepmode": 0,
						"gsmcellid": 41303,
						"gsmareacode": 8015,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 52911,
						"numOfData2": 1,
						"timestamp": "2022-09-21 17:23:08",
						"priority": 0,
						"longitude": 96.1288333,
						"latitude": 22.2710633,
						"altitude": 65,
						"angle": 252,
						"satellites": 14,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 23:53:08",
						"thingName": "ltpk_7h_5745",
						"batchnumber": "2004",
						"city": "madaya",
						"make": "ford",
						"manufacturingdate": "26-Jun-1905",
						"model": "ford-ranger",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05121"
					}
				],
				"Total Distance Travelled": 25.483
			},
			{
				"Declutter Point": [
					{
						"iccid1": 8995016202,
						"iccid2": 1091240077,
						"totalodometer": 244824637,
						"axisx": 241,
						"axisy": 65496,
						"axisz": 65507,
						"gsmsignal": 3,
						"speed": 0,
						"externalvoltage": 12.742,
						"gnssstatus": 1,
						"batterylevel": 84,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 436892,
						"sleepmode": 0,
						"gsmcellid": 11292,
						"gsmareacode": 309,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 17954,
						"numOfData2": 1,
						"timestamp": "2022-09-20 18:45:38",
						"priority": 0,
						"longitude": 96.0673183,
						"latitude": 16.96248,
						"altitude": 65527,
						"angle": 240,
						"satellites": 14,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 01:15:38",
						"thingName": "ltpk_8g_9972",
						"batchnumber": "2010",
						"city": "pabedan",
						"make": "toyota",
						"manufacturingdate": "02-Jul-1905",
						"model": "sun-tun",
						"state": "yangon-region",
						"type": "light-truck-4x2-r",
						"zipcode": "11143"
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240077,
						"totalodometer": 244873538,
						"axisx": 229,
						"axisy": 65509,
						"axisz": 65506,
						"gsmsignal": 4,
						"speed": 0,
						"externalvoltage": 12.698,
						"gnssstatus": 1,
						"batterylevel": 88,
						"gnsspdop": 0.4,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 485793,
						"sleepmode": 0,
						"gsmcellid": 12703,
						"gsmareacode": 309,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 14363,
						"numOfData2": 1,
						"timestamp": "2022-09-21 17:19:45",
						"priority": 0,
						"longitude": 96.06733,
						"latitude": 16.9624483,
						"altitude": 3,
						"angle": 226,
						"satellites": 16,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 23:49:45",
						"thingName": "ltpk_8g_9972",
						"batchnumber": "2010",
						"city": "pabedan",
						"make": "toyota",
						"manufacturingdate": "02-Jul-1905",
						"model": "sun-tun",
						"state": "yangon-region",
						"type": "light-truck-4x2-r",
						"zipcode": "11143"
					}
				],
				"state": "yangon-region",
				"city": "pabedan",
				"pincode": "11143",
				"siteName": "",
				"siteId": "",
				"make": "toyota",
				"model": "sun-tun",
				"size": "",
				"thingName": "ltpk_8g_9972",
				"UnDesignated Stoppage Points": [
					{
						"iccid1": 8995016202,
						"iccid2": 1091240077,
						"totalodometer": 244824637,
						"axisx": 235,
						"axisy": 65496,
						"axisz": 65501,
						"gsmsignal": 3,
						"speed": 0,
						"externalvoltage": 12.742,
						"gnssstatus": 1,
						"batterylevel": 84,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 436892,
						"sleepmode": 0,
						"gsmcellid": 11292,
						"gsmareacode": 309,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 6629,
						"numOfData2": 1,
						"timestamp": "2022-09-20 18:30:38",
						"priority": 0,
						"longitude": 96.0673183,
						"latitude": 16.96248,
						"altitude": 65527,
						"angle": 240,
						"satellites": 15,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 01:00:38",
						"thingName": "ltpk_8g_9972",
						"batchnumber": "2010",
						"city": "pabedan",
						"make": "toyota",
						"manufacturingdate": "02-Jul-1905",
						"model": "sun-tun",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11143",
						"stoppageDuration": 378.93333333333334,
						"ignitionOnTime": 22736
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240077,
						"totalodometer": 244829432,
						"axisx": 189,
						"axisy": 13,
						"axisz": 23,
						"gsmsignal": 3,
						"speed": 5,
						"externalvoltage": 14.157,
						"gnssstatus": 1,
						"batterylevel": 99,
						"gnsspdop": 0.5,
						"gnsshdop": 0.2,
						"tripodometer": 441687,
						"sleepmode": 0,
						"gsmcellid": 10581,
						"gsmareacode": 309,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 13463,
						"numOfData2": 1,
						"timestamp": "2022-09-21 01:08:59",
						"priority": 0,
						"longitude": 96.04242,
						"latitude": 16.977395,
						"altitude": 5,
						"angle": 229,
						"satellites": 19,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 07:38:59",
						"thingName": "ltpk_8g_9972",
						"batchnumber": "2010",
						"city": "pabedan",
						"make": "toyota",
						"manufacturingdate": "02-Jul-1905",
						"model": "sun-tun",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11143",
						"stoppageDuration": 20.216666666666665,
						"ignitionOnTime": 1213
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240077,
						"totalodometer": 244834304,
						"axisx": 395,
						"axisy": 65528,
						"axisz": 271,
						"gsmsignal": 3,
						"speed": 15,
						"externalvoltage": 14.030000000000001,
						"gnssstatus": 1,
						"batterylevel": 95,
						"gnsspdop": 0.5,
						"gnsshdop": 0.2,
						"tripodometer": 446559,
						"sleepmode": 0,
						"gsmcellid": 11761,
						"gsmareacode": 309,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 35876,
						"numOfData2": 1,
						"timestamp": "2022-09-21 01:44:04",
						"priority": 0,
						"longitude": 96.0672616,
						"latitude": 16.9626233,
						"altitude": 65533,
						"angle": 122,
						"satellites": 19,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 08:14:04",
						"thingName": "ltpk_8g_9972",
						"batchnumber": "2010",
						"city": "pabedan",
						"make": "toyota",
						"manufacturingdate": "02-Jul-1905",
						"model": "sun-tun",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11143",
						"stoppageDuration": 32.85,
						"ignitionOnTime": 1971
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240077,
						"totalodometer": 244838956,
						"axisx": 453,
						"axisy": 80,
						"axisz": 65510,
						"gsmsignal": 5,
						"speed": 8,
						"externalvoltage": 14.107000000000001,
						"gnssstatus": 1,
						"batterylevel": 94,
						"gnsspdop": 0.4,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 451211,
						"sleepmode": 0,
						"gsmcellid": 12203,
						"gsmareacode": 309,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 45381,
						"numOfData2": 1,
						"timestamp": "2022-09-21 02:29:33",
						"priority": 0,
						"longitude": 96.0926333,
						"latitude": 16.9706516,
						"altitude": 8,
						"angle": 199,
						"satellites": 17,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 08:59:33",
						"thingName": "ltpk_8g_9972",
						"batchnumber": "2010",
						"city": "pabedan",
						"make": "toyota",
						"manufacturingdate": "02-Jul-1905",
						"model": "sun-tun",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11143",
						"stoppageDuration": 268.3333333333333,
						"ignitionOnTime": 16097
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240077,
						"totalodometer": 244868788,
						"axisx": 281,
						"axisy": 65422,
						"axisz": 248,
						"gsmsignal": 4,
						"speed": 7,
						"externalvoltage": 14.269,
						"gnssstatus": 1,
						"batterylevel": 92,
						"gnsspdop": 0.4,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 481043,
						"sleepmode": 0,
						"gsmcellid": 12203,
						"gsmareacode": 309,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 31383,
						"numOfData2": 1,
						"timestamp": "2022-09-21 08:32:37",
						"priority": 0,
						"longitude": 96.0925666,
						"latitude": 16.9708366,
						"altitude": 9,
						"angle": 178,
						"satellites": 17,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 15:02:37",
						"thingName": "ltpk_8g_9972",
						"batchnumber": "2010",
						"city": "pabedan",
						"make": "toyota",
						"manufacturingdate": "02-Jul-1905",
						"model": "sun-tun",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11143",
						"stoppageDuration": 138.7,
						"ignitionOnTime": 8322
					}
				],
				"Designated Stoppage Points": [],
				"Start Time": "2022-09-21 01:00:38",
				"End Time": "2022-09-21 23:49:45",
				"Stoppage Points": [
					{
						"iccid1": 8995016202,
						"iccid2": 1091240077,
						"totalodometer": 244824637,
						"axisx": 235,
						"axisy": 65496,
						"axisz": 65501,
						"gsmsignal": 3,
						"speed": 0,
						"externalvoltage": 12.742,
						"gnssstatus": 1,
						"batterylevel": 84,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 436892,
						"sleepmode": 0,
						"gsmcellid": 11292,
						"gsmareacode": 309,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 6629,
						"numOfData2": 1,
						"timestamp": "2022-09-20 18:30:38",
						"priority": 0,
						"longitude": 96.0673183,
						"latitude": 16.96248,
						"altitude": 65527,
						"angle": 240,
						"satellites": 15,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 01:00:38",
						"thingName": "ltpk_8g_9972",
						"batchnumber": "2010",
						"city": "pabedan",
						"make": "toyota",
						"manufacturingdate": "02-Jul-1905",
						"model": "sun-tun",
						"state": "yangon-region",
						"type": "light-truck-4x2-r",
						"zipcode": "11143",
						"stoppageDuration": 378.93333333333334,
						"ignitionOnTime": 22736
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240077,
						"totalodometer": 244829432,
						"axisx": 189,
						"axisy": 13,
						"axisz": 23,
						"gsmsignal": 3,
						"speed": 5,
						"externalvoltage": 14.157,
						"gnssstatus": 1,
						"batterylevel": 99,
						"gnsspdop": 0.5,
						"gnsshdop": 0.2,
						"tripodometer": 441687,
						"sleepmode": 0,
						"gsmcellid": 10581,
						"gsmareacode": 309,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 13463,
						"numOfData2": 1,
						"timestamp": "2022-09-21 01:08:59",
						"priority": 0,
						"longitude": 96.04242,
						"latitude": 16.977395,
						"altitude": 5,
						"angle": 229,
						"satellites": 19,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 07:38:59",
						"thingName": "ltpk_8g_9972",
						"batchnumber": "2010",
						"city": "pabedan",
						"make": "toyota",
						"manufacturingdate": "02-Jul-1905",
						"model": "sun-tun",
						"state": "yangon-region",
						"type": "light-truck-4x2-r",
						"zipcode": "11143",
						"stoppageDuration": 20.216666666666665,
						"ignitionOnTime": 1213
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240077,
						"totalodometer": 244834304,
						"axisx": 395,
						"axisy": 65528,
						"axisz": 271,
						"gsmsignal": 3,
						"speed": 15,
						"externalvoltage": 14.030000000000001,
						"gnssstatus": 1,
						"batterylevel": 95,
						"gnsspdop": 0.5,
						"gnsshdop": 0.2,
						"tripodometer": 446559,
						"sleepmode": 0,
						"gsmcellid": 11761,
						"gsmareacode": 309,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 35876,
						"numOfData2": 1,
						"timestamp": "2022-09-21 01:44:04",
						"priority": 0,
						"longitude": 96.0672616,
						"latitude": 16.9626233,
						"altitude": 65533,
						"angle": 122,
						"satellites": 19,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 08:14:04",
						"thingName": "ltpk_8g_9972",
						"batchnumber": "2010",
						"city": "pabedan",
						"make": "toyota",
						"manufacturingdate": "02-Jul-1905",
						"model": "sun-tun",
						"state": "yangon-region",
						"type": "light-truck-4x2-r",
						"zipcode": "11143",
						"stoppageDuration": 32.85,
						"ignitionOnTime": 1971
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240077,
						"totalodometer": 244838956,
						"axisx": 453,
						"axisy": 80,
						"axisz": 65510,
						"gsmsignal": 5,
						"speed": 8,
						"externalvoltage": 14.107000000000001,
						"gnssstatus": 1,
						"batterylevel": 94,
						"gnsspdop": 0.4,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 451211,
						"sleepmode": 0,
						"gsmcellid": 12203,
						"gsmareacode": 309,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 45381,
						"numOfData2": 1,
						"timestamp": "2022-09-21 02:29:33",
						"priority": 0,
						"longitude": 96.0926333,
						"latitude": 16.9706516,
						"altitude": 8,
						"angle": 199,
						"satellites": 17,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 08:59:33",
						"thingName": "ltpk_8g_9972",
						"batchnumber": "2010",
						"city": "pabedan",
						"make": "toyota",
						"manufacturingdate": "02-Jul-1905",
						"model": "sun-tun",
						"state": "yangon-region",
						"type": "light-truck-4x2-r",
						"zipcode": "11143",
						"stoppageDuration": 268.3333333333333,
						"ignitionOnTime": 16097
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240077,
						"totalodometer": 244868788,
						"axisx": 281,
						"axisy": 65422,
						"axisz": 248,
						"gsmsignal": 4,
						"speed": 7,
						"externalvoltage": 14.269,
						"gnssstatus": 1,
						"batterylevel": 92,
						"gnsspdop": 0.4,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 481043,
						"sleepmode": 0,
						"gsmcellid": 12203,
						"gsmareacode": 309,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 31383,
						"numOfData2": 1,
						"timestamp": "2022-09-21 08:32:37",
						"priority": 0,
						"longitude": 96.0925666,
						"latitude": 16.9708366,
						"altitude": 9,
						"angle": 178,
						"satellites": 17,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 15:02:37",
						"thingName": "ltpk_8g_9972",
						"batchnumber": "2010",
						"city": "pabedan",
						"make": "toyota",
						"manufacturingdate": "02-Jul-1905",
						"model": "sun-tun",
						"state": "yangon-region",
						"type": "light-truck-4x2-r",
						"zipcode": "11143",
						"stoppageDuration": 138.7,
						"ignitionOnTime": 8322
					}
				],
				"Total Stoppage Time": 839.0333333333333,
				"Total Travel Time": "8hr 50min 5sec",
				"Route Points": [
					{
						"iccid1": 8995016202,
						"iccid2": 1091240077,
						"totalodometer": 244824637,
						"axisx": 235,
						"axisy": 65496,
						"axisz": 65501,
						"gsmsignal": 3,
						"speed": 0,
						"externalvoltage": 12.742,
						"gnssstatus": 1,
						"batterylevel": 84,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 436892,
						"sleepmode": 0,
						"gsmcellid": 11292,
						"gsmareacode": 309,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 6629,
						"numOfData2": 1,
						"timestamp": "2022-09-20 18:30:38",
						"priority": 0,
						"longitude": 96.0673183,
						"latitude": 16.96248,
						"altitude": 65527,
						"angle": 240,
						"satellites": 15,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 01:00:38",
						"thingName": "ltpk_8g_9972",
						"batchnumber": "2010",
						"city": "pabedan",
						"make": "toyota",
						"manufacturingdate": "02-Jul-1905",
						"model": "sun-tun",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11143",
						"stoppageDuration": 378.93333333333334,
						"ignitionOnTime": 22736
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240077,
						"totalodometer": 244824637,
						"axisx": 241,
						"axisy": 65496,
						"axisz": 65507,
						"gsmsignal": 3,
						"speed": 0,
						"externalvoltage": 12.742,
						"gnssstatus": 1,
						"batterylevel": 84,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 436892,
						"sleepmode": 0,
						"gsmcellid": 11292,
						"gsmareacode": 309,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 17954,
						"numOfData2": 1,
						"timestamp": "2022-09-20 18:45:38",
						"priority": 0,
						"longitude": 96.0673183,
						"latitude": 16.96248,
						"altitude": 65527,
						"angle": 240,
						"satellites": 14,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 01:15:38",
						"thingName": "ltpk_8g_9972",
						"batchnumber": "2010",
						"city": "pabedan",
						"make": "toyota",
						"manufacturingdate": "02-Jul-1905",
						"model": "sun-tun",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11143"
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240077,
						"totalodometer": 244829432,
						"axisx": 189,
						"axisy": 13,
						"axisz": 23,
						"gsmsignal": 3,
						"speed": 5,
						"externalvoltage": 14.157,
						"gnssstatus": 1,
						"batterylevel": 99,
						"gnsspdop": 0.5,
						"gnsshdop": 0.2,
						"tripodometer": 441687,
						"sleepmode": 0,
						"gsmcellid": 10581,
						"gsmareacode": 309,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 13463,
						"numOfData2": 1,
						"timestamp": "2022-09-21 01:08:59",
						"priority": 0,
						"longitude": 96.04242,
						"latitude": 16.977395,
						"altitude": 5,
						"angle": 229,
						"satellites": 19,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 07:38:59",
						"thingName": "ltpk_8g_9972",
						"batchnumber": "2010",
						"city": "pabedan",
						"make": "toyota",
						"manufacturingdate": "02-Jul-1905",
						"model": "sun-tun",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11143",
						"stoppageDuration": 20.216666666666665,
						"ignitionOnTime": 1213
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240077,
						"totalodometer": 244834304,
						"axisx": 395,
						"axisy": 65528,
						"axisz": 271,
						"gsmsignal": 3,
						"speed": 15,
						"externalvoltage": 14.030000000000001,
						"gnssstatus": 1,
						"batterylevel": 95,
						"gnsspdop": 0.5,
						"gnsshdop": 0.2,
						"tripodometer": 446559,
						"sleepmode": 0,
						"gsmcellid": 11761,
						"gsmareacode": 309,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 35876,
						"numOfData2": 1,
						"timestamp": "2022-09-21 01:44:04",
						"priority": 0,
						"longitude": 96.0672616,
						"latitude": 16.9626233,
						"altitude": 65533,
						"angle": 122,
						"satellites": 19,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 08:14:04",
						"thingName": "ltpk_8g_9972",
						"batchnumber": "2010",
						"city": "pabedan",
						"make": "toyota",
						"manufacturingdate": "02-Jul-1905",
						"model": "sun-tun",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11143",
						"stoppageDuration": 32.85,
						"ignitionOnTime": 1971
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240077,
						"totalodometer": 244838956,
						"axisx": 453,
						"axisy": 80,
						"axisz": 65510,
						"gsmsignal": 5,
						"speed": 8,
						"externalvoltage": 14.107000000000001,
						"gnssstatus": 1,
						"batterylevel": 94,
						"gnsspdop": 0.4,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 451211,
						"sleepmode": 0,
						"gsmcellid": 12203,
						"gsmareacode": 309,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 45381,
						"numOfData2": 1,
						"timestamp": "2022-09-21 02:29:33",
						"priority": 0,
						"longitude": 96.0926333,
						"latitude": 16.9706516,
						"altitude": 8,
						"angle": 199,
						"satellites": 17,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 08:59:33",
						"thingName": "ltpk_8g_9972",
						"batchnumber": "2010",
						"city": "pabedan",
						"make": "toyota",
						"manufacturingdate": "02-Jul-1905",
						"model": "sun-tun",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11143",
						"stoppageDuration": 268.3333333333333,
						"ignitionOnTime": 16097
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240077,
						"totalodometer": 244868788,
						"axisx": 281,
						"axisy": 65422,
						"axisz": 248,
						"gsmsignal": 4,
						"speed": 7,
						"externalvoltage": 14.269,
						"gnssstatus": 1,
						"batterylevel": 92,
						"gnsspdop": 0.4,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 481043,
						"sleepmode": 0,
						"gsmcellid": 12203,
						"gsmareacode": 309,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 31383,
						"numOfData2": 1,
						"timestamp": "2022-09-21 08:32:37",
						"priority": 0,
						"longitude": 96.0925666,
						"latitude": 16.9708366,
						"altitude": 9,
						"angle": 178,
						"satellites": 17,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 15:02:37",
						"thingName": "ltpk_8g_9972",
						"batchnumber": "2010",
						"city": "pabedan",
						"make": "toyota",
						"manufacturingdate": "02-Jul-1905",
						"model": "sun-tun",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11143",
						"stoppageDuration": 138.7,
						"ignitionOnTime": 8322
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240077,
						"totalodometer": 244873538,
						"axisx": 229,
						"axisy": 65509,
						"axisz": 65506,
						"gsmsignal": 4,
						"speed": 0,
						"externalvoltage": 12.698,
						"gnssstatus": 1,
						"batterylevel": 88,
						"gnsspdop": 0.4,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 485793,
						"sleepmode": 0,
						"gsmcellid": 12703,
						"gsmareacode": 309,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 14363,
						"numOfData2": 1,
						"timestamp": "2022-09-21 17:19:45",
						"priority": 0,
						"longitude": 96.06733,
						"latitude": 16.9624483,
						"altitude": 3,
						"angle": 226,
						"satellites": 16,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 23:49:45",
						"thingName": "ltpk_8g_9972",
						"batchnumber": "2010",
						"city": "pabedan",
						"make": "toyota",
						"manufacturingdate": "02-Jul-1905",
						"model": "sun-tun",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11143"
					}
				],
				"Total Distance Travelled": 48.901
			},
			{
				"Declutter Point": [
					{
						"iccid1": 8995016202,
						"iccid2": 1091240010,
						"totalodometer": 270138578,
						"axisx": 7,
						"axisy": 15,
						"axisz": 65534,
						"gsmsignal": 4,
						"speed": 0,
						"externalvoltage": 12.280000000000001,
						"gnssstatus": 1,
						"batterylevel": 96,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 380746,
						"sleepmode": 0,
						"gsmcellid": 42911,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 32495,
						"numOfData2": 1,
						"timestamp": "2022-09-20 18:46:28",
						"priority": 0,
						"longitude": 96.1002099,
						"latitude": 21.8920133,
						"altitude": 59,
						"angle": 166,
						"satellites": 12,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 01:16:28",
						"thingName": "ltpk_2f_4001",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "subaru",
						"manufacturingdate": "25-Jun-1905",
						"model": "subaru-sambar-tt1",
						"state": "mandalay-region",
						"type": "truck-4x2-r",
						"zipcode": "05041"
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240010,
						"totalodometer": 270204530,
						"axisx": 65527,
						"axisy": 12,
						"axisz": 65533,
						"gsmsignal": 4,
						"speed": 0,
						"externalvoltage": 12.125,
						"gnssstatus": 1,
						"batterylevel": 96,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 446698,
						"sleepmode": 0,
						"gsmcellid": 42911,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 8219,
						"numOfData2": 1,
						"timestamp": "2022-09-21 17:21:46",
						"priority": 0,
						"longitude": 96.1002733,
						"latitude": 21.8920766,
						"altitude": 75,
						"angle": 10,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 23:51:46",
						"thingName": "ltpk_2f_4001",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "subaru",
						"manufacturingdate": "25-Jun-1905",
						"model": "subaru-sambar-tt1",
						"state": "mandalay-region",
						"type": "truck-4x2-r",
						"zipcode": "05041"
					}
				],
				"state": "mandalay-region",
				"city": "chanmyathazi",
				"pincode": "05041",
				"siteName": "",
				"siteId": "",
				"make": "subaru",
				"model": "subaru-sambar-tt1",
				"size": "",
				"thingName": "ltpk_2f_4001",
				"UnDesignated Stoppage Points": [
					{
						"iccid1": 8995016202,
						"iccid2": 1091240010,
						"totalodometer": 270138578,
						"axisx": 5,
						"axisy": 20,
						"axisz": 65534,
						"gsmsignal": 4,
						"speed": 0,
						"externalvoltage": 12.302,
						"gnssstatus": 1,
						"batterylevel": 96,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 380746,
						"sleepmode": 0,
						"gsmcellid": 42911,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 23028,
						"numOfData2": 1,
						"timestamp": "2022-09-20 18:31:28",
						"priority": 0,
						"longitude": 96.1002099,
						"latitude": 21.8920133,
						"altitude": 63,
						"angle": 166,
						"satellites": 14,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 01:01:28",
						"thingName": "ltpk_2f_4001",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "subaru",
						"manufacturingdate": "25-Jun-1905",
						"model": "subaru-sambar-tt1",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05041",
						"stoppageDuration": 471.9,
						"ignitionOnTime": 25149
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240010,
						"totalodometer": 270172896,
						"axisx": 65450,
						"axisy": 37,
						"axisz": 217,
						"gsmsignal": 2,
						"speed": 26,
						"externalvoltage": 14.267,
						"gnssstatus": 1,
						"batterylevel": 95,
						"gnsspdop": 1,
						"gnsshdop": 0.6000000000000001,
						"tripodometer": 415064,
						"sleepmode": 0,
						"gsmcellid": 40323,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 22135,
						"numOfData2": 1,
						"timestamp": "2022-09-21 03:45:24",
						"priority": 0,
						"longitude": 96.0693316,
						"latitude": 21.9708566,
						"altitude": 60,
						"angle": 274,
						"satellites": 10,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 10:15:24",
						"thingName": "ltpk_2f_4001",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "subaru",
						"manufacturingdate": "25-Jun-1905",
						"model": "subaru-sambar-tt1",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05041",
						"stoppageDuration": 55.266666666666666,
						"ignitionOnTime": 3316
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240010,
						"totalodometer": 270173061,
						"axisx": 65500,
						"axisy": 17,
						"axisz": 65528,
						"gsmsignal": 4,
						"speed": 6,
						"externalvoltage": 12.263,
						"gnssstatus": 1,
						"batterylevel": 96,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 415229,
						"sleepmode": 0,
						"gsmcellid": 42171,
						"gsmareacode": 8022,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 57673,
						"numOfData2": 1,
						"timestamp": "2022-09-21 04:41:02",
						"priority": 0,
						"longitude": 96.0690166,
						"latitude": 21.9707833,
						"altitude": 69,
						"angle": 105,
						"satellites": 10,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 11:11:02",
						"thingName": "ltpk_2f_4001",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "subaru",
						"manufacturingdate": "25-Jun-1905",
						"model": "subaru-sambar-tt1",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05041",
						"stoppageDuration": 134.95,
						"ignitionOnTime": 7171
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240010,
						"totalodometer": 270176408,
						"axisx": 65510,
						"axisy": 22,
						"axisz": 65380,
						"gsmsignal": 2,
						"speed": 8,
						"externalvoltage": 14.269,
						"gnssstatus": 1,
						"batterylevel": 96,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 418576,
						"sleepmode": 0,
						"gsmcellid": 42171,
						"gsmareacode": 8022,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 51562,
						"numOfData2": 1,
						"timestamp": "2022-09-21 07:17:09",
						"priority": 0,
						"longitude": 96.0691466,
						"latitude": 21.9708149,
						"altitude": 63,
						"angle": 234,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 13:47:09",
						"thingName": "ltpk_2f_4001",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "subaru",
						"manufacturingdate": "25-Jun-1905",
						"model": "subaru-sambar-tt1",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05041",
						"stoppageDuration": 171.71666666666667,
						"ignitionOnTime": 10284
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240010,
						"totalodometer": 270195581,
						"axisx": 65473,
						"axisy": 40,
						"axisz": 288,
						"gsmsignal": 3,
						"speed": 15,
						"externalvoltage": 15.498000000000001,
						"gnssstatus": 1,
						"batterylevel": 82,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.5,
						"tripodometer": 437749,
						"sleepmode": 0,
						"gsmcellid": 42911,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 207,
						"codecId": 8,
						"numOfData1": 2,
						"crc": 52966,
						"numOfData2": 2,
						"timestamp": "2022-09-21 11:26:09",
						"priority": 0,
						"longitude": 96.1048383,
						"latitude": 21.9002333,
						"altitude": 67,
						"angle": 252,
						"satellites": 10,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 17:56:09",
						"thingName": "ltpk_2f_4001",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "subaru",
						"manufacturingdate": "25-Jun-1905",
						"model": "subaru-sambar-tt1",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05041",
						"stoppageDuration": 25.016666666666666,
						"ignitionOnTime": 1062
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240010,
						"totalodometer": 270197107,
						"axisx": 65495,
						"axisy": 12,
						"axisz": 104,
						"gsmsignal": 4,
						"speed": 13,
						"externalvoltage": 14.204,
						"gnssstatus": 1,
						"batterylevel": 96,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 439275,
						"sleepmode": 0,
						"gsmcellid": 42911,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 402,
						"numOfData2": 1,
						"timestamp": "2022-09-21 11:59:56",
						"priority": 0,
						"longitude": 96.1001766,
						"latitude": 21.8924883,
						"altitude": 62,
						"angle": 189,
						"satellites": 14,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 18:29:56",
						"thingName": "ltpk_2f_4001",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "subaru",
						"manufacturingdate": "25-Jun-1905",
						"model": "subaru-sambar-tt1",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05041",
						"stoppageDuration": 17.283333333333335,
						"ignitionOnTime": 799
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240010,
						"totalodometer": 270200587,
						"axisx": 65507,
						"axisy": 9,
						"axisz": 77,
						"gsmsignal": 3,
						"speed": 18,
						"externalvoltage": 14.311,
						"gnssstatus": 1,
						"batterylevel": 95,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 442755,
						"sleepmode": 0,
						"gsmcellid": 40761,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 40211,
						"numOfData2": 1,
						"timestamp": "2022-09-21 12:25:13",
						"priority": 0,
						"longitude": 96.1106066,
						"latitude": 21.9130283,
						"altitude": 65,
						"angle": 89,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 18:55:13",
						"thingName": "ltpk_2f_4001",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "subaru",
						"manufacturingdate": "25-Jun-1905",
						"model": "subaru-sambar-tt1",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05041",
						"stoppageDuration": 26.8,
						"ignitionOnTime": 636
					}
				],
				"Designated Stoppage Points": [],
				"Start Time": "2022-09-21 01:01:28",
				"End Time": "2022-09-21 23:51:46",
				"Stoppage Points": [
					{
						"iccid1": 8995016202,
						"iccid2": 1091240010,
						"totalodometer": 270138578,
						"axisx": 5,
						"axisy": 20,
						"axisz": 65534,
						"gsmsignal": 4,
						"speed": 0,
						"externalvoltage": 12.302,
						"gnssstatus": 1,
						"batterylevel": 96,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 380746,
						"sleepmode": 0,
						"gsmcellid": 42911,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 23028,
						"numOfData2": 1,
						"timestamp": "2022-09-20 18:31:28",
						"priority": 0,
						"longitude": 96.1002099,
						"latitude": 21.8920133,
						"altitude": 63,
						"angle": 166,
						"satellites": 14,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 01:01:28",
						"thingName": "ltpk_2f_4001",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "subaru",
						"manufacturingdate": "25-Jun-1905",
						"model": "subaru-sambar-tt1",
						"state": "mandalay-region",
						"type": "truck-4x2-r",
						"zipcode": "05041",
						"stoppageDuration": 471.9,
						"ignitionOnTime": 25149
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240010,
						"totalodometer": 270172896,
						"axisx": 65450,
						"axisy": 37,
						"axisz": 217,
						"gsmsignal": 2,
						"speed": 26,
						"externalvoltage": 14.267,
						"gnssstatus": 1,
						"batterylevel": 95,
						"gnsspdop": 1,
						"gnsshdop": 0.6000000000000001,
						"tripodometer": 415064,
						"sleepmode": 0,
						"gsmcellid": 40323,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 22135,
						"numOfData2": 1,
						"timestamp": "2022-09-21 03:45:24",
						"priority": 0,
						"longitude": 96.0693316,
						"latitude": 21.9708566,
						"altitude": 60,
						"angle": 274,
						"satellites": 10,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 10:15:24",
						"thingName": "ltpk_2f_4001",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "subaru",
						"manufacturingdate": "25-Jun-1905",
						"model": "subaru-sambar-tt1",
						"state": "mandalay-region",
						"type": "truck-4x2-r",
						"zipcode": "05041",
						"stoppageDuration": 55.266666666666666,
						"ignitionOnTime": 3316
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240010,
						"totalodometer": 270173061,
						"axisx": 65500,
						"axisy": 17,
						"axisz": 65528,
						"gsmsignal": 4,
						"speed": 6,
						"externalvoltage": 12.263,
						"gnssstatus": 1,
						"batterylevel": 96,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 415229,
						"sleepmode": 0,
						"gsmcellid": 42171,
						"gsmareacode": 8022,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 57673,
						"numOfData2": 1,
						"timestamp": "2022-09-21 04:41:02",
						"priority": 0,
						"longitude": 96.0690166,
						"latitude": 21.9707833,
						"altitude": 69,
						"angle": 105,
						"satellites": 10,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 11:11:02",
						"thingName": "ltpk_2f_4001",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "subaru",
						"manufacturingdate": "25-Jun-1905",
						"model": "subaru-sambar-tt1",
						"state": "mandalay-region",
						"type": "truck-4x2-r",
						"zipcode": "05041",
						"stoppageDuration": 134.95,
						"ignitionOnTime": 7171
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240010,
						"totalodometer": 270176408,
						"axisx": 65510,
						"axisy": 22,
						"axisz": 65380,
						"gsmsignal": 2,
						"speed": 8,
						"externalvoltage": 14.269,
						"gnssstatus": 1,
						"batterylevel": 96,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 418576,
						"sleepmode": 0,
						"gsmcellid": 42171,
						"gsmareacode": 8022,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 51562,
						"numOfData2": 1,
						"timestamp": "2022-09-21 07:17:09",
						"priority": 0,
						"longitude": 96.0691466,
						"latitude": 21.9708149,
						"altitude": 63,
						"angle": 234,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 13:47:09",
						"thingName": "ltpk_2f_4001",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "subaru",
						"manufacturingdate": "25-Jun-1905",
						"model": "subaru-sambar-tt1",
						"state": "mandalay-region",
						"type": "truck-4x2-r",
						"zipcode": "05041",
						"stoppageDuration": 171.71666666666667,
						"ignitionOnTime": 10284
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240010,
						"totalodometer": 270195581,
						"axisx": 65473,
						"axisy": 40,
						"axisz": 288,
						"gsmsignal": 3,
						"speed": 15,
						"externalvoltage": 15.498000000000001,
						"gnssstatus": 1,
						"batterylevel": 82,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.5,
						"tripodometer": 437749,
						"sleepmode": 0,
						"gsmcellid": 42911,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 207,
						"codecId": 8,
						"numOfData1": 2,
						"crc": 52966,
						"numOfData2": 2,
						"timestamp": "2022-09-21 11:26:09",
						"priority": 0,
						"longitude": 96.1048383,
						"latitude": 21.9002333,
						"altitude": 67,
						"angle": 252,
						"satellites": 10,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 17:56:09",
						"thingName": "ltpk_2f_4001",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "subaru",
						"manufacturingdate": "25-Jun-1905",
						"model": "subaru-sambar-tt1",
						"state": "mandalay-region",
						"type": "truck-4x2-r",
						"zipcode": "05041",
						"stoppageDuration": 25.016666666666666,
						"ignitionOnTime": 1062
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240010,
						"totalodometer": 270197107,
						"axisx": 65495,
						"axisy": 12,
						"axisz": 104,
						"gsmsignal": 4,
						"speed": 13,
						"externalvoltage": 14.204,
						"gnssstatus": 1,
						"batterylevel": 96,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 439275,
						"sleepmode": 0,
						"gsmcellid": 42911,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 402,
						"numOfData2": 1,
						"timestamp": "2022-09-21 11:59:56",
						"priority": 0,
						"longitude": 96.1001766,
						"latitude": 21.8924883,
						"altitude": 62,
						"angle": 189,
						"satellites": 14,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 18:29:56",
						"thingName": "ltpk_2f_4001",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "subaru",
						"manufacturingdate": "25-Jun-1905",
						"model": "subaru-sambar-tt1",
						"state": "mandalay-region",
						"type": "truck-4x2-r",
						"zipcode": "05041",
						"stoppageDuration": 17.283333333333335,
						"ignitionOnTime": 799
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240010,
						"totalodometer": 270200587,
						"axisx": 65507,
						"axisy": 9,
						"axisz": 77,
						"gsmsignal": 3,
						"speed": 18,
						"externalvoltage": 14.311,
						"gnssstatus": 1,
						"batterylevel": 95,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 442755,
						"sleepmode": 0,
						"gsmcellid": 40761,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 40211,
						"numOfData2": 1,
						"timestamp": "2022-09-21 12:25:13",
						"priority": 0,
						"longitude": 96.1106066,
						"latitude": 21.9130283,
						"altitude": 65,
						"angle": 89,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 18:55:13",
						"thingName": "ltpk_2f_4001",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "subaru",
						"manufacturingdate": "25-Jun-1905",
						"model": "subaru-sambar-tt1",
						"state": "mandalay-region",
						"type": "truck-4x2-r",
						"zipcode": "05041",
						"stoppageDuration": 26.8,
						"ignitionOnTime": 636
					}
				],
				"Total Stoppage Time": 902.9333333333333,
				"Total Travel Time": "7hr 47min 22sec",
				"Route Points": [
					{
						"iccid1": 8995016202,
						"iccid2": 1091240010,
						"totalodometer": 270138578,
						"axisx": 5,
						"axisy": 20,
						"axisz": 65534,
						"gsmsignal": 4,
						"speed": 0,
						"externalvoltage": 12.302,
						"gnssstatus": 1,
						"batterylevel": 96,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 380746,
						"sleepmode": 0,
						"gsmcellid": 42911,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 23028,
						"numOfData2": 1,
						"timestamp": "2022-09-20 18:31:28",
						"priority": 0,
						"longitude": 96.1002099,
						"latitude": 21.8920133,
						"altitude": 63,
						"angle": 166,
						"satellites": 14,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 01:01:28",
						"thingName": "ltpk_2f_4001",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "subaru",
						"manufacturingdate": "25-Jun-1905",
						"model": "subaru-sambar-tt1",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05041",
						"stoppageDuration": 471.9,
						"ignitionOnTime": 25149
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240010,
						"totalodometer": 270138578,
						"axisx": 7,
						"axisy": 15,
						"axisz": 65534,
						"gsmsignal": 4,
						"speed": 0,
						"externalvoltage": 12.280000000000001,
						"gnssstatus": 1,
						"batterylevel": 96,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 380746,
						"sleepmode": 0,
						"gsmcellid": 42911,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 32495,
						"numOfData2": 1,
						"timestamp": "2022-09-20 18:46:28",
						"priority": 0,
						"longitude": 96.1002099,
						"latitude": 21.8920133,
						"altitude": 59,
						"angle": 166,
						"satellites": 12,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 01:16:28",
						"thingName": "ltpk_2f_4001",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "subaru",
						"manufacturingdate": "25-Jun-1905",
						"model": "subaru-sambar-tt1",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05041"
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240010,
						"totalodometer": 270172896,
						"axisx": 65450,
						"axisy": 37,
						"axisz": 217,
						"gsmsignal": 2,
						"speed": 26,
						"externalvoltage": 14.267,
						"gnssstatus": 1,
						"batterylevel": 95,
						"gnsspdop": 1,
						"gnsshdop": 0.6000000000000001,
						"tripodometer": 415064,
						"sleepmode": 0,
						"gsmcellid": 40323,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 22135,
						"numOfData2": 1,
						"timestamp": "2022-09-21 03:45:24",
						"priority": 0,
						"longitude": 96.0693316,
						"latitude": 21.9708566,
						"altitude": 60,
						"angle": 274,
						"satellites": 10,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 10:15:24",
						"thingName": "ltpk_2f_4001",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "subaru",
						"manufacturingdate": "25-Jun-1905",
						"model": "subaru-sambar-tt1",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05041",
						"stoppageDuration": 55.266666666666666,
						"ignitionOnTime": 3316
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240010,
						"totalodometer": 270173061,
						"axisx": 65500,
						"axisy": 17,
						"axisz": 65528,
						"gsmsignal": 4,
						"speed": 6,
						"externalvoltage": 12.263,
						"gnssstatus": 1,
						"batterylevel": 96,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 415229,
						"sleepmode": 0,
						"gsmcellid": 42171,
						"gsmareacode": 8022,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 57673,
						"numOfData2": 1,
						"timestamp": "2022-09-21 04:41:02",
						"priority": 0,
						"longitude": 96.0690166,
						"latitude": 21.9707833,
						"altitude": 69,
						"angle": 105,
						"satellites": 10,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 11:11:02",
						"thingName": "ltpk_2f_4001",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "subaru",
						"manufacturingdate": "25-Jun-1905",
						"model": "subaru-sambar-tt1",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05041",
						"stoppageDuration": 134.95,
						"ignitionOnTime": 7171
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240010,
						"totalodometer": 270176408,
						"axisx": 65510,
						"axisy": 22,
						"axisz": 65380,
						"gsmsignal": 2,
						"speed": 8,
						"externalvoltage": 14.269,
						"gnssstatus": 1,
						"batterylevel": 96,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 418576,
						"sleepmode": 0,
						"gsmcellid": 42171,
						"gsmareacode": 8022,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 51562,
						"numOfData2": 1,
						"timestamp": "2022-09-21 07:17:09",
						"priority": 0,
						"longitude": 96.0691466,
						"latitude": 21.9708149,
						"altitude": 63,
						"angle": 234,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 13:47:09",
						"thingName": "ltpk_2f_4001",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "subaru",
						"manufacturingdate": "25-Jun-1905",
						"model": "subaru-sambar-tt1",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05041",
						"stoppageDuration": 171.71666666666667,
						"ignitionOnTime": 10284
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240010,
						"totalodometer": 270195581,
						"axisx": 65473,
						"axisy": 40,
						"axisz": 288,
						"gsmsignal": 3,
						"speed": 15,
						"externalvoltage": 15.498000000000001,
						"gnssstatus": 1,
						"batterylevel": 82,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.5,
						"tripodometer": 437749,
						"sleepmode": 0,
						"gsmcellid": 42911,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 207,
						"codecId": 8,
						"numOfData1": 2,
						"crc": 52966,
						"numOfData2": 2,
						"timestamp": "2022-09-21 11:26:09",
						"priority": 0,
						"longitude": 96.1048383,
						"latitude": 21.9002333,
						"altitude": 67,
						"angle": 252,
						"satellites": 10,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 17:56:09",
						"thingName": "ltpk_2f_4001",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "subaru",
						"manufacturingdate": "25-Jun-1905",
						"model": "subaru-sambar-tt1",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05041",
						"stoppageDuration": 25.016666666666666,
						"ignitionOnTime": 1062
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240010,
						"totalodometer": 270197107,
						"axisx": 65495,
						"axisy": 12,
						"axisz": 104,
						"gsmsignal": 4,
						"speed": 13,
						"externalvoltage": 14.204,
						"gnssstatus": 1,
						"batterylevel": 96,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 439275,
						"sleepmode": 0,
						"gsmcellid": 42911,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 402,
						"numOfData2": 1,
						"timestamp": "2022-09-21 11:59:56",
						"priority": 0,
						"longitude": 96.1001766,
						"latitude": 21.8924883,
						"altitude": 62,
						"angle": 189,
						"satellites": 14,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 18:29:56",
						"thingName": "ltpk_2f_4001",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "subaru",
						"manufacturingdate": "25-Jun-1905",
						"model": "subaru-sambar-tt1",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05041",
						"stoppageDuration": 17.283333333333335,
						"ignitionOnTime": 799
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240010,
						"totalodometer": 270200587,
						"axisx": 65507,
						"axisy": 9,
						"axisz": 77,
						"gsmsignal": 3,
						"speed": 18,
						"externalvoltage": 14.311,
						"gnssstatus": 1,
						"batterylevel": 95,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 442755,
						"sleepmode": 0,
						"gsmcellid": 40761,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 40211,
						"numOfData2": 1,
						"timestamp": "2022-09-21 12:25:13",
						"priority": 0,
						"longitude": 96.1106066,
						"latitude": 21.9130283,
						"altitude": 65,
						"angle": 89,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 18:55:13",
						"thingName": "ltpk_2f_4001",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "subaru",
						"manufacturingdate": "25-Jun-1905",
						"model": "subaru-sambar-tt1",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05041",
						"stoppageDuration": 26.8,
						"ignitionOnTime": 636
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240010,
						"totalodometer": 270204530,
						"axisx": 65527,
						"axisy": 12,
						"axisz": 65533,
						"gsmsignal": 4,
						"speed": 0,
						"externalvoltage": 12.125,
						"gnssstatus": 1,
						"batterylevel": 96,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 446698,
						"sleepmode": 0,
						"gsmcellid": 42911,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 8219,
						"numOfData2": 1,
						"timestamp": "2022-09-21 17:21:46",
						"priority": 0,
						"longitude": 96.1002733,
						"latitude": 21.8920766,
						"altitude": 75,
						"angle": 10,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 23:51:46",
						"thingName": "ltpk_2f_4001",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "subaru",
						"manufacturingdate": "25-Jun-1905",
						"model": "subaru-sambar-tt1",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05041"
					}
				],
				"Total Distance Travelled": 65.952
			},
			{
				"Declutter Point": [
					{
						"iccid1": 8995016202,
						"iccid2": 1091240093,
						"totalodometer": 401657987,
						"axisx": 64630,
						"axisy": 194,
						"axisz": 64602,
						"gsmsignal": 2,
						"speed": 0,
						"externalvoltage": 12.411,
						"gnssstatus": 1,
						"batterylevel": 87,
						"gnsspdop": 0.8,
						"gnsshdop": 0.6000000000000001,
						"tripodometer": 31,
						"sleepmode": 0,
						"gsmcellid": 13983,
						"gsmareacode": 314,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 58324,
						"numOfData2": 1,
						"timestamp": "2022-09-20 18:46:59",
						"priority": 0,
						"longitude": 95.9966466,
						"latitude": 17.144305,
						"altitude": 65533,
						"angle": 190,
						"satellites": 9,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 01:16:59",
						"thingName": "ltpk_8g_4613",
						"batchnumber": "2010",
						"city": "dagon-myothit",
						"make": "toyota",
						"manufacturingdate": "26-Jun-1905",
						"model": "shwe-gantawin-l-t-sgtwl-04",
						"state": "yangon-region",
						"type": "pickup-4x2-r",
						"zipcode": "11431"
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240093,
						"totalodometer": 401725405,
						"axisx": 64631,
						"axisy": 175,
						"axisz": 64568,
						"gsmsignal": 1,
						"speed": 0,
						"externalvoltage": 12.586,
						"gnssstatus": 1,
						"batterylevel": 87,
						"gnsspdop": 0.9,
						"gnsshdop": 0.7000000000000001,
						"tripodometer": 11669,
						"sleepmode": 0,
						"gsmcellid": 13983,
						"gsmareacode": 314,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 309,
						"codecId": 8,
						"numOfData1": 3,
						"crc": 13626,
						"numOfData2": 3,
						"timestamp": "2022-09-21 11:07:00",
						"priority": 0,
						"longitude": 95.9966066,
						"latitude": 17.1442883,
						"altitude": 12,
						"angle": 212,
						"satellites": 7,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 17:37:00",
						"thingName": "ltpk_8g_4613",
						"batchnumber": "2010",
						"city": "dagon-myothit",
						"make": "toyota",
						"manufacturingdate": "26-Jun-1905",
						"model": "shwe-gantawin-l-t-sgtwl-04",
						"state": "yangon-region",
						"type": "pickup-4x2-r",
						"zipcode": "11431"
					}
				],
				"state": "yangon-region",
				"city": "dagon-myothit",
				"pincode": "11431",
				"siteName": "",
				"siteId": "",
				"make": "toyota",
				"model": "shwe-gantawin-l-t-sgtwl-04",
				"size": "",
				"thingName": "ltpk_8g_4613",
				"UnDesignated Stoppage Points": [
					{
						"iccid1": 8995016202,
						"iccid2": 1091240093,
						"totalodometer": 401657987,
						"axisx": 64632,
						"axisy": 188,
						"axisz": 64583,
						"gsmsignal": 2,
						"speed": 0,
						"externalvoltage": 12.411,
						"gnssstatus": 1,
						"batterylevel": 87,
						"gnsspdop": 0.9,
						"gnsshdop": 0.8,
						"tripodometer": 31,
						"sleepmode": 0,
						"gsmcellid": 13983,
						"gsmareacode": 314,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 2554,
						"numOfData2": 1,
						"timestamp": "2022-09-20 18:31:59",
						"priority": 0,
						"longitude": 95.9966466,
						"latitude": 17.144305,
						"altitude": 65534,
						"angle": 190,
						"satellites": 8,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 01:01:59",
						"thingName": "ltpk_8g_4613",
						"batchnumber": "2010",
						"city": "dagon-myothit",
						"make": "toyota",
						"manufacturingdate": "26-Jun-1905",
						"model": "shwe-gantawin-l-t-sgtwl-04",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11431",
						"stoppageDuration": 462.55,
						"ignitionOnTime": 27390
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240093,
						"totalodometer": 401668984,
						"axisx": 64595,
						"axisy": 181,
						"axisz": 64611,
						"gsmsignal": 3,
						"speed": 19,
						"externalvoltage": 13.619,
						"gnssstatus": 1,
						"batterylevel": 84,
						"gnsspdop": 0.8,
						"gnsshdop": 0.4,
						"tripodometer": 11028,
						"sleepmode": 0,
						"gsmcellid": 13962,
						"gsmareacode": 314,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 23835,
						"numOfData2": 1,
						"timestamp": "2022-09-21 02:42:55",
						"priority": 0,
						"longitude": 96.0578816,
						"latitude": 17.1063633,
						"altitude": 20,
						"angle": 92,
						"satellites": 11,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 09:12:55",
						"thingName": "ltpk_8g_4613",
						"batchnumber": "2010",
						"city": "dagon-myothit",
						"make": "toyota",
						"manufacturingdate": "26-Jun-1905",
						"model": "shwe-gantawin-l-t-sgtwl-04",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11431",
						"stoppageDuration": 27.633333333333333,
						"ignitionOnTime": 680
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240093,
						"totalodometer": 401669266,
						"axisx": 64636,
						"axisy": 197,
						"axisz": 64544,
						"gsmsignal": 3,
						"speed": 24,
						"externalvoltage": 0,
						"gnssstatus": 1,
						"batterylevel": 81,
						"gnsspdop": 1,
						"gnsshdop": 0.7000000000000001,
						"tripodometer": 11310,
						"sleepmode": 0,
						"gsmcellid": 13962,
						"gsmareacode": 314,
						"ignition": 0,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 58252,
						"numOfData2": 1,
						"timestamp": "2022-09-21 03:12:00",
						"priority": 0,
						"longitude": 96.059365,
						"latitude": 17.1064616,
						"altitude": 65517,
						"angle": 10,
						"satellites": 9,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 09:42:00",
						"thingName": "ltpk_8g_4613",
						"batchnumber": "2010",
						"city": "dagon-myothit",
						"make": "toyota",
						"manufacturingdate": "26-Jun-1905",
						"model": "shwe-gantawin-l-t-sgtwl-04",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11431",
						"stoppageDuration": 37.53333333333333,
						"ignitionOnTime": 0
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240093,
						"totalodometer": 401700371,
						"axisx": 64696,
						"axisy": 205,
						"axisz": 64565,
						"gsmsignal": 1,
						"speed": 8,
						"externalvoltage": 13.636000000000001,
						"gnssstatus": 1,
						"batterylevel": 88,
						"gnsspdop": 1,
						"gnsshdop": 0.5,
						"tripodometer": 42415,
						"sleepmode": 0,
						"gsmcellid": 14953,
						"gsmareacode": 314,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 5943,
						"numOfData2": 1,
						"timestamp": "2022-09-21 04:54:58",
						"priority": 0,
						"longitude": 96.1411733,
						"latitude": 17.1282416,
						"altitude": 4,
						"angle": 279,
						"satellites": 10,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 11:24:58",
						"thingName": "ltpk_8g_4613",
						"batchnumber": "2010",
						"city": "dagon-myothit",
						"make": "toyota",
						"manufacturingdate": "26-Jun-1905",
						"model": "shwe-gantawin-l-t-sgtwl-04",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11431",
						"stoppageDuration": 38.81666666666667,
						"ignitionOnTime": 195
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240093,
						"totalodometer": 401713676,
						"axisx": 64597,
						"axisy": 221,
						"axisz": 64762,
						"gsmsignal": 2,
						"speed": 28,
						"externalvoltage": 13.564,
						"gnssstatus": 1,
						"batterylevel": 90,
						"gnsspdop": 0.7000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 55720,
						"sleepmode": 0,
						"gsmcellid": 14912,
						"gsmareacode": 314,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 40511,
						"numOfData2": 1,
						"timestamp": "2022-09-21 06:10:09",
						"priority": 0,
						"longitude": 96.064865,
						"latitude": 17.1071,
						"altitude": 9,
						"angle": 264,
						"satellites": 10,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 12:40:09",
						"thingName": "ltpk_8g_4613",
						"batchnumber": "2010",
						"city": "dagon-myothit",
						"make": "toyota",
						"manufacturingdate": "26-Jun-1905",
						"model": "shwe-gantawin-l-t-sgtwl-04",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11431",
						"stoppageDuration": 99.01666666666667,
						"ignitionOnTime": 594
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240093,
						"totalodometer": 401716628,
						"axisx": 64590,
						"axisy": 84,
						"axisz": 64631,
						"gsmsignal": 3,
						"speed": 11,
						"externalvoltage": 0,
						"gnssstatus": 1,
						"batterylevel": 76,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 2892,
						"sleepmode": 0,
						"gsmcellid": 15453,
						"gsmareacode": 314,
						"ignition": 0,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 55179,
						"numOfData2": 1,
						"timestamp": "2022-09-21 07:59:41",
						"priority": 0,
						"longitude": 96.0463099,
						"latitude": 17.09889,
						"altitude": 30,
						"angle": 93,
						"satellites": 11,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 14:29:41",
						"thingName": "ltpk_8g_4613",
						"batchnumber": "2010",
						"city": "dagon-myothit",
						"make": "toyota",
						"manufacturingdate": "26-Jun-1905",
						"model": "shwe-gantawin-l-t-sgtwl-04",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11431",
						"stoppageDuration": 21.216666666666665,
						"ignitionOnTime": 32
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240093,
						"totalodometer": 401725139,
						"axisx": 64418,
						"axisy": 236,
						"axisz": 64620,
						"gsmsignal": 3,
						"speed": 15,
						"externalvoltage": 13.428,
						"gnssstatus": 1,
						"batterylevel": 95,
						"gnsspdop": 0.8,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 11403,
						"sleepmode": 0,
						"gsmcellid": 13983,
						"gsmareacode": 314,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 207,
						"codecId": 8,
						"numOfData1": 2,
						"crc": 61271,
						"numOfData2": 2,
						"timestamp": "2022-09-21 08:34:27",
						"priority": 0,
						"longitude": 95.9987716,
						"latitude": 17.1444416,
						"altitude": 7,
						"angle": 305,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 15:04:27",
						"thingName": "ltpk_8g_4613",
						"batchnumber": "2010",
						"city": "dagon-myothit",
						"make": "toyota",
						"manufacturingdate": "26-Jun-1905",
						"model": "shwe-gantawin-l-t-sgtwl-04",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11431",
						"stoppageDuration": 32.78333333333333,
						"ignitionOnTime": 1956
					}
				],
				"Designated Stoppage Points": [],
				"Start Time": "2022-09-21 01:01:59",
				"End Time": "2022-09-21 17:37:00",
				"Stoppage Points": [
					{
						"iccid1": 8995016202,
						"iccid2": 1091240093,
						"totalodometer": 401657987,
						"axisx": 64632,
						"axisy": 188,
						"axisz": 64583,
						"gsmsignal": 2,
						"speed": 0,
						"externalvoltage": 12.411,
						"gnssstatus": 1,
						"batterylevel": 87,
						"gnsspdop": 0.9,
						"gnsshdop": 0.8,
						"tripodometer": 31,
						"sleepmode": 0,
						"gsmcellid": 13983,
						"gsmareacode": 314,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 2554,
						"numOfData2": 1,
						"timestamp": "2022-09-20 18:31:59",
						"priority": 0,
						"longitude": 95.9966466,
						"latitude": 17.144305,
						"altitude": 65534,
						"angle": 190,
						"satellites": 8,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 01:01:59",
						"thingName": "ltpk_8g_4613",
						"batchnumber": "2010",
						"city": "dagon-myothit",
						"make": "toyota",
						"manufacturingdate": "26-Jun-1905",
						"model": "shwe-gantawin-l-t-sgtwl-04",
						"state": "yangon-region",
						"type": "pickup-4x2-r",
						"zipcode": "11431",
						"stoppageDuration": 462.55,
						"ignitionOnTime": 27390
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240093,
						"totalodometer": 401668984,
						"axisx": 64595,
						"axisy": 181,
						"axisz": 64611,
						"gsmsignal": 3,
						"speed": 19,
						"externalvoltage": 13.619,
						"gnssstatus": 1,
						"batterylevel": 84,
						"gnsspdop": 0.8,
						"gnsshdop": 0.4,
						"tripodometer": 11028,
						"sleepmode": 0,
						"gsmcellid": 13962,
						"gsmareacode": 314,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 23835,
						"numOfData2": 1,
						"timestamp": "2022-09-21 02:42:55",
						"priority": 0,
						"longitude": 96.0578816,
						"latitude": 17.1063633,
						"altitude": 20,
						"angle": 92,
						"satellites": 11,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 09:12:55",
						"thingName": "ltpk_8g_4613",
						"batchnumber": "2010",
						"city": "dagon-myothit",
						"make": "toyota",
						"manufacturingdate": "26-Jun-1905",
						"model": "shwe-gantawin-l-t-sgtwl-04",
						"state": "yangon-region",
						"type": "pickup-4x2-r",
						"zipcode": "11431",
						"stoppageDuration": 27.633333333333333,
						"ignitionOnTime": 680
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240093,
						"totalodometer": 401669266,
						"axisx": 64636,
						"axisy": 197,
						"axisz": 64544,
						"gsmsignal": 3,
						"speed": 24,
						"externalvoltage": 0,
						"gnssstatus": 1,
						"batterylevel": 81,
						"gnsspdop": 1,
						"gnsshdop": 0.7000000000000001,
						"tripodometer": 11310,
						"sleepmode": 0,
						"gsmcellid": 13962,
						"gsmareacode": 314,
						"ignition": 0,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 58252,
						"numOfData2": 1,
						"timestamp": "2022-09-21 03:12:00",
						"priority": 0,
						"longitude": 96.059365,
						"latitude": 17.1064616,
						"altitude": 65517,
						"angle": 10,
						"satellites": 9,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 09:42:00",
						"thingName": "ltpk_8g_4613",
						"batchnumber": "2010",
						"city": "dagon-myothit",
						"make": "toyota",
						"manufacturingdate": "26-Jun-1905",
						"model": "shwe-gantawin-l-t-sgtwl-04",
						"state": "yangon-region",
						"type": "pickup-4x2-r",
						"zipcode": "11431",
						"stoppageDuration": 37.53333333333333,
						"ignitionOnTime": 0
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240093,
						"totalodometer": 401700371,
						"axisx": 64696,
						"axisy": 205,
						"axisz": 64565,
						"gsmsignal": 1,
						"speed": 8,
						"externalvoltage": 13.636000000000001,
						"gnssstatus": 1,
						"batterylevel": 88,
						"gnsspdop": 1,
						"gnsshdop": 0.5,
						"tripodometer": 42415,
						"sleepmode": 0,
						"gsmcellid": 14953,
						"gsmareacode": 314,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 5943,
						"numOfData2": 1,
						"timestamp": "2022-09-21 04:54:58",
						"priority": 0,
						"longitude": 96.1411733,
						"latitude": 17.1282416,
						"altitude": 4,
						"angle": 279,
						"satellites": 10,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 11:24:58",
						"thingName": "ltpk_8g_4613",
						"batchnumber": "2010",
						"city": "dagon-myothit",
						"make": "toyota",
						"manufacturingdate": "26-Jun-1905",
						"model": "shwe-gantawin-l-t-sgtwl-04",
						"state": "yangon-region",
						"type": "pickup-4x2-r",
						"zipcode": "11431",
						"stoppageDuration": 38.81666666666667,
						"ignitionOnTime": 195
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240093,
						"totalodometer": 401713676,
						"axisx": 64597,
						"axisy": 221,
						"axisz": 64762,
						"gsmsignal": 2,
						"speed": 28,
						"externalvoltage": 13.564,
						"gnssstatus": 1,
						"batterylevel": 90,
						"gnsspdop": 0.7000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 55720,
						"sleepmode": 0,
						"gsmcellid": 14912,
						"gsmareacode": 314,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 40511,
						"numOfData2": 1,
						"timestamp": "2022-09-21 06:10:09",
						"priority": 0,
						"longitude": 96.064865,
						"latitude": 17.1071,
						"altitude": 9,
						"angle": 264,
						"satellites": 10,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 12:40:09",
						"thingName": "ltpk_8g_4613",
						"batchnumber": "2010",
						"city": "dagon-myothit",
						"make": "toyota",
						"manufacturingdate": "26-Jun-1905",
						"model": "shwe-gantawin-l-t-sgtwl-04",
						"state": "yangon-region",
						"type": "pickup-4x2-r",
						"zipcode": "11431",
						"stoppageDuration": 99.01666666666667,
						"ignitionOnTime": 594
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240093,
						"totalodometer": 401716628,
						"axisx": 64590,
						"axisy": 84,
						"axisz": 64631,
						"gsmsignal": 3,
						"speed": 11,
						"externalvoltage": 0,
						"gnssstatus": 1,
						"batterylevel": 76,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 2892,
						"sleepmode": 0,
						"gsmcellid": 15453,
						"gsmareacode": 314,
						"ignition": 0,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 55179,
						"numOfData2": 1,
						"timestamp": "2022-09-21 07:59:41",
						"priority": 0,
						"longitude": 96.0463099,
						"latitude": 17.09889,
						"altitude": 30,
						"angle": 93,
						"satellites": 11,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 14:29:41",
						"thingName": "ltpk_8g_4613",
						"batchnumber": "2010",
						"city": "dagon-myothit",
						"make": "toyota",
						"manufacturingdate": "26-Jun-1905",
						"model": "shwe-gantawin-l-t-sgtwl-04",
						"state": "yangon-region",
						"type": "pickup-4x2-r",
						"zipcode": "11431",
						"stoppageDuration": 21.216666666666665,
						"ignitionOnTime": 32
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240093,
						"totalodometer": 401725139,
						"axisx": 64418,
						"axisy": 236,
						"axisz": 64620,
						"gsmsignal": 3,
						"speed": 15,
						"externalvoltage": 13.428,
						"gnssstatus": 1,
						"batterylevel": 95,
						"gnsspdop": 0.8,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 11403,
						"sleepmode": 0,
						"gsmcellid": 13983,
						"gsmareacode": 314,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 207,
						"codecId": 8,
						"numOfData1": 2,
						"crc": 61271,
						"numOfData2": 2,
						"timestamp": "2022-09-21 08:34:27",
						"priority": 0,
						"longitude": 95.9987716,
						"latitude": 17.1444416,
						"altitude": 7,
						"angle": 305,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 15:04:27",
						"thingName": "ltpk_8g_4613",
						"batchnumber": "2010",
						"city": "dagon-myothit",
						"make": "toyota",
						"manufacturingdate": "26-Jun-1905",
						"model": "shwe-gantawin-l-t-sgtwl-04",
						"state": "yangon-region",
						"type": "pickup-4x2-r",
						"zipcode": "11431",
						"stoppageDuration": 32.78333333333333,
						"ignitionOnTime": 1956
					}
				],
				"Total Stoppage Time": 719.55,
				"Total Travel Time": "4hr 35min 28sec",
				"Route Points": [
					{
						"iccid1": 8995016202,
						"iccid2": 1091240093,
						"totalodometer": 401657987,
						"axisx": 64632,
						"axisy": 188,
						"axisz": 64583,
						"gsmsignal": 2,
						"speed": 0,
						"externalvoltage": 12.411,
						"gnssstatus": 1,
						"batterylevel": 87,
						"gnsspdop": 0.9,
						"gnsshdop": 0.8,
						"tripodometer": 31,
						"sleepmode": 0,
						"gsmcellid": 13983,
						"gsmareacode": 314,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 2554,
						"numOfData2": 1,
						"timestamp": "2022-09-20 18:31:59",
						"priority": 0,
						"longitude": 95.9966466,
						"latitude": 17.144305,
						"altitude": 65534,
						"angle": 190,
						"satellites": 8,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 01:01:59",
						"thingName": "ltpk_8g_4613",
						"batchnumber": "2010",
						"city": "dagon-myothit",
						"make": "toyota",
						"manufacturingdate": "26-Jun-1905",
						"model": "shwe-gantawin-l-t-sgtwl-04",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11431",
						"stoppageDuration": 462.55,
						"ignitionOnTime": 27390
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240093,
						"totalodometer": 401657987,
						"axisx": 64630,
						"axisy": 194,
						"axisz": 64602,
						"gsmsignal": 2,
						"speed": 0,
						"externalvoltage": 12.411,
						"gnssstatus": 1,
						"batterylevel": 87,
						"gnsspdop": 0.8,
						"gnsshdop": 0.6000000000000001,
						"tripodometer": 31,
						"sleepmode": 0,
						"gsmcellid": 13983,
						"gsmareacode": 314,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 58324,
						"numOfData2": 1,
						"timestamp": "2022-09-20 18:46:59",
						"priority": 0,
						"longitude": 95.9966466,
						"latitude": 17.144305,
						"altitude": 65533,
						"angle": 190,
						"satellites": 9,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 01:16:59",
						"thingName": "ltpk_8g_4613",
						"batchnumber": "2010",
						"city": "dagon-myothit",
						"make": "toyota",
						"manufacturingdate": "26-Jun-1905",
						"model": "shwe-gantawin-l-t-sgtwl-04",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11431"
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240093,
						"totalodometer": 401668984,
						"axisx": 64595,
						"axisy": 181,
						"axisz": 64611,
						"gsmsignal": 3,
						"speed": 19,
						"externalvoltage": 13.619,
						"gnssstatus": 1,
						"batterylevel": 84,
						"gnsspdop": 0.8,
						"gnsshdop": 0.4,
						"tripodometer": 11028,
						"sleepmode": 0,
						"gsmcellid": 13962,
						"gsmareacode": 314,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 23835,
						"numOfData2": 1,
						"timestamp": "2022-09-21 02:42:55",
						"priority": 0,
						"longitude": 96.0578816,
						"latitude": 17.1063633,
						"altitude": 20,
						"angle": 92,
						"satellites": 11,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 09:12:55",
						"thingName": "ltpk_8g_4613",
						"batchnumber": "2010",
						"city": "dagon-myothit",
						"make": "toyota",
						"manufacturingdate": "26-Jun-1905",
						"model": "shwe-gantawin-l-t-sgtwl-04",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11431",
						"stoppageDuration": 27.633333333333333,
						"ignitionOnTime": 680
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240093,
						"totalodometer": 401669266,
						"axisx": 64636,
						"axisy": 197,
						"axisz": 64544,
						"gsmsignal": 3,
						"speed": 24,
						"externalvoltage": 0,
						"gnssstatus": 1,
						"batterylevel": 81,
						"gnsspdop": 1,
						"gnsshdop": 0.7000000000000001,
						"tripodometer": 11310,
						"sleepmode": 0,
						"gsmcellid": 13962,
						"gsmareacode": 314,
						"ignition": 0,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 58252,
						"numOfData2": 1,
						"timestamp": "2022-09-21 03:12:00",
						"priority": 0,
						"longitude": 96.059365,
						"latitude": 17.1064616,
						"altitude": 65517,
						"angle": 10,
						"satellites": 9,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 09:42:00",
						"thingName": "ltpk_8g_4613",
						"batchnumber": "2010",
						"city": "dagon-myothit",
						"make": "toyota",
						"manufacturingdate": "26-Jun-1905",
						"model": "shwe-gantawin-l-t-sgtwl-04",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11431",
						"stoppageDuration": 37.53333333333333,
						"ignitionOnTime": 0
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240093,
						"totalodometer": 401700371,
						"axisx": 64696,
						"axisy": 205,
						"axisz": 64565,
						"gsmsignal": 1,
						"speed": 8,
						"externalvoltage": 13.636000000000001,
						"gnssstatus": 1,
						"batterylevel": 88,
						"gnsspdop": 1,
						"gnsshdop": 0.5,
						"tripodometer": 42415,
						"sleepmode": 0,
						"gsmcellid": 14953,
						"gsmareacode": 314,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 5943,
						"numOfData2": 1,
						"timestamp": "2022-09-21 04:54:58",
						"priority": 0,
						"longitude": 96.1411733,
						"latitude": 17.1282416,
						"altitude": 4,
						"angle": 279,
						"satellites": 10,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 11:24:58",
						"thingName": "ltpk_8g_4613",
						"batchnumber": "2010",
						"city": "dagon-myothit",
						"make": "toyota",
						"manufacturingdate": "26-Jun-1905",
						"model": "shwe-gantawin-l-t-sgtwl-04",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11431",
						"stoppageDuration": 38.81666666666667,
						"ignitionOnTime": 195
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240093,
						"totalodometer": 401713676,
						"axisx": 64597,
						"axisy": 221,
						"axisz": 64762,
						"gsmsignal": 2,
						"speed": 28,
						"externalvoltage": 13.564,
						"gnssstatus": 1,
						"batterylevel": 90,
						"gnsspdop": 0.7000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 55720,
						"sleepmode": 0,
						"gsmcellid": 14912,
						"gsmareacode": 314,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 40511,
						"numOfData2": 1,
						"timestamp": "2022-09-21 06:10:09",
						"priority": 0,
						"longitude": 96.064865,
						"latitude": 17.1071,
						"altitude": 9,
						"angle": 264,
						"satellites": 10,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 12:40:09",
						"thingName": "ltpk_8g_4613",
						"batchnumber": "2010",
						"city": "dagon-myothit",
						"make": "toyota",
						"manufacturingdate": "26-Jun-1905",
						"model": "shwe-gantawin-l-t-sgtwl-04",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11431",
						"stoppageDuration": 99.01666666666667,
						"ignitionOnTime": 594
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240093,
						"totalodometer": 401716628,
						"axisx": 64590,
						"axisy": 84,
						"axisz": 64631,
						"gsmsignal": 3,
						"speed": 11,
						"externalvoltage": 0,
						"gnssstatus": 1,
						"batterylevel": 76,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 2892,
						"sleepmode": 0,
						"gsmcellid": 15453,
						"gsmareacode": 314,
						"ignition": 0,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 55179,
						"numOfData2": 1,
						"timestamp": "2022-09-21 07:59:41",
						"priority": 0,
						"longitude": 96.0463099,
						"latitude": 17.09889,
						"altitude": 30,
						"angle": 93,
						"satellites": 11,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 14:29:41",
						"thingName": "ltpk_8g_4613",
						"batchnumber": "2010",
						"city": "dagon-myothit",
						"make": "toyota",
						"manufacturingdate": "26-Jun-1905",
						"model": "shwe-gantawin-l-t-sgtwl-04",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11431",
						"stoppageDuration": 21.216666666666665,
						"ignitionOnTime": 32
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240093,
						"totalodometer": 401725139,
						"axisx": 64418,
						"axisy": 236,
						"axisz": 64620,
						"gsmsignal": 3,
						"speed": 15,
						"externalvoltage": 13.428,
						"gnssstatus": 1,
						"batterylevel": 95,
						"gnsspdop": 0.8,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 11403,
						"sleepmode": 0,
						"gsmcellid": 13983,
						"gsmareacode": 314,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 207,
						"codecId": 8,
						"numOfData1": 2,
						"crc": 61271,
						"numOfData2": 2,
						"timestamp": "2022-09-21 08:34:27",
						"priority": 0,
						"longitude": 95.9987716,
						"latitude": 17.1444416,
						"altitude": 7,
						"angle": 305,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 15:04:27",
						"thingName": "ltpk_8g_4613",
						"batchnumber": "2010",
						"city": "dagon-myothit",
						"make": "toyota",
						"manufacturingdate": "26-Jun-1905",
						"model": "shwe-gantawin-l-t-sgtwl-04",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11431",
						"stoppageDuration": 32.78333333333333,
						"ignitionOnTime": 1956
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240093,
						"totalodometer": 401725405,
						"axisx": 64631,
						"axisy": 175,
						"axisz": 64568,
						"gsmsignal": 1,
						"speed": 0,
						"externalvoltage": 12.586,
						"gnssstatus": 1,
						"batterylevel": 87,
						"gnsspdop": 0.9,
						"gnsshdop": 0.7000000000000001,
						"tripodometer": 11669,
						"sleepmode": 0,
						"gsmcellid": 13983,
						"gsmareacode": 314,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 309,
						"codecId": 8,
						"numOfData1": 3,
						"crc": 13626,
						"numOfData2": 3,
						"timestamp": "2022-09-21 11:07:00",
						"priority": 0,
						"longitude": 95.9966066,
						"latitude": 17.1442883,
						"altitude": 12,
						"angle": 212,
						"satellites": 7,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 17:37:00",
						"thingName": "ltpk_8g_4613",
						"batchnumber": "2010",
						"city": "dagon-myothit",
						"make": "toyota",
						"manufacturingdate": "26-Jun-1905",
						"model": "shwe-gantawin-l-t-sgtwl-04",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11431"
					}
				],
				"Total Distance Travelled": 67.428
			},
			{
				"Declutter Point": [
					{
						"iccid1": 8995016202,
						"iccid2": 1091240028,
						"totalodometer": 242617467,
						"axisx": 65517,
						"axisy": 68,
						"axisz": 65507,
						"gsmsignal": 5,
						"speed": 0,
						"externalvoltage": 12.919,
						"gnssstatus": 1,
						"batterylevel": 82,
						"gnsspdop": 0.5,
						"gnsshdop": 0.4,
						"tripodometer": 8346649,
						"sleepmode": 0,
						"gsmcellid": 40761,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 35312,
						"numOfData2": 1,
						"timestamp": "2022-09-20 18:49:08",
						"priority": 0,
						"longitude": 96.1111833,
						"latitude": 21.9154849,
						"altitude": 64,
						"angle": 283,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 01:19:08",
						"thingName": "ltpk_1g_2584",
						"batchnumber": "2003",
						"city": "pyigyitagon",
						"make": "mazda",
						"manufacturingdate": "25-Jun-1905",
						"model": "mazda",
						"state": "mandalay-region",
						"type": "minni-truck-4x2",
						"zipcode": "05051"
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240028,
						"totalodometer": 242761244,
						"axisx": 65514,
						"axisy": 70,
						"axisz": 65516,
						"gsmsignal": 5,
						"speed": 0,
						"externalvoltage": 12.928,
						"gnssstatus": 1,
						"batterylevel": 82,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 8490426,
						"sleepmode": 0,
						"gsmcellid": 40761,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 40432,
						"numOfData2": 1,
						"timestamp": "2022-09-21 17:28:48",
						"priority": 0,
						"longitude": 96.1112366,
						"latitude": 21.9155133,
						"altitude": 67,
						"angle": 192,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 23:58:48",
						"thingName": "ltpk_1g_2584",
						"batchnumber": "2003",
						"city": "pyigyitagon",
						"make": "mazda",
						"manufacturingdate": "25-Jun-1905",
						"model": "mazda",
						"state": "mandalay-region",
						"type": "minni-truck-4x2",
						"zipcode": "05051"
					}
				],
				"state": "mandalay-region",
				"city": "pyigyitagon",
				"pincode": "05051",
				"siteName": "",
				"siteId": "",
				"make": "mazda",
				"model": "mazda",
				"size": "",
				"thingName": "ltpk_1g_2584",
				"UnDesignated Stoppage Points": [
					{
						"iccid1": 8995016202,
						"iccid2": 1091240028,
						"totalodometer": 242617467,
						"axisx": 65532,
						"axisy": 64,
						"axisz": 65503,
						"gsmsignal": 5,
						"speed": 0,
						"externalvoltage": 12.924,
						"gnssstatus": 1,
						"batterylevel": 82,
						"gnsspdop": 0.5,
						"gnsshdop": 0.4,
						"tripodometer": 8346649,
						"sleepmode": 0,
						"gsmcellid": 40761,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 31908,
						"numOfData2": 1,
						"timestamp": "2022-09-20 18:34:08",
						"priority": 0,
						"longitude": 96.1111833,
						"latitude": 21.9154849,
						"altitude": 64,
						"angle": 283,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 01:04:08",
						"thingName": "ltpk_1g_2584",
						"batchnumber": "2003",
						"city": "pyigyitagon",
						"make": "mazda",
						"manufacturingdate": "25-Jun-1905",
						"model": "mazda",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05051",
						"stoppageDuration": 185.65,
						"ignitionOnTime": 11139
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240028,
						"totalodometer": 242638925,
						"axisx": 108,
						"axisy": 85,
						"axisz": 52,
						"gsmsignal": 3,
						"speed": 8,
						"externalvoltage": 13.988,
						"gnssstatus": 1,
						"batterylevel": 82,
						"gnsspdop": 0.8,
						"gnsshdop": 0.4,
						"tripodometer": 8368107,
						"sleepmode": 0,
						"gsmcellid": 40761,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 207,
						"codecId": 8,
						"numOfData1": 2,
						"crc": 53066,
						"numOfData2": 2,
						"timestamp": "2022-09-20 22:15:05",
						"priority": 0,
						"longitude": 96.11126,
						"latitude": 21.9156883,
						"altitude": 61,
						"angle": 168,
						"satellites": 11,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 04:45:05",
						"thingName": "ltpk_1g_2584",
						"batchnumber": "2003",
						"city": "pyigyitagon",
						"make": "mazda",
						"manufacturingdate": "25-Jun-1905",
						"model": "mazda",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05051",
						"stoppageDuration": 213.36666666666667,
						"ignitionOnTime": 12802
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240028,
						"totalodometer": 242639840,
						"axisx": 56,
						"axisy": 97,
						"axisz": 65493,
						"gsmsignal": 4,
						"speed": 19,
						"externalvoltage": 14.347,
						"gnssstatus": 1,
						"batterylevel": 81,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 8369022,
						"sleepmode": 0,
						"gsmcellid": 41611,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 207,
						"codecId": 8,
						"numOfData1": 2,
						"crc": 31251,
						"numOfData2": 2,
						"timestamp": "2022-09-21 01:50:56",
						"priority": 0,
						"longitude": 96.1123499,
						"latitude": 21.9199533,
						"altitude": 70,
						"angle": 91,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 08:20:56",
						"thingName": "ltpk_1g_2584",
						"batchnumber": "2003",
						"city": "pyigyitagon",
						"make": "mazda",
						"manufacturingdate": "25-Jun-1905",
						"model": "mazda",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05051",
						"stoppageDuration": 66.96666666666667,
						"ignitionOnTime": 4018
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240028,
						"totalodometer": 242679990,
						"axisx": 13,
						"axisy": 55,
						"axisz": 65521,
						"gsmsignal": 5,
						"speed": 14,
						"externalvoltage": 14.259,
						"gnssstatus": 1,
						"batterylevel": 81,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 8409172,
						"sleepmode": 0,
						"gsmcellid": 41713,
						"gsmareacode": 8033,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 24539,
						"numOfData2": 1,
						"timestamp": "2022-09-21 04:22:32",
						"priority": 0,
						"longitude": 95.9673183,
						"latitude": 21.7086216,
						"altitude": 80,
						"angle": 145,
						"satellites": 14,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 10:52:32",
						"thingName": "ltpk_1g_2584",
						"batchnumber": "2003",
						"city": "pyigyitagon",
						"make": "mazda",
						"manufacturingdate": "25-Jun-1905",
						"model": "mazda",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05051",
						"stoppageDuration": 71.45,
						"ignitionOnTime": 4287
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240028,
						"totalodometer": 242716365,
						"axisx": 100,
						"axisy": 160,
						"axisz": 70,
						"gsmsignal": 4,
						"speed": 12,
						"externalvoltage": 14.247,
						"gnssstatus": 1,
						"batterylevel": 81,
						"gnsspdop": 0.8,
						"gnsshdop": 0.4,
						"tripodometer": 8445547,
						"sleepmode": 0,
						"gsmcellid": 42281,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 207,
						"codecId": 8,
						"numOfData1": 2,
						"crc": 63147,
						"numOfData2": 2,
						"timestamp": "2022-09-21 06:22:35",
						"priority": 0,
						"longitude": 96.1327333,
						"latitude": 21.91491,
						"altitude": 61,
						"angle": 79,
						"satellites": 9,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 12:52:35",
						"thingName": "ltpk_1g_2584",
						"batchnumber": "2003",
						"city": "pyigyitagon",
						"make": "mazda",
						"manufacturingdate": "25-Jun-1905",
						"model": "mazda",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05051",
						"stoppageDuration": 39.333333333333336,
						"ignitionOnTime": 2360
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240028,
						"totalodometer": 242727243,
						"axisx": 9,
						"axisy": 24,
						"axisz": 138,
						"gsmsignal": 4,
						"speed": 19,
						"externalvoltage": 14.251,
						"gnssstatus": 1,
						"batterylevel": 81,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 8456425,
						"sleepmode": 0,
						"gsmcellid": 42781,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 37530,
						"numOfData2": 1,
						"timestamp": "2022-09-21 07:17:59",
						"priority": 0,
						"longitude": 96.0814683,
						"latitude": 21.851035,
						"altitude": 75,
						"angle": 77,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 13:47:59",
						"thingName": "ltpk_1g_2584",
						"batchnumber": "2003",
						"city": "pyigyitagon",
						"make": "mazda",
						"manufacturingdate": "25-Jun-1905",
						"model": "mazda",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05051",
						"stoppageDuration": 20.95,
						"ignitionOnTime": 1257
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240028,
						"totalodometer": 242729193,
						"axisx": 65435,
						"axisy": 65482,
						"axisz": 65518,
						"gsmsignal": 5,
						"speed": 6,
						"externalvoltage": 14.366,
						"gnssstatus": 1,
						"batterylevel": 82,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 8458375,
						"sleepmode": 0,
						"gsmcellid": 42901,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 411,
						"codecId": 8,
						"numOfData1": 4,
						"crc": 26759,
						"numOfData2": 4,
						"timestamp": "2022-09-21 07:44:29",
						"priority": 0,
						"longitude": 96.0687516,
						"latitude": 21.8472016,
						"altitude": 65,
						"angle": 308,
						"satellites": 17,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 14:14:29",
						"thingName": "ltpk_1g_2584",
						"batchnumber": "2003",
						"city": "pyigyitagon",
						"make": "mazda",
						"manufacturingdate": "25-Jun-1905",
						"model": "mazda",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05051",
						"stoppageDuration": 36.18333333333333,
						"ignitionOnTime": 2171
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240028,
						"totalodometer": 242749330,
						"axisx": 1,
						"axisy": 65453,
						"axisz": 117,
						"gsmsignal": 5,
						"speed": 9,
						"externalvoltage": 14.223,
						"gnssstatus": 1,
						"batterylevel": 81,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 8478512,
						"sleepmode": 0,
						"gsmcellid": 40761,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 42912,
						"numOfData2": 1,
						"timestamp": "2022-09-21 09:33:27",
						"priority": 0,
						"longitude": 96.1111616,
						"latitude": 21.9156966,
						"altitude": 61,
						"angle": 186,
						"satellites": 16,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 16:03:27",
						"thingName": "ltpk_1g_2584",
						"batchnumber": "2003",
						"city": "pyigyitagon",
						"make": "mazda",
						"manufacturingdate": "25-Jun-1905",
						"model": "mazda",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05051",
						"stoppageDuration": 63.8,
						"ignitionOnTime": 3828
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240028,
						"totalodometer": 242754204,
						"axisx": 118,
						"axisy": 65533,
						"axisz": 124,
						"gsmsignal": 3,
						"speed": 10,
						"externalvoltage": 14.377,
						"gnssstatus": 1,
						"batterylevel": 82,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 8483386,
						"sleepmode": 0,
						"gsmcellid": 42771,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 14905,
						"numOfData2": 1,
						"timestamp": "2022-09-21 10:48:25",
						"priority": 0,
						"longitude": 96.132965,
						"latitude": 21.8942983,
						"altitude": 68,
						"angle": 98,
						"satellites": 15,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 17:18:25",
						"thingName": "ltpk_1g_2584",
						"batchnumber": "2003",
						"city": "pyigyitagon",
						"make": "mazda",
						"manufacturingdate": "25-Jun-1905",
						"model": "mazda",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05051",
						"stoppageDuration": 51.916666666666664,
						"ignitionOnTime": 3115
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240028,
						"totalodometer": 242758878,
						"axisx": 45,
						"axisy": 65510,
						"axisz": 42,
						"gsmsignal": 4,
						"speed": 17,
						"externalvoltage": 13.777000000000001,
						"gnssstatus": 1,
						"batterylevel": 82,
						"gnsspdop": 0.7000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 8488060,
						"sleepmode": 0,
						"gsmcellid": 40521,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 13050,
						"numOfData2": 1,
						"timestamp": "2022-09-21 11:51:39",
						"priority": 0,
						"longitude": 96.1230116,
						"latitude": 21.9258516,
						"altitude": 67,
						"angle": 250,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 18:21:39",
						"thingName": "ltpk_1g_2584",
						"batchnumber": "2003",
						"city": "pyigyitagon",
						"make": "mazda",
						"manufacturingdate": "25-Jun-1905",
						"model": "mazda",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05051",
						"stoppageDuration": 43.71666666666667,
						"ignitionOnTime": 2623
					}
				],
				"Designated Stoppage Points": [],
				"Start Time": "2022-09-21 01:04:08",
				"End Time": "2022-09-21 23:58:48",
				"Stoppage Points": [
					{
						"iccid1": 8995016202,
						"iccid2": 1091240028,
						"totalodometer": 242617467,
						"axisx": 65532,
						"axisy": 64,
						"axisz": 65503,
						"gsmsignal": 5,
						"speed": 0,
						"externalvoltage": 12.924,
						"gnssstatus": 1,
						"batterylevel": 82,
						"gnsspdop": 0.5,
						"gnsshdop": 0.4,
						"tripodometer": 8346649,
						"sleepmode": 0,
						"gsmcellid": 40761,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 31908,
						"numOfData2": 1,
						"timestamp": "2022-09-20 18:34:08",
						"priority": 0,
						"longitude": 96.1111833,
						"latitude": 21.9154849,
						"altitude": 64,
						"angle": 283,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 01:04:08",
						"thingName": "ltpk_1g_2584",
						"batchnumber": "2003",
						"city": "pyigyitagon",
						"make": "mazda",
						"manufacturingdate": "25-Jun-1905",
						"model": "mazda",
						"state": "mandalay-region",
						"type": "minni-truck-4x2",
						"zipcode": "05051",
						"stoppageDuration": 185.65,
						"ignitionOnTime": 11139
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240028,
						"totalodometer": 242638925,
						"axisx": 108,
						"axisy": 85,
						"axisz": 52,
						"gsmsignal": 3,
						"speed": 8,
						"externalvoltage": 13.988,
						"gnssstatus": 1,
						"batterylevel": 82,
						"gnsspdop": 0.8,
						"gnsshdop": 0.4,
						"tripodometer": 8368107,
						"sleepmode": 0,
						"gsmcellid": 40761,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 207,
						"codecId": 8,
						"numOfData1": 2,
						"crc": 53066,
						"numOfData2": 2,
						"timestamp": "2022-09-20 22:15:05",
						"priority": 0,
						"longitude": 96.11126,
						"latitude": 21.9156883,
						"altitude": 61,
						"angle": 168,
						"satellites": 11,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 04:45:05",
						"thingName": "ltpk_1g_2584",
						"batchnumber": "2003",
						"city": "pyigyitagon",
						"make": "mazda",
						"manufacturingdate": "25-Jun-1905",
						"model": "mazda",
						"state": "mandalay-region",
						"type": "minni-truck-4x2",
						"zipcode": "05051",
						"stoppageDuration": 213.36666666666667,
						"ignitionOnTime": 12802
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240028,
						"totalodometer": 242639840,
						"axisx": 56,
						"axisy": 97,
						"axisz": 65493,
						"gsmsignal": 4,
						"speed": 19,
						"externalvoltage": 14.347,
						"gnssstatus": 1,
						"batterylevel": 81,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 8369022,
						"sleepmode": 0,
						"gsmcellid": 41611,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 207,
						"codecId": 8,
						"numOfData1": 2,
						"crc": 31251,
						"numOfData2": 2,
						"timestamp": "2022-09-21 01:50:56",
						"priority": 0,
						"longitude": 96.1123499,
						"latitude": 21.9199533,
						"altitude": 70,
						"angle": 91,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 08:20:56",
						"thingName": "ltpk_1g_2584",
						"batchnumber": "2003",
						"city": "pyigyitagon",
						"make": "mazda",
						"manufacturingdate": "25-Jun-1905",
						"model": "mazda",
						"state": "mandalay-region",
						"type": "minni-truck-4x2",
						"zipcode": "05051",
						"stoppageDuration": 66.96666666666667,
						"ignitionOnTime": 4018
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240028,
						"totalodometer": 242679990,
						"axisx": 13,
						"axisy": 55,
						"axisz": 65521,
						"gsmsignal": 5,
						"speed": 14,
						"externalvoltage": 14.259,
						"gnssstatus": 1,
						"batterylevel": 81,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 8409172,
						"sleepmode": 0,
						"gsmcellid": 41713,
						"gsmareacode": 8033,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 24539,
						"numOfData2": 1,
						"timestamp": "2022-09-21 04:22:32",
						"priority": 0,
						"longitude": 95.9673183,
						"latitude": 21.7086216,
						"altitude": 80,
						"angle": 145,
						"satellites": 14,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 10:52:32",
						"thingName": "ltpk_1g_2584",
						"batchnumber": "2003",
						"city": "pyigyitagon",
						"make": "mazda",
						"manufacturingdate": "25-Jun-1905",
						"model": "mazda",
						"state": "mandalay-region",
						"type": "minni-truck-4x2",
						"zipcode": "05051",
						"stoppageDuration": 71.45,
						"ignitionOnTime": 4287
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240028,
						"totalodometer": 242716365,
						"axisx": 100,
						"axisy": 160,
						"axisz": 70,
						"gsmsignal": 4,
						"speed": 12,
						"externalvoltage": 14.247,
						"gnssstatus": 1,
						"batterylevel": 81,
						"gnsspdop": 0.8,
						"gnsshdop": 0.4,
						"tripodometer": 8445547,
						"sleepmode": 0,
						"gsmcellid": 42281,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 207,
						"codecId": 8,
						"numOfData1": 2,
						"crc": 63147,
						"numOfData2": 2,
						"timestamp": "2022-09-21 06:22:35",
						"priority": 0,
						"longitude": 96.1327333,
						"latitude": 21.91491,
						"altitude": 61,
						"angle": 79,
						"satellites": 9,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 12:52:35",
						"thingName": "ltpk_1g_2584",
						"batchnumber": "2003",
						"city": "pyigyitagon",
						"make": "mazda",
						"manufacturingdate": "25-Jun-1905",
						"model": "mazda",
						"state": "mandalay-region",
						"type": "minni-truck-4x2",
						"zipcode": "05051",
						"stoppageDuration": 39.333333333333336,
						"ignitionOnTime": 2360
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240028,
						"totalodometer": 242727243,
						"axisx": 9,
						"axisy": 24,
						"axisz": 138,
						"gsmsignal": 4,
						"speed": 19,
						"externalvoltage": 14.251,
						"gnssstatus": 1,
						"batterylevel": 81,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 8456425,
						"sleepmode": 0,
						"gsmcellid": 42781,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 37530,
						"numOfData2": 1,
						"timestamp": "2022-09-21 07:17:59",
						"priority": 0,
						"longitude": 96.0814683,
						"latitude": 21.851035,
						"altitude": 75,
						"angle": 77,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 13:47:59",
						"thingName": "ltpk_1g_2584",
						"batchnumber": "2003",
						"city": "pyigyitagon",
						"make": "mazda",
						"manufacturingdate": "25-Jun-1905",
						"model": "mazda",
						"state": "mandalay-region",
						"type": "minni-truck-4x2",
						"zipcode": "05051",
						"stoppageDuration": 20.95,
						"ignitionOnTime": 1257
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240028,
						"totalodometer": 242729193,
						"axisx": 65435,
						"axisy": 65482,
						"axisz": 65518,
						"gsmsignal": 5,
						"speed": 6,
						"externalvoltage": 14.366,
						"gnssstatus": 1,
						"batterylevel": 82,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 8458375,
						"sleepmode": 0,
						"gsmcellid": 42901,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 411,
						"codecId": 8,
						"numOfData1": 4,
						"crc": 26759,
						"numOfData2": 4,
						"timestamp": "2022-09-21 07:44:29",
						"priority": 0,
						"longitude": 96.0687516,
						"latitude": 21.8472016,
						"altitude": 65,
						"angle": 308,
						"satellites": 17,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 14:14:29",
						"thingName": "ltpk_1g_2584",
						"batchnumber": "2003",
						"city": "pyigyitagon",
						"make": "mazda",
						"manufacturingdate": "25-Jun-1905",
						"model": "mazda",
						"state": "mandalay-region",
						"type": "minni-truck-4x2",
						"zipcode": "05051",
						"stoppageDuration": 36.18333333333333,
						"ignitionOnTime": 2171
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240028,
						"totalodometer": 242749330,
						"axisx": 1,
						"axisy": 65453,
						"axisz": 117,
						"gsmsignal": 5,
						"speed": 9,
						"externalvoltage": 14.223,
						"gnssstatus": 1,
						"batterylevel": 81,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 8478512,
						"sleepmode": 0,
						"gsmcellid": 40761,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 42912,
						"numOfData2": 1,
						"timestamp": "2022-09-21 09:33:27",
						"priority": 0,
						"longitude": 96.1111616,
						"latitude": 21.9156966,
						"altitude": 61,
						"angle": 186,
						"satellites": 16,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 16:03:27",
						"thingName": "ltpk_1g_2584",
						"batchnumber": "2003",
						"city": "pyigyitagon",
						"make": "mazda",
						"manufacturingdate": "25-Jun-1905",
						"model": "mazda",
						"state": "mandalay-region",
						"type": "minni-truck-4x2",
						"zipcode": "05051",
						"stoppageDuration": 63.8,
						"ignitionOnTime": 3828
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240028,
						"totalodometer": 242754204,
						"axisx": 118,
						"axisy": 65533,
						"axisz": 124,
						"gsmsignal": 3,
						"speed": 10,
						"externalvoltage": 14.377,
						"gnssstatus": 1,
						"batterylevel": 82,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 8483386,
						"sleepmode": 0,
						"gsmcellid": 42771,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 14905,
						"numOfData2": 1,
						"timestamp": "2022-09-21 10:48:25",
						"priority": 0,
						"longitude": 96.132965,
						"latitude": 21.8942983,
						"altitude": 68,
						"angle": 98,
						"satellites": 15,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 17:18:25",
						"thingName": "ltpk_1g_2584",
						"batchnumber": "2003",
						"city": "pyigyitagon",
						"make": "mazda",
						"manufacturingdate": "25-Jun-1905",
						"model": "mazda",
						"state": "mandalay-region",
						"type": "minni-truck-4x2",
						"zipcode": "05051",
						"stoppageDuration": 51.916666666666664,
						"ignitionOnTime": 3115
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240028,
						"totalodometer": 242758878,
						"axisx": 45,
						"axisy": 65510,
						"axisz": 42,
						"gsmsignal": 4,
						"speed": 17,
						"externalvoltage": 13.777000000000001,
						"gnssstatus": 1,
						"batterylevel": 82,
						"gnsspdop": 0.7000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 8488060,
						"sleepmode": 0,
						"gsmcellid": 40521,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 13050,
						"numOfData2": 1,
						"timestamp": "2022-09-21 11:51:39",
						"priority": 0,
						"longitude": 96.1230116,
						"latitude": 21.9258516,
						"altitude": 67,
						"angle": 250,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 18:21:39",
						"thingName": "ltpk_1g_2584",
						"batchnumber": "2003",
						"city": "pyigyitagon",
						"make": "mazda",
						"manufacturingdate": "25-Jun-1905",
						"model": "mazda",
						"state": "mandalay-region",
						"type": "minni-truck-4x2",
						"zipcode": "05051",
						"stoppageDuration": 43.71666666666667,
						"ignitionOnTime": 2623
					}
				],
				"Total Stoppage Time": 793.3333333333334,
				"Total Travel Time": "9hr 41min 20sec",
				"Route Points": [
					{
						"iccid1": 8995016202,
						"iccid2": 1091240028,
						"totalodometer": 242617467,
						"axisx": 65532,
						"axisy": 64,
						"axisz": 65503,
						"gsmsignal": 5,
						"speed": 0,
						"externalvoltage": 12.924,
						"gnssstatus": 1,
						"batterylevel": 82,
						"gnsspdop": 0.5,
						"gnsshdop": 0.4,
						"tripodometer": 8346649,
						"sleepmode": 0,
						"gsmcellid": 40761,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 31908,
						"numOfData2": 1,
						"timestamp": "2022-09-20 18:34:08",
						"priority": 0,
						"longitude": 96.1111833,
						"latitude": 21.9154849,
						"altitude": 64,
						"angle": 283,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 01:04:08",
						"thingName": "ltpk_1g_2584",
						"batchnumber": "2003",
						"city": "pyigyitagon",
						"make": "mazda",
						"manufacturingdate": "25-Jun-1905",
						"model": "mazda",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05051",
						"stoppageDuration": 185.65,
						"ignitionOnTime": 11139
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240028,
						"totalodometer": 242617467,
						"axisx": 65517,
						"axisy": 68,
						"axisz": 65507,
						"gsmsignal": 5,
						"speed": 0,
						"externalvoltage": 12.919,
						"gnssstatus": 1,
						"batterylevel": 82,
						"gnsspdop": 0.5,
						"gnsshdop": 0.4,
						"tripodometer": 8346649,
						"sleepmode": 0,
						"gsmcellid": 40761,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 35312,
						"numOfData2": 1,
						"timestamp": "2022-09-20 18:49:08",
						"priority": 0,
						"longitude": 96.1111833,
						"latitude": 21.9154849,
						"altitude": 64,
						"angle": 283,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 01:19:08",
						"thingName": "ltpk_1g_2584",
						"batchnumber": "2003",
						"city": "pyigyitagon",
						"make": "mazda",
						"manufacturingdate": "25-Jun-1905",
						"model": "mazda",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05051"
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240028,
						"totalodometer": 242638925,
						"axisx": 108,
						"axisy": 85,
						"axisz": 52,
						"gsmsignal": 3,
						"speed": 8,
						"externalvoltage": 13.988,
						"gnssstatus": 1,
						"batterylevel": 82,
						"gnsspdop": 0.8,
						"gnsshdop": 0.4,
						"tripodometer": 8368107,
						"sleepmode": 0,
						"gsmcellid": 40761,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 207,
						"codecId": 8,
						"numOfData1": 2,
						"crc": 53066,
						"numOfData2": 2,
						"timestamp": "2022-09-20 22:15:05",
						"priority": 0,
						"longitude": 96.11126,
						"latitude": 21.9156883,
						"altitude": 61,
						"angle": 168,
						"satellites": 11,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 04:45:05",
						"thingName": "ltpk_1g_2584",
						"batchnumber": "2003",
						"city": "pyigyitagon",
						"make": "mazda",
						"manufacturingdate": "25-Jun-1905",
						"model": "mazda",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05051",
						"stoppageDuration": 213.36666666666667,
						"ignitionOnTime": 12802
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240028,
						"totalodometer": 242639840,
						"axisx": 56,
						"axisy": 97,
						"axisz": 65493,
						"gsmsignal": 4,
						"speed": 19,
						"externalvoltage": 14.347,
						"gnssstatus": 1,
						"batterylevel": 81,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 8369022,
						"sleepmode": 0,
						"gsmcellid": 41611,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 207,
						"codecId": 8,
						"numOfData1": 2,
						"crc": 31251,
						"numOfData2": 2,
						"timestamp": "2022-09-21 01:50:56",
						"priority": 0,
						"longitude": 96.1123499,
						"latitude": 21.9199533,
						"altitude": 70,
						"angle": 91,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 08:20:56",
						"thingName": "ltpk_1g_2584",
						"batchnumber": "2003",
						"city": "pyigyitagon",
						"make": "mazda",
						"manufacturingdate": "25-Jun-1905",
						"model": "mazda",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05051",
						"stoppageDuration": 66.96666666666667,
						"ignitionOnTime": 4018
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240028,
						"totalodometer": 242679990,
						"axisx": 13,
						"axisy": 55,
						"axisz": 65521,
						"gsmsignal": 5,
						"speed": 14,
						"externalvoltage": 14.259,
						"gnssstatus": 1,
						"batterylevel": 81,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 8409172,
						"sleepmode": 0,
						"gsmcellid": 41713,
						"gsmareacode": 8033,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 24539,
						"numOfData2": 1,
						"timestamp": "2022-09-21 04:22:32",
						"priority": 0,
						"longitude": 95.9673183,
						"latitude": 21.7086216,
						"altitude": 80,
						"angle": 145,
						"satellites": 14,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 10:52:32",
						"thingName": "ltpk_1g_2584",
						"batchnumber": "2003",
						"city": "pyigyitagon",
						"make": "mazda",
						"manufacturingdate": "25-Jun-1905",
						"model": "mazda",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05051",
						"stoppageDuration": 71.45,
						"ignitionOnTime": 4287
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240028,
						"totalodometer": 242716365,
						"axisx": 100,
						"axisy": 160,
						"axisz": 70,
						"gsmsignal": 4,
						"speed": 12,
						"externalvoltage": 14.247,
						"gnssstatus": 1,
						"batterylevel": 81,
						"gnsspdop": 0.8,
						"gnsshdop": 0.4,
						"tripodometer": 8445547,
						"sleepmode": 0,
						"gsmcellid": 42281,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 207,
						"codecId": 8,
						"numOfData1": 2,
						"crc": 63147,
						"numOfData2": 2,
						"timestamp": "2022-09-21 06:22:35",
						"priority": 0,
						"longitude": 96.1327333,
						"latitude": 21.91491,
						"altitude": 61,
						"angle": 79,
						"satellites": 9,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 12:52:35",
						"thingName": "ltpk_1g_2584",
						"batchnumber": "2003",
						"city": "pyigyitagon",
						"make": "mazda",
						"manufacturingdate": "25-Jun-1905",
						"model": "mazda",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05051",
						"stoppageDuration": 39.333333333333336,
						"ignitionOnTime": 2360
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240028,
						"totalodometer": 242727243,
						"axisx": 9,
						"axisy": 24,
						"axisz": 138,
						"gsmsignal": 4,
						"speed": 19,
						"externalvoltage": 14.251,
						"gnssstatus": 1,
						"batterylevel": 81,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 8456425,
						"sleepmode": 0,
						"gsmcellid": 42781,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 37530,
						"numOfData2": 1,
						"timestamp": "2022-09-21 07:17:59",
						"priority": 0,
						"longitude": 96.0814683,
						"latitude": 21.851035,
						"altitude": 75,
						"angle": 77,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 13:47:59",
						"thingName": "ltpk_1g_2584",
						"batchnumber": "2003",
						"city": "pyigyitagon",
						"make": "mazda",
						"manufacturingdate": "25-Jun-1905",
						"model": "mazda",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05051",
						"stoppageDuration": 20.95,
						"ignitionOnTime": 1257
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240028,
						"totalodometer": 242729193,
						"axisx": 65435,
						"axisy": 65482,
						"axisz": 65518,
						"gsmsignal": 5,
						"speed": 6,
						"externalvoltage": 14.366,
						"gnssstatus": 1,
						"batterylevel": 82,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 8458375,
						"sleepmode": 0,
						"gsmcellid": 42901,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 411,
						"codecId": 8,
						"numOfData1": 4,
						"crc": 26759,
						"numOfData2": 4,
						"timestamp": "2022-09-21 07:44:29",
						"priority": 0,
						"longitude": 96.0687516,
						"latitude": 21.8472016,
						"altitude": 65,
						"angle": 308,
						"satellites": 17,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 14:14:29",
						"thingName": "ltpk_1g_2584",
						"batchnumber": "2003",
						"city": "pyigyitagon",
						"make": "mazda",
						"manufacturingdate": "25-Jun-1905",
						"model": "mazda",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05051",
						"stoppageDuration": 36.18333333333333,
						"ignitionOnTime": 2171
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240028,
						"totalodometer": 242749330,
						"axisx": 1,
						"axisy": 65453,
						"axisz": 117,
						"gsmsignal": 5,
						"speed": 9,
						"externalvoltage": 14.223,
						"gnssstatus": 1,
						"batterylevel": 81,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 8478512,
						"sleepmode": 0,
						"gsmcellid": 40761,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 42912,
						"numOfData2": 1,
						"timestamp": "2022-09-21 09:33:27",
						"priority": 0,
						"longitude": 96.1111616,
						"latitude": 21.9156966,
						"altitude": 61,
						"angle": 186,
						"satellites": 16,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 16:03:27",
						"thingName": "ltpk_1g_2584",
						"batchnumber": "2003",
						"city": "pyigyitagon",
						"make": "mazda",
						"manufacturingdate": "25-Jun-1905",
						"model": "mazda",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05051",
						"stoppageDuration": 63.8,
						"ignitionOnTime": 3828
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240028,
						"totalodometer": 242754204,
						"axisx": 118,
						"axisy": 65533,
						"axisz": 124,
						"gsmsignal": 3,
						"speed": 10,
						"externalvoltage": 14.377,
						"gnssstatus": 1,
						"batterylevel": 82,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 8483386,
						"sleepmode": 0,
						"gsmcellid": 42771,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 14905,
						"numOfData2": 1,
						"timestamp": "2022-09-21 10:48:25",
						"priority": 0,
						"longitude": 96.132965,
						"latitude": 21.8942983,
						"altitude": 68,
						"angle": 98,
						"satellites": 15,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 17:18:25",
						"thingName": "ltpk_1g_2584",
						"batchnumber": "2003",
						"city": "pyigyitagon",
						"make": "mazda",
						"manufacturingdate": "25-Jun-1905",
						"model": "mazda",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05051",
						"stoppageDuration": 51.916666666666664,
						"ignitionOnTime": 3115
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240028,
						"totalodometer": 242758878,
						"axisx": 45,
						"axisy": 65510,
						"axisz": 42,
						"gsmsignal": 4,
						"speed": 17,
						"externalvoltage": 13.777000000000001,
						"gnssstatus": 1,
						"batterylevel": 82,
						"gnsspdop": 0.7000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 8488060,
						"sleepmode": 0,
						"gsmcellid": 40521,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 13050,
						"numOfData2": 1,
						"timestamp": "2022-09-21 11:51:39",
						"priority": 0,
						"longitude": 96.1230116,
						"latitude": 21.9258516,
						"altitude": 67,
						"angle": 250,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 18:21:39",
						"thingName": "ltpk_1g_2584",
						"batchnumber": "2003",
						"city": "pyigyitagon",
						"make": "mazda",
						"manufacturingdate": "25-Jun-1905",
						"model": "mazda",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05051",
						"stoppageDuration": 43.71666666666667,
						"ignitionOnTime": 2623
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240028,
						"totalodometer": 242761244,
						"axisx": 65514,
						"axisy": 70,
						"axisz": 65516,
						"gsmsignal": 5,
						"speed": 0,
						"externalvoltage": 12.928,
						"gnssstatus": 1,
						"batterylevel": 82,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 8490426,
						"sleepmode": 0,
						"gsmcellid": 40761,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 40432,
						"numOfData2": 1,
						"timestamp": "2022-09-21 17:28:48",
						"priority": 0,
						"longitude": 96.1112366,
						"latitude": 21.9155133,
						"altitude": 67,
						"angle": 192,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 23:58:48",
						"thingName": "ltpk_1g_2584",
						"batchnumber": "2003",
						"city": "pyigyitagon",
						"make": "mazda",
						"manufacturingdate": "25-Jun-1905",
						"model": "mazda",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05051"
					}
				],
				"Total Distance Travelled": 143.777
			},
			{
				"Declutter Point": [
					{
						"iccid1": 8995016202,
						"iccid2": 1091240051,
						"totalodometer": 622768801,
						"axisx": 590,
						"axisy": 65037,
						"axisz": 65090,
						"gsmsignal": 3,
						"speed": 0,
						"externalvoltage": 12.674,
						"gnssstatus": 1,
						"batterylevel": 95,
						"gnsspdop": 0.5,
						"gnsshdop": 0.4,
						"tripodometer": 419482,
						"sleepmode": 0,
						"gsmcellid": 41082,
						"gsmareacode": 8022,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 1548,
						"numOfData2": 1,
						"timestamp": "2022-09-20 18:54:19",
						"priority": 0,
						"longitude": 96.0741066,
						"latitude": 21.9649383,
						"altitude": 69,
						"angle": 165,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 01:24:19",
						"thingName": "ltpk_2g_6332",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford",
						"state": "mandalay-region",
						"type": "pick-up-4x4",
						"zipcode": "05041"
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240051,
						"totalodometer": 622837384,
						"axisx": 597,
						"axisy": 65048,
						"axisz": 65076,
						"gsmsignal": 3,
						"speed": 0,
						"externalvoltage": 12.63,
						"gnssstatus": 1,
						"batterylevel": 95,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 488065,
						"sleepmode": 0,
						"gsmcellid": 41083,
						"gsmareacode": 8022,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 793,
						"numOfData2": 1,
						"timestamp": "2022-09-21 17:19:54",
						"priority": 0,
						"longitude": 96.0740883,
						"latitude": 21.96497,
						"altitude": 78,
						"angle": 192,
						"satellites": 14,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 23:49:54",
						"thingName": "ltpk_2g_6332",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford",
						"state": "mandalay-region",
						"type": "pick-up-4x4",
						"zipcode": "05041"
					}
				],
				"state": "mandalay-region",
				"city": "chanmyathazi",
				"pincode": "05041",
				"siteName": "",
				"siteId": "",
				"make": "ford",
				"model": "ford",
				"size": "",
				"thingName": "ltpk_2g_6332",
				"UnDesignated Stoppage Points": [
					{
						"iccid1": 8995016202,
						"iccid2": 1091240051,
						"totalodometer": 622768801,
						"axisx": 585,
						"axisy": 65030,
						"axisz": 65073,
						"gsmsignal": 3,
						"speed": 0,
						"externalvoltage": 12.674,
						"gnssstatus": 1,
						"batterylevel": 95,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.5,
						"tripodometer": 419482,
						"sleepmode": 0,
						"gsmcellid": 41082,
						"gsmareacode": 8022,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 39088,
						"numOfData2": 1,
						"timestamp": "2022-09-20 18:39:19",
						"priority": 0,
						"longitude": 96.0741066,
						"latitude": 21.9649383,
						"altitude": 69,
						"angle": 165,
						"satellites": 12,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 01:09:19",
						"thingName": "ltpk_2g_6332",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05041",
						"stoppageDuration": 417.6666666666667,
						"ignitionOnTime": 25060
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240051,
						"totalodometer": 622781947,
						"axisx": 585,
						"axisy": 65027,
						"axisz": 65084,
						"gsmsignal": 5,
						"speed": 9,
						"externalvoltage": 13.049,
						"gnssstatus": 1,
						"batterylevel": 95,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 432628,
						"sleepmode": 0,
						"gsmcellid": 40351,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 57288,
						"numOfData2": 1,
						"timestamp": "2022-09-21 02:42:21",
						"priority": 0,
						"longitude": 96.1039283,
						"latitude": 21.94523,
						"altitude": 53,
						"angle": 172,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 09:12:21",
						"thingName": "ltpk_2g_6332",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05041",
						"stoppageDuration": 91.68333333333334,
						"ignitionOnTime": 5499
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240051,
						"totalodometer": 622782038,
						"axisx": 623,
						"axisy": 65088,
						"axisz": 65076,
						"gsmsignal": 4,
						"speed": 0,
						"externalvoltage": 12.77,
						"gnssstatus": 1,
						"batterylevel": 95,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 432719,
						"sleepmode": 0,
						"gsmcellid": 40351,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 8876,
						"numOfData2": 1,
						"timestamp": "2022-09-21 04:13:54",
						"priority": 0,
						"longitude": 96.1041066,
						"latitude": 21.9451066,
						"altitude": 78,
						"angle": 172,
						"satellites": 11,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 10:43:54",
						"thingName": "ltpk_2g_6332",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05041",
						"stoppageDuration": 16.4,
						"ignitionOnTime": 984
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240051,
						"totalodometer": 622786639,
						"axisx": 690,
						"axisy": 65107,
						"axisz": 65100,
						"gsmsignal": 3,
						"speed": 17,
						"externalvoltage": 13.794,
						"gnssstatus": 1,
						"batterylevel": 95,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 437320,
						"sleepmode": 0,
						"gsmcellid": 40301,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 46441,
						"numOfData2": 1,
						"timestamp": "2022-09-21 04:48:48",
						"priority": 0,
						"longitude": 96.1250566,
						"latitude": 21.9289183,
						"altitude": 64,
						"angle": 170,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 11:18:48",
						"thingName": "ltpk_2g_6332",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05041",
						"stoppageDuration": 174.66666666666666,
						"ignitionOnTime": 10480
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240051,
						"totalodometer": 622800813,
						"axisx": 659,
						"axisy": 64925,
						"axisz": 65092,
						"gsmsignal": 3,
						"speed": 13,
						"externalvoltage": 12.341000000000001,
						"gnssstatus": 1,
						"batterylevel": 96,
						"gnsspdop": 0.8,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 451494,
						"sleepmode": 0,
						"gsmcellid": 40291,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 411,
						"codecId": 8,
						"numOfData1": 4,
						"crc": 27942,
						"numOfData2": 4,
						"timestamp": "2022-09-21 08:18:04",
						"priority": 0,
						"longitude": 96.10822,
						"latitude": 21.9537966,
						"altitude": 77,
						"angle": 192,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 14:48:04",
						"thingName": "ltpk_2g_6332",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05041",
						"stoppageDuration": 82.58333333333333,
						"ignitionOnTime": 4952
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240051,
						"totalodometer": 622823294,
						"axisx": 609,
						"axisy": 64960,
						"axisz": 65126,
						"gsmsignal": 4,
						"speed": 6,
						"externalvoltage": 13.624,
						"gnssstatus": 1,
						"batterylevel": 96,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 473975,
						"sleepmode": 0,
						"gsmcellid": 41751,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 33551,
						"numOfData2": 1,
						"timestamp": "2022-09-21 10:55:48",
						"priority": 0,
						"longitude": 96.0677049,
						"latitude": 21.9577083,
						"altitude": 62,
						"angle": 221,
						"satellites": 12,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 17:25:48",
						"thingName": "ltpk_2g_6332",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05041",
						"stoppageDuration": 59.666666666666664,
						"ignitionOnTime": 3580
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240051,
						"totalodometer": 622828874,
						"axisx": 651,
						"axisy": 65088,
						"axisz": 65065,
						"gsmsignal": 3,
						"speed": 9,
						"externalvoltage": 12.8,
						"gnssstatus": 1,
						"batterylevel": 96,
						"gnsspdop": 0.5,
						"gnsshdop": 0.4,
						"tripodometer": 479555,
						"sleepmode": 0,
						"gsmcellid": 40031,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 49230,
						"numOfData2": 1,
						"timestamp": "2022-09-21 12:10:59",
						"priority": 0,
						"longitude": 96.05522,
						"latitude": 21.9233366,
						"altitude": 58,
						"angle": 42,
						"satellites": 12,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 18:40:59",
						"thingName": "ltpk_2g_6332",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05041",
						"stoppageDuration": 75.95,
						"ignitionOnTime": 4518
					}
				],
				"Designated Stoppage Points": [],
				"Start Time": "2022-09-21 01:09:19",
				"End Time": "2022-09-21 23:49:54",
				"Stoppage Points": [
					{
						"iccid1": 8995016202,
						"iccid2": 1091240051,
						"totalodometer": 622768801,
						"axisx": 585,
						"axisy": 65030,
						"axisz": 65073,
						"gsmsignal": 3,
						"speed": 0,
						"externalvoltage": 12.674,
						"gnssstatus": 1,
						"batterylevel": 95,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.5,
						"tripodometer": 419482,
						"sleepmode": 0,
						"gsmcellid": 41082,
						"gsmareacode": 8022,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 39088,
						"numOfData2": 1,
						"timestamp": "2022-09-20 18:39:19",
						"priority": 0,
						"longitude": 96.0741066,
						"latitude": 21.9649383,
						"altitude": 69,
						"angle": 165,
						"satellites": 12,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 01:09:19",
						"thingName": "ltpk_2g_6332",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford",
						"state": "mandalay-region",
						"type": "pick-up-4x4",
						"zipcode": "05041",
						"stoppageDuration": 417.6666666666667,
						"ignitionOnTime": 25060
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240051,
						"totalodometer": 622781947,
						"axisx": 585,
						"axisy": 65027,
						"axisz": 65084,
						"gsmsignal": 5,
						"speed": 9,
						"externalvoltage": 13.049,
						"gnssstatus": 1,
						"batterylevel": 95,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 432628,
						"sleepmode": 0,
						"gsmcellid": 40351,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 57288,
						"numOfData2": 1,
						"timestamp": "2022-09-21 02:42:21",
						"priority": 0,
						"longitude": 96.1039283,
						"latitude": 21.94523,
						"altitude": 53,
						"angle": 172,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 09:12:21",
						"thingName": "ltpk_2g_6332",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford",
						"state": "mandalay-region",
						"type": "pick-up-4x4",
						"zipcode": "05041",
						"stoppageDuration": 91.68333333333334,
						"ignitionOnTime": 5499
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240051,
						"totalodometer": 622782038,
						"axisx": 623,
						"axisy": 65088,
						"axisz": 65076,
						"gsmsignal": 4,
						"speed": 0,
						"externalvoltage": 12.77,
						"gnssstatus": 1,
						"batterylevel": 95,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 432719,
						"sleepmode": 0,
						"gsmcellid": 40351,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 8876,
						"numOfData2": 1,
						"timestamp": "2022-09-21 04:13:54",
						"priority": 0,
						"longitude": 96.1041066,
						"latitude": 21.9451066,
						"altitude": 78,
						"angle": 172,
						"satellites": 11,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 10:43:54",
						"thingName": "ltpk_2g_6332",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford",
						"state": "mandalay-region",
						"type": "pick-up-4x4",
						"zipcode": "05041",
						"stoppageDuration": 16.4,
						"ignitionOnTime": 984
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240051,
						"totalodometer": 622786639,
						"axisx": 690,
						"axisy": 65107,
						"axisz": 65100,
						"gsmsignal": 3,
						"speed": 17,
						"externalvoltage": 13.794,
						"gnssstatus": 1,
						"batterylevel": 95,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 437320,
						"sleepmode": 0,
						"gsmcellid": 40301,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 46441,
						"numOfData2": 1,
						"timestamp": "2022-09-21 04:48:48",
						"priority": 0,
						"longitude": 96.1250566,
						"latitude": 21.9289183,
						"altitude": 64,
						"angle": 170,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 11:18:48",
						"thingName": "ltpk_2g_6332",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford",
						"state": "mandalay-region",
						"type": "pick-up-4x4",
						"zipcode": "05041",
						"stoppageDuration": 174.66666666666666,
						"ignitionOnTime": 10480
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240051,
						"totalodometer": 622800813,
						"axisx": 659,
						"axisy": 64925,
						"axisz": 65092,
						"gsmsignal": 3,
						"speed": 13,
						"externalvoltage": 12.341000000000001,
						"gnssstatus": 1,
						"batterylevel": 96,
						"gnsspdop": 0.8,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 451494,
						"sleepmode": 0,
						"gsmcellid": 40291,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 411,
						"codecId": 8,
						"numOfData1": 4,
						"crc": 27942,
						"numOfData2": 4,
						"timestamp": "2022-09-21 08:18:04",
						"priority": 0,
						"longitude": 96.10822,
						"latitude": 21.9537966,
						"altitude": 77,
						"angle": 192,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 14:48:04",
						"thingName": "ltpk_2g_6332",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford",
						"state": "mandalay-region",
						"type": "pick-up-4x4",
						"zipcode": "05041",
						"stoppageDuration": 82.58333333333333,
						"ignitionOnTime": 4952
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240051,
						"totalodometer": 622823294,
						"axisx": 609,
						"axisy": 64960,
						"axisz": 65126,
						"gsmsignal": 4,
						"speed": 6,
						"externalvoltage": 13.624,
						"gnssstatus": 1,
						"batterylevel": 96,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 473975,
						"sleepmode": 0,
						"gsmcellid": 41751,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 33551,
						"numOfData2": 1,
						"timestamp": "2022-09-21 10:55:48",
						"priority": 0,
						"longitude": 96.0677049,
						"latitude": 21.9577083,
						"altitude": 62,
						"angle": 221,
						"satellites": 12,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 17:25:48",
						"thingName": "ltpk_2g_6332",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford",
						"state": "mandalay-region",
						"type": "pick-up-4x4",
						"zipcode": "05041",
						"stoppageDuration": 59.666666666666664,
						"ignitionOnTime": 3580
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240051,
						"totalodometer": 622828874,
						"axisx": 651,
						"axisy": 65088,
						"axisz": 65065,
						"gsmsignal": 3,
						"speed": 9,
						"externalvoltage": 12.8,
						"gnssstatus": 1,
						"batterylevel": 96,
						"gnsspdop": 0.5,
						"gnsshdop": 0.4,
						"tripodometer": 479555,
						"sleepmode": 0,
						"gsmcellid": 40031,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 49230,
						"numOfData2": 1,
						"timestamp": "2022-09-21 12:10:59",
						"priority": 0,
						"longitude": 96.05522,
						"latitude": 21.9233366,
						"altitude": 58,
						"angle": 42,
						"satellites": 12,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 18:40:59",
						"thingName": "ltpk_2g_6332",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford",
						"state": "mandalay-region",
						"type": "pick-up-4x4",
						"zipcode": "05041",
						"stoppageDuration": 75.95,
						"ignitionOnTime": 4518
					}
				],
				"Total Stoppage Time": 918.6166666666667,
				"Total Travel Time": "7hr 21min 58sec",
				"Route Points": [
					{
						"iccid1": 8995016202,
						"iccid2": 1091240051,
						"totalodometer": 622768801,
						"axisx": 585,
						"axisy": 65030,
						"axisz": 65073,
						"gsmsignal": 3,
						"speed": 0,
						"externalvoltage": 12.674,
						"gnssstatus": 1,
						"batterylevel": 95,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.5,
						"tripodometer": 419482,
						"sleepmode": 0,
						"gsmcellid": 41082,
						"gsmareacode": 8022,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 39088,
						"numOfData2": 1,
						"timestamp": "2022-09-20 18:39:19",
						"priority": 0,
						"longitude": 96.0741066,
						"latitude": 21.9649383,
						"altitude": 69,
						"angle": 165,
						"satellites": 12,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 01:09:19",
						"thingName": "ltpk_2g_6332",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05041",
						"stoppageDuration": 417.6666666666667,
						"ignitionOnTime": 25060
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240051,
						"totalodometer": 622768801,
						"axisx": 590,
						"axisy": 65037,
						"axisz": 65090,
						"gsmsignal": 3,
						"speed": 0,
						"externalvoltage": 12.674,
						"gnssstatus": 1,
						"batterylevel": 95,
						"gnsspdop": 0.5,
						"gnsshdop": 0.4,
						"tripodometer": 419482,
						"sleepmode": 0,
						"gsmcellid": 41082,
						"gsmareacode": 8022,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 1548,
						"numOfData2": 1,
						"timestamp": "2022-09-20 18:54:19",
						"priority": 0,
						"longitude": 96.0741066,
						"latitude": 21.9649383,
						"altitude": 69,
						"angle": 165,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 01:24:19",
						"thingName": "ltpk_2g_6332",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05041"
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240051,
						"totalodometer": 622781947,
						"axisx": 585,
						"axisy": 65027,
						"axisz": 65084,
						"gsmsignal": 5,
						"speed": 9,
						"externalvoltage": 13.049,
						"gnssstatus": 1,
						"batterylevel": 95,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 432628,
						"sleepmode": 0,
						"gsmcellid": 40351,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 57288,
						"numOfData2": 1,
						"timestamp": "2022-09-21 02:42:21",
						"priority": 0,
						"longitude": 96.1039283,
						"latitude": 21.94523,
						"altitude": 53,
						"angle": 172,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 09:12:21",
						"thingName": "ltpk_2g_6332",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05041",
						"stoppageDuration": 91.68333333333334,
						"ignitionOnTime": 5499
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240051,
						"totalodometer": 622782038,
						"axisx": 623,
						"axisy": 65088,
						"axisz": 65076,
						"gsmsignal": 4,
						"speed": 0,
						"externalvoltage": 12.77,
						"gnssstatus": 1,
						"batterylevel": 95,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 432719,
						"sleepmode": 0,
						"gsmcellid": 40351,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 8876,
						"numOfData2": 1,
						"timestamp": "2022-09-21 04:13:54",
						"priority": 0,
						"longitude": 96.1041066,
						"latitude": 21.9451066,
						"altitude": 78,
						"angle": 172,
						"satellites": 11,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 10:43:54",
						"thingName": "ltpk_2g_6332",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05041",
						"stoppageDuration": 16.4,
						"ignitionOnTime": 984
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240051,
						"totalodometer": 622786639,
						"axisx": 690,
						"axisy": 65107,
						"axisz": 65100,
						"gsmsignal": 3,
						"speed": 17,
						"externalvoltage": 13.794,
						"gnssstatus": 1,
						"batterylevel": 95,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 437320,
						"sleepmode": 0,
						"gsmcellid": 40301,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 46441,
						"numOfData2": 1,
						"timestamp": "2022-09-21 04:48:48",
						"priority": 0,
						"longitude": 96.1250566,
						"latitude": 21.9289183,
						"altitude": 64,
						"angle": 170,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 11:18:48",
						"thingName": "ltpk_2g_6332",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05041",
						"stoppageDuration": 174.66666666666666,
						"ignitionOnTime": 10480
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240051,
						"totalodometer": 622800813,
						"axisx": 659,
						"axisy": 64925,
						"axisz": 65092,
						"gsmsignal": 3,
						"speed": 13,
						"externalvoltage": 12.341000000000001,
						"gnssstatus": 1,
						"batterylevel": 96,
						"gnsspdop": 0.8,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 451494,
						"sleepmode": 0,
						"gsmcellid": 40291,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 411,
						"codecId": 8,
						"numOfData1": 4,
						"crc": 27942,
						"numOfData2": 4,
						"timestamp": "2022-09-21 08:18:04",
						"priority": 0,
						"longitude": 96.10822,
						"latitude": 21.9537966,
						"altitude": 77,
						"angle": 192,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 14:48:04",
						"thingName": "ltpk_2g_6332",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05041",
						"stoppageDuration": 82.58333333333333,
						"ignitionOnTime": 4952
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240051,
						"totalodometer": 622823294,
						"axisx": 609,
						"axisy": 64960,
						"axisz": 65126,
						"gsmsignal": 4,
						"speed": 6,
						"externalvoltage": 13.624,
						"gnssstatus": 1,
						"batterylevel": 96,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 473975,
						"sleepmode": 0,
						"gsmcellid": 41751,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 33551,
						"numOfData2": 1,
						"timestamp": "2022-09-21 10:55:48",
						"priority": 0,
						"longitude": 96.0677049,
						"latitude": 21.9577083,
						"altitude": 62,
						"angle": 221,
						"satellites": 12,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 17:25:48",
						"thingName": "ltpk_2g_6332",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05041",
						"stoppageDuration": 59.666666666666664,
						"ignitionOnTime": 3580
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240051,
						"totalodometer": 622828874,
						"axisx": 651,
						"axisy": 65088,
						"axisz": 65065,
						"gsmsignal": 3,
						"speed": 9,
						"externalvoltage": 12.8,
						"gnssstatus": 1,
						"batterylevel": 96,
						"gnsspdop": 0.5,
						"gnsshdop": 0.4,
						"tripodometer": 479555,
						"sleepmode": 0,
						"gsmcellid": 40031,
						"gsmareacode": 8032,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 49230,
						"numOfData2": 1,
						"timestamp": "2022-09-21 12:10:59",
						"priority": 0,
						"longitude": 96.05522,
						"latitude": 21.9233366,
						"altitude": 58,
						"angle": 42,
						"satellites": 12,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 18:40:59",
						"thingName": "ltpk_2g_6332",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05041",
						"stoppageDuration": 75.95,
						"ignitionOnTime": 4518
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240051,
						"totalodometer": 622837384,
						"axisx": 597,
						"axisy": 65048,
						"axisz": 65076,
						"gsmsignal": 3,
						"speed": 0,
						"externalvoltage": 12.63,
						"gnssstatus": 1,
						"batterylevel": 95,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 488065,
						"sleepmode": 0,
						"gsmcellid": 41083,
						"gsmareacode": 8022,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 793,
						"numOfData2": 1,
						"timestamp": "2022-09-21 17:19:54",
						"priority": 0,
						"longitude": 96.0740883,
						"latitude": 21.96497,
						"altitude": 78,
						"angle": 192,
						"satellites": 14,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 23:49:54",
						"thingName": "ltpk_2g_6332",
						"batchnumber": "2003",
						"city": "chanmyathazi",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05041"
					}
				],
				"Total Distance Travelled": 68.583
			},
			{
				"Declutter Point": [
					{
						"iccid1": 8995016202,
						"iccid2": 1091240036,
						"totalodometer": 387011239,
						"axisx": 145,
						"axisy": 83,
						"axisz": 63526,
						"gsmsignal": 2,
						"speed": 2,
						"externalvoltage": 13.834,
						"gnssstatus": 1,
						"batterylevel": 63,
						"gnsspdop": 0.8,
						"gnsshdop": 0.6000000000000001,
						"tripodometer": 0,
						"sleepmode": 0,
						"gsmcellid": 14911,
						"gsmareacode": 314,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 3597,
						"numOfData2": 1,
						"timestamp": "2022-09-20 21:57:02",
						"priority": 0,
						"longitude": 96.0509816,
						"latitude": 17.1432699,
						"altitude": 65492,
						"angle": 260,
						"satellites": 5,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 04:27:02",
						"thingName": "ltpk_1p_5753",
						"batchnumber": "2007",
						"city": "insein",
						"make": "isuzu",
						"manufacturingdate": "29-Jun-1905",
						"model": "elf-nhr85",
						"state": "yangon-region",
						"type": "light-truck-4x2-r",
						"zipcode": "11012"
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240036,
						"totalodometer": 387335025,
						"axisx": 69,
						"axisy": 197,
						"axisz": 63534,
						"gsmsignal": 3,
						"speed": 0,
						"externalvoltage": 13.011000000000001,
						"gnssstatus": 1,
						"batterylevel": 90,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 323786,
						"sleepmode": 0,
						"gsmcellid": 54191,
						"gsmareacode": 312,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 6265,
						"numOfData2": 1,
						"timestamp": "2022-09-21 13:19:14",
						"priority": 0,
						"longitude": 96.5279549,
						"latitude": 16.7682166,
						"altitude": 5,
						"angle": 81,
						"satellites": 14,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 19:49:14",
						"thingName": "ltpk_1p_5753",
						"batchnumber": "2007",
						"city": "insein",
						"make": "isuzu",
						"manufacturingdate": "29-Jun-1905",
						"model": "elf-nhr85",
						"state": "yangon-region",
						"type": "light-truck-4x2-r",
						"zipcode": "11012"
					}
				],
				"state": "yangon-region",
				"city": "insein",
				"pincode": "11012",
				"siteName": "",
				"siteId": "",
				"make": "isuzu",
				"model": "elf-nhr85",
				"size": "",
				"thingName": "ltpk_1p_5753",
				"UnDesignated Stoppage Points": [
					{
						"iccid1": 8995016202,
						"iccid2": 1091240036,
						"totalodometer": 387105558,
						"axisx": 199,
						"axisy": 180,
						"axisz": 63418,
						"gsmsignal": 3,
						"speed": 13,
						"externalvoltage": 13.943,
						"gnssstatus": 1,
						"batterylevel": 92,
						"gnsspdop": 0.7000000000000001,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 94319,
						"sleepmode": 0,
						"gsmcellid": 54191,
						"gsmareacode": 312,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 17671,
						"numOfData2": 1,
						"timestamp": "2022-09-21 00:35:09",
						"priority": 0,
						"longitude": 96.5278183,
						"latitude": 16.76821,
						"altitude": 65535,
						"angle": 105,
						"satellites": 14,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 07:05:09",
						"thingName": "ltpk_1p_5753",
						"batchnumber": "2007",
						"city": "insein",
						"make": "isuzu",
						"manufacturingdate": "29-Jun-1905",
						"model": "elf-nhr85",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11012",
						"stoppageDuration": 29.55,
						"ignitionOnTime": 138
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240036,
						"totalodometer": 387141387,
						"axisx": 175,
						"axisy": 130,
						"axisz": 63544,
						"gsmsignal": 3,
						"speed": 19,
						"externalvoltage": 13.957,
						"gnssstatus": 1,
						"batterylevel": 91,
						"gnsspdop": 0.8,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 130148,
						"sleepmode": 0,
						"gsmcellid": 19912,
						"gsmareacode": 312,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 7280,
						"numOfData2": 1,
						"timestamp": "2022-09-21 02:00:01",
						"priority": 0,
						"longitude": 96.2501116,
						"latitude": 16.77378,
						"altitude": 20,
						"angle": 353,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 08:30:01",
						"thingName": "ltpk_1p_5753",
						"batchnumber": "2007",
						"city": "insein",
						"make": "isuzu",
						"manufacturingdate": "29-Jun-1905",
						"model": "elf-nhr85",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11012",
						"stoppageDuration": 22.433333333333334,
						"ignitionOnTime": 82
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240036,
						"totalodometer": 387141976,
						"axisx": 270,
						"axisy": 206,
						"axisz": 63205,
						"gsmsignal": 3,
						"speed": 10,
						"externalvoltage": 13.371,
						"gnssstatus": 1,
						"batterylevel": 85,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 130737,
						"sleepmode": 0,
						"gsmcellid": 19913,
						"gsmareacode": 312,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 17073,
						"numOfData2": 1,
						"timestamp": "2022-09-21 02:23:50",
						"priority": 0,
						"longitude": 96.250715,
						"latitude": 16.7718266,
						"altitude": 7,
						"angle": 75,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 08:53:50",
						"thingName": "ltpk_1p_5753",
						"batchnumber": "2007",
						"city": "insein",
						"make": "isuzu",
						"manufacturingdate": "29-Jun-1905",
						"model": "elf-nhr85",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11012",
						"stoppageDuration": 17.183333333333334,
						"ignitionOnTime": 71
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240036,
						"totalodometer": 387145770,
						"axisx": 72,
						"axisy": 145,
						"axisz": 63505,
						"gsmsignal": 5,
						"speed": 10,
						"externalvoltage": 14.063,
						"gnssstatus": 1,
						"batterylevel": 92,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 134531,
						"sleepmode": 0,
						"gsmcellid": 17943,
						"gsmareacode": 312,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 1599,
						"numOfData2": 1,
						"timestamp": "2022-09-21 02:47:28",
						"priority": 0,
						"longitude": 96.2661999,
						"latitude": 16.7434133,
						"altitude": 34,
						"angle": 274,
						"satellites": 15,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 09:17:28",
						"thingName": "ltpk_1p_5753",
						"batchnumber": "2007",
						"city": "insein",
						"make": "isuzu",
						"manufacturingdate": "29-Jun-1905",
						"model": "elf-nhr85",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11012",
						"stoppageDuration": 16.333333333333332,
						"ignitionOnTime": 162
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240036,
						"totalodometer": 387179447,
						"axisx": 131,
						"axisy": 87,
						"axisz": 63437,
						"gsmsignal": 1,
						"speed": 9,
						"externalvoltage": 13.483,
						"gnssstatus": 1,
						"batterylevel": 85,
						"gnsspdop": 0.7000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 168208,
						"sleepmode": 0,
						"gsmcellid": 18611,
						"gsmareacode": 312,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 61012,
						"numOfData2": 1,
						"timestamp": "2022-09-21 04:09:54",
						"priority": 0,
						"longitude": 96.5013916,
						"latitude": 16.6407299,
						"altitude": 10,
						"angle": 103,
						"satellites": 12,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 10:39:54",
						"thingName": "ltpk_1p_5753",
						"batchnumber": "2007",
						"city": "insein",
						"make": "isuzu",
						"manufacturingdate": "29-Jun-1905",
						"model": "elf-nhr85",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11012",
						"stoppageDuration": 41.1,
						"ignitionOnTime": 69
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240036,
						"totalodometer": 387191104,
						"axisx": 93,
						"axisy": 76,
						"axisz": 63418,
						"gsmsignal": 5,
						"speed": 18,
						"externalvoltage": 14.009,
						"gnssstatus": 1,
						"batterylevel": 91,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 179865,
						"sleepmode": 0,
						"gsmcellid": 54751,
						"gsmareacode": 312,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 55709,
						"numOfData2": 1,
						"timestamp": "2022-09-21 05:19:35",
						"priority": 0,
						"longitude": 96.4030966,
						"latitude": 16.6483,
						"altitude": 4,
						"angle": 168,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 11:49:35",
						"thingName": "ltpk_1p_5753",
						"batchnumber": "2007",
						"city": "insein",
						"make": "isuzu",
						"manufacturingdate": "29-Jun-1905",
						"model": "elf-nhr85",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11012",
						"stoppageDuration": 64.05,
						"ignitionOnTime": 146
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240036,
						"totalodometer": 387240163,
						"axisx": 209,
						"axisy": 204,
						"axisz": 63582,
						"gsmsignal": 3,
						"speed": 19,
						"externalvoltage": 13.903,
						"gnssstatus": 1,
						"batterylevel": 86,
						"gnsspdop": 0.7000000000000001,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 228924,
						"sleepmode": 0,
						"gsmcellid": 54191,
						"gsmareacode": 312,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 33632,
						"numOfData2": 1,
						"timestamp": "2022-09-21 07:59:59",
						"priority": 0,
						"longitude": 96.5297783,
						"latitude": 16.7692716,
						"altitude": 2,
						"angle": 50,
						"satellites": 16,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 14:29:59",
						"thingName": "ltpk_1p_5753",
						"batchnumber": "2007",
						"city": "insein",
						"make": "isuzu",
						"manufacturingdate": "29-Jun-1905",
						"model": "elf-nhr85",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11012",
						"stoppageDuration": 26.883333333333333,
						"ignitionOnTime": 64
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240036,
						"totalodometer": 387294187,
						"axisx": 105,
						"axisy": 213,
						"axisz": 63563,
						"gsmsignal": 1,
						"speed": 20,
						"externalvoltage": 14.068,
						"gnssstatus": 1,
						"batterylevel": 88,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 282948,
						"sleepmode": 0,
						"gsmcellid": 18393,
						"gsmareacode": 312,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 44057,
						"numOfData2": 1,
						"timestamp": "2022-09-21 10:17:42",
						"priority": 0,
						"longitude": 96.6188399,
						"latitude": 16.910485,
						"altitude": 19,
						"angle": 26,
						"satellites": 14,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 16:47:42",
						"thingName": "ltpk_1p_5753",
						"batchnumber": "2007",
						"city": "insein",
						"make": "isuzu",
						"manufacturingdate": "29-Jun-1905",
						"model": "elf-nhr85",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11012",
						"stoppageDuration": 86.23333333333333,
						"ignitionOnTime": 79
					}
				],
				"Designated Stoppage Points": [],
				"Start Time": "2022-09-21 04:27:02",
				"End Time": "2022-09-21 19:49:14",
				"Stoppage Points": [
					{
						"iccid1": 8995016202,
						"iccid2": 1091240036,
						"totalodometer": 387105558,
						"axisx": 199,
						"axisy": 180,
						"axisz": 63418,
						"gsmsignal": 3,
						"speed": 13,
						"externalvoltage": 13.943,
						"gnssstatus": 1,
						"batterylevel": 92,
						"gnsspdop": 0.7000000000000001,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 94319,
						"sleepmode": 0,
						"gsmcellid": 54191,
						"gsmareacode": 312,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 17671,
						"numOfData2": 1,
						"timestamp": "2022-09-21 00:35:09",
						"priority": 0,
						"longitude": 96.5278183,
						"latitude": 16.76821,
						"altitude": 65535,
						"angle": 105,
						"satellites": 14,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 07:05:09",
						"thingName": "ltpk_1p_5753",
						"batchnumber": "2007",
						"city": "insein",
						"make": "isuzu",
						"manufacturingdate": "29-Jun-1905",
						"model": "elf-nhr85",
						"state": "yangon-region",
						"type": "light-truck-4x2-r",
						"zipcode": "11012",
						"stoppageDuration": 29.55,
						"ignitionOnTime": 138
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240036,
						"totalodometer": 387141387,
						"axisx": 175,
						"axisy": 130,
						"axisz": 63544,
						"gsmsignal": 3,
						"speed": 19,
						"externalvoltage": 13.957,
						"gnssstatus": 1,
						"batterylevel": 91,
						"gnsspdop": 0.8,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 130148,
						"sleepmode": 0,
						"gsmcellid": 19912,
						"gsmareacode": 312,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 7280,
						"numOfData2": 1,
						"timestamp": "2022-09-21 02:00:01",
						"priority": 0,
						"longitude": 96.2501116,
						"latitude": 16.77378,
						"altitude": 20,
						"angle": 353,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 08:30:01",
						"thingName": "ltpk_1p_5753",
						"batchnumber": "2007",
						"city": "insein",
						"make": "isuzu",
						"manufacturingdate": "29-Jun-1905",
						"model": "elf-nhr85",
						"state": "yangon-region",
						"type": "light-truck-4x2-r",
						"zipcode": "11012",
						"stoppageDuration": 22.433333333333334,
						"ignitionOnTime": 82
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240036,
						"totalodometer": 387141976,
						"axisx": 270,
						"axisy": 206,
						"axisz": 63205,
						"gsmsignal": 3,
						"speed": 10,
						"externalvoltage": 13.371,
						"gnssstatus": 1,
						"batterylevel": 85,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 130737,
						"sleepmode": 0,
						"gsmcellid": 19913,
						"gsmareacode": 312,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 17073,
						"numOfData2": 1,
						"timestamp": "2022-09-21 02:23:50",
						"priority": 0,
						"longitude": 96.250715,
						"latitude": 16.7718266,
						"altitude": 7,
						"angle": 75,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 08:53:50",
						"thingName": "ltpk_1p_5753",
						"batchnumber": "2007",
						"city": "insein",
						"make": "isuzu",
						"manufacturingdate": "29-Jun-1905",
						"model": "elf-nhr85",
						"state": "yangon-region",
						"type": "light-truck-4x2-r",
						"zipcode": "11012",
						"stoppageDuration": 17.183333333333334,
						"ignitionOnTime": 71
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240036,
						"totalodometer": 387145770,
						"axisx": 72,
						"axisy": 145,
						"axisz": 63505,
						"gsmsignal": 5,
						"speed": 10,
						"externalvoltage": 14.063,
						"gnssstatus": 1,
						"batterylevel": 92,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 134531,
						"sleepmode": 0,
						"gsmcellid": 17943,
						"gsmareacode": 312,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 1599,
						"numOfData2": 1,
						"timestamp": "2022-09-21 02:47:28",
						"priority": 0,
						"longitude": 96.2661999,
						"latitude": 16.7434133,
						"altitude": 34,
						"angle": 274,
						"satellites": 15,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 09:17:28",
						"thingName": "ltpk_1p_5753",
						"batchnumber": "2007",
						"city": "insein",
						"make": "isuzu",
						"manufacturingdate": "29-Jun-1905",
						"model": "elf-nhr85",
						"state": "yangon-region",
						"type": "light-truck-4x2-r",
						"zipcode": "11012",
						"stoppageDuration": 16.333333333333332,
						"ignitionOnTime": 162
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240036,
						"totalodometer": 387179447,
						"axisx": 131,
						"axisy": 87,
						"axisz": 63437,
						"gsmsignal": 1,
						"speed": 9,
						"externalvoltage": 13.483,
						"gnssstatus": 1,
						"batterylevel": 85,
						"gnsspdop": 0.7000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 168208,
						"sleepmode": 0,
						"gsmcellid": 18611,
						"gsmareacode": 312,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 61012,
						"numOfData2": 1,
						"timestamp": "2022-09-21 04:09:54",
						"priority": 0,
						"longitude": 96.5013916,
						"latitude": 16.6407299,
						"altitude": 10,
						"angle": 103,
						"satellites": 12,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 10:39:54",
						"thingName": "ltpk_1p_5753",
						"batchnumber": "2007",
						"city": "insein",
						"make": "isuzu",
						"manufacturingdate": "29-Jun-1905",
						"model": "elf-nhr85",
						"state": "yangon-region",
						"type": "light-truck-4x2-r",
						"zipcode": "11012",
						"stoppageDuration": 41.1,
						"ignitionOnTime": 69
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240036,
						"totalodometer": 387191104,
						"axisx": 93,
						"axisy": 76,
						"axisz": 63418,
						"gsmsignal": 5,
						"speed": 18,
						"externalvoltage": 14.009,
						"gnssstatus": 1,
						"batterylevel": 91,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 179865,
						"sleepmode": 0,
						"gsmcellid": 54751,
						"gsmareacode": 312,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 55709,
						"numOfData2": 1,
						"timestamp": "2022-09-21 05:19:35",
						"priority": 0,
						"longitude": 96.4030966,
						"latitude": 16.6483,
						"altitude": 4,
						"angle": 168,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 11:49:35",
						"thingName": "ltpk_1p_5753",
						"batchnumber": "2007",
						"city": "insein",
						"make": "isuzu",
						"manufacturingdate": "29-Jun-1905",
						"model": "elf-nhr85",
						"state": "yangon-region",
						"type": "light-truck-4x2-r",
						"zipcode": "11012",
						"stoppageDuration": 64.05,
						"ignitionOnTime": 146
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240036,
						"totalodometer": 387240163,
						"axisx": 209,
						"axisy": 204,
						"axisz": 63582,
						"gsmsignal": 3,
						"speed": 19,
						"externalvoltage": 13.903,
						"gnssstatus": 1,
						"batterylevel": 86,
						"gnsspdop": 0.7000000000000001,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 228924,
						"sleepmode": 0,
						"gsmcellid": 54191,
						"gsmareacode": 312,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 33632,
						"numOfData2": 1,
						"timestamp": "2022-09-21 07:59:59",
						"priority": 0,
						"longitude": 96.5297783,
						"latitude": 16.7692716,
						"altitude": 2,
						"angle": 50,
						"satellites": 16,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 14:29:59",
						"thingName": "ltpk_1p_5753",
						"batchnumber": "2007",
						"city": "insein",
						"make": "isuzu",
						"manufacturingdate": "29-Jun-1905",
						"model": "elf-nhr85",
						"state": "yangon-region",
						"type": "light-truck-4x2-r",
						"zipcode": "11012",
						"stoppageDuration": 26.883333333333333,
						"ignitionOnTime": 64
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240036,
						"totalodometer": 387294187,
						"axisx": 105,
						"axisy": 213,
						"axisz": 63563,
						"gsmsignal": 1,
						"speed": 20,
						"externalvoltage": 14.068,
						"gnssstatus": 1,
						"batterylevel": 88,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 282948,
						"sleepmode": 0,
						"gsmcellid": 18393,
						"gsmareacode": 312,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 44057,
						"numOfData2": 1,
						"timestamp": "2022-09-21 10:17:42",
						"priority": 0,
						"longitude": 96.6188399,
						"latitude": 16.910485,
						"altitude": 19,
						"angle": 26,
						"satellites": 14,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 16:47:42",
						"thingName": "ltpk_1p_5753",
						"batchnumber": "2007",
						"city": "insein",
						"make": "isuzu",
						"manufacturingdate": "29-Jun-1905",
						"model": "elf-nhr85",
						"state": "yangon-region",
						"type": "light-truck-4x2-r",
						"zipcode": "11012",
						"stoppageDuration": 86.23333333333333,
						"ignitionOnTime": 79
					}
				],
				"Total Stoppage Time": 303.76666666666665,
				"Total Travel Time": "10hr 18min 26sec",
				"Route Points": [
					{
						"iccid1": 8995016202,
						"iccid2": 1091240036,
						"totalodometer": 387011239,
						"axisx": 145,
						"axisy": 83,
						"axisz": 63526,
						"gsmsignal": 2,
						"speed": 2,
						"externalvoltage": 13.834,
						"gnssstatus": 1,
						"batterylevel": 63,
						"gnsspdop": 0.8,
						"gnsshdop": 0.6000000000000001,
						"tripodometer": 0,
						"sleepmode": 0,
						"gsmcellid": 14911,
						"gsmareacode": 314,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 3597,
						"numOfData2": 1,
						"timestamp": "2022-09-20 21:57:02",
						"priority": 0,
						"longitude": 96.0509816,
						"latitude": 17.1432699,
						"altitude": 65492,
						"angle": 260,
						"satellites": 5,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 04:27:02",
						"thingName": "ltpk_1p_5753",
						"batchnumber": "2007",
						"city": "insein",
						"make": "isuzu",
						"manufacturingdate": "29-Jun-1905",
						"model": "elf-nhr85",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11012"
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240036,
						"totalodometer": 387105558,
						"axisx": 199,
						"axisy": 180,
						"axisz": 63418,
						"gsmsignal": 3,
						"speed": 13,
						"externalvoltage": 13.943,
						"gnssstatus": 1,
						"batterylevel": 92,
						"gnsspdop": 0.7000000000000001,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 94319,
						"sleepmode": 0,
						"gsmcellid": 54191,
						"gsmareacode": 312,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 17671,
						"numOfData2": 1,
						"timestamp": "2022-09-21 00:35:09",
						"priority": 0,
						"longitude": 96.5278183,
						"latitude": 16.76821,
						"altitude": 65535,
						"angle": 105,
						"satellites": 14,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 07:05:09",
						"thingName": "ltpk_1p_5753",
						"batchnumber": "2007",
						"city": "insein",
						"make": "isuzu",
						"manufacturingdate": "29-Jun-1905",
						"model": "elf-nhr85",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11012",
						"stoppageDuration": 29.55,
						"ignitionOnTime": 138
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240036,
						"totalodometer": 387141387,
						"axisx": 175,
						"axisy": 130,
						"axisz": 63544,
						"gsmsignal": 3,
						"speed": 19,
						"externalvoltage": 13.957,
						"gnssstatus": 1,
						"batterylevel": 91,
						"gnsspdop": 0.8,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 130148,
						"sleepmode": 0,
						"gsmcellid": 19912,
						"gsmareacode": 312,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 7280,
						"numOfData2": 1,
						"timestamp": "2022-09-21 02:00:01",
						"priority": 0,
						"longitude": 96.2501116,
						"latitude": 16.77378,
						"altitude": 20,
						"angle": 353,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 08:30:01",
						"thingName": "ltpk_1p_5753",
						"batchnumber": "2007",
						"city": "insein",
						"make": "isuzu",
						"manufacturingdate": "29-Jun-1905",
						"model": "elf-nhr85",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11012",
						"stoppageDuration": 22.433333333333334,
						"ignitionOnTime": 82
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240036,
						"totalodometer": 387141976,
						"axisx": 270,
						"axisy": 206,
						"axisz": 63205,
						"gsmsignal": 3,
						"speed": 10,
						"externalvoltage": 13.371,
						"gnssstatus": 1,
						"batterylevel": 85,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 130737,
						"sleepmode": 0,
						"gsmcellid": 19913,
						"gsmareacode": 312,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 17073,
						"numOfData2": 1,
						"timestamp": "2022-09-21 02:23:50",
						"priority": 0,
						"longitude": 96.250715,
						"latitude": 16.7718266,
						"altitude": 7,
						"angle": 75,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 08:53:50",
						"thingName": "ltpk_1p_5753",
						"batchnumber": "2007",
						"city": "insein",
						"make": "isuzu",
						"manufacturingdate": "29-Jun-1905",
						"model": "elf-nhr85",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11012",
						"stoppageDuration": 17.183333333333334,
						"ignitionOnTime": 71
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240036,
						"totalodometer": 387145770,
						"axisx": 72,
						"axisy": 145,
						"axisz": 63505,
						"gsmsignal": 5,
						"speed": 10,
						"externalvoltage": 14.063,
						"gnssstatus": 1,
						"batterylevel": 92,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 134531,
						"sleepmode": 0,
						"gsmcellid": 17943,
						"gsmareacode": 312,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 1599,
						"numOfData2": 1,
						"timestamp": "2022-09-21 02:47:28",
						"priority": 0,
						"longitude": 96.2661999,
						"latitude": 16.7434133,
						"altitude": 34,
						"angle": 274,
						"satellites": 15,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 09:17:28",
						"thingName": "ltpk_1p_5753",
						"batchnumber": "2007",
						"city": "insein",
						"make": "isuzu",
						"manufacturingdate": "29-Jun-1905",
						"model": "elf-nhr85",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11012",
						"stoppageDuration": 16.333333333333332,
						"ignitionOnTime": 162
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240036,
						"totalodometer": 387179447,
						"axisx": 131,
						"axisy": 87,
						"axisz": 63437,
						"gsmsignal": 1,
						"speed": 9,
						"externalvoltage": 13.483,
						"gnssstatus": 1,
						"batterylevel": 85,
						"gnsspdop": 0.7000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 168208,
						"sleepmode": 0,
						"gsmcellid": 18611,
						"gsmareacode": 312,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 61012,
						"numOfData2": 1,
						"timestamp": "2022-09-21 04:09:54",
						"priority": 0,
						"longitude": 96.5013916,
						"latitude": 16.6407299,
						"altitude": 10,
						"angle": 103,
						"satellites": 12,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 10:39:54",
						"thingName": "ltpk_1p_5753",
						"batchnumber": "2007",
						"city": "insein",
						"make": "isuzu",
						"manufacturingdate": "29-Jun-1905",
						"model": "elf-nhr85",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11012",
						"stoppageDuration": 41.1,
						"ignitionOnTime": 69
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240036,
						"totalodometer": 387191104,
						"axisx": 93,
						"axisy": 76,
						"axisz": 63418,
						"gsmsignal": 5,
						"speed": 18,
						"externalvoltage": 14.009,
						"gnssstatus": 1,
						"batterylevel": 91,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 179865,
						"sleepmode": 0,
						"gsmcellid": 54751,
						"gsmareacode": 312,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 55709,
						"numOfData2": 1,
						"timestamp": "2022-09-21 05:19:35",
						"priority": 0,
						"longitude": 96.4030966,
						"latitude": 16.6483,
						"altitude": 4,
						"angle": 168,
						"satellites": 13,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 11:49:35",
						"thingName": "ltpk_1p_5753",
						"batchnumber": "2007",
						"city": "insein",
						"make": "isuzu",
						"manufacturingdate": "29-Jun-1905",
						"model": "elf-nhr85",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11012",
						"stoppageDuration": 64.05,
						"ignitionOnTime": 146
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240036,
						"totalodometer": 387240163,
						"axisx": 209,
						"axisy": 204,
						"axisz": 63582,
						"gsmsignal": 3,
						"speed": 19,
						"externalvoltage": 13.903,
						"gnssstatus": 1,
						"batterylevel": 86,
						"gnsspdop": 0.7000000000000001,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 228924,
						"sleepmode": 0,
						"gsmcellid": 54191,
						"gsmareacode": 312,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 33632,
						"numOfData2": 1,
						"timestamp": "2022-09-21 07:59:59",
						"priority": 0,
						"longitude": 96.5297783,
						"latitude": 16.7692716,
						"altitude": 2,
						"angle": 50,
						"satellites": 16,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 14:29:59",
						"thingName": "ltpk_1p_5753",
						"batchnumber": "2007",
						"city": "insein",
						"make": "isuzu",
						"manufacturingdate": "29-Jun-1905",
						"model": "elf-nhr85",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11012",
						"stoppageDuration": 26.883333333333333,
						"ignitionOnTime": 64
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240036,
						"totalodometer": 387294187,
						"axisx": 105,
						"axisy": 213,
						"axisz": 63563,
						"gsmsignal": 1,
						"speed": 20,
						"externalvoltage": 14.068,
						"gnssstatus": 1,
						"batterylevel": 88,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 282948,
						"sleepmode": 0,
						"gsmcellid": 18393,
						"gsmareacode": 312,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 44057,
						"numOfData2": 1,
						"timestamp": "2022-09-21 10:17:42",
						"priority": 0,
						"longitude": 96.6188399,
						"latitude": 16.910485,
						"altitude": 19,
						"angle": 26,
						"satellites": 14,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 16:47:42",
						"thingName": "ltpk_1p_5753",
						"batchnumber": "2007",
						"city": "insein",
						"make": "isuzu",
						"manufacturingdate": "29-Jun-1905",
						"model": "elf-nhr85",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11012",
						"stoppageDuration": 86.23333333333333,
						"ignitionOnTime": 79
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240036,
						"totalodometer": 387335025,
						"axisx": 69,
						"axisy": 197,
						"axisz": 63534,
						"gsmsignal": 3,
						"speed": 0,
						"externalvoltage": 13.011000000000001,
						"gnssstatus": 1,
						"batterylevel": 90,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 323786,
						"sleepmode": 0,
						"gsmcellid": 54191,
						"gsmareacode": 312,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 6265,
						"numOfData2": 1,
						"timestamp": "2022-09-21 13:19:14",
						"priority": 0,
						"longitude": 96.5279549,
						"latitude": 16.7682166,
						"altitude": 5,
						"angle": 81,
						"satellites": 14,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 19:49:14",
						"thingName": "ltpk_1p_5753",
						"batchnumber": "2007",
						"city": "insein",
						"make": "isuzu",
						"manufacturingdate": "29-Jun-1905",
						"model": "elf-nhr85",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11012"
					}
				],
				"Total Distance Travelled": 323.786
			},
			{
				"Declutter Point": [
					{
						"iccid1": 8995016202,
						"iccid2": 1091240002,
						"totalodometer": 301420702,
						"axisx": 688,
						"axisy": 64824,
						"axisz": 63992,
						"gsmsignal": 5,
						"speed": 0,
						"externalvoltage": 14.467,
						"gnssstatus": 2,
						"batterylevel": 66,
						"gnsspdop": 0,
						"gnsshdop": 0,
						"tripodometer": 0,
						"sleepmode": 0,
						"gsmcellid": 40621,
						"gsmareacode": 8023,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 23341,
						"numOfData2": 1,
						"timestamp": "2022-09-21 00:44:29",
						"priority": 0,
						"longitude": 0,
						"latitude": 0,
						"altitude": 0,
						"angle": 0,
						"satellites": 0,
						"eventIoId": 240,
						"totalRecords": 20,
						"ts": "2022-09-21 07:14:29",
						"thingName": "ltpk_9h_2360",
						"batchnumber": "2003",
						"city": "pyinoolwin",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford-ranger",
						"state": "mandalay-region",
						"type": "pickup-4x2-r",
						"zipcode": "05081"
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240002,
						"totalodometer": 301501085,
						"axisx": 622,
						"axisy": 64954,
						"axisz": 64006,
						"gsmsignal": 5,
						"speed": 0,
						"externalvoltage": 12.529,
						"gnssstatus": 1,
						"batterylevel": 79,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 16630,
						"sleepmode": 0,
						"gsmcellid": 31182,
						"gsmareacode": 8001,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 53790,
						"numOfData2": 1,
						"timestamp": "2022-09-21 12:28:53",
						"priority": 0,
						"longitude": 96.4389783,
						"latitude": 21.9415966,
						"altitude": 1127,
						"angle": 276,
						"satellites": 14,
						"eventIoId": 239,
						"totalRecords": 20,
						"ts": "2022-09-21 18:58:53",
						"thingName": "ltpk_9h_2360",
						"batchnumber": "2003",
						"city": "pyinoolwin",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford-ranger",
						"state": "mandalay-region",
						"type": "pickup-4x2-r",
						"zipcode": "05081"
					}
				],
				"state": "mandalay-region",
				"city": "pyinoolwin",
				"pincode": "05081",
				"siteName": "",
				"siteId": "",
				"make": "ford",
				"model": "ford-ranger",
				"size": "",
				"thingName": "ltpk_9h_2360",
				"UnDesignated Stoppage Points": [
					{
						"iccid1": 8995016202,
						"iccid2": 1091240002,
						"totalodometer": 301426337,
						"axisx": 661,
						"axisy": 64879,
						"axisz": 63897,
						"gsmsignal": 5,
						"speed": 10,
						"externalvoltage": 14.18,
						"gnssstatus": 1,
						"batterylevel": 78,
						"gnsspdop": 1,
						"gnsshdop": 0.5,
						"tripodometer": 5635,
						"sleepmode": 0,
						"gsmcellid": 40592,
						"gsmareacode": 8023,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 27208,
						"numOfData2": 1,
						"timestamp": "2022-09-21 00:59:57",
						"priority": 0,
						"longitude": 96.4559633,
						"latitude": 22.066235,
						"altitude": 1116,
						"angle": 1,
						"satellites": 9,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 07:29:57",
						"thingName": "ltpk_9h_2360",
						"batchnumber": "2003",
						"city": "pyinoolwin",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford-ranger",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05081",
						"stoppageDuration": 22.366666666666667,
						"ignitionOnTime": 29
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240002,
						"totalodometer": 301436524,
						"axisx": 604,
						"axisy": 64817,
						"axisz": 63891,
						"gsmsignal": 5,
						"speed": 11,
						"externalvoltage": 14.248000000000001,
						"gnssstatus": 1,
						"batterylevel": 94,
						"gnsspdop": 0.7000000000000001,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 15822,
						"sleepmode": 0,
						"gsmcellid": 40621,
						"gsmareacode": 8023,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 44009,
						"numOfData2": 1,
						"timestamp": "2022-09-21 01:54:47",
						"priority": 0,
						"longitude": 96.5012466,
						"latitude": 22.0403199,
						"altitude": 1067,
						"angle": 211,
						"satellites": 12,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 08:24:47",
						"thingName": "ltpk_9h_2360",
						"batchnumber": "2003",
						"city": "pyinoolwin",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford-ranger",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05081",
						"stoppageDuration": 26.616666666666667,
						"ignitionOnTime": 137
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240002,
						"totalodometer": 301445027,
						"axisx": 590,
						"axisy": 64886,
						"axisz": 63781,
						"gsmsignal": 2,
						"speed": 7,
						"externalvoltage": 15.49,
						"gnssstatus": 1,
						"batterylevel": 94,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 24325,
						"sleepmode": 0,
						"gsmcellid": 40092,
						"gsmareacode": 8023,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 20110,
						"numOfData2": 1,
						"timestamp": "2022-09-21 03:04:21",
						"priority": 0,
						"longitude": 96.5395566,
						"latitude": 21.9907999,
						"altitude": 1171,
						"angle": 282,
						"satellites": 12,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 09:34:21",
						"thingName": "ltpk_9h_2360",
						"batchnumber": "2003",
						"city": "pyinoolwin",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford-ranger",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05081",
						"stoppageDuration": 19,
						"ignitionOnTime": 128
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240002,
						"totalodometer": 301466144,
						"axisx": 665,
						"axisy": 64799,
						"axisz": 63903,
						"gsmsignal": 5,
						"speed": 4,
						"externalvoltage": 14.161,
						"gnssstatus": 1,
						"batterylevel": 97,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 45442,
						"sleepmode": 0,
						"gsmcellid": 31181,
						"gsmareacode": 8001,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 11406,
						"numOfData2": 1,
						"timestamp": "2022-09-21 04:11:36",
						"priority": 0,
						"longitude": 96.439515,
						"latitude": 21.9419866,
						"altitude": 1150,
						"angle": 257,
						"satellites": 11,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 10:41:36",
						"thingName": "ltpk_9h_2360",
						"batchnumber": "2003",
						"city": "pyinoolwin",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford-ranger",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05081",
						"stoppageDuration": 128.3,
						"ignitionOnTime": 342
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240002,
						"totalodometer": 301484415,
						"axisx": 773,
						"axisy": 64785,
						"axisz": 63905,
						"gsmsignal": 5,
						"speed": 9,
						"externalvoltage": 14.127,
						"gnssstatus": 1,
						"batterylevel": 100,
						"gnsspdop": 0.7000000000000001,
						"gnsshdop": 0.5,
						"tripodometer": 18147,
						"sleepmode": 0,
						"gsmcellid": 40621,
						"gsmareacode": 8023,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 34567,
						"numOfData2": 1,
						"timestamp": "2022-09-21 07:05:48",
						"priority": 0,
						"longitude": 96.5012083,
						"latitude": 22.0402516,
						"altitude": 1090,
						"angle": 199,
						"satellites": 9,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 13:35:48",
						"thingName": "ltpk_9h_2360",
						"batchnumber": "2003",
						"city": "pyinoolwin",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford-ranger",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05081",
						"stoppageDuration": 180.73333333333332,
						"ignitionOnTime": 24
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240002,
						"totalodometer": 301500777,
						"axisx": 642,
						"axisy": 64901,
						"axisz": 63962,
						"gsmsignal": 2,
						"speed": 11,
						"externalvoltage": 14.079,
						"gnssstatus": 1,
						"batterylevel": 85,
						"gnsspdop": 1.4000000000000001,
						"gnsshdop": 0.8,
						"tripodometer": 16322,
						"sleepmode": 0,
						"gsmcellid": 43981,
						"gsmareacode": 8023,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 58048,
						"numOfData2": 1,
						"timestamp": "2022-09-21 10:34:10",
						"priority": 0,
						"longitude": 96.4396149,
						"latitude": 21.94203,
						"altitude": 1166,
						"angle": 274,
						"satellites": 9,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 17:04:10",
						"thingName": "ltpk_9h_2360",
						"batchnumber": "2003",
						"city": "pyinoolwin",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford-ranger",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05081",
						"stoppageDuration": 31.316666666666666,
						"ignitionOnTime": 89
					}
				],
				"Designated Stoppage Points": [],
				"Start Time": "2022-09-21 07:14:29",
				"End Time": "2022-09-21 18:58:53",
				"Stoppage Points": [
					{
						"iccid1": 8995016202,
						"iccid2": 1091240002,
						"totalodometer": 301426337,
						"axisx": 661,
						"axisy": 64879,
						"axisz": 63897,
						"gsmsignal": 5,
						"speed": 10,
						"externalvoltage": 14.18,
						"gnssstatus": 1,
						"batterylevel": 78,
						"gnsspdop": 1,
						"gnsshdop": 0.5,
						"tripodometer": 5635,
						"sleepmode": 0,
						"gsmcellid": 40592,
						"gsmareacode": 8023,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 27208,
						"numOfData2": 1,
						"timestamp": "2022-09-21 00:59:57",
						"priority": 0,
						"longitude": 96.4559633,
						"latitude": 22.066235,
						"altitude": 1116,
						"angle": 1,
						"satellites": 9,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 07:29:57",
						"thingName": "ltpk_9h_2360",
						"batchnumber": "2003",
						"city": "pyinoolwin",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford-ranger",
						"state": "mandalay-region",
						"type": "pickup-4x2-r",
						"zipcode": "05081",
						"stoppageDuration": 22.366666666666667,
						"ignitionOnTime": 29
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240002,
						"totalodometer": 301436524,
						"axisx": 604,
						"axisy": 64817,
						"axisz": 63891,
						"gsmsignal": 5,
						"speed": 11,
						"externalvoltage": 14.248000000000001,
						"gnssstatus": 1,
						"batterylevel": 94,
						"gnsspdop": 0.7000000000000001,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 15822,
						"sleepmode": 0,
						"gsmcellid": 40621,
						"gsmareacode": 8023,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 44009,
						"numOfData2": 1,
						"timestamp": "2022-09-21 01:54:47",
						"priority": 0,
						"longitude": 96.5012466,
						"latitude": 22.0403199,
						"altitude": 1067,
						"angle": 211,
						"satellites": 12,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 08:24:47",
						"thingName": "ltpk_9h_2360",
						"batchnumber": "2003",
						"city": "pyinoolwin",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford-ranger",
						"state": "mandalay-region",
						"type": "pickup-4x2-r",
						"zipcode": "05081",
						"stoppageDuration": 26.616666666666667,
						"ignitionOnTime": 137
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240002,
						"totalodometer": 301445027,
						"axisx": 590,
						"axisy": 64886,
						"axisz": 63781,
						"gsmsignal": 2,
						"speed": 7,
						"externalvoltage": 15.49,
						"gnssstatus": 1,
						"batterylevel": 94,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 24325,
						"sleepmode": 0,
						"gsmcellid": 40092,
						"gsmareacode": 8023,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 20110,
						"numOfData2": 1,
						"timestamp": "2022-09-21 03:04:21",
						"priority": 0,
						"longitude": 96.5395566,
						"latitude": 21.9907999,
						"altitude": 1171,
						"angle": 282,
						"satellites": 12,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 09:34:21",
						"thingName": "ltpk_9h_2360",
						"batchnumber": "2003",
						"city": "pyinoolwin",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford-ranger",
						"state": "mandalay-region",
						"type": "pickup-4x2-r",
						"zipcode": "05081",
						"stoppageDuration": 19,
						"ignitionOnTime": 128
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240002,
						"totalodometer": 301466144,
						"axisx": 665,
						"axisy": 64799,
						"axisz": 63903,
						"gsmsignal": 5,
						"speed": 4,
						"externalvoltage": 14.161,
						"gnssstatus": 1,
						"batterylevel": 97,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 45442,
						"sleepmode": 0,
						"gsmcellid": 31181,
						"gsmareacode": 8001,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 11406,
						"numOfData2": 1,
						"timestamp": "2022-09-21 04:11:36",
						"priority": 0,
						"longitude": 96.439515,
						"latitude": 21.9419866,
						"altitude": 1150,
						"angle": 257,
						"satellites": 11,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 10:41:36",
						"thingName": "ltpk_9h_2360",
						"batchnumber": "2003",
						"city": "pyinoolwin",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford-ranger",
						"state": "mandalay-region",
						"type": "pickup-4x2-r",
						"zipcode": "05081",
						"stoppageDuration": 128.3,
						"ignitionOnTime": 342
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240002,
						"totalodometer": 301484415,
						"axisx": 773,
						"axisy": 64785,
						"axisz": 63905,
						"gsmsignal": 5,
						"speed": 9,
						"externalvoltage": 14.127,
						"gnssstatus": 1,
						"batterylevel": 100,
						"gnsspdop": 0.7000000000000001,
						"gnsshdop": 0.5,
						"tripodometer": 18147,
						"sleepmode": 0,
						"gsmcellid": 40621,
						"gsmareacode": 8023,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 34567,
						"numOfData2": 1,
						"timestamp": "2022-09-21 07:05:48",
						"priority": 0,
						"longitude": 96.5012083,
						"latitude": 22.0402516,
						"altitude": 1090,
						"angle": 199,
						"satellites": 9,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 13:35:48",
						"thingName": "ltpk_9h_2360",
						"batchnumber": "2003",
						"city": "pyinoolwin",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford-ranger",
						"state": "mandalay-region",
						"type": "pickup-4x2-r",
						"zipcode": "05081",
						"stoppageDuration": 180.73333333333332,
						"ignitionOnTime": 24
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240002,
						"totalodometer": 301500777,
						"axisx": 642,
						"axisy": 64901,
						"axisz": 63962,
						"gsmsignal": 2,
						"speed": 11,
						"externalvoltage": 14.079,
						"gnssstatus": 1,
						"batterylevel": 85,
						"gnsspdop": 1.4000000000000001,
						"gnsshdop": 0.8,
						"tripodometer": 16322,
						"sleepmode": 0,
						"gsmcellid": 43981,
						"gsmareacode": 8023,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 58048,
						"numOfData2": 1,
						"timestamp": "2022-09-21 10:34:10",
						"priority": 0,
						"longitude": 96.4396149,
						"latitude": 21.94203,
						"altitude": 1166,
						"angle": 274,
						"satellites": 9,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 17:04:10",
						"thingName": "ltpk_9h_2360",
						"batchnumber": "2003",
						"city": "pyinoolwin",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford-ranger",
						"state": "mandalay-region",
						"type": "pickup-4x2-r",
						"zipcode": "05081",
						"stoppageDuration": 31.316666666666666,
						"ignitionOnTime": 89
					}
				],
				"Total Stoppage Time": 408.3333333333333,
				"Total Travel Time": "4hr 56min 4sec",
				"Route Points": [
					{
						"iccid1": 8995016202,
						"iccid2": 1091240002,
						"totalodometer": 301420702,
						"axisx": 688,
						"axisy": 64824,
						"axisz": 63992,
						"gsmsignal": 5,
						"speed": 0,
						"externalvoltage": 14.467,
						"gnssstatus": 2,
						"batterylevel": 66,
						"gnsspdop": 0,
						"gnsshdop": 0,
						"tripodometer": 0,
						"sleepmode": 0,
						"gsmcellid": 40621,
						"gsmareacode": 8023,
						"ignition": 1,
						"movement": 0,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 23341,
						"numOfData2": 1,
						"timestamp": "2022-09-21 00:44:29",
						"priority": 0,
						"longitude": 0,
						"latitude": 0,
						"altitude": 0,
						"angle": 0,
						"satellites": 0,
						"eventIoId": 240,
						"totalRecords": 20,
						"ts": "2022-09-21 07:14:29",
						"thingName": "ltpk_9h_2360",
						"batchnumber": "2003",
						"city": "pyinoolwin",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford-ranger",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05081"
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240002,
						"totalodometer": 301426337,
						"axisx": 661,
						"axisy": 64879,
						"axisz": 63897,
						"gsmsignal": 5,
						"speed": 10,
						"externalvoltage": 14.18,
						"gnssstatus": 1,
						"batterylevel": 78,
						"gnsspdop": 1,
						"gnsshdop": 0.5,
						"tripodometer": 5635,
						"sleepmode": 0,
						"gsmcellid": 40592,
						"gsmareacode": 8023,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 27208,
						"numOfData2": 1,
						"timestamp": "2022-09-21 00:59:57",
						"priority": 0,
						"longitude": 96.4559633,
						"latitude": 22.066235,
						"altitude": 1116,
						"angle": 1,
						"satellites": 9,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 07:29:57",
						"thingName": "ltpk_9h_2360",
						"batchnumber": "2003",
						"city": "pyinoolwin",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford-ranger",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05081",
						"stoppageDuration": 22.366666666666667,
						"ignitionOnTime": 29
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240002,
						"totalodometer": 301436524,
						"axisx": 604,
						"axisy": 64817,
						"axisz": 63891,
						"gsmsignal": 5,
						"speed": 11,
						"externalvoltage": 14.248000000000001,
						"gnssstatus": 1,
						"batterylevel": 94,
						"gnsspdop": 0.7000000000000001,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 15822,
						"sleepmode": 0,
						"gsmcellid": 40621,
						"gsmareacode": 8023,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 44009,
						"numOfData2": 1,
						"timestamp": "2022-09-21 01:54:47",
						"priority": 0,
						"longitude": 96.5012466,
						"latitude": 22.0403199,
						"altitude": 1067,
						"angle": 211,
						"satellites": 12,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 08:24:47",
						"thingName": "ltpk_9h_2360",
						"batchnumber": "2003",
						"city": "pyinoolwin",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford-ranger",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05081",
						"stoppageDuration": 26.616666666666667,
						"ignitionOnTime": 137
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240002,
						"totalodometer": 301445027,
						"axisx": 590,
						"axisy": 64886,
						"axisz": 63781,
						"gsmsignal": 2,
						"speed": 7,
						"externalvoltage": 15.49,
						"gnssstatus": 1,
						"batterylevel": 94,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 24325,
						"sleepmode": 0,
						"gsmcellid": 40092,
						"gsmareacode": 8023,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 20110,
						"numOfData2": 1,
						"timestamp": "2022-09-21 03:04:21",
						"priority": 0,
						"longitude": 96.5395566,
						"latitude": 21.9907999,
						"altitude": 1171,
						"angle": 282,
						"satellites": 12,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 09:34:21",
						"thingName": "ltpk_9h_2360",
						"batchnumber": "2003",
						"city": "pyinoolwin",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford-ranger",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05081",
						"stoppageDuration": 19,
						"ignitionOnTime": 128
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240002,
						"totalodometer": 301466144,
						"axisx": 665,
						"axisy": 64799,
						"axisz": 63903,
						"gsmsignal": 5,
						"speed": 4,
						"externalvoltage": 14.161,
						"gnssstatus": 1,
						"batterylevel": 97,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 45442,
						"sleepmode": 0,
						"gsmcellid": 31181,
						"gsmareacode": 8001,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 11406,
						"numOfData2": 1,
						"timestamp": "2022-09-21 04:11:36",
						"priority": 0,
						"longitude": 96.439515,
						"latitude": 21.9419866,
						"altitude": 1150,
						"angle": 257,
						"satellites": 11,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 10:41:36",
						"thingName": "ltpk_9h_2360",
						"batchnumber": "2003",
						"city": "pyinoolwin",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford-ranger",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05081",
						"stoppageDuration": 128.3,
						"ignitionOnTime": 342
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240002,
						"totalodometer": 301484415,
						"axisx": 773,
						"axisy": 64785,
						"axisz": 63905,
						"gsmsignal": 5,
						"speed": 9,
						"externalvoltage": 14.127,
						"gnssstatus": 1,
						"batterylevel": 100,
						"gnsspdop": 0.7000000000000001,
						"gnsshdop": 0.5,
						"tripodometer": 18147,
						"sleepmode": 0,
						"gsmcellid": 40621,
						"gsmareacode": 8023,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 34567,
						"numOfData2": 1,
						"timestamp": "2022-09-21 07:05:48",
						"priority": 0,
						"longitude": 96.5012083,
						"latitude": 22.0402516,
						"altitude": 1090,
						"angle": 199,
						"satellites": 9,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 13:35:48",
						"thingName": "ltpk_9h_2360",
						"batchnumber": "2003",
						"city": "pyinoolwin",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford-ranger",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05081",
						"stoppageDuration": 180.73333333333332,
						"ignitionOnTime": 24
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240002,
						"totalodometer": 301500777,
						"axisx": 642,
						"axisy": 64901,
						"axisz": 63962,
						"gsmsignal": 2,
						"speed": 11,
						"externalvoltage": 14.079,
						"gnssstatus": 1,
						"batterylevel": 85,
						"gnsspdop": 1.4000000000000001,
						"gnsshdop": 0.8,
						"tripodometer": 16322,
						"sleepmode": 0,
						"gsmcellid": 43981,
						"gsmareacode": 8023,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 58048,
						"numOfData2": 1,
						"timestamp": "2022-09-21 10:34:10",
						"priority": 0,
						"longitude": 96.4396149,
						"latitude": 21.94203,
						"altitude": 1166,
						"angle": 274,
						"satellites": 9,
						"eventIoId": 0,
						"totalRecords": 20,
						"ts": "2022-09-21 17:04:10",
						"thingName": "ltpk_9h_2360",
						"batchnumber": "2003",
						"city": "pyinoolwin",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford-ranger",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05081",
						"stoppageDuration": 31.316666666666666,
						"ignitionOnTime": 89
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240002,
						"totalodometer": 301501085,
						"axisx": 622,
						"axisy": 64954,
						"axisz": 64006,
						"gsmsignal": 5,
						"speed": 0,
						"externalvoltage": 12.529,
						"gnssstatus": 1,
						"batterylevel": 79,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 16630,
						"sleepmode": 0,
						"gsmcellid": 31182,
						"gsmareacode": 8001,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"dataFieldLength": 105,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 53790,
						"numOfData2": 1,
						"timestamp": "2022-09-21 12:28:53",
						"priority": 0,
						"longitude": 96.4389783,
						"latitude": 21.9415966,
						"altitude": 1127,
						"angle": 276,
						"satellites": 14,
						"eventIoId": 239,
						"totalRecords": 20,
						"ts": "2022-09-21 18:58:53",
						"thingName": "ltpk_9h_2360",
						"batchnumber": "2003",
						"city": "pyinoolwin",
						"make": "ford",
						"manufacturingdate": "25-Jun-1905",
						"model": "ford-ranger",
						"state": "mandalay-region",
						"type": "undesignated",
						"zipcode": "05081"
					}
				],
				"Total Distance Travelled": 80.383
			},
			{
				"Declutter Point": [
					{
						"iccid1": 8995016202,
						"iccid2": 1091240085,
						"totalodometer": 355275346,
						"axisx": 3,
						"axisy": 37,
						"axisz": 81,
						"gsmsignal": 4,
						"speed": 0,
						"externalvoltage": 14.099,
						"gnssstatus": 1,
						"batterylevel": 58,
						"gnsspdop": 0.7000000000000001,
						"gnsshdop": 0.5,
						"tripodometer": 0,
						"sleepmode": 0,
						"gsmcellid": 12203,
						"gsmareacode": 309,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"towing": 0,
						"trip": 0,
						"idling": 0,
						"unplug": 0,
						"dataFieldLength": 113,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 60017,
						"numOfData2": 1,
						"timestamp": "2022-09-21 01:32:31",
						"priority": 0,
						"longitude": 96.092505,
						"latitude": 16.9708816,
						"altitude": 43,
						"angle": 0,
						"satellites": 5,
						"eventIoId": 0,
						"totalRecords": 24,
						"ts": "2022-09-21 08:02:31",
						"thingName": "ltpk_8l_4418",
						"batchnumber": "2013",
						"city": "hlaingtharya",
						"make": "toyota",
						"manufacturingdate": "2013",
						"model": "asia-maung-l-t-1-ton",
						"state": "yangon-region",
						"type": "truck-4x2-r",
						"zipcode": "11401"
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240085,
						"totalodometer": 355381569,
						"axisx": 48,
						"axisy": 30,
						"axisz": 65484,
						"gsmsignal": 4,
						"speed": 0,
						"externalvoltage": 5.15,
						"gnssstatus": 1,
						"batterylevel": 92,
						"gnsspdop": 0.5,
						"gnsshdop": 0.4,
						"tripodometer": 4677,
						"sleepmode": 0,
						"gsmcellid": 12203,
						"gsmareacode": 309,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"towing": 0,
						"trip": 1,
						"idling": 0,
						"unplug": 1,
						"dataFieldLength": 113,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 23690,
						"numOfData2": 1,
						"timestamp": "2022-09-21 12:35:32",
						"priority": 1,
						"longitude": 96.092475,
						"latitude": 16.97068,
						"altitude": 65525,
						"angle": 319,
						"satellites": 12,
						"eventIoId": 252,
						"totalRecords": 24,
						"ts": "2022-09-21 19:05:32",
						"thingName": "ltpk_8l_4418",
						"batchnumber": "2013",
						"city": "hlaingtharya",
						"make": "toyota",
						"manufacturingdate": "2013",
						"model": "asia-maung-l-t-1-ton",
						"state": "yangon-region",
						"type": "truck-4x2-r",
						"zipcode": "11401"
					}
				],
				"state": "yangon-region",
				"city": "hlaingtharya",
				"pincode": "11401",
				"siteName": "",
				"siteId": "",
				"make": "toyota",
				"model": "asia-maung-l-t-1-ton",
				"size": "",
				"thingName": "ltpk_8l_4418",
				"UnDesignated Stoppage Points": [
					{
						"iccid1": 8995016202,
						"iccid2": 1091240085,
						"totalodometer": 355290206,
						"axisx": 428,
						"axisy": 65140,
						"axisz": 224,
						"gsmsignal": 5,
						"speed": 28,
						"externalvoltage": 13.785,
						"gnssstatus": 1,
						"batterylevel": 87,
						"gnsspdop": 0.5,
						"gnsshdop": 0.4,
						"tripodometer": 14860,
						"sleepmode": 0,
						"gsmcellid": 12021,
						"gsmareacode": 300,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"towing": 0,
						"trip": 1,
						"idling": 0,
						"unplug": 0,
						"dataFieldLength": 113,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 35613,
						"numOfData2": 1,
						"timestamp": "2022-09-21 02:28:45",
						"priority": 0,
						"longitude": 96.1219116,
						"latitude": 16.88268,
						"altitude": 16,
						"angle": 46,
						"satellites": 14,
						"eventIoId": 0,
						"totalRecords": 24,
						"ts": "2022-09-21 08:58:45",
						"thingName": "ltpk_8l_4418",
						"batchnumber": "2013",
						"city": "hlaingtharya",
						"make": "toyota",
						"manufacturingdate": "2013",
						"model": "asia-maung-l-t-1-ton",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11401",
						"stoppageDuration": 15.15,
						"ignitionOnTime": 909
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240085,
						"totalodometer": 355320500,
						"axisx": 506,
						"axisy": 250,
						"axisz": 407,
						"gsmsignal": 5,
						"speed": 9,
						"externalvoltage": 13.865,
						"gnssstatus": 1,
						"batterylevel": 86,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 45154,
						"sleepmode": 0,
						"gsmcellid": 11951,
						"gsmareacode": 306,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"towing": 0,
						"trip": 1,
						"idling": 0,
						"unplug": 0,
						"dataFieldLength": 113,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 18461,
						"numOfData2": 1,
						"timestamp": "2022-09-21 03:47:09",
						"priority": 0,
						"longitude": 95.9160299,
						"latitude": 16.9108333,
						"altitude": 4,
						"angle": 262,
						"satellites": 15,
						"eventIoId": 0,
						"totalRecords": 24,
						"ts": "2022-09-21 10:17:09",
						"thingName": "ltpk_8l_4418",
						"batchnumber": "2013",
						"city": "hlaingtharya",
						"make": "toyota",
						"manufacturingdate": "2013",
						"model": "asia-maung-l-t-1-ton",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11401",
						"stoppageDuration": 17.2,
						"ignitionOnTime": 380
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240085,
						"totalodometer": 355342054,
						"axisx": 65482,
						"axisy": 138,
						"axisz": 0,
						"gsmsignal": 2,
						"speed": 12,
						"externalvoltage": 13.739,
						"gnssstatus": 1,
						"batterylevel": 89,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 66708,
						"sleepmode": 0,
						"gsmcellid": 11702,
						"gsmareacode": 306,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"towing": 0,
						"trip": 1,
						"idling": 0,
						"unplug": 0,
						"dataFieldLength": 113,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 46679,
						"numOfData2": 1,
						"timestamp": "2022-09-21 04:55:56",
						"priority": 0,
						"longitude": 96.0449416,
						"latitude": 16.8971816,
						"altitude": 8,
						"angle": 325,
						"satellites": 11,
						"eventIoId": 0,
						"totalRecords": 24,
						"ts": "2022-09-21 11:25:56",
						"thingName": "ltpk_8l_4418",
						"batchnumber": "2013",
						"city": "hlaingtharya",
						"make": "toyota",
						"manufacturingdate": "2013",
						"model": "asia-maung-l-t-1-ton",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11401",
						"stoppageDuration": 33.88333333333333,
						"ignitionOnTime": 2033
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240085,
						"totalodometer": 355355224,
						"axisx": 42,
						"axisy": 65508,
						"axisz": 54,
						"gsmsignal": 3,
						"speed": 6,
						"externalvoltage": 13.675,
						"gnssstatus": 1,
						"batterylevel": 89,
						"gnsspdop": 0.8,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 79878,
						"sleepmode": 0,
						"gsmcellid": 12773,
						"gsmareacode": 300,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"towing": 0,
						"trip": 1,
						"idling": 0,
						"unplug": 0,
						"dataFieldLength": 113,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 24704,
						"numOfData2": 1,
						"timestamp": "2022-09-21 05:51:43",
						"priority": 0,
						"longitude": 96.106155,
						"latitude": 16.9113716,
						"altitude": 8,
						"angle": 240,
						"satellites": 14,
						"eventIoId": 0,
						"totalRecords": 24,
						"ts": "2022-09-21 12:21:43",
						"thingName": "ltpk_8l_4418",
						"batchnumber": "2013",
						"city": "hlaingtharya",
						"make": "toyota",
						"manufacturingdate": "2013",
						"model": "asia-maung-l-t-1-ton",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11401",
						"stoppageDuration": 27.65,
						"ignitionOnTime": 1659
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240085,
						"totalodometer": 355364897,
						"axisx": 307,
						"axisy": 65114,
						"axisz": 144,
						"gsmsignal": 3,
						"speed": 9,
						"externalvoltage": 13.856,
						"gnssstatus": 1,
						"batterylevel": 89,
						"gnsspdop": 0.4,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 89551,
						"sleepmode": 0,
						"gsmcellid": 12203,
						"gsmareacode": 309,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"towing": 0,
						"trip": 1,
						"idling": 0,
						"unplug": 0,
						"dataFieldLength": 113,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 19572,
						"numOfData2": 1,
						"timestamp": "2022-09-21 06:41:29",
						"priority": 0,
						"longitude": 96.0925916,
						"latitude": 16.970885,
						"altitude": 0,
						"angle": 139,
						"satellites": 16,
						"eventIoId": 0,
						"totalRecords": 24,
						"ts": "2022-09-21 13:11:29",
						"thingName": "ltpk_8l_4418",
						"batchnumber": "2013",
						"city": "hlaingtharya",
						"make": "toyota",
						"manufacturingdate": "2013",
						"model": "asia-maung-l-t-1-ton",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11401",
						"stoppageDuration": 50.75,
						"ignitionOnTime": 3045
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240085,
						"totalodometer": 355368947,
						"axisx": 251,
						"axisy": 65446,
						"axisz": 42,
						"gsmsignal": 4,
						"speed": 27,
						"externalvoltage": 13.65,
						"gnssstatus": 1,
						"batterylevel": 89,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 93601,
						"sleepmode": 0,
						"gsmcellid": 10153,
						"gsmareacode": 309,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"towing": 0,
						"trip": 1,
						"idling": 0,
						"unplug": 0,
						"dataFieldLength": 113,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 16502,
						"numOfData2": 1,
						"timestamp": "2022-09-21 07:48:49",
						"priority": 0,
						"longitude": 96.0838483,
						"latitude": 16.9458216,
						"altitude": 65532,
						"angle": 158,
						"satellites": 17,
						"eventIoId": 0,
						"totalRecords": 24,
						"ts": "2022-09-21 14:18:49",
						"thingName": "ltpk_8l_4418",
						"batchnumber": "2013",
						"city": "hlaingtharya",
						"make": "toyota",
						"manufacturingdate": "2013",
						"model": "asia-maung-l-t-1-ton",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11401",
						"stoppageDuration": 28.033333333333335,
						"ignitionOnTime": 1682
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240085,
						"totalodometer": 355372987,
						"axisx": 792,
						"axisy": 325,
						"axisz": 363,
						"gsmsignal": 4,
						"speed": 8,
						"externalvoltage": 13.638,
						"gnssstatus": 1,
						"batterylevel": 89,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 97641,
						"sleepmode": 0,
						"gsmcellid": 12203,
						"gsmareacode": 309,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"towing": 0,
						"trip": 1,
						"idling": 0,
						"unplug": 0,
						"dataFieldLength": 113,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 44138,
						"numOfData2": 1,
						"timestamp": "2022-09-21 08:36:26",
						"priority": 0,
						"longitude": 96.09262,
						"latitude": 16.9706083,
						"altitude": 4,
						"angle": 179,
						"satellites": 17,
						"eventIoId": 0,
						"totalRecords": 24,
						"ts": "2022-09-21 15:06:26",
						"thingName": "ltpk_8l_4418",
						"batchnumber": "2013",
						"city": "hlaingtharya",
						"make": "toyota",
						"manufacturingdate": "2013",
						"model": "asia-maung-l-t-1-ton",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11401",
						"stoppageDuration": 20.766666666666666,
						"ignitionOnTime": 1246
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240085,
						"totalodometer": 355376872,
						"axisx": 140,
						"axisy": 65473,
						"axisz": 88,
						"gsmsignal": 4,
						"speed": 17,
						"externalvoltage": 13.648,
						"gnssstatus": 1,
						"batterylevel": 89,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 101526,
						"sleepmode": 0,
						"gsmcellid": 10153,
						"gsmareacode": 309,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"towing": 0,
						"trip": 1,
						"idling": 0,
						"unplug": 0,
						"dataFieldLength": 113,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 19692,
						"numOfData2": 1,
						"timestamp": "2022-09-21 09:06:08",
						"priority": 0,
						"longitude": 96.083905,
						"latitude": 16.9457299,
						"altitude": 3,
						"angle": 156,
						"satellites": 16,
						"eventIoId": 0,
						"totalRecords": 24,
						"ts": "2022-09-21 15:36:08",
						"thingName": "ltpk_8l_4418",
						"batchnumber": "2013",
						"city": "hlaingtharya",
						"make": "toyota",
						"manufacturingdate": "2013",
						"model": "asia-maung-l-t-1-ton",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11401",
						"stoppageDuration": 159.83333333333334,
						"ignitionOnTime": 5680
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240085,
						"totalodometer": 355380862,
						"axisx": 214,
						"axisy": 65442,
						"axisz": 345,
						"gsmsignal": 5,
						"speed": 10,
						"externalvoltage": 12.780000000000001,
						"gnssstatus": 1,
						"batterylevel": 88,
						"gnsspdop": 0.4,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 3970,
						"sleepmode": 0,
						"gsmcellid": 12203,
						"gsmareacode": 309,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"towing": 0,
						"trip": 1,
						"idling": 0,
						"unplug": 0,
						"dataFieldLength": 113,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 53799,
						"numOfData2": 1,
						"timestamp": "2022-09-21 12:00:49",
						"priority": 0,
						"longitude": 96.0925816,
						"latitude": 16.9708733,
						"altitude": 5,
						"angle": 179,
						"satellites": 16,
						"eventIoId": 0,
						"totalRecords": 24,
						"ts": "2022-09-21 18:30:49",
						"thingName": "ltpk_8l_4418",
						"batchnumber": "2013",
						"city": "hlaingtharya",
						"make": "toyota",
						"manufacturingdate": "2013",
						"model": "asia-maung-l-t-1-ton",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11401",
						"stoppageDuration": 16.366666666666667,
						"ignitionOnTime": 982
					}
				],
				"Designated Stoppage Points": [],
				"Start Time": "2022-09-21 08:02:31",
				"End Time": "2022-09-21 19:05:32",
				"Stoppage Points": [
					{
						"iccid1": 8995016202,
						"iccid2": 1091240085,
						"totalodometer": 355290206,
						"axisx": 428,
						"axisy": 65140,
						"axisz": 224,
						"gsmsignal": 5,
						"speed": 28,
						"externalvoltage": 13.785,
						"gnssstatus": 1,
						"batterylevel": 87,
						"gnsspdop": 0.5,
						"gnsshdop": 0.4,
						"tripodometer": 14860,
						"sleepmode": 0,
						"gsmcellid": 12021,
						"gsmareacode": 300,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"towing": 0,
						"trip": 1,
						"idling": 0,
						"unplug": 0,
						"dataFieldLength": 113,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 35613,
						"numOfData2": 1,
						"timestamp": "2022-09-21 02:28:45",
						"priority": 0,
						"longitude": 96.1219116,
						"latitude": 16.88268,
						"altitude": 16,
						"angle": 46,
						"satellites": 14,
						"eventIoId": 0,
						"totalRecords": 24,
						"ts": "2022-09-21 08:58:45",
						"thingName": "ltpk_8l_4418",
						"batchnumber": "2013",
						"city": "hlaingtharya",
						"make": "toyota",
						"manufacturingdate": "2013",
						"model": "asia-maung-l-t-1-ton",
						"state": "yangon-region",
						"type": "truck-4x2-r",
						"zipcode": "11401",
						"stoppageDuration": 15.15,
						"ignitionOnTime": 909
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240085,
						"totalodometer": 355320500,
						"axisx": 506,
						"axisy": 250,
						"axisz": 407,
						"gsmsignal": 5,
						"speed": 9,
						"externalvoltage": 13.865,
						"gnssstatus": 1,
						"batterylevel": 86,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 45154,
						"sleepmode": 0,
						"gsmcellid": 11951,
						"gsmareacode": 306,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"towing": 0,
						"trip": 1,
						"idling": 0,
						"unplug": 0,
						"dataFieldLength": 113,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 18461,
						"numOfData2": 1,
						"timestamp": "2022-09-21 03:47:09",
						"priority": 0,
						"longitude": 95.9160299,
						"latitude": 16.9108333,
						"altitude": 4,
						"angle": 262,
						"satellites": 15,
						"eventIoId": 0,
						"totalRecords": 24,
						"ts": "2022-09-21 10:17:09",
						"thingName": "ltpk_8l_4418",
						"batchnumber": "2013",
						"city": "hlaingtharya",
						"make": "toyota",
						"manufacturingdate": "2013",
						"model": "asia-maung-l-t-1-ton",
						"state": "yangon-region",
						"type": "truck-4x2-r",
						"zipcode": "11401",
						"stoppageDuration": 17.2,
						"ignitionOnTime": 380
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240085,
						"totalodometer": 355342054,
						"axisx": 65482,
						"axisy": 138,
						"axisz": 0,
						"gsmsignal": 2,
						"speed": 12,
						"externalvoltage": 13.739,
						"gnssstatus": 1,
						"batterylevel": 89,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 66708,
						"sleepmode": 0,
						"gsmcellid": 11702,
						"gsmareacode": 306,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"towing": 0,
						"trip": 1,
						"idling": 0,
						"unplug": 0,
						"dataFieldLength": 113,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 46679,
						"numOfData2": 1,
						"timestamp": "2022-09-21 04:55:56",
						"priority": 0,
						"longitude": 96.0449416,
						"latitude": 16.8971816,
						"altitude": 8,
						"angle": 325,
						"satellites": 11,
						"eventIoId": 0,
						"totalRecords": 24,
						"ts": "2022-09-21 11:25:56",
						"thingName": "ltpk_8l_4418",
						"batchnumber": "2013",
						"city": "hlaingtharya",
						"make": "toyota",
						"manufacturingdate": "2013",
						"model": "asia-maung-l-t-1-ton",
						"state": "yangon-region",
						"type": "truck-4x2-r",
						"zipcode": "11401",
						"stoppageDuration": 33.88333333333333,
						"ignitionOnTime": 2033
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240085,
						"totalodometer": 355355224,
						"axisx": 42,
						"axisy": 65508,
						"axisz": 54,
						"gsmsignal": 3,
						"speed": 6,
						"externalvoltage": 13.675,
						"gnssstatus": 1,
						"batterylevel": 89,
						"gnsspdop": 0.8,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 79878,
						"sleepmode": 0,
						"gsmcellid": 12773,
						"gsmareacode": 300,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"towing": 0,
						"trip": 1,
						"idling": 0,
						"unplug": 0,
						"dataFieldLength": 113,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 24704,
						"numOfData2": 1,
						"timestamp": "2022-09-21 05:51:43",
						"priority": 0,
						"longitude": 96.106155,
						"latitude": 16.9113716,
						"altitude": 8,
						"angle": 240,
						"satellites": 14,
						"eventIoId": 0,
						"totalRecords": 24,
						"ts": "2022-09-21 12:21:43",
						"thingName": "ltpk_8l_4418",
						"batchnumber": "2013",
						"city": "hlaingtharya",
						"make": "toyota",
						"manufacturingdate": "2013",
						"model": "asia-maung-l-t-1-ton",
						"state": "yangon-region",
						"type": "truck-4x2-r",
						"zipcode": "11401",
						"stoppageDuration": 27.65,
						"ignitionOnTime": 1659
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240085,
						"totalodometer": 355364897,
						"axisx": 307,
						"axisy": 65114,
						"axisz": 144,
						"gsmsignal": 3,
						"speed": 9,
						"externalvoltage": 13.856,
						"gnssstatus": 1,
						"batterylevel": 89,
						"gnsspdop": 0.4,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 89551,
						"sleepmode": 0,
						"gsmcellid": 12203,
						"gsmareacode": 309,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"towing": 0,
						"trip": 1,
						"idling": 0,
						"unplug": 0,
						"dataFieldLength": 113,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 19572,
						"numOfData2": 1,
						"timestamp": "2022-09-21 06:41:29",
						"priority": 0,
						"longitude": 96.0925916,
						"latitude": 16.970885,
						"altitude": 0,
						"angle": 139,
						"satellites": 16,
						"eventIoId": 0,
						"totalRecords": 24,
						"ts": "2022-09-21 13:11:29",
						"thingName": "ltpk_8l_4418",
						"batchnumber": "2013",
						"city": "hlaingtharya",
						"make": "toyota",
						"manufacturingdate": "2013",
						"model": "asia-maung-l-t-1-ton",
						"state": "yangon-region",
						"type": "truck-4x2-r",
						"zipcode": "11401",
						"stoppageDuration": 50.75,
						"ignitionOnTime": 3045
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240085,
						"totalodometer": 355368947,
						"axisx": 251,
						"axisy": 65446,
						"axisz": 42,
						"gsmsignal": 4,
						"speed": 27,
						"externalvoltage": 13.65,
						"gnssstatus": 1,
						"batterylevel": 89,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 93601,
						"sleepmode": 0,
						"gsmcellid": 10153,
						"gsmareacode": 309,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"towing": 0,
						"trip": 1,
						"idling": 0,
						"unplug": 0,
						"dataFieldLength": 113,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 16502,
						"numOfData2": 1,
						"timestamp": "2022-09-21 07:48:49",
						"priority": 0,
						"longitude": 96.0838483,
						"latitude": 16.9458216,
						"altitude": 65532,
						"angle": 158,
						"satellites": 17,
						"eventIoId": 0,
						"totalRecords": 24,
						"ts": "2022-09-21 14:18:49",
						"thingName": "ltpk_8l_4418",
						"batchnumber": "2013",
						"city": "hlaingtharya",
						"make": "toyota",
						"manufacturingdate": "2013",
						"model": "asia-maung-l-t-1-ton",
						"state": "yangon-region",
						"type": "truck-4x2-r",
						"zipcode": "11401",
						"stoppageDuration": 28.033333333333335,
						"ignitionOnTime": 1682
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240085,
						"totalodometer": 355372987,
						"axisx": 792,
						"axisy": 325,
						"axisz": 363,
						"gsmsignal": 4,
						"speed": 8,
						"externalvoltage": 13.638,
						"gnssstatus": 1,
						"batterylevel": 89,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 97641,
						"sleepmode": 0,
						"gsmcellid": 12203,
						"gsmareacode": 309,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"towing": 0,
						"trip": 1,
						"idling": 0,
						"unplug": 0,
						"dataFieldLength": 113,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 44138,
						"numOfData2": 1,
						"timestamp": "2022-09-21 08:36:26",
						"priority": 0,
						"longitude": 96.09262,
						"latitude": 16.9706083,
						"altitude": 4,
						"angle": 179,
						"satellites": 17,
						"eventIoId": 0,
						"totalRecords": 24,
						"ts": "2022-09-21 15:06:26",
						"thingName": "ltpk_8l_4418",
						"batchnumber": "2013",
						"city": "hlaingtharya",
						"make": "toyota",
						"manufacturingdate": "2013",
						"model": "asia-maung-l-t-1-ton",
						"state": "yangon-region",
						"type": "truck-4x2-r",
						"zipcode": "11401",
						"stoppageDuration": 20.766666666666666,
						"ignitionOnTime": 1246
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240085,
						"totalodometer": 355376872,
						"axisx": 140,
						"axisy": 65473,
						"axisz": 88,
						"gsmsignal": 4,
						"speed": 17,
						"externalvoltage": 13.648,
						"gnssstatus": 1,
						"batterylevel": 89,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 101526,
						"sleepmode": 0,
						"gsmcellid": 10153,
						"gsmareacode": 309,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"towing": 0,
						"trip": 1,
						"idling": 0,
						"unplug": 0,
						"dataFieldLength": 113,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 19692,
						"numOfData2": 1,
						"timestamp": "2022-09-21 09:06:08",
						"priority": 0,
						"longitude": 96.083905,
						"latitude": 16.9457299,
						"altitude": 3,
						"angle": 156,
						"satellites": 16,
						"eventIoId": 0,
						"totalRecords": 24,
						"ts": "2022-09-21 15:36:08",
						"thingName": "ltpk_8l_4418",
						"batchnumber": "2013",
						"city": "hlaingtharya",
						"make": "toyota",
						"manufacturingdate": "2013",
						"model": "asia-maung-l-t-1-ton",
						"state": "yangon-region",
						"type": "truck-4x2-r",
						"zipcode": "11401",
						"stoppageDuration": 159.83333333333334,
						"ignitionOnTime": 5680
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240085,
						"totalodometer": 355380862,
						"axisx": 214,
						"axisy": 65442,
						"axisz": 345,
						"gsmsignal": 5,
						"speed": 10,
						"externalvoltage": 12.780000000000001,
						"gnssstatus": 1,
						"batterylevel": 88,
						"gnsspdop": 0.4,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 3970,
						"sleepmode": 0,
						"gsmcellid": 12203,
						"gsmareacode": 309,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"towing": 0,
						"trip": 1,
						"idling": 0,
						"unplug": 0,
						"dataFieldLength": 113,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 53799,
						"numOfData2": 1,
						"timestamp": "2022-09-21 12:00:49",
						"priority": 0,
						"longitude": 96.0925816,
						"latitude": 16.9708733,
						"altitude": 5,
						"angle": 179,
						"satellites": 16,
						"eventIoId": 0,
						"totalRecords": 24,
						"ts": "2022-09-21 18:30:49",
						"thingName": "ltpk_8l_4418",
						"batchnumber": "2013",
						"city": "hlaingtharya",
						"make": "toyota",
						"manufacturingdate": "2013",
						"model": "asia-maung-l-t-1-ton",
						"state": "yangon-region",
						"type": "truck-4x2-r",
						"zipcode": "11401",
						"stoppageDuration": 16.366666666666667,
						"ignitionOnTime": 982
					}
				],
				"Total Stoppage Time": 369.6333333333333,
				"Total Travel Time": "4hr 53min 23sec",
				"Route Points": [
					{
						"iccid1": 8995016202,
						"iccid2": 1091240085,
						"totalodometer": 355275346,
						"axisx": 3,
						"axisy": 37,
						"axisz": 81,
						"gsmsignal": 4,
						"speed": 0,
						"externalvoltage": 14.099,
						"gnssstatus": 1,
						"batterylevel": 58,
						"gnsspdop": 0.7000000000000001,
						"gnsshdop": 0.5,
						"tripodometer": 0,
						"sleepmode": 0,
						"gsmcellid": 12203,
						"gsmareacode": 309,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"towing": 0,
						"trip": 0,
						"idling": 0,
						"unplug": 0,
						"dataFieldLength": 113,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 60017,
						"numOfData2": 1,
						"timestamp": "2022-09-21 01:32:31",
						"priority": 0,
						"longitude": 96.092505,
						"latitude": 16.9708816,
						"altitude": 43,
						"angle": 0,
						"satellites": 5,
						"eventIoId": 0,
						"totalRecords": 24,
						"ts": "2022-09-21 08:02:31",
						"thingName": "ltpk_8l_4418",
						"batchnumber": "2013",
						"city": "hlaingtharya",
						"make": "toyota",
						"manufacturingdate": "2013",
						"model": "asia-maung-l-t-1-ton",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11401"
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240085,
						"totalodometer": 355290206,
						"axisx": 428,
						"axisy": 65140,
						"axisz": 224,
						"gsmsignal": 5,
						"speed": 28,
						"externalvoltage": 13.785,
						"gnssstatus": 1,
						"batterylevel": 87,
						"gnsspdop": 0.5,
						"gnsshdop": 0.4,
						"tripodometer": 14860,
						"sleepmode": 0,
						"gsmcellid": 12021,
						"gsmareacode": 300,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"towing": 0,
						"trip": 1,
						"idling": 0,
						"unplug": 0,
						"dataFieldLength": 113,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 35613,
						"numOfData2": 1,
						"timestamp": "2022-09-21 02:28:45",
						"priority": 0,
						"longitude": 96.1219116,
						"latitude": 16.88268,
						"altitude": 16,
						"angle": 46,
						"satellites": 14,
						"eventIoId": 0,
						"totalRecords": 24,
						"ts": "2022-09-21 08:58:45",
						"thingName": "ltpk_8l_4418",
						"batchnumber": "2013",
						"city": "hlaingtharya",
						"make": "toyota",
						"manufacturingdate": "2013",
						"model": "asia-maung-l-t-1-ton",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11401",
						"stoppageDuration": 15.15,
						"ignitionOnTime": 909
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240085,
						"totalodometer": 355320500,
						"axisx": 506,
						"axisy": 250,
						"axisz": 407,
						"gsmsignal": 5,
						"speed": 9,
						"externalvoltage": 13.865,
						"gnssstatus": 1,
						"batterylevel": 86,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 45154,
						"sleepmode": 0,
						"gsmcellid": 11951,
						"gsmareacode": 306,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"towing": 0,
						"trip": 1,
						"idling": 0,
						"unplug": 0,
						"dataFieldLength": 113,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 18461,
						"numOfData2": 1,
						"timestamp": "2022-09-21 03:47:09",
						"priority": 0,
						"longitude": 95.9160299,
						"latitude": 16.9108333,
						"altitude": 4,
						"angle": 262,
						"satellites": 15,
						"eventIoId": 0,
						"totalRecords": 24,
						"ts": "2022-09-21 10:17:09",
						"thingName": "ltpk_8l_4418",
						"batchnumber": "2013",
						"city": "hlaingtharya",
						"make": "toyota",
						"manufacturingdate": "2013",
						"model": "asia-maung-l-t-1-ton",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11401",
						"stoppageDuration": 17.2,
						"ignitionOnTime": 380
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240085,
						"totalodometer": 355342054,
						"axisx": 65482,
						"axisy": 138,
						"axisz": 0,
						"gsmsignal": 2,
						"speed": 12,
						"externalvoltage": 13.739,
						"gnssstatus": 1,
						"batterylevel": 89,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.4,
						"tripodometer": 66708,
						"sleepmode": 0,
						"gsmcellid": 11702,
						"gsmareacode": 306,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"towing": 0,
						"trip": 1,
						"idling": 0,
						"unplug": 0,
						"dataFieldLength": 113,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 46679,
						"numOfData2": 1,
						"timestamp": "2022-09-21 04:55:56",
						"priority": 0,
						"longitude": 96.0449416,
						"latitude": 16.8971816,
						"altitude": 8,
						"angle": 325,
						"satellites": 11,
						"eventIoId": 0,
						"totalRecords": 24,
						"ts": "2022-09-21 11:25:56",
						"thingName": "ltpk_8l_4418",
						"batchnumber": "2013",
						"city": "hlaingtharya",
						"make": "toyota",
						"manufacturingdate": "2013",
						"model": "asia-maung-l-t-1-ton",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11401",
						"stoppageDuration": 33.88333333333333,
						"ignitionOnTime": 2033
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240085,
						"totalodometer": 355355224,
						"axisx": 42,
						"axisy": 65508,
						"axisz": 54,
						"gsmsignal": 3,
						"speed": 6,
						"externalvoltage": 13.675,
						"gnssstatus": 1,
						"batterylevel": 89,
						"gnsspdop": 0.8,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 79878,
						"sleepmode": 0,
						"gsmcellid": 12773,
						"gsmareacode": 300,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"towing": 0,
						"trip": 1,
						"idling": 0,
						"unplug": 0,
						"dataFieldLength": 113,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 24704,
						"numOfData2": 1,
						"timestamp": "2022-09-21 05:51:43",
						"priority": 0,
						"longitude": 96.106155,
						"latitude": 16.9113716,
						"altitude": 8,
						"angle": 240,
						"satellites": 14,
						"eventIoId": 0,
						"totalRecords": 24,
						"ts": "2022-09-21 12:21:43",
						"thingName": "ltpk_8l_4418",
						"batchnumber": "2013",
						"city": "hlaingtharya",
						"make": "toyota",
						"manufacturingdate": "2013",
						"model": "asia-maung-l-t-1-ton",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11401",
						"stoppageDuration": 27.65,
						"ignitionOnTime": 1659
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240085,
						"totalodometer": 355364897,
						"axisx": 307,
						"axisy": 65114,
						"axisz": 144,
						"gsmsignal": 3,
						"speed": 9,
						"externalvoltage": 13.856,
						"gnssstatus": 1,
						"batterylevel": 89,
						"gnsspdop": 0.4,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 89551,
						"sleepmode": 0,
						"gsmcellid": 12203,
						"gsmareacode": 309,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"towing": 0,
						"trip": 1,
						"idling": 0,
						"unplug": 0,
						"dataFieldLength": 113,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 19572,
						"numOfData2": 1,
						"timestamp": "2022-09-21 06:41:29",
						"priority": 0,
						"longitude": 96.0925916,
						"latitude": 16.970885,
						"altitude": 0,
						"angle": 139,
						"satellites": 16,
						"eventIoId": 0,
						"totalRecords": 24,
						"ts": "2022-09-21 13:11:29",
						"thingName": "ltpk_8l_4418",
						"batchnumber": "2013",
						"city": "hlaingtharya",
						"make": "toyota",
						"manufacturingdate": "2013",
						"model": "asia-maung-l-t-1-ton",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11401",
						"stoppageDuration": 50.75,
						"ignitionOnTime": 3045
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240085,
						"totalodometer": 355368947,
						"axisx": 251,
						"axisy": 65446,
						"axisz": 42,
						"gsmsignal": 4,
						"speed": 27,
						"externalvoltage": 13.65,
						"gnssstatus": 1,
						"batterylevel": 89,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 93601,
						"sleepmode": 0,
						"gsmcellid": 10153,
						"gsmareacode": 309,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"towing": 0,
						"trip": 1,
						"idling": 0,
						"unplug": 0,
						"dataFieldLength": 113,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 16502,
						"numOfData2": 1,
						"timestamp": "2022-09-21 07:48:49",
						"priority": 0,
						"longitude": 96.0838483,
						"latitude": 16.9458216,
						"altitude": 65532,
						"angle": 158,
						"satellites": 17,
						"eventIoId": 0,
						"totalRecords": 24,
						"ts": "2022-09-21 14:18:49",
						"thingName": "ltpk_8l_4418",
						"batchnumber": "2013",
						"city": "hlaingtharya",
						"make": "toyota",
						"manufacturingdate": "2013",
						"model": "asia-maung-l-t-1-ton",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11401",
						"stoppageDuration": 28.033333333333335,
						"ignitionOnTime": 1682
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240085,
						"totalodometer": 355372987,
						"axisx": 792,
						"axisy": 325,
						"axisz": 363,
						"gsmsignal": 4,
						"speed": 8,
						"externalvoltage": 13.638,
						"gnssstatus": 1,
						"batterylevel": 89,
						"gnsspdop": 0.6000000000000001,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 97641,
						"sleepmode": 0,
						"gsmcellid": 12203,
						"gsmareacode": 309,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"towing": 0,
						"trip": 1,
						"idling": 0,
						"unplug": 0,
						"dataFieldLength": 113,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 44138,
						"numOfData2": 1,
						"timestamp": "2022-09-21 08:36:26",
						"priority": 0,
						"longitude": 96.09262,
						"latitude": 16.9706083,
						"altitude": 4,
						"angle": 179,
						"satellites": 17,
						"eventIoId": 0,
						"totalRecords": 24,
						"ts": "2022-09-21 15:06:26",
						"thingName": "ltpk_8l_4418",
						"batchnumber": "2013",
						"city": "hlaingtharya",
						"make": "toyota",
						"manufacturingdate": "2013",
						"model": "asia-maung-l-t-1-ton",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11401",
						"stoppageDuration": 20.766666666666666,
						"ignitionOnTime": 1246
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240085,
						"totalodometer": 355376872,
						"axisx": 140,
						"axisy": 65473,
						"axisz": 88,
						"gsmsignal": 4,
						"speed": 17,
						"externalvoltage": 13.648,
						"gnssstatus": 1,
						"batterylevel": 89,
						"gnsspdop": 0.5,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 101526,
						"sleepmode": 0,
						"gsmcellid": 10153,
						"gsmareacode": 309,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"towing": 0,
						"trip": 1,
						"idling": 0,
						"unplug": 0,
						"dataFieldLength": 113,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 19692,
						"numOfData2": 1,
						"timestamp": "2022-09-21 09:06:08",
						"priority": 0,
						"longitude": 96.083905,
						"latitude": 16.9457299,
						"altitude": 3,
						"angle": 156,
						"satellites": 16,
						"eventIoId": 0,
						"totalRecords": 24,
						"ts": "2022-09-21 15:36:08",
						"thingName": "ltpk_8l_4418",
						"batchnumber": "2013",
						"city": "hlaingtharya",
						"make": "toyota",
						"manufacturingdate": "2013",
						"model": "asia-maung-l-t-1-ton",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11401",
						"stoppageDuration": 159.83333333333334,
						"ignitionOnTime": 5680
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240085,
						"totalodometer": 355380862,
						"axisx": 214,
						"axisy": 65442,
						"axisz": 345,
						"gsmsignal": 5,
						"speed": 10,
						"externalvoltage": 12.780000000000001,
						"gnssstatus": 1,
						"batterylevel": 88,
						"gnsspdop": 0.4,
						"gnsshdop": 0.30000000000000004,
						"tripodometer": 3970,
						"sleepmode": 0,
						"gsmcellid": 12203,
						"gsmareacode": 309,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"towing": 0,
						"trip": 1,
						"idling": 0,
						"unplug": 0,
						"dataFieldLength": 113,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 53799,
						"numOfData2": 1,
						"timestamp": "2022-09-21 12:00:49",
						"priority": 0,
						"longitude": 96.0925816,
						"latitude": 16.9708733,
						"altitude": 5,
						"angle": 179,
						"satellites": 16,
						"eventIoId": 0,
						"totalRecords": 24,
						"ts": "2022-09-21 18:30:49",
						"thingName": "ltpk_8l_4418",
						"batchnumber": "2013",
						"city": "hlaingtharya",
						"make": "toyota",
						"manufacturingdate": "2013",
						"model": "asia-maung-l-t-1-ton",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11401",
						"stoppageDuration": 16.366666666666667,
						"ignitionOnTime": 982
					},
					{
						"iccid1": 8995016202,
						"iccid2": 1091240085,
						"totalodometer": 355381569,
						"axisx": 48,
						"axisy": 30,
						"axisz": 65484,
						"gsmsignal": 4,
						"speed": 0,
						"externalvoltage": 5.15,
						"gnssstatus": 1,
						"batterylevel": 92,
						"gnsspdop": 0.5,
						"gnsshdop": 0.4,
						"tripodometer": 4677,
						"sleepmode": 0,
						"gsmcellid": 12203,
						"gsmareacode": 309,
						"ignition": 1,
						"movement": 1,
						"activegsmoperator": 41401,
						"towing": 0,
						"trip": 1,
						"idling": 0,
						"unplug": 1,
						"dataFieldLength": 113,
						"codecId": 8,
						"numOfData1": 1,
						"crc": 23690,
						"numOfData2": 1,
						"timestamp": "2022-09-21 12:35:32",
						"priority": 1,
						"longitude": 96.092475,
						"latitude": 16.97068,
						"altitude": 65525,
						"angle": 319,
						"satellites": 12,
						"eventIoId": 252,
						"totalRecords": 24,
						"ts": "2022-09-21 19:05:32",
						"thingName": "ltpk_8l_4418",
						"batchnumber": "2013",
						"city": "hlaingtharya",
						"make": "toyota",
						"manufacturingdate": "2013",
						"model": "asia-maung-l-t-1-ton",
						"state": "yangon-region",
						"type": "undesignated",
						"zipcode": "11401"
					}
				],
				"Total Distance Travelled": 106.223
			}
		]
	},
	"message": "",
	"success": true,
	"responseCode": 200
};

export const fuel_consumption = [
    {
        "_id": "631b41126164e146ac337750",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-06-13 23:43:18",
        "city": "mohali",
        "closingFuelLevel": 38.45,
        "date": "2022-09-09T13:35:14.154Z",
        "deFuellingAmount": 0,
        "fuelAdded": 8.27,
        "fuelConsumed": 9.25,
        "fuelConsumedperRunHour": 4.55,
        "fuelDrained": 9.55,
        "fuelLessThanThreshold": 38.45,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 47.7,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "2 hr 2 min 3 sec"
    },
    {
        "_id": "631b41126164e146ac337751",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-06-13 23:51:23",
        "city": "mohali",
        "closingFuelLevel": 153.68,
        "date": "2022-09-09T13:35:14.154Z",
        "deFuellingAmount": 4.77,
        "fuelAdded": 69.96,
        "fuelConsumed": 1.89,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 13.99,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 62.63,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 97.71,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e146ac337752",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-06-13 23:50:05",
        "city": "mohali",
        "closingFuelLevel": 61.41,
        "date": "2022-09-09T13:35:14.154Z",
        "deFuellingAmount": 0,
        "fuelAdded": 9.57,
        "fuelConsumed": 11.07,
        "fuelConsumedperRunHour": 4.7,
        "fuelDrained": 9.05,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 72.48,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "2 hr 21 min 18 sec"
    },
    {
        "_id": "631b41126164e146ac337753",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-06-13 22:30:14",
        "city": "mohali",
        "closingFuelLevel": 118.28,
        "date": "2022-09-09T13:35:14.154Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.16,
        "fuelConsumed": -0.38,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 2.78,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 117.9,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e146ac337754",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-07-23 23:49:28",
        "city": "mohali",
        "closingFuelLevel": 78.18,
        "date": "2022-09-09T13:35:14.343Z",
        "deFuellingAmount": 3.16,
        "fuelAdded": 6.13,
        "fuelConsumed": -0.31,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 8.98,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 81.03,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e146ac337755",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-07-23 23:47:00",
        "city": "mohali",
        "closingFuelLevel": 48.7,
        "date": "2022-09-09T13:35:14.343Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.62,
        "fuelConsumed": 0.74,
        "fuelConsumedperRunHour": 2.56,
        "fuelDrained": 4.56,
        "fuelLessThanThreshold": 48.7,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 49.44,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 17 min 22 sec"
    },
    {
        "_id": "631b41126164e146ac337761",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-05-25 23:56:06",
        "city": "mohali",
        "closingFuelLevel": 36.49,
        "date": "2022-09-09T13:35:14.595Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.39,
        "fuelConsumed": -0.17,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 5.22,
        "fuelLessThanThreshold": 36.49,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 36.32,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b05906164e1396f29f339",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-09-08 23:43:10",
        "city": "mohali",
        "closingFuelLevel": 30.41,
        "date": "2022-09-09T13:35:15.184Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.8,
        "fuelConsumed": 4.42,
        "fuelConsumedperRunHour": 2.21,
        "fuelDrained": 6.3,
        "fuelLessThanThreshold": 30.41,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 34.83,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 59 min 48 sec"
    },
    {
        "_id": "631b05906164e1396f29f33a",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-09-08 23:22:42",
        "city": "mohali",
        "closingFuelLevel": 102.1,
        "date": "2022-09-09T13:35:15.184Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.35,
        "fuelConsumed": -0.28,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.07,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 101.82,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e146ac337756",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-07-23 23:56:54",
        "city": "mohali",
        "closingFuelLevel": 45.42,
        "date": "2022-09-09T13:35:14.343Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.13,
        "fuelConsumed": -0.05,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.08,
        "fuelLessThanThreshold": 45.42,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 45.37,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e146ac337757",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-07-23 23:39:30",
        "city": "mohali",
        "closingFuelLevel": 112.99,
        "date": "2022-09-09T13:35:14.343Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.95,
        "fuelConsumed": 1.04,
        "fuelConsumedperRunHour": 3.61,
        "fuelDrained": 5.08,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 114.03,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 17 min 17 sec"
    },
    {
        "_id": "631b41126164e146ac337758",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-04-29 23:37:55",
        "city": "mohali",
        "closingFuelLevel": 42.91,
        "date": "2022-09-09T13:35:14.459Z",
        "deFuellingAmount": 0,
        "fuelAdded": 9.1,
        "fuelConsumed": 2.51,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 11.61,
        "fuelLessThanThreshold": 42.91,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 45.42,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e146ac337759",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-04-29 23:32:50",
        "city": "mohali",
        "closingFuelLevel": 146.91,
        "date": "2022-09-09T13:35:14.459Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.79,
        "fuelConsumed": 1.08,
        "fuelConsumedperRunHour": 2.09,
        "fuelDrained": 5.32,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 147.99,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 30 min 56 sec"
    },
    {
        "_id": "631b41126164e146ac33775a",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-04-29 23:30:19",
        "city": "mohali",
        "closingFuelLevel": 60.35,
        "date": "2022-09-09T13:35:14.459Z",
        "deFuellingAmount": 0,
        "fuelAdded": 9.83,
        "fuelConsumed": 18.67,
        "fuelConsumedperRunHour": 4.25,
        "fuelDrained": 6.5,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 79.02,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "4 hr 23 min 40 sec"
    },
    {
        "_id": "631b41126164e146ac33775b",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-04-20 23:45:27",
        "city": "mohali",
        "closingFuelLevel": 191.12,
        "date": "2022-09-09T13:35:14.529Z",
        "deFuellingAmount": 0,
        "fuelAdded": 19.3,
        "fuelConsumed": 27.18,
        "fuelConsumedperRunHour": 4.21,
        "fuelDrained": 6.5,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 218.3,
        "pincode": "140603",
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "6 hr 27 min 25 sec"
    },
    {
        "_id": "631b41126164e146ac33775c",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-04-20 23:51:23",
        "city": "mohali",
        "closingFuelLevel": 78.92,
        "date": "2022-09-09T13:35:14.529Z",
        "deFuellingAmount": 0,
        "fuelAdded": 18.32,
        "fuelConsumed": 14.13,
        "fuelConsumedperRunHour": 4.24,
        "fuelDrained": 13.16,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 93.05,
        "pincode": "160103",
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "3 hr 19 min 57 sec"
    },
    {
        "_id": "631b41126164e146ac33775d",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-04-20 23:44:41",
        "city": "mohali",
        "closingFuelLevel": 208.35,
        "date": "2022-09-09T13:35:14.529Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.94,
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.94,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 208.35,
        "pincode": "160055",
        "siteId": "",
        "siteName": "",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e146ac33775e",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-04-20 23:34:42",
        "city": "mohali",
        "closingFuelLevel": 177.75,
        "date": "2022-09-09T13:35:14.529Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.86,
        "fuelConsumed": 0.13,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.99,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 177.88,
        "pincode": "140307",
        "siteId": "",
        "siteName": "",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e146ac33775f",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-05-25 23:54:10",
        "city": "mohali",
        "closingFuelLevel": 65.16,
        "date": "2022-09-09T13:35:14.595Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.66,
        "fuelConsumed": -0.32,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 5.34,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 64.84,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e146ac337760",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-05-25 23:38:20",
        "city": "mohali",
        "closingFuelLevel": 66.2,
        "date": "2022-09-09T13:35:14.595Z",
        "deFuellingAmount": 0,
        "fuelAdded": 27.13,
        "fuelConsumed": 1.22,
        "fuelConsumedperRunHour": 2.51,
        "fuelDrained": 5.85,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 19.84,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 47.58,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 29 min 12 sec"
    },
    {
        "_id": "631b41126164e14734763277",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-05-15 23:58:46",
        "city": "mohali",
        "closingFuelLevel": 77.39,
        "date": "2022-09-09T13:35:14.609Z",
        "deFuellingAmount": 0,
        "fuelAdded": 7.93,
        "fuelConsumed": 0.25,
        "fuelConsumedperRunHour": 0.72,
        "fuelDrained": 6.76,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 77.64,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 20 min 51 sec"
    },
    {
        "_id": "631b41126164e14734763278",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-05-15 23:52:03",
        "city": "mohali",
        "closingFuelLevel": 34.32,
        "date": "2022-09-09T13:35:14.609Z",
        "deFuellingAmount": 0,
        "fuelAdded": 10.7,
        "fuelConsumed": 1,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 11.7,
        "fuelLessThanThreshold": 34.32,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 35.32,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e14734763279",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-05-15 23:52:34",
        "city": "mohali",
        "closingFuelLevel": 99.72,
        "date": "2022-09-09T13:35:14.609Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.1,
        "fuelConsumed": 0.9,
        "fuelConsumedperRunHour": 4.77,
        "fuelDrained": 6.5,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 100.62,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 11 min 19 sec"
    },
    {
        "_id": "631b41126164e1473476327a",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-05-15 23:58:50",
        "city": "mohali",
        "closingFuelLevel": 65.97,
        "date": "2022-09-09T13:35:14.609Z",
        "deFuellingAmount": 0,
        "fuelAdded": 7.12,
        "fuelConsumed": -0.27,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 6.85,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 65.7,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e1473476327b",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-06-07 23:36:08",
        "city": "mohali",
        "closingFuelLevel": 69.6,
        "date": "2022-09-09T13:35:14.593Z",
        "deFuellingAmount": 0,
        "fuelAdded": 7.72,
        "fuelConsumed": 1.96,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 9.68,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 71.56,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e1473476327c",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-06-07 23:57:31",
        "city": "mohali",
        "closingFuelLevel": 63,
        "date": "2022-09-09T13:35:14.593Z",
        "deFuellingAmount": 0,
        "fuelAdded": 7.83,
        "fuelConsumed": 5.47,
        "fuelConsumedperRunHour": 4.39,
        "fuelDrained": 8.61,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 68.47,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 14 min 50 sec"
    },
    {
        "_id": "631b41126164e1473476327d",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-06-07 23:48:28",
        "city": "mohali",
        "closingFuelLevel": 69.57,
        "date": "2022-09-09T13:35:14.593Z",
        "deFuellingAmount": 0,
        "fuelAdded": 8.85,
        "fuelConsumed": 4.84,
        "fuelConsumedperRunHour": 4.61,
        "fuelDrained": 8.92,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 74.41,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 3 min 3 sec"
    },
    {
        "_id": "631b41126164e1473476327e",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-06-07 23:40:58",
        "city": "mohali",
        "closingFuelLevel": 82.12,
        "date": "2022-09-09T13:35:14.593Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.43,
        "fuelConsumed": -0.03,
        "fuelConsumedperRunHour": -0.38,
        "fuelDrained": 3.24,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 82.09,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 4 min 46 sec"
    },
    {
        "_id": "631b41126164e1473476327f",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-04-14 23:53:26",
        "city": "mohali",
        "closingFuelLevel": 232.95,
        "date": "2022-09-09T13:35:14.634Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.91,
        "fuelConsumed": 0.1,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 5.01,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 233.05,
        "pincode": "160055",
        "siteId": "",
        "siteName": "",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e14734763280",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-04-14 23:37:35",
        "city": "mohali",
        "closingFuelLevel": 144.47,
        "date": "2022-09-09T13:35:14.634Z",
        "deFuellingAmount": 0,
        "fuelAdded": 11.08,
        "fuelConsumed": 19.24,
        "fuelConsumedperRunHour": 4.46,
        "fuelDrained": 9.96,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 163.71,
        "pincode": "160103",
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "4 hr 19 min 2 sec"
    },
    {
        "_id": "631b41126164e14734763281",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-05-24 23:51:37",
        "city": "mohali",
        "closingFuelLevel": 64.9,
        "date": "2022-09-09T13:35:14.631Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.04,
        "fuelConsumed": -0.06,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.98,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 64.84,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e146ac337762",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-05-25 23:41:43",
        "city": "mohali",
        "closingFuelLevel": 62.94,
        "date": "2022-09-09T13:35:14.595Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.37,
        "fuelConsumed": 0.47,
        "fuelConsumedperRunHour": 16.75,
        "fuelDrained": 6.84,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 63.41,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 1 min 41 sec"
    },
    {
        "_id": "631b41126164e146ac337763",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-05-13 23:53:45",
        "city": "mohali",
        "closingFuelLevel": 65.48,
        "date": "2022-09-09T13:35:14.593Z",
        "deFuellingAmount": 0,
        "fuelAdded": 50.06,
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 11.4,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 38.66,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 26.82,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e146ac337764",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-05-13 23:54:48",
        "city": "mohali",
        "closingFuelLevel": 107.44,
        "date": "2022-09-09T13:35:14.593Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.66,
        "fuelConsumed": 0.58,
        "fuelConsumedperRunHour": 10.24,
        "fuelDrained": 7.13,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 108.02,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 3 min 24 sec"
    },
    {
        "_id": "631b41126164e146ac337765",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-05-13 23:59:08",
        "city": "mohali",
        "closingFuelLevel": 39.68,
        "date": "2022-09-09T13:35:14.593Z",
        "deFuellingAmount": 2.15,
        "fuelAdded": 6.06,
        "fuelConsumed": 3.29,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 11.5,
        "fuelLessThanThreshold": 39.68,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 45.12,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e146ac337766",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-05-13 23:46:57",
        "city": "mohali",
        "closingFuelLevel": 77.83,
        "date": "2022-09-09T13:35:14.593Z",
        "deFuellingAmount": 0,
        "fuelAdded": 47.32,
        "fuelConsumed": 0.06,
        "fuelConsumedperRunHour": 3.48,
        "fuelDrained": 9.8,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 37.58,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 40.31,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 1 min 2 sec"
    },
    {
        "_id": "631b41126164e146ac337767",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-04-21 23:42:20",
        "city": "mohali",
        "closingFuelLevel": 172.87,
        "date": "2022-09-09T13:35:14.615Z",
        "deFuellingAmount": 0,
        "fuelAdded": 15.98,
        "fuelConsumed": 18.09,
        "fuelConsumedperRunHour": 4.42,
        "fuelDrained": 7.11,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 190.96,
        "pincode": "140603",
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "4 hr 5 min 33 sec"
    },
    {
        "_id": "631b41126164e16bc02c31fa",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-05-24 23:53:35",
        "city": "mohali",
        "closingFuelLevel": 36.38,
        "date": "2022-09-09T13:35:14.631Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.65,
        "fuelConsumed": 3.22,
        "fuelConsumedperRunHour": 4.87,
        "fuelDrained": 6.46,
        "fuelLessThanThreshold": 36.38,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 39.6,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 39 min 42 sec"
    },
    {
        "_id": "631b41126164e1469f0693d5",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-04-11 23:20:33",
        "city": "mohali",
        "closingFuelLevel": 264.59,
        "date": "2022-09-09T13:35:14.636Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.73,
        "fuelConsumed": 1.57,
        "fuelConsumedperRunHour": 3.26,
        "fuelDrained": 4.96,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 266.16,
        "pincode": "140307",
        "siteId": "",
        "siteName": "",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 28 min 54 sec"
    },
    {
        "_id": "631b41126164e146ac337768",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-04-21 23:39:33",
        "city": "mohali",
        "closingFuelLevel": 173.75,
        "date": "2022-09-09T13:35:14.615Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.8,
        "fuelConsumed": 3.88,
        "fuelConsumedperRunHour": 4.61,
        "fuelDrained": 4.23,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 177.63,
        "pincode": "140307",
        "siteId": "",
        "siteName": "",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 50 min 27 sec"
    },
    {
        "_id": "631b41126164e15c0e0abb8d",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-06-11 23:56:58",
        "city": "mohali",
        "closingFuelLevel": 72.76,
        "date": "2022-09-09T13:35:14.655Z",
        "deFuellingAmount": 0,
        "fuelAdded": 8.44,
        "fuelConsumed": 14.18,
        "fuelConsumedperRunHour": 3.86,
        "fuelDrained": 7.36,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 86.94,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "3 hr 40 min 27 sec"
    },
    {
        "_id": "631b41126164e1469f0693d6",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-04-11 23:43:20",
        "city": "mohali",
        "closingFuelLevel": 234.3,
        "date": "2022-09-09T13:35:14.636Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.21,
        "fuelConsumed": -0.12,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 6.09,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 234.18,
        "pincode": "140603",
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e1467f61e875",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-06-21 23:39:53",
        "city": "mohali",
        "closingFuelLevel": 85.82,
        "date": "2022-09-09T13:35:14.638Z",
        "deFuellingAmount": 3.1,
        "fuelAdded": 3.15,
        "fuelConsumed": 3.21,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 9.46,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 92.13,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e1467f61e876",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-06-21 23:47:58",
        "city": "mohali",
        "closingFuelLevel": 74.22,
        "date": "2022-09-09T13:35:14.638Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.1,
        "fuelConsumed": -0.06,
        "fuelConsumedperRunHour": -0.67,
        "fuelDrained": 3.67,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 74.16,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 5 min 21 sec"
    },
    {
        "_id": "631b41126164e1467f61e877",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-06-21 23:36:28",
        "city": "mohali",
        "closingFuelLevel": 44.02,
        "date": "2022-09-09T13:35:14.638Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.79,
        "fuelConsumed": 8.62,
        "fuelConsumedperRunHour": 2.99,
        "fuelDrained": 5.66,
        "fuelLessThanThreshold": 44.02,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 52.64,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "2 hr 53 min 13 sec"
    },
    {
        "_id": "631b41126164e1467f61e878",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-07-16 23:46:43",
        "city": "mohali",
        "closingFuelLevel": 93.05,
        "date": "2022-09-09T13:35:14.673Z",
        "deFuellingAmount": 2.18,
        "fuelAdded": 3.75,
        "fuelConsumed": 7.84,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 13.77,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 103.07,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e1467f61e879",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-07-05 23:33:47",
        "city": "mohali",
        "closingFuelLevel": 76.36,
        "date": "2022-09-09T13:35:15.394Z",
        "deFuellingAmount": 9.82,
        "fuelAdded": 4.12,
        "fuelConsumed": 5.2,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 19.14,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 91.38,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e1467f61e87a",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-07-05 23:59:52",
        "city": "mohali",
        "closingFuelLevel": 77.99,
        "date": "2022-09-09T13:35:15.394Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.88,
        "fuelConsumed": 23.75,
        "fuelConsumedperRunHour": 3.39,
        "fuelDrained": 5.47,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 101.74,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "7 hr 0 min 17 sec"
    },
    {
        "_id": "631b41136164e1467f61e87b",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-05-30 23:48:12",
        "city": "mohali",
        "closingFuelLevel": 65.06,
        "date": "2022-09-09T13:35:14.683Z",
        "deFuellingAmount": 2.32,
        "fuelAdded": 5.25,
        "fuelConsumed": 3.01,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 10.58,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 70.39,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e1467f61e87c",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-07-26 23:47:23",
        "city": "mohali",
        "closingFuelLevel": 78.93,
        "date": "2022-09-09T13:35:15.392Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.6,
        "fuelConsumed": 2.56,
        "fuelConsumedperRunHour": 4.62,
        "fuelDrained": 2.63,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 81.49,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 33 min 13 sec"
    },
    {
        "_id": "631b41136164e1467f61e87d",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-08-09 19:22:08",
        "city": "mohali",
        "closingFuelLevel": 0,
        "date": "2022-09-09T13:35:14.698Z",
        "deFuellingAmount": 68.71,
        "fuelAdded": 32.3,
        "fuelConsumed": 10.66,
        "fuelConsumedperRunHour": 6.82,
        "fuelDrained": 78.43,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 22.44,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 56.93,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 33 min 47 sec"
    },
    {
        "_id": "631b41136164e1467f61e87e",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-08-09 23:45:37",
        "city": "mohali",
        "closingFuelLevel": 102.65,
        "date": "2022-09-09T13:35:14.698Z",
        "deFuellingAmount": 0,
        "fuelAdded": 46.72,
        "fuelConsumed": 1.98,
        "fuelConsumedperRunHour": 5.82,
        "fuelDrained": 6.93,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 39.97,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 64.66,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 20 min 25 sec"
    },
    {
        "_id": "631b41146164e1467f61e87f",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-06-18 23:56:08",
        "city": "mohali",
        "closingFuelLevel": 121.49,
        "date": "2022-09-09T13:35:14.727Z",
        "deFuellingAmount": 0,
        "fuelAdded": 2.79,
        "fuelConsumed": 5.39,
        "fuelConsumedperRunHour": 25.23,
        "fuelDrained": 7.03,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 126.88,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 12 min 49 sec"
    },
    {
        "_id": "631b41126164e146ac337769",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-04-14 23:33:41",
        "city": "mohali",
        "closingFuelLevel": 220.7,
        "date": "2022-09-09T13:35:14.634Z",
        "deFuellingAmount": 0,
        "fuelAdded": 19.22,
        "fuelConsumed": 6.22,
        "fuelConsumedperRunHour": 4.22,
        "fuelDrained": 8.89,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 226.92,
        "pincode": "140603",
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 28 min 23 sec"
    },
    {
        "_id": "631b41126164e127dc24413d",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-04-17 23:34:20",
        "city": "mohali",
        "closingFuelLevel": 114.43,
        "date": "2022-09-09T13:35:14.616Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.62,
        "fuelConsumed": 0.34,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 6.96,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 114.77,
        "pincode": "160103",
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e146ac33776a",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-04-14 23:25:37",
        "city": "mohali",
        "closingFuelLevel": 253.13,
        "date": "2022-09-09T13:35:14.634Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.64,
        "fuelConsumed": 3.93,
        "fuelConsumedperRunHour": 3.46,
        "fuelDrained": 4.5,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 257.06,
        "pincode": "140307",
        "siteId": "",
        "siteName": "",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 8 min 9 sec"
    },
    {
        "_id": "631b41126164e127dc24413e",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-04-17 23:41:10",
        "city": "mohali",
        "closingFuelLevel": 220.42,
        "date": "2022-09-09T13:35:14.616Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.51,
        "fuelConsumed": 19.64,
        "fuelConsumedperRunHour": 5.27,
        "fuelDrained": 3.89,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 240.06,
        "pincode": "140307",
        "siteId": "",
        "siteName": "",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "3 hr 43 min 48 sec"
    },
    {
        "_id": "631b41126164e127dc24413f",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-04-17 23:52:05",
        "city": "mohali",
        "closingFuelLevel": 208.35,
        "date": "2022-09-09T13:35:14.616Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.78,
        "fuelConsumed": 0.1,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 5.88,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 208.45,
        "pincode": "160055",
        "siteId": "",
        "siteName": "",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e146ac33776b",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-05-09 23:41:19",
        "city": "mohali",
        "closingFuelLevel": 59.31,
        "date": "2022-09-09T13:35:14.633Z",
        "deFuellingAmount": 0,
        "fuelAdded": 10.05,
        "fuelConsumed": 4.63,
        "fuelConsumedperRunHour": 3.74,
        "fuelDrained": 6.77,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 63.94,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 14 min 11 sec"
    },
    {
        "_id": "631b41126164e127dc244140",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-04-17 23:44:32",
        "city": "mohali",
        "closingFuelLevel": 218.3,
        "date": "2022-09-09T13:35:14.616Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.04,
        "fuelConsumed": 0.28,
        "fuelConsumedperRunHour": 19.38,
        "fuelDrained": 5.75,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 218.58,
        "pincode": "140603",
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 52 sec"
    },
    {
        "_id": "631b41126164e146ac33776c",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-05-09 23:57:43",
        "city": "mohali",
        "closingFuelLevel": 64.35,
        "date": "2022-09-09T13:35:14.633Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.9,
        "fuelConsumed": 2.01,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 6.91,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 66.36,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e146ac33776d",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-07-14 23:57:05",
        "city": "mohali",
        "closingFuelLevel": 77.27,
        "date": "2022-09-09T13:35:15.301Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.87,
        "fuelConsumed": 13.72,
        "fuelConsumedperRunHour": 5.45,
        "fuelDrained": 3.99,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 90.99,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "2 hr 31 min 11 sec"
    },
    {
        "_id": "631b41136164e146ac33776e",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-07-14 23:23:08",
        "city": "mohali",
        "closingFuelLevel": 132.42,
        "date": "2022-09-09T13:35:15.301Z",
        "deFuellingAmount": 0,
        "fuelAdded": 40.76,
        "fuelConsumed": 11.34,
        "fuelConsumedperRunHour": 4.36,
        "fuelDrained": 6.74,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 35.68,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 108.08,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "2 hr 36 min 14 sec"
    },
    {
        "_id": "631b41136164e146ac33776f",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-07-14 23:41:38",
        "city": "mohali",
        "closingFuelLevel": 63.88,
        "date": "2022-09-09T13:35:15.301Z",
        "deFuellingAmount": 7.75,
        "fuelAdded": 41.42,
        "fuelConsumed": 4.21,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 17.77,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 35.61,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 40.23,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e16bc02c31fb",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-04-21 23:35:25",
        "city": "mohali",
        "closingFuelLevel": 65.8,
        "date": "2022-09-09T13:35:14.615Z",
        "deFuellingAmount": 0,
        "fuelAdded": 25.6,
        "fuelConsumed": 13.01,
        "fuelConsumedperRunHour": 5.37,
        "fuelDrained": 18.04,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 78.81,
        "pincode": "160103",
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "2 hr 25 min 22 sec"
    },
    {
        "_id": "631b41126164e15da67e8629",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-07-19 23:44:11",
        "city": "mohali",
        "closingFuelLevel": 54.17,
        "date": "2022-09-09T13:35:14.640Z",
        "deFuellingAmount": 54.23,
        "fuelAdded": 60.88,
        "fuelConsumed": 3.01,
        "fuelConsumedperRunHour": 3.5,
        "fuelDrained": 59.85,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 54.23,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 57.18,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 51 min 34 sec"
    },
    {
        "_id": "631b41126164e15da67e862a",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-07-19 23:54:21",
        "city": "mohali",
        "closingFuelLevel": 87.35,
        "date": "2022-09-09T13:35:14.640Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.44,
        "fuelConsumed": 0.65,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 7.09,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 88,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e16bc02c31fc",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-04-21 23:47:13",
        "city": "mohali",
        "closingFuelLevel": 207.94,
        "date": "2022-09-09T13:35:14.615Z",
        "deFuellingAmount": 0,
        "fuelAdded": 2.53,
        "fuelConsumed": 0.31,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 2.84,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 208.25,
        "pincode": "160055",
        "siteId": "",
        "siteName": "",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e15da67e862b",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-07-19 23:43:06",
        "city": "mohali",
        "closingFuelLevel": 66.77,
        "date": "2022-09-09T13:35:14.640Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.94,
        "fuelConsumed": -0.1,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.84,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 66.67,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e16bc02c31fd",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-05-04 23:32:19",
        "city": "mohali",
        "closingFuelLevel": 91.32,
        "date": "2022-09-09T13:35:14.671Z",
        "deFuellingAmount": 15.79,
        "fuelAdded": 68.39,
        "fuelConsumed": 4.07,
        "fuelConsumedperRunHour": 3.74,
        "fuelDrained": 20.74,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 58.77,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 52.41,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 5 min 17 sec"
    },
    {
        "_id": "631b41126164e15da67e862c",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-07-19 23:50:40",
        "city": "mohali",
        "closingFuelLevel": 129.6,
        "date": "2022-09-09T13:35:14.640Z",
        "deFuellingAmount": 0,
        "fuelAdded": 41.76,
        "fuelConsumed": 4.9,
        "fuelConsumedperRunHour": 4.31,
        "fuelDrained": 6.49,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 36.35,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 98.15,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 8 min 17 sec"
    },
    {
        "_id": "631b41126164e16bc02c31fe",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-05-04 23:52:58",
        "city": "mohali",
        "closingFuelLevel": 40.52,
        "date": "2022-09-09T13:35:14.671Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.36,
        "fuelConsumed": 11.32,
        "fuelConsumedperRunHour": 4.16,
        "fuelDrained": 4.44,
        "fuelLessThanThreshold": 40.52,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 51.84,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "2 hr 43 min 17 sec"
    },
    {
        "_id": "631b41126164e15da67e862d",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-06-02 23:37:26",
        "city": "mohali",
        "closingFuelLevel": 60.76,
        "date": "2022-09-09T13:35:14.470Z",
        "deFuellingAmount": 0,
        "fuelAdded": 8.13,
        "fuelConsumed": 4.12,
        "fuelConsumedperRunHour": 4.29,
        "fuelDrained": 7.97,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 64.88,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 57 min 39 sec"
    },
    {
        "_id": "631b41126164e15da67e862e",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-06-02 23:38:28",
        "city": "mohali",
        "closingFuelLevel": 60.76,
        "date": "2022-09-09T13:35:14.470Z",
        "deFuellingAmount": 33.09,
        "fuelAdded": 59.3,
        "fuelConsumed": 5.86,
        "fuelConsumedperRunHour": 23.34,
        "fuelDrained": 40.9,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 55.81,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 43.9,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 15 min 4 sec"
    },
    {
        "_id": "631b41136164e16bc02c31ff",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-05-08 23:40:08",
        "city": "mohali",
        "closingFuelLevel": 66.7,
        "date": "2022-09-09T13:35:14.663Z",
        "deFuellingAmount": 0,
        "fuelAdded": 10.63,
        "fuelConsumed": 1.99,
        "fuelConsumedperRunHour": 8.72,
        "fuelDrained": 9.96,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 68.69,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 13 min 42 sec"
    },
    {
        "_id": "631b41126164e15c0e0abb8e",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-06-11 23:46:48",
        "city": "mohali",
        "closingFuelLevel": 74.84,
        "date": "2022-09-09T13:35:14.655Z",
        "deFuellingAmount": 0,
        "fuelAdded": 8.15,
        "fuelConsumed": 0.38,
        "fuelConsumedperRunHour": 7.95,
        "fuelDrained": 8.34,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 75.22,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 2 min 52 sec"
    },
    {
        "_id": "631b41126164e146ad4532a6",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-05-22 23:40:39",
        "city": "mohali",
        "closingFuelLevel": 59.89,
        "date": "2022-09-09T13:35:14.648Z",
        "deFuellingAmount": 0,
        "fuelAdded": 7.26,
        "fuelConsumed": 1.58,
        "fuelConsumedperRunHour": 3.77,
        "fuelDrained": 6.78,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 61.47,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 25 min 7 sec"
    },
    {
        "_id": "631b41126164e146ad4532a7",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-05-22 23:39:47",
        "city": "mohali",
        "closingFuelLevel": 68.81,
        "date": "2022-09-09T13:35:14.648Z",
        "deFuellingAmount": 2.24,
        "fuelAdded": 7.8,
        "fuelConsumed": -0.66,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 9.38,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 70.39,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e15c0e0abb8f",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-06-11 23:46:17",
        "city": "mohali",
        "closingFuelLevel": 99.59,
        "date": "2022-09-09T13:35:14.655Z",
        "deFuellingAmount": 3.64,
        "fuelAdded": 7.45,
        "fuelConsumed": 1.6,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 12.69,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 104.83,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e146ad4532a8",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-06-04 23:43:32",
        "city": "mohali",
        "closingFuelLevel": 78.69,
        "date": "2022-09-09T13:35:14.665Z",
        "deFuellingAmount": 0,
        "fuelAdded": 8.63,
        "fuelConsumed": 0.34,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 8.97,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 79.03,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e15c0e0abb90",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-06-11 19:57:31",
        "city": "mohali",
        "closingFuelLevel": 118.49,
        "date": "2022-09-09T13:35:14.655Z",
        "deFuellingAmount": 0,
        "fuelAdded": 2.36,
        "fuelConsumed": -0.26,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 2.1,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 118.23,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e146ad4532a9",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-06-04 23:58:14",
        "city": "mohali",
        "closingFuelLevel": 49.14,
        "date": "2022-09-09T13:35:14.665Z",
        "deFuellingAmount": 0,
        "fuelAdded": 8.14,
        "fuelConsumed": 6.61,
        "fuelConsumedperRunHour": 2.48,
        "fuelDrained": 6.12,
        "fuelLessThanThreshold": 49.14,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 55.75,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "2 hr 39 min 57 sec"
    },
    {
        "_id": "631b41126164e15c0e0abb91",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-06-01 21:57:18",
        "city": "mohali",
        "closingFuelLevel": 83.51,
        "date": "2022-09-09T13:35:14.688Z",
        "deFuellingAmount": 0,
        "fuelAdded": 131.63,
        "fuelConsumed": 112.44,
        "fuelConsumedperRunHour": 13.81,
        "fuelDrained": 0.87,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 130.3,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 65.65,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "8 hr 8 min 23 sec"
    },
    {
        "_id": "631b41126164e146ad4532aa",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-06-04 23:32:52",
        "city": "mohali",
        "closingFuelLevel": 82.12,
        "date": "2022-09-09T13:35:14.665Z",
        "deFuellingAmount": 0,
        "fuelAdded": 1.25,
        "fuelConsumed": -0.03,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 1.22,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 82.09,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e146ad4532ab",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-07-18 23:57:45",
        "city": "mohali",
        "closingFuelLevel": 98.26,
        "date": "2022-09-09T13:35:15.315Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.75,
        "fuelConsumed": 1.91,
        "fuelConsumedperRunHour": 2.24,
        "fuelDrained": 5.86,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 100.17,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 51 min 6 sec"
    },
    {
        "_id": "631b41136164e146ad4532ac",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-07-18 23:51:48",
        "city": "mohali",
        "closingFuelLevel": 88.24,
        "date": "2022-09-09T13:35:15.315Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.81,
        "fuelConsumed": 1.93,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 7.74,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 90.17,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e1469f0693d7",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-04-11 23:59:57",
        "city": "mohali",
        "closingFuelLevel": 209.01,
        "date": "2022-09-09T13:35:14.636Z",
        "deFuellingAmount": 0,
        "fuelAdded": 7.72,
        "fuelConsumed": 14.36,
        "fuelConsumedperRunHour": 4.19,
        "fuelDrained": 7.64,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 223.37,
        "pincode": "160103",
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "3 hr 25 min 27 sec"
    },
    {
        "_id": "631b41126164e14301439790",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-04-23 23:32:16",
        "city": "mohali",
        "closingFuelLevel": 165,
        "date": "2022-09-09T13:35:14.611Z",
        "deFuellingAmount": 0,
        "fuelAdded": 11.83,
        "fuelConsumed": 7.13,
        "fuelConsumedperRunHour": 3.96,
        "fuelDrained": 7.14,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 172.13,
        "pincode": "140603",
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 47 min 55 sec"
    },
    {
        "_id": "631b41126164e10a67277d64",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-05-09 23:34:08",
        "city": "mohali",
        "closingFuelLevel": 86.67,
        "date": "2022-09-09T13:35:14.633Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.68,
        "fuelConsumed": -0.28,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 5.4,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 86.39,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e1469247cf9d",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-07-09 23:34:05",
        "city": "mohali",
        "closingFuelLevel": 96.52,
        "date": "2022-09-09T13:35:14.651Z",
        "deFuellingAmount": 0,
        "fuelAdded": 56.44,
        "fuelConsumed": 13.44,
        "fuelConsumedperRunHour": 3.88,
        "fuelDrained": 5.6,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 53.52,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 56.44,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "3 hr 27 min 40 sec"
    },
    {
        "_id": "631b41126164e12b805a2396",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-07-16 23:49:14",
        "city": "mohali",
        "closingFuelLevel": 74.53,
        "date": "2022-09-09T13:35:14.673Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.66,
        "fuelConsumed": 7.18,
        "fuelConsumedperRunHour": 3.71,
        "fuelDrained": 6.84,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 81.71,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 56 min 9 sec"
    },
    {
        "_id": "631b41126164e1468140a99f",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-06-28 23:52:12",
        "city": "mohali",
        "closingFuelLevel": 45.69,
        "date": "2022-09-09T13:35:14.641Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.98,
        "fuelConsumed": 21.81,
        "fuelConsumedperRunHour": 4.04,
        "fuelDrained": 4.79,
        "fuelLessThanThreshold": 45.69,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 67.5,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "5 hr 24 min 2 sec"
    },
    {
        "_id": "631b41126164e154f92b215a",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-04-30 23:33:23",
        "city": "mohali",
        "closingFuelLevel": 57.76,
        "date": "2022-09-09T13:35:14.637Z",
        "deFuellingAmount": 0,
        "fuelAdded": 10.13,
        "fuelConsumed": 2.83,
        "fuelConsumedperRunHour": 4.15,
        "fuelDrained": 7.69,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 60.59,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 40 min 57 sec"
    },
    {
        "_id": "631b41126164e143711ab3d3",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-07-24 23:58:00",
        "city": "mohali",
        "closingFuelLevel": 42.42,
        "date": "2022-09-09T13:35:14.633Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.71,
        "fuelConsumed": 6.21,
        "fuelConsumedperRunHour": 2.38,
        "fuelDrained": 5.29,
        "fuelLessThanThreshold": 42.42,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 48.63,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "2 hr 36 min 17 sec"
    },
    {
        "_id": "631b41126164e146961a196b",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-06-01 23:53:15",
        "city": "mohali",
        "closingFuelLevel": 44.13,
        "date": "2022-09-09T13:35:14.688Z",
        "deFuellingAmount": 2.14,
        "fuelAdded": 7.92,
        "fuelConsumed": 3.43,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 13.49,
        "fuelLessThanThreshold": 44.13,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 49.7,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e146ae5acf25",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-04-24 23:47:03",
        "city": "mohali",
        "closingFuelLevel": 73.38,
        "date": "2022-09-09T13:35:14.668Z",
        "deFuellingAmount": 0,
        "fuelAdded": 65.89,
        "fuelConsumed": 12.46,
        "fuelConsumedperRunHour": 4.83,
        "fuelDrained": 10.89,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 39.71,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 46.13,
        "pincode": "160103",
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "2 hr 34 min 48 sec"
    },
    {
        "_id": "631b41126164e11f1c7ba045",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-04-09 23:55:44",
        "city": "mohali",
        "closingFuelLevel": 232.58,
        "date": "2022-09-09T13:35:14.658Z",
        "deFuellingAmount": 0,
        "fuelAdded": 16.41,
        "fuelConsumed": 10.91,
        "fuelConsumedperRunHour": 44.99,
        "fuelDrained": 5.87,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 10.49,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 233,
        "pincode": "160055",
        "siteId": "",
        "siteName": "",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 14 min 33 sec"
    },
    {
        "_id": "631b41126164e143047fea07",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-06-26 23:58:50",
        "city": "mohali",
        "closingFuelLevel": 40.34,
        "date": "2022-09-09T13:35:14.655Z",
        "deFuellingAmount": 2.29,
        "fuelAdded": 52.47,
        "fuelConsumed": 0.73,
        "fuelConsumedperRunHour": 0.56,
        "fuelDrained": 14.27,
        "fuelLessThanThreshold": 40.34,
        "fuellingAmount": 33.97,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 9.39,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 18 min 35 sec"
    },
    {
        "_id": "631b41126164e143766128ae",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-05-01 23:15:15",
        "city": "mohali",
        "closingFuelLevel": 60.71,
        "date": "2022-09-09T13:35:14.657Z",
        "deFuellingAmount": 4.5,
        "fuelAdded": 88.02,
        "fuelConsumed": 24.55,
        "fuelConsumedperRunHour": 103.61,
        "fuelDrained": 78.49,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 26.76,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 63,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 14 min 13 sec"
    },
    {
        "_id": "631b41126164e146b23e9bd7",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-06-04 23:46:54",
        "city": "mohali",
        "closingFuelLevel": 74.29,
        "date": "2022-09-09T13:35:14.665Z",
        "deFuellingAmount": 0,
        "fuelAdded": 9.77,
        "fuelConsumed": 2.05,
        "fuelConsumedperRunHour": 3.46,
        "fuelDrained": 8.28,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 76.34,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 35 min 33 sec"
    },
    {
        "_id": "631b41126164e1436931cf66",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-04-10 23:40:48",
        "city": "mohali",
        "closingFuelLevel": 234.43,
        "date": "2022-09-09T13:35:14.662Z",
        "deFuellingAmount": 0,
        "fuelAdded": 14.13,
        "fuelConsumed": 2.33,
        "fuelConsumedperRunHour": 3.41,
        "fuelDrained": 6.53,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 236.76,
        "pincode": "140603",
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 41 min 2 sec"
    },
    {
        "_id": "631b41126164e143503d1fcc",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-08-06 23:48:00",
        "city": "mohali",
        "closingFuelLevel": 77.01,
        "date": "2022-09-09T13:35:14.639Z",
        "deFuellingAmount": 0,
        "fuelAdded": 7.42,
        "fuelConsumed": 10.4,
        "fuelConsumedperRunHour": 3.94,
        "fuelDrained": 8.16,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 87.41,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "2 hr 38 min 19 sec"
    },
    {
        "_id": "631b41126164e146b556ca51",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-07-12 23:36:33",
        "city": "mohali",
        "closingFuelLevel": 40.87,
        "date": "2022-09-09T13:35:14.659Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.78,
        "fuelConsumed": 4.49,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 9.27,
        "fuelLessThanThreshold": 40.87,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 45.36,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e159623d7b9c",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-08-07 23:34:13",
        "city": "mohali",
        "closingFuelLevel": 71.67,
        "date": "2022-09-09T13:35:14.668Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.2,
        "fuelConsumed": 3.87,
        "fuelConsumedperRunHour": 6.12,
        "fuelDrained": 5.74,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 75.54,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 37 min 56 sec"
    },
    {
        "_id": "631b41126164e142da57cde4",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-07-08 23:36:20",
        "city": "mohali",
        "closingFuelLevel": 58.4,
        "date": "2022-09-09T13:35:14.659Z",
        "deFuellingAmount": 0,
        "fuelAdded": 41.87,
        "fuelConsumed": 15.78,
        "fuelConsumedperRunHour": 5.58,
        "fuelDrained": 7.49,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 35.64,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 38.54,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "2 hr 49 min 39 sec"
    },
    {
        "_id": "631b41126164e1467b52b4ff",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-07-21 23:51:49",
        "city": "mohali",
        "closingFuelLevel": 45.42,
        "date": "2022-09-09T13:35:14.691Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.19,
        "fuelConsumed": 21.09,
        "fuelConsumedperRunHour": 5.01,
        "fuelDrained": 2.92,
        "fuelLessThanThreshold": 45.42,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 66.51,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "4 hr 12 min 28 sec"
    },
    {
        "_id": "631b41126164e127db3d983b",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-05-04 23:28:39",
        "city": "mohali",
        "closingFuelLevel": 59.45,
        "date": "2022-09-09T13:35:14.671Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.83,
        "fuelConsumed": 10.49,
        "fuelConsumedperRunHour": 4.7,
        "fuelDrained": 4.01,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 69.94,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "2 hr 13 min 55 sec"
    },
    {
        "_id": "631b41126164e143047fea08",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-06-26 23:46:32",
        "city": "mohali",
        "closingFuelLevel": 43.29,
        "date": "2022-09-09T13:35:14.655Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.4,
        "fuelConsumed": 6.65,
        "fuelConsumedperRunHour": 4.62,
        "fuelDrained": 6.27,
        "fuelLessThanThreshold": 43.29,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 49.94,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 26 min 18 sec"
    },
    {
        "_id": "631b41126164e14734763282",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-05-24 23:52:40",
        "city": "mohali",
        "closingFuelLevel": 47.64,
        "date": "2022-09-09T13:35:14.631Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.49,
        "fuelConsumed": 0.31,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.8,
        "fuelLessThanThreshold": 47.64,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 47.95,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e14734763283",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-07-16 23:59:14",
        "city": "mohali",
        "closingFuelLevel": 126.67,
        "date": "2022-09-09T13:35:14.673Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.14,
        "fuelConsumed": 5.52,
        "fuelConsumedperRunHour": 3.6,
        "fuelDrained": 4.64,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 132.19,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 32 min 6 sec"
    },
    {
        "_id": "631b41126164e14734763284",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-06-22 23:20:27",
        "city": "mohali",
        "closingFuelLevel": 74.6,
        "date": "2022-09-09T13:35:14.669Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.94,
        "fuelConsumed": -0.69,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.25,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 73.91,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e14734763285",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-06-22 23:57:28",
        "city": "mohali",
        "closingFuelLevel": 79.09,
        "date": "2022-09-09T13:35:14.669Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.24,
        "fuelConsumed": 6.85,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 12.09,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 85.94,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e14734763286",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-06-22 23:38:48",
        "city": "mohali",
        "closingFuelLevel": 37.18,
        "date": "2022-09-09T13:35:14.669Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.77,
        "fuelConsumed": 6.27,
        "fuelConsumedperRunHour": 4.04,
        "fuelDrained": 5.52,
        "fuelLessThanThreshold": 37.18,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 43.45,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 33 min 3 sec"
    },
    {
        "_id": "631b41136164e14734763287",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-06-22 23:37:28",
        "city": "mohali",
        "closingFuelLevel": 111.47,
        "date": "2022-09-09T13:35:14.669Z",
        "deFuellingAmount": 0,
        "fuelAdded": 1.25,
        "fuelConsumed": -0.1,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 1.15,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 111.37,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e14734763288",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-07-07 23:40:06",
        "city": "mohali",
        "closingFuelLevel": 59.89,
        "date": "2022-09-09T13:35:15.326Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.33,
        "fuelConsumed": 6.3,
        "fuelConsumedperRunHour": 2.94,
        "fuelDrained": 4.83,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 66.19,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "2 hr 8 min 46 sec"
    },
    {
        "_id": "631b41136164e14734763289",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-07-07 23:38:52",
        "city": "mohali",
        "closingFuelLevel": 42.85,
        "date": "2022-09-09T13:35:15.326Z",
        "deFuellingAmount": 12.82,
        "fuelAdded": 4.16,
        "fuelConsumed": 5.57,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 22.55,
        "fuelLessThanThreshold": 42.85,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 61.24,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e1473476328a",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-07-07 23:58:49",
        "city": "mohali",
        "closingFuelLevel": 82.28,
        "date": "2022-09-09T13:35:15.326Z",
        "deFuellingAmount": 0,
        "fuelAdded": 2.88,
        "fuelConsumed": -0.22,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 2.66,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 82.06,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e1473476328b",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-07-07 23:38:16",
        "city": "mohali",
        "closingFuelLevel": 38.74,
        "date": "2022-09-09T13:35:15.326Z",
        "deFuellingAmount": 0,
        "fuelAdded": 10.17,
        "fuelConsumed": 1.16,
        "fuelConsumedperRunHour": 2.42,
        "fuelDrained": 9.1,
        "fuelLessThanThreshold": 38.74,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 39.9,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 28 min 45 sec"
    },
    {
        "_id": "631b41136164e1473476328c",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-09-07 23:49:17",
        "city": "mohali",
        "closingFuelLevel": 101.03,
        "date": "2022-09-09T13:35:15.395Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.95,
        "fuelConsumed": -0.32,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.63,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 100.71,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e1468140a9a0",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-06-28 23:34:39",
        "city": "mohali",
        "closingFuelLevel": 83.05,
        "date": "2022-09-09T13:35:14.641Z",
        "deFuellingAmount": 0,
        "fuelAdded": 2.47,
        "fuelConsumed": 14.66,
        "fuelConsumedperRunHour": 635.86,
        "fuelDrained": 15.22,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 97.71,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 1 min 23 sec"
    },
    {
        "_id": "631b41126164e1468140a9a1",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-06-28 23:53:43",
        "city": "mohali",
        "closingFuelLevel": 73.75,
        "date": "2022-09-09T13:35:14.641Z",
        "deFuellingAmount": 0,
        "fuelAdded": 7.52,
        "fuelConsumed": 5.36,
        "fuelConsumedperRunHour": 10.63,
        "fuelDrained": 7.35,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 79.11,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 30 min 16 sec"
    },
    {
        "_id": "631b41126164e1468140a9a2",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-06-06 23:48:37",
        "city": "mohali",
        "closingFuelLevel": 71.36,
        "date": "2022-09-09T13:35:14.666Z",
        "deFuellingAmount": 2.18,
        "fuelAdded": 6.77,
        "fuelConsumed": 3.45,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 12.4,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 76.99,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e1468140a9a3",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-08-23 23:42:10",
        "city": "mohali",
        "closingFuelLevel": 86.29,
        "date": "2022-09-09T13:35:14.690Z",
        "deFuellingAmount": 0,
        "fuelAdded": 2.83,
        "fuelConsumed": 2.42,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 5.25,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 88.71,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e1468140a9a4",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-05-12 23:52:22",
        "city": "mohali",
        "closingFuelLevel": 107.97,
        "date": "2022-09-09T13:35:15.327Z",
        "deFuellingAmount": 0,
        "fuelAdded": 7.37,
        "fuelConsumed": 8.03,
        "fuelConsumedperRunHour": 4.15,
        "fuelDrained": 6.93,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 116,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 56 min 1 sec"
    },
    {
        "_id": "631b41136164e1468140a9a5",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-05-12 23:44:33",
        "city": "mohali",
        "closingFuelLevel": 40.68,
        "date": "2022-09-09T13:35:15.327Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.23,
        "fuelConsumed": 11.04,
        "fuelConsumedperRunHour": 4.4,
        "fuelDrained": 7.09,
        "fuelLessThanThreshold": 40.68,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 51.72,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "2 hr 30 min 28 sec"
    },
    {
        "_id": "631b41136164e1468140a9a6",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-05-12 23:56:36",
        "city": "mohali",
        "closingFuelLevel": 45.3,
        "date": "2022-09-09T13:35:15.327Z",
        "deFuellingAmount": 2.73,
        "fuelAdded": 7.37,
        "fuelConsumed": 0.07,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 10.17,
        "fuelLessThanThreshold": 45.3,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 48.1,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e1468140a9a7",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-05-18 23:36:24",
        "city": "mohali",
        "closingFuelLevel": 65.43,
        "date": "2022-09-09T13:35:14.414Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.24,
        "fuelConsumed": 0.05,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 6.29,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 65.48,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e1468140a9a8",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-05-18 23:33:21",
        "city": "mohali",
        "closingFuelLevel": 72.98,
        "date": "2022-09-09T13:35:14.414Z",
        "deFuellingAmount": 0,
        "fuelAdded": 7.52,
        "fuelConsumed": 1.37,
        "fuelConsumedperRunHour": 3.44,
        "fuelDrained": 6.78,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 74.35,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 23 min 54 sec"
    },
    {
        "_id": "631b41136164e1468140a9a9",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-05-18 23:58:49",
        "city": "mohali",
        "closingFuelLevel": 81.35,
        "date": "2022-09-09T13:35:14.414Z",
        "deFuellingAmount": 0,
        "fuelAdded": 7.5,
        "fuelConsumed": 11.09,
        "fuelConsumedperRunHour": 3.8,
        "fuelDrained": 7.96,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 92.44,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "2 hr 55 min 1 sec"
    },
    {
        "_id": "631b41136164e1468140a9aa",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-05-29 23:51:28",
        "city": "mohali",
        "closingFuelLevel": 48.45,
        "date": "2022-09-09T13:35:14.745Z",
        "deFuellingAmount": 0,
        "fuelAdded": 8.19,
        "fuelConsumed": 0.43,
        "fuelConsumedperRunHour": 1.96,
        "fuelDrained": 7.5,
        "fuelLessThanThreshold": 48.45,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 48.88,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 13 min 8 sec"
    },
    {
        "_id": "631b41126164e10a67277d65",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-05-09 23:36:27",
        "city": "mohali",
        "closingFuelLevel": 57.67,
        "date": "2022-09-09T13:35:14.633Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.4,
        "fuelConsumed": 0.92,
        "fuelConsumedperRunHour": 3.4,
        "fuelDrained": 4.72,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 58.59,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 16 min 13 sec"
    },
    {
        "_id": "631b41126164e10a67277d66",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-07-24 23:53:33",
        "city": "mohali",
        "closingFuelLevel": 108.2,
        "date": "2022-09-09T13:35:14.633Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.57,
        "fuelConsumed": 4.66,
        "fuelConsumedperRunHour": 2.01,
        "fuelDrained": 4.3,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 112.86,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "2 hr 19 min 4 sec"
    },
    {
        "_id": "631b41126164e10a67277d67",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-07-24 23:57:34",
        "city": "mohali",
        "closingFuelLevel": 44.78,
        "date": "2022-09-09T13:35:14.633Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.28,
        "fuelConsumed": 0.59,
        "fuelConsumedperRunHour": 2.97,
        "fuelDrained": 4.12,
        "fuelLessThanThreshold": 44.78,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 45.37,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 11 min 54 sec"
    },
    {
        "_id": "631b41126164e10a67277d68",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-06-06 23:52:02",
        "city": "mohali",
        "closingFuelLevel": 74.47,
        "date": "2022-09-09T13:35:14.666Z",
        "deFuellingAmount": 0,
        "fuelAdded": 7.76,
        "fuelConsumed": -0.12,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 7.64,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 74.35,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e10a67277d69",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-06-06 23:57:23",
        "city": "mohali",
        "closingFuelLevel": 68.64,
        "date": "2022-09-09T13:35:14.666Z",
        "deFuellingAmount": 0,
        "fuelAdded": 45.41,
        "fuelConsumed": 4.52,
        "fuelConsumedperRunHour": 3.91,
        "fuelDrained": 9.13,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 36.21,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 36.95,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 9 min 22 sec"
    },
    {
        "_id": "631b41136164e10a67277d6a",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-06-06 23:08:16",
        "city": "mohali",
        "closingFuelLevel": 82.06,
        "date": "2022-09-09T13:35:14.666Z",
        "deFuellingAmount": 0,
        "fuelAdded": 1.93,
        "fuelConsumed": -0.22,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 1.71,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 81.84,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e10a67277d6b",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-05-21 23:37:14",
        "city": "mohali",
        "closingFuelLevel": 70.32,
        "date": "2022-09-09T13:35:14.686Z",
        "deFuellingAmount": 2.53,
        "fuelAdded": 26.47,
        "fuelConsumed": 1.19,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 10.15,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 20.04,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 54,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e10a67277d6c",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-05-21 23:56:23",
        "city": "mohali",
        "closingFuelLevel": 78.32,
        "date": "2022-09-09T13:35:14.686Z",
        "deFuellingAmount": 0,
        "fuelAdded": 28.27,
        "fuelConsumed": 3.09,
        "fuelConsumedperRunHour": 10.52,
        "fuelDrained": 8.73,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 20.92,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 60.49,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 17 min 37 sec"
    },
    {
        "_id": "631b41136164e10a67277d6d",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-05-21 23:44:00",
        "city": "mohali",
        "closingFuelLevel": 65.22,
        "date": "2022-09-09T13:35:14.686Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.99,
        "fuelConsumed": 0.64,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 6.63,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 65.86,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e10a67277d6e",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-05-21 23:45:34",
        "city": "mohali",
        "closingFuelLevel": 61.65,
        "date": "2022-09-09T13:35:14.686Z",
        "deFuellingAmount": 0,
        "fuelAdded": 7.37,
        "fuelConsumed": 5.74,
        "fuelConsumedperRunHour": 4.72,
        "fuelDrained": 7.84,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 67.39,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 12 min 57 sec"
    },
    {
        "_id": "631b41136164e10a67277d6f",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-08-13 23:48:43",
        "city": "mohali",
        "closingFuelLevel": 60.12,
        "date": "2022-09-09T13:35:15.288Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.94,
        "fuelConsumed": 4.47,
        "fuelConsumedperRunHour": 1.01,
        "fuelDrained": 4.76,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 64.59,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "4 hr 25 min 14 sec"
    },
    {
        "_id": "631b41126164e146961a196c",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-06-01 23:55:02",
        "city": "mohali",
        "closingFuelLevel": 78.01,
        "date": "2022-09-09T13:35:14.688Z",
        "deFuellingAmount": 0,
        "fuelAdded": 81.37,
        "fuelConsumed": 41.96,
        "fuelConsumedperRunHour": 4.43,
        "fuelDrained": 6.16,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 74.07,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 45.9,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "9 hr 27 min 53 sec"
    },
    {
        "_id": "631b41126164e146961a196d",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-06-01 23:58:32",
        "city": "mohali",
        "closingFuelLevel": 65.23,
        "date": "2022-09-09T13:35:14.688Z",
        "deFuellingAmount": 0,
        "fuelAdded": 7.82,
        "fuelConsumed": 1.7,
        "fuelConsumedperRunHour": 3.54,
        "fuelDrained": 7.53,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 66.93,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 28 min 48 sec"
    },
    {
        "_id": "631b41126164e146ae5acf26",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-04-24 23:34:48",
        "city": "mohali",
        "closingFuelLevel": 164.61,
        "date": "2022-09-09T13:35:14.668Z",
        "deFuellingAmount": 0,
        "fuelAdded": 8.15,
        "fuelConsumed": 0.45,
        "fuelConsumedperRunHour": 3.55,
        "fuelDrained": 6.25,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 165.06,
        "pincode": "140603",
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 7 min 36 sec"
    },
    {
        "_id": "631b41126164e146ae5acf27",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-04-24 23:31:11",
        "city": "mohali",
        "closingFuelLevel": 150.89,
        "date": "2022-09-09T13:35:14.668Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5,
        "fuelConsumed": 0.71,
        "fuelConsumedperRunHour": 3.31,
        "fuelDrained": 4.29,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 151.6,
        "pincode": "140307",
        "siteId": "",
        "siteName": "",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 12 min 52 sec"
    },
    {
        "_id": "631b41126164e146ae5acf28",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-05-20 23:34:43",
        "city": "mohali",
        "closingFuelLevel": 54.29,
        "date": "2022-09-09T13:35:14.733Z",
        "deFuellingAmount": 2.19,
        "fuelAdded": 7.52,
        "fuelConsumed": 2.72,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 12.43,
        "fuelLessThanThreshold": 54.29,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 59.2,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e146ae5acf29",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-05-20 23:54:48",
        "city": "mohali",
        "closingFuelLevel": 60.49,
        "date": "2022-09-09T13:35:14.733Z",
        "deFuellingAmount": 0,
        "fuelAdded": 10.09,
        "fuelConsumed": 10.75,
        "fuelConsumedperRunHour": 4.06,
        "fuelDrained": 7.54,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 71.24,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "2 hr 38 min 45 sec"
    },
    {
        "_id": "631b41126164e146ae5acf2a",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-05-20 23:59:35",
        "city": "mohali",
        "closingFuelLevel": 67.27,
        "date": "2022-09-09T13:35:14.733Z",
        "deFuellingAmount": 0,
        "fuelAdded": 8.34,
        "fuelConsumed": 12.79,
        "fuelConsumedperRunHour": 3.97,
        "fuelDrained": 7.71,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 80.06,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "3 hr 13 min 6 sec"
    },
    {
        "_id": "631b41136164e146ae5acf2b",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-05-20 23:41:29",
        "city": "mohali",
        "closingFuelLevel": 65.65,
        "date": "2022-09-09T13:35:14.733Z",
        "deFuellingAmount": 0,
        "fuelAdded": 7.03,
        "fuelConsumed": -0.17,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 6.86,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 65.48,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e146ae5acf2c",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-07-31 23:28:48",
        "city": "mohali",
        "closingFuelLevel": 70.19,
        "date": "2022-09-09T13:35:14.706Z",
        "deFuellingAmount": 2.67,
        "fuelAdded": 1.75,
        "fuelConsumed": 1.49,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 5.91,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 74.35,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e146ae5acf2d",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-08-02 23:57:40",
        "city": "mohali",
        "closingFuelLevel": 62.24,
        "date": "2022-09-09T13:35:15.325Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.07,
        "fuelConsumed": 3.73,
        "fuelConsumedperRunHour": 4.94,
        "fuelDrained": 5.22,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 65.97,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 45 min 18 sec"
    },
    {
        "_id": "631b41136164e146ae5acf2e",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-08-02 21:18:42",
        "city": "mohali",
        "closingFuelLevel": 93.6,
        "date": "2022-09-09T13:35:15.325Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.58,
        "fuelConsumed": 3.59,
        "fuelConsumedperRunHour": 4.58,
        "fuelDrained": 4.97,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 97.19,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 47 min 1 sec"
    },
    {
        "_id": "631b41136164e146ae5acf2f",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-08-02 23:59:49",
        "city": "mohali",
        "closingFuelLevel": 61.47,
        "date": "2022-09-09T13:35:15.325Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.24,
        "fuelConsumed": -0.18,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.06,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 61.29,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e146ae5acf30",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-08-02 23:18:51",
        "city": "mohali",
        "closingFuelLevel": 61.29,
        "date": "2022-09-09T13:35:15.325Z",
        "deFuellingAmount": 0,
        "fuelAdded": 2.53,
        "fuelConsumed": 1.95,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.48,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 63.24,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e12b805a2397",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-07-16 23:41:48",
        "city": "mohali",
        "closingFuelLevel": 72.71,
        "date": "2022-09-09T13:35:14.673Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.82,
        "fuelConsumed": -0.05,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.77,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 72.66,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e12b805a2398",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-08-23 23:48:03",
        "city": "mohali",
        "closingFuelLevel": 52.13,
        "date": "2022-09-09T13:35:14.690Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.72,
        "fuelConsumed": 0.23,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.95,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 52.36,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e12b805a2399",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-04-23 10:06:39",
        "city": "mohali",
        "closingFuelLevel": 188.75,
        "date": "2022-09-09T13:35:14.611Z",
        "deFuellingAmount": 0,
        "fuelAdded": 0,
        "fuelConsumed": 0.31,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 0.31,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 189.06,
        "pincode": "160055",
        "siteId": "",
        "siteName": "",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e12b805a239a",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-08-31 23:47:03",
        "city": "mohali",
        "closingFuelLevel": 46.26,
        "date": "2022-09-09T13:35:15.305Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.39,
        "fuelConsumed": 6.9,
        "fuelConsumedperRunHour": 3.97,
        "fuelDrained": 6.32,
        "fuelLessThanThreshold": 46.26,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 53.16,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 44 min 22 sec"
    },
    {
        "_id": "631b41136164e12b805a239b",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-08-31 23:46:27",
        "city": "mohali",
        "closingFuelLevel": 100.71,
        "date": "2022-09-09T13:35:15.305Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.85,
        "fuelConsumed": 0.13,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.98,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 100.84,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e12b805a239c",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-08-31 23:17:24",
        "city": "mohali",
        "closingFuelLevel": 65.51,
        "date": "2022-09-09T13:35:15.305Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.12,
        "fuelConsumed": 0.23,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.35,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 65.74,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e14301439791",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-04-23 23:59:57",
        "city": "mohali",
        "closingFuelLevel": 46.19,
        "date": "2022-09-09T13:35:14.611Z",
        "deFuellingAmount": 0,
        "fuelAdded": 20.32,
        "fuelConsumed": 14.87,
        "fuelConsumedperRunHour": 3.92,
        "fuelDrained": 8.97,
        "fuelLessThanThreshold": 46.19,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 61.06,
        "pincode": "160103",
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "3 hr 47 min 35 sec"
    },
    {
        "_id": "631b41126164e14301439792",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-04-16 23:33:30",
        "city": "mohali",
        "closingFuelLevel": 239.94,
        "date": "2022-09-09T13:35:14.671Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.44,
        "fuelConsumed": 12.69,
        "fuelConsumedperRunHour": 4.66,
        "fuelDrained": 5.63,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 252.63,
        "pincode": "140307",
        "siteId": "",
        "siteName": "",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "2 hr 43 min 28 sec"
    },
    {
        "_id": "631b41126164e14301439793",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-04-16 23:49:33",
        "city": "mohali",
        "closingFuelLevel": 208.4,
        "date": "2022-09-09T13:35:14.671Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.8,
        "fuelConsumed": 24.92,
        "fuelConsumedperRunHour": 5.71,
        "fuelDrained": 4.28,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 233.32,
        "pincode": "160055",
        "siteId": "",
        "siteName": "",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "4 hr 21 min 41 sec"
    },
    {
        "_id": "631b41136164e14301439794",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-07-30 23:58:25",
        "city": "mohali",
        "closingFuelLevel": 107.27,
        "date": "2022-09-09T13:35:14.674Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.61,
        "fuelConsumed": 5.59,
        "fuelConsumedperRunHour": 3.67,
        "fuelDrained": 6.94,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 112.86,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 31 min 20 sec"
    },
    {
        "_id": "631b41136164e14301439795",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-07-30 23:26:16",
        "city": "mohali",
        "closingFuelLevel": 74.16,
        "date": "2022-09-09T13:35:14.674Z",
        "deFuellingAmount": 0,
        "fuelAdded": 2.88,
        "fuelConsumed": 0.71,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.59,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 74.87,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e14301439796",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-07-30 23:50:45",
        "city": "mohali",
        "closingFuelLevel": 55,
        "date": "2022-09-09T13:35:14.674Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.43,
        "fuelConsumed": 23.39,
        "fuelConsumedperRunHour": 5.77,
        "fuelDrained": 6.35,
        "fuelLessThanThreshold": 55,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 78.39,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "4 hr 3 min 14 sec"
    },
    {
        "_id": "631b41136164e14301439797",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-07-30 23:37:26",
        "city": "mohali",
        "closingFuelLevel": 51.84,
        "date": "2022-09-09T13:35:14.674Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.22,
        "fuelConsumed": 20.58,
        "fuelConsumedperRunHour": 5.47,
        "fuelDrained": 4.58,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 72.42,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "3 hr 45 min 35 sec"
    },
    {
        "_id": "631b41136164e14301439798",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-05-07 23:44:05",
        "city": "mohali",
        "closingFuelLevel": 86.5,
        "date": "2022-09-09T13:35:15.254Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.73,
        "fuelConsumed": -0.39,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 6.34,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 86.11,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e14301439799",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-05-07 23:32:29",
        "city": "mohali",
        "closingFuelLevel": 69.03,
        "date": "2022-09-09T13:35:15.254Z",
        "deFuellingAmount": 7.57,
        "fuelAdded": 5.94,
        "fuelConsumed": 2.26,
        "fuelConsumedperRunHour": 42.6,
        "fuelDrained": 15.03,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 78.86,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 3 min 11 sec"
    },
    {
        "_id": "631b41136164e1430143979a",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-05-07 23:35:08",
        "city": "mohali",
        "closingFuelLevel": 64.94,
        "date": "2022-09-09T13:35:15.254Z",
        "deFuellingAmount": 0,
        "fuelAdded": 11.74,
        "fuelConsumed": 16.63,
        "fuelConsumedperRunHour": 4.07,
        "fuelDrained": 7.42,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 81.57,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "4 hr 5 min 17 sec"
    },
    {
        "_id": "631b41136164e1430143979b",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-05-07 23:24:38",
        "city": "mohali",
        "closingFuelLevel": 58.28,
        "date": "2022-09-09T13:35:15.254Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.4,
        "fuelConsumed": 0.19,
        "fuelConsumedperRunHour": 20.73,
        "fuelDrained": 4.53,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 58.47,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 33 sec"
    },
    {
        "_id": "631b41126164e143711ab3d4",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-07-24 23:52:01",
        "city": "mohali",
        "closingFuelLevel": 72.99,
        "date": "2022-09-09T13:35:14.633Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.47,
        "fuelConsumed": 5.19,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 10.66,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 78.18,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e143711ab3d5",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-05-24 23:44:51",
        "city": "mohali",
        "closingFuelLevel": 63.18,
        "date": "2022-09-09T13:35:14.631Z",
        "deFuellingAmount": 16.14,
        "fuelAdded": 4.46,
        "fuelConsumed": 1.71,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 22.31,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 81.03,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e143711ab3d6",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-07-31 23:50:48",
        "city": "mohali",
        "closingFuelLevel": 65.81,
        "date": "2022-09-09T13:35:14.706Z",
        "deFuellingAmount": 0,
        "fuelAdded": 36.43,
        "fuelConsumed": 22.7,
        "fuelConsumedperRunHour": 4.48,
        "fuelDrained": 3.82,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 33.2,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 55.31,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "5 hr 3 min 50 sec"
    },
    {
        "_id": "631b41126164e143711ab3d7",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-07-31 23:40:28",
        "city": "mohali",
        "closingFuelLevel": 61.35,
        "date": "2022-09-09T13:35:14.706Z",
        "deFuellingAmount": 0,
        "fuelAdded": 23.28,
        "fuelConsumed": 9.83,
        "fuelConsumedperRunHour": 5.48,
        "fuelDrained": 5.3,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 19.03,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 52.15,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 47 min 42 sec"
    },
    {
        "_id": "631b41136164e143711ab3d8",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-07-31 23:42:12",
        "city": "mohali",
        "closingFuelLevel": 106.74,
        "date": "2022-09-09T13:35:14.706Z",
        "deFuellingAmount": 0,
        "fuelAdded": 2.73,
        "fuelConsumed": 0.47,
        "fuelConsumedperRunHour": 2.19,
        "fuelDrained": 2.27,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 107.21,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 12 min 51 sec"
    },
    {
        "_id": "631b41136164e143711ab3d9",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-06-23 23:35:20",
        "city": "mohali",
        "closingFuelLevel": 101.74,
        "date": "2022-09-09T13:35:14.655Z",
        "deFuellingAmount": 0,
        "fuelAdded": 8.64,
        "fuelConsumed": 9.42,
        "fuelConsumedperRunHour": 6.31,
        "fuelDrained": 9.59,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 111.16,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 29 min 32 sec"
    },
    {
        "_id": "631b41136164e143711ab3da",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-06-17 23:35:47",
        "city": "mohali",
        "closingFuelLevel": 84.21,
        "date": "2022-09-09T13:35:15.337Z",
        "deFuellingAmount": 0,
        "fuelAdded": 107.57,
        "fuelConsumed": 54.35,
        "fuelConsumedperRunHour": 3.95,
        "fuelDrained": 4.42,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 102.58,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 35.98,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "13 hr 45 min 29 sec"
    },
    {
        "_id": "631b41136164e143711ab3db",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-08-22 23:53:29",
        "city": "mohali",
        "closingFuelLevel": 100.58,
        "date": "2022-09-09T13:35:15.378Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.88,
        "fuelConsumed": -0.19,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.69,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 100.39,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143711ab3dc",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-08-22 23:45:30",
        "city": "mohali",
        "closingFuelLevel": 52.41,
        "date": "2022-09-09T13:35:15.378Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.51,
        "fuelConsumed": 1.5,
        "fuelConsumedperRunHour": 4.38,
        "fuelDrained": 5.69,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 53.91,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 20 min 33 sec"
    },
    {
        "_id": "631b41136164e143711ab3dd",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-08-22 23:39:38",
        "city": "mohali",
        "closingFuelLevel": 88.88,
        "date": "2022-09-09T13:35:15.378Z",
        "deFuellingAmount": 2.19,
        "fuelAdded": 2.71,
        "fuelConsumed": 0.48,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 5.38,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 91.55,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143711ab3de",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-05-31 23:35:41",
        "city": "mohali",
        "closingFuelLevel": 49.11,
        "date": "2022-09-09T13:35:14.685Z",
        "deFuellingAmount": 14.56,
        "fuelAdded": 5.29,
        "fuelConsumed": 1.61,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 21.46,
        "fuelLessThanThreshold": 49.11,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 65.28,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e1469f0693d8",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-04-16 23:31:48",
        "city": "mohali",
        "closingFuelLevel": 115.12,
        "date": "2022-09-09T13:35:14.671Z",
        "deFuellingAmount": 0,
        "fuelAdded": 9.96,
        "fuelConsumed": 26.76,
        "fuelConsumedperRunHour": 4.55,
        "fuelDrained": 8,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 141.88,
        "pincode": "160103",
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "5 hr 52 min 58 sec"
    },
    {
        "_id": "631b41126164e143613895af",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-04-11 23:45:48",
        "city": "mohali",
        "closingFuelLevel": 232.74,
        "date": "2022-09-09T13:35:14.636Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.88,
        "fuelConsumed": -0.06,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.82,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 232.68,
        "pincode": "160055",
        "siteId": "",
        "siteName": "",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e1469f0693d9",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-04-23 23:22:22",
        "city": "mohali",
        "closingFuelLevel": 154.56,
        "date": "2022-09-09T13:35:14.611Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.04,
        "fuelConsumed": 17.69,
        "fuelConsumedperRunHour": 4.79,
        "fuelDrained": 4.29,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 172.25,
        "pincode": "140307",
        "siteId": "",
        "siteName": "",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "3 hr 41 min 31 sec"
    },
    {
        "_id": "631b41126164e143613895b0",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-07-11 23:49:02",
        "city": "mohali",
        "closingFuelLevel": 45.42,
        "date": "2022-09-09T13:35:14.644Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.09,
        "fuelConsumed": 3.51,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 7.6,
        "fuelLessThanThreshold": 45.42,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 48.93,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e1469f0693da",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-08-23 23:56:03",
        "city": "mohali",
        "closingFuelLevel": 100.45,
        "date": "2022-09-09T13:35:14.690Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.95,
        "fuelConsumed": 0.13,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.08,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 100.58,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e143613895b1",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-06-09 23:41:12",
        "city": "mohali",
        "closingFuelLevel": 108.33,
        "date": "2022-09-09T13:35:14.649Z",
        "deFuellingAmount": 0,
        "fuelAdded": 46.92,
        "fuelConsumed": 1.59,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 7.34,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 41.17,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 68.75,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e143613895b2",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-06-09 23:38:55",
        "city": "mohali",
        "closingFuelLevel": 118.28,
        "date": "2022-09-09T13:35:14.649Z",
        "deFuellingAmount": 0,
        "fuelAdded": 43.35,
        "fuelConsumed": 3.51,
        "fuelConsumedperRunHour": 5.57,
        "fuelDrained": 3.74,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 39.7,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 82.09,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 37 min 47 sec"
    },
    {
        "_id": "631b41136164e143613895b3",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-06-10 23:48:51",
        "city": "mohali",
        "closingFuelLevel": 75.4,
        "date": "2022-09-09T13:35:14.668Z",
        "deFuellingAmount": 0,
        "fuelAdded": 8.91,
        "fuelConsumed": 10.03,
        "fuelConsumedperRunHour": 5.15,
        "fuelDrained": 9.92,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 85.43,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 56 min 57 sec"
    },
    {
        "_id": "631b41136164e143613895b4",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-07-02 23:48:34",
        "city": "mohali",
        "closingFuelLevel": 88.06,
        "date": "2022-09-09T13:35:14.647Z",
        "deFuellingAmount": 0,
        "fuelAdded": 21.27,
        "fuelConsumed": 4.05,
        "fuelConsumedperRunHour": 5.64,
        "fuelDrained": 3.27,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 18.41,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 73.7,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 43 min 7 sec"
    },
    {
        "_id": "631b41136164e143613895b5",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-06-02 14:20:16",
        "city": "mohali",
        "closingFuelLevel": 82.78,
        "date": "2022-09-09T13:35:14.470Z",
        "deFuellingAmount": 0,
        "fuelAdded": 38.73,
        "fuelConsumed": 23.13,
        "fuelConsumedperRunHour": 4.29,
        "fuelDrained": 0,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 37.85,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 68.06,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "5 hr 23 min 52 sec"
    },
    {
        "_id": "631b41136164e143613895b6",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-06-20 23:37:22",
        "city": "mohali",
        "closingFuelLevel": 92.3,
        "date": "2022-09-09T13:35:15.306Z",
        "deFuellingAmount": 7.64,
        "fuelAdded": 4.04,
        "fuelConsumed": 6.07,
        "fuelConsumedperRunHour": 24.12,
        "fuelDrained": 16.99,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 106.01,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 15 min 6 sec"
    },
    {
        "_id": "631b41126164e1469247cf9e",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-07-09 23:35:32",
        "city": "mohali",
        "closingFuelLevel": 52.45,
        "date": "2022-09-09T13:35:14.651Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.38,
        "fuelConsumed": 5.83,
        "fuelConsumedperRunHour": 3.81,
        "fuelDrained": 5.15,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 58.28,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 31 min 51 sec"
    },
    {
        "_id": "631b41126164e1469247cf9f",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-07-21 23:31:52",
        "city": "mohali",
        "closingFuelLevel": 114.61,
        "date": "2022-09-09T13:35:14.691Z",
        "deFuellingAmount": 0,
        "fuelAdded": 2.96,
        "fuelConsumed": 10.16,
        "fuelConsumedperRunHour": 3.9,
        "fuelDrained": 4.09,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 124.77,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "2 hr 36 min 15 sec"
    },
    {
        "_id": "631b41126164e1469247cfa0",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-07-13 23:48:10",
        "city": "mohali",
        "closingFuelLevel": 108.02,
        "date": "2022-09-09T13:35:14.681Z",
        "deFuellingAmount": 0,
        "fuelAdded": 42.37,
        "fuelConsumed": 19.53,
        "fuelConsumedperRunHour": 3.98,
        "fuelDrained": 7.99,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 36.39,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 91.16,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "4 hr 54 min 42 sec"
    },
    {
        "_id": "631b41126164e1469247cfa1",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-06-23 23:58:37",
        "city": "mohali",
        "closingFuelLevel": 27.57,
        "date": "2022-09-09T13:35:14.655Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.25,
        "fuelConsumed": 9.61,
        "fuelConsumedperRunHour": 2.02,
        "fuelDrained": 4.88,
        "fuelLessThanThreshold": 27.57,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 37.18,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "4 hr 46 min 8 sec"
    },
    {
        "_id": "631b41136164e1469247cfa2",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-07-13 23:45:16",
        "city": "mohali",
        "closingFuelLevel": 91.06,
        "date": "2022-09-09T13:35:14.681Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.98,
        "fuelConsumed": -0.13,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.85,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 90.93,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e1469247cfa3",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-06-10 23:43:46",
        "city": "mohali",
        "closingFuelLevel": 104.83,
        "date": "2022-09-09T13:35:14.668Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.83,
        "fuelConsumed": 3.68,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 10.51,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 108.51,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e1469247cfa4",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-07-10 23:51:07",
        "city": "mohali",
        "closingFuelLevel": 95.67,
        "date": "2022-09-09T13:35:15.313Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.5,
        "fuelConsumed": 0.79,
        "fuelConsumedperRunHour": 2.44,
        "fuelDrained": 3.72,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 96.46,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 19 min 27 sec"
    },
    {
        "_id": "631b41136164e1469247cfa5",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-07-10 23:52:37",
        "city": "mohali",
        "closingFuelLevel": 50.31,
        "date": "2022-09-09T13:35:15.313Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.69,
        "fuelConsumed": 1.96,
        "fuelConsumedperRunHour": 3.76,
        "fuelDrained": 4.5,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 52.27,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 31 min 15 sec"
    },
    {
        "_id": "631b41136164e1469247cfa6",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-07-10 23:46:31",
        "city": "mohali",
        "closingFuelLevel": 48.93,
        "date": "2022-09-09T13:35:15.313Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.97,
        "fuelConsumed": 6.41,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 10.38,
        "fuelLessThanThreshold": 48.93,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 55.34,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e1469247cfa7",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-08-03 23:33:01",
        "city": "mohali",
        "closingFuelLevel": 58.83,
        "date": "2022-09-09T13:35:15.322Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.38,
        "fuelConsumed": 2.58,
        "fuelConsumedperRunHour": 4.44,
        "fuelDrained": 5.19,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 61.41,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 34 min 54 sec"
    },
    {
        "_id": "631b41136164e1469247cfa8",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-08-03 23:21:23",
        "city": "mohali",
        "closingFuelLevel": 55.17,
        "date": "2022-09-09T13:35:15.322Z",
        "deFuellingAmount": 4.62,
        "fuelAdded": 2.58,
        "fuelConsumed": 1.15,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 8.35,
        "fuelLessThanThreshold": 55.17,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 60.94,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e146b556ca52",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-07-12 23:50:19",
        "city": "mohali",
        "closingFuelLevel": 91.28,
        "date": "2022-09-09T13:35:14.659Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.1,
        "fuelConsumed": 3.37,
        "fuelConsumedperRunHour": 4.39,
        "fuelDrained": 4.69,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 94.65,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 46 min 1 sec"
    },
    {
        "_id": "631b41126164e146b556ca53",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-07-12 23:57:44",
        "city": "mohali",
        "closingFuelLevel": 90.93,
        "date": "2022-09-09T13:35:14.659Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.67,
        "fuelConsumed": -0.25,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.42,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 90.68,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e146b556ca54",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-05-23 23:49:05",
        "city": "mohali",
        "closingFuelLevel": 64.84,
        "date": "2022-09-09T13:35:14.718Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.24,
        "fuelConsumed": 0.59,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 5.83,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 65.43,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e146b556ca55",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-05-23 23:42:18",
        "city": "mohali",
        "closingFuelLevel": 83.28,
        "date": "2022-09-09T13:35:14.718Z",
        "deFuellingAmount": 19.15,
        "fuelAdded": 44.37,
        "fuelConsumed": 7.45,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 30.12,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 40.85,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 69.03,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e146b556ca56",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-05-23 23:58:57",
        "city": "mohali",
        "closingFuelLevel": 39.77,
        "date": "2022-09-09T13:35:14.718Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.85,
        "fuelConsumed": 19.77,
        "fuelConsumedperRunHour": 3.73,
        "fuelDrained": 5.4,
        "fuelLessThanThreshold": 39.77,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 59.54,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "5 hr 17 min 48 sec"
    },
    {
        "_id": "631b41136164e146b556ca57",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-05-23 23:50:07",
        "city": "mohali",
        "closingFuelLevel": 47.7,
        "date": "2022-09-09T13:35:14.718Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.74,
        "fuelConsumed": 30.5,
        "fuelConsumedperRunHour": 4.11,
        "fuelDrained": 6.37,
        "fuelLessThanThreshold": 47.7,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 78.2,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "7 hr 24 min 43 sec"
    },
    {
        "_id": "631b41126164e1436931cf67",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-04-10 23:43:15",
        "city": "mohali",
        "closingFuelLevel": 232.47,
        "date": "2022-09-09T13:35:14.662Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.26,
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 5.26,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 232.47,
        "pincode": "160055",
        "siteId": "",
        "siteName": "",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e1436931cf68",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-04-10 23:56:29",
        "city": "mohali",
        "closingFuelLevel": 223.49,
        "date": "2022-09-09T13:35:14.662Z",
        "deFuellingAmount": 0,
        "fuelAdded": 7.92,
        "fuelConsumed": 12.21,
        "fuelConsumedperRunHour": 4.1,
        "fuelDrained": 7.38,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 235.7,
        "pincode": "160103",
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "2 hr 58 min 31 sec"
    },
    {
        "_id": "631b41126164e1436931cf69",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-04-10 23:46:02",
        "city": "mohali",
        "closingFuelLevel": 265.79,
        "date": "2022-09-09T13:35:14.662Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.15,
        "fuelConsumed": 0.81,
        "fuelConsumedperRunHour": 4.68,
        "fuelDrained": 5.27,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 266.6,
        "pincode": "140307",
        "siteId": "",
        "siteName": "",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 10 min 23 sec"
    },
    {
        "_id": "631b41126164e1436931cf6a",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-04-13 23:14:40",
        "city": "mohali",
        "closingFuelLevel": 257.44,
        "date": "2022-09-09T13:35:14.741Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.36,
        "fuelConsumed": 0.06,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.42,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 257.5,
        "pincode": "140307",
        "siteId": "",
        "siteName": "",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e1436931cf6b",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-04-13 23:37:57",
        "city": "mohali",
        "closingFuelLevel": 163.88,
        "date": "2022-09-09T13:35:14.741Z",
        "deFuellingAmount": 0,
        "fuelAdded": 8.75,
        "fuelConsumed": 15.94,
        "fuelConsumedperRunHour": 4.68,
        "fuelDrained": 8.83,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 179.82,
        "pincode": "160103",
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "3 hr 24 min 28 sec"
    },
    {
        "_id": "631b41136164e1436931cf6c",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-04-13 23:43:54",
        "city": "mohali",
        "closingFuelLevel": 226.69,
        "date": "2022-09-09T13:35:14.741Z",
        "deFuellingAmount": 0,
        "fuelAdded": 11.91,
        "fuelConsumed": 2.44,
        "fuelConsumedperRunHour": 4.43,
        "fuelDrained": 5.17,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 229.13,
        "pincode": "140603",
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 33 min 2 sec"
    },
    {
        "_id": "631b41136164e1436931cf6d",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-04-27 19:54:33",
        "city": "mohali",
        "closingFuelLevel": 95,
        "date": "2022-09-09T13:35:15.320Z",
        "deFuellingAmount": 0,
        "fuelAdded": 2.16,
        "fuelConsumed": 28.79,
        "fuelConsumedperRunHour": 5.47,
        "fuelDrained": 17.8,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 123.79,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "5 hr 15 min 52 sec"
    },
    {
        "_id": "631b41136164e1436931cf6e",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-04-27 18:15:21",
        "city": "mohali",
        "closingFuelLevel": 52.82,
        "date": "2022-09-09T13:35:15.320Z",
        "deFuellingAmount": 0,
        "fuelAdded": 0.83,
        "fuelConsumed": -0.06,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 0.77,
        "fuelLessThanThreshold": 52.82,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 52.76,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e1436931cf6f",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-08-29 23:47:52",
        "city": "mohali",
        "closingFuelLevel": 53.74,
        "date": "2022-09-09T13:35:15.397Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.21,
        "fuelConsumed": 0.17,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.38,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 53.91,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e1436931cf70",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-09-01 23:22:44",
        "city": "mohali",
        "closingFuelLevel": 64.43,
        "date": "2022-09-09T13:35:15.321Z",
        "deFuellingAmount": 0,
        "fuelAdded": 0.88,
        "fuelConsumed": 0.36,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 1.24,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 64.79,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e1436931cf71",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-07-05 23:56:36",
        "city": "mohali",
        "closingFuelLevel": 53.07,
        "date": "2022-09-09T13:35:15.394Z",
        "deFuellingAmount": 0,
        "fuelAdded": 26.57,
        "fuelConsumed": 3.72,
        "fuelConsumedperRunHour": 3.16,
        "fuelDrained": 7.6,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 16.98,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 39.81,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 10 min 41 sec"
    },
    {
        "_id": "631b41126164e142da57cde5",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-07-08 23:41:26",
        "city": "mohali",
        "closingFuelLevel": 64.24,
        "date": "2022-09-09T13:35:14.659Z",
        "deFuellingAmount": 0,
        "fuelAdded": 49.18,
        "fuelConsumed": 23.57,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 27.5,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 45.25,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 42.56,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e142da57cde6",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-04-30 23:42:55",
        "city": "mohali",
        "closingFuelLevel": 145.5,
        "date": "2022-09-09T13:35:14.637Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.92,
        "fuelConsumed": 1.55,
        "fuelConsumedperRunHour": 4.73,
        "fuelDrained": 5.38,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 147.05,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 19 min 39 sec"
    },
    {
        "_id": "631b41126164e142da57cde7",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-06-10 23:47:26",
        "city": "mohali",
        "closingFuelLevel": 87,
        "date": "2022-09-09T13:35:14.668Z",
        "deFuellingAmount": 0,
        "fuelAdded": 44.54,
        "fuelConsumed": 0.77,
        "fuelConsumedperRunHour": 8.03,
        "fuelDrained": 7.82,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 36.85,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 50.92,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 5 min 45 sec"
    },
    {
        "_id": "631b41126164e142da57cde8",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-06-10 23:25:02",
        "city": "mohali",
        "closingFuelLevel": 118.23,
        "date": "2022-09-09T13:35:14.668Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.75,
        "fuelConsumed": 0.16,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.91,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 118.39,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e142da57cde9",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-06-09 23:46:58",
        "city": "mohali",
        "closingFuelLevel": 51.15,
        "date": "2022-09-09T13:35:14.649Z",
        "deFuellingAmount": 0,
        "fuelAdded": 8.06,
        "fuelConsumed": 6.26,
        "fuelConsumedperRunHour": 4.36,
        "fuelDrained": 6.92,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 57.41,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 26 min 11 sec"
    },
    {
        "_id": "631b41136164e142da57cdea",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-08-15 23:35:37",
        "city": "mohali",
        "closingFuelLevel": 100.06,
        "date": "2022-09-09T13:35:15.268Z",
        "deFuellingAmount": 0,
        "fuelAdded": 1.91,
        "fuelConsumed": -0.18,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 1.73,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 99.88,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e142da57cdeb",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-08-15 23:56:53",
        "city": "mohali",
        "closingFuelLevel": 73.68,
        "date": "2022-09-09T13:35:15.268Z",
        "deFuellingAmount": 0,
        "fuelAdded": 38.63,
        "fuelConsumed": 3.16,
        "fuelConsumedperRunHour": 5.9,
        "fuelDrained": 5.7,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 34.31,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 42.53,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 32 min 9 sec"
    },
    {
        "_id": "631b41136164e142da57cdec",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-08-15 23:21:51",
        "city": "mohali",
        "closingFuelLevel": 72.01,
        "date": "2022-09-09T13:35:15.268Z",
        "deFuellingAmount": 0,
        "fuelAdded": 1.77,
        "fuelConsumed": 1.89,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.66,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 73.9,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e142da57cded",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-08-10 23:48:11",
        "city": "mohali",
        "closingFuelLevel": 102.77,
        "date": "2022-09-09T13:35:15.390Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.36,
        "fuelConsumed": -0.19,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 5.17,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 102.58,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e142da57cdee",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-08-10 23:58:01",
        "city": "mohali",
        "closingFuelLevel": 69.55,
        "date": "2022-09-09T13:35:15.390Z",
        "deFuellingAmount": 0,
        "fuelAdded": 23.89,
        "fuelConsumed": 3.03,
        "fuelConsumedperRunHour": 3.24,
        "fuelDrained": 5.89,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 17.41,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 55.17,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 56 min 2 sec"
    },
    {
        "_id": "631b41136164e142da57cdef",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-08-10 23:39:12",
        "city": "mohali",
        "closingFuelLevel": 82.53,
        "date": "2022-09-09T13:35:15.390Z",
        "deFuellingAmount": 0,
        "fuelAdded": 2.6,
        "fuelConsumed": 12.94,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 15.54,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 95.47,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e11f1c7ba046",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-04-09 23:59:21",
        "city": "mohali",
        "closingFuelLevel": 266.73,
        "date": "2022-09-09T13:35:14.658Z",
        "deFuellingAmount": 0,
        "fuelAdded": 14.75,
        "fuelConsumed": 11.62,
        "fuelConsumedperRunHour": 21.97,
        "fuelDrained": 5.03,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 9.54,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 268.81,
        "pincode": "140307",
        "siteId": "",
        "siteName": "",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 31 min 44 sec"
    },
    {
        "_id": "631b41126164e11f1c7ba047",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-05-01 23:40:52",
        "city": "mohali",
        "closingFuelLevel": 126.07,
        "date": "2022-09-09T13:35:14.657Z",
        "deFuellingAmount": 0,
        "fuelAdded": 7.67,
        "fuelConsumed": 19.5,
        "fuelConsumedperRunHour": 4.84,
        "fuelDrained": 6.19,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 145.57,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "4 hr 1 min 37 sec"
    },
    {
        "_id": "631b41126164e11f1c7ba048",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-05-01 23:36:50",
        "city": "mohali",
        "closingFuelLevel": 151.32,
        "date": "2022-09-09T13:35:14.657Z",
        "deFuellingAmount": 0,
        "fuelAdded": 2.75,
        "fuelConsumed": 7.31,
        "fuelConsumedperRunHour": 3.86,
        "fuelDrained": 3.41,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 158.63,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 53 min 32 sec"
    },
    {
        "_id": "631b41136164e11f1c7ba049",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-04-19 23:52:13",
        "city": "mohali",
        "closingFuelLevel": 93.33,
        "date": "2022-09-09T13:35:15.340Z",
        "deFuellingAmount": 0,
        "fuelAdded": 24.62,
        "fuelConsumed": 19.11,
        "fuelConsumedperRunHour": 4,
        "fuelDrained": 13.08,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 112.44,
        "pincode": "160103",
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "4 hr 46 min 53 sec"
    },
    {
        "_id": "631b41136164e11f1c7ba04a",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-04-19 23:33:30",
        "city": "mohali",
        "closingFuelLevel": 218.52,
        "date": "2022-09-09T13:35:15.340Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.24,
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 5.22,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 218.52,
        "pincode": "140603",
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 2 min 55 sec"
    },
    {
        "_id": "631b41136164e11f1c7ba04b",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-04-19 23:57:10",
        "city": "mohali",
        "closingFuelLevel": 208.45,
        "date": "2022-09-09T13:35:15.340Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.85,
        "fuelConsumed": -0.1,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.75,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 208.35,
        "pincode": "160055",
        "siteId": "",
        "siteName": "",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e11f1c7ba04c",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-08-17 23:47:28",
        "city": "mohali",
        "closingFuelLevel": 67.73,
        "date": "2022-09-09T13:35:15.380Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.14,
        "fuelConsumed": 1.93,
        "fuelConsumedperRunHour": 12.89,
        "fuelDrained": 5.44,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 69.66,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 8 min 59 sec"
    },
    {
        "_id": "631b41136164e11f1c7ba04d",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-08-17 23:40:43",
        "city": "mohali",
        "closingFuelLevel": 100.06,
        "date": "2022-09-09T13:35:15.380Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.89,
        "fuelConsumed": -0.06,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.83,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 100,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e11f1c7ba04e",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-08-17 23:41:57",
        "city": "mohali",
        "closingFuelLevel": 57.59,
        "date": "2022-09-09T13:35:15.380Z",
        "deFuellingAmount": 9.23,
        "fuelAdded": 2.11,
        "fuelConsumed": 4.67,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 16.01,
        "fuelLessThanThreshold": 57.59,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 71.49,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e11f1c7ba04f",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-09-05 23:17:52",
        "city": "mohali",
        "closingFuelLevel": 64.58,
        "date": "2022-09-09T13:35:15.375Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.35,
        "fuelConsumed": -0.1,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.25,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 64.48,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e11f1c7ba050",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-09-05 23:31:55",
        "city": "mohali",
        "closingFuelLevel": 43.1,
        "date": "2022-09-09T13:35:15.375Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.3,
        "fuelConsumed": 0.46,
        "fuelConsumedperRunHour": 3.82,
        "fuelDrained": 6.13,
        "fuelLessThanThreshold": 43.1,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 43.56,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 7 min 14 sec"
    },
    {
        "_id": "631b41126164e159623d7b9d",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-08-07 23:47:20",
        "city": "mohali",
        "closingFuelLevel": 64.82,
        "date": "2022-09-09T13:35:14.668Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.49,
        "fuelConsumed": 12.08,
        "fuelConsumedperRunHour": 3.97,
        "fuelDrained": 8.17,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 76.9,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "3 hr 2 min 38 sec"
    },
    {
        "_id": "631b41126164e159623d7b9e",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-06-09 23:54:13",
        "city": "mohali",
        "closingFuelLevel": 85.24,
        "date": "2022-09-09T13:35:14.649Z",
        "deFuellingAmount": 0,
        "fuelAdded": 48.43,
        "fuelConsumed": 18.2,
        "fuelConsumedperRunHour": 4.86,
        "fuelDrained": 7.74,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 38.96,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 64.48,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "3 hr 44 min 46 sec"
    },
    {
        "_id": "631b41126164e159623d7b9f",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-06-23 23:45:00",
        "city": "mohali",
        "closingFuelLevel": 74.16,
        "date": "2022-09-09T13:35:14.655Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.42,
        "fuelConsumed": 5.1,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 11.52,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 79.26,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e159623d7ba0",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-08-12 23:49:40",
        "city": "mohali",
        "closingFuelLevel": 64.59,
        "date": "2022-09-09T13:35:15.339Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.79,
        "fuelConsumed": -0.06,
        "fuelConsumedperRunHour": -0.54,
        "fuelDrained": 4.62,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 64.53,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 6 min 38 sec"
    },
    {
        "_id": "631b41136164e159623d7ba1",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-08-12 23:53:17",
        "city": "mohali",
        "closingFuelLevel": 102.71,
        "date": "2022-09-09T13:35:15.339Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.73,
        "fuelConsumed": -0.19,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.54,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 102.52,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e159623d7ba2",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-07-18 23:50:45",
        "city": "mohali",
        "closingFuelLevel": 57.18,
        "date": "2022-09-09T13:35:15.315Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.66,
        "fuelConsumed": 0.3,
        "fuelConsumedperRunHour": 1.69,
        "fuelDrained": 5.47,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 57.48,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 10 min 40 sec"
    },
    {
        "_id": "631b41136164e159623d7ba3",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-07-18 23:40:34",
        "city": "mohali",
        "closingFuelLevel": 66.77,
        "date": "2022-09-09T13:35:15.315Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.61,
        "fuelConsumed": -0.32,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.29,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 66.45,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e143766128af",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-05-01 23:46:21",
        "city": "mohali",
        "closingFuelLevel": 53.33,
        "date": "2022-09-09T13:35:14.657Z",
        "deFuellingAmount": 0,
        "fuelAdded": 12.99,
        "fuelConsumed": 4.31,
        "fuelConsumedperRunHour": 4.57,
        "fuelDrained": 9.24,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 57.64,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 56 min 32 sec"
    },
    {
        "_id": "631b41126164e143766128b0",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-08-07 23:33:29",
        "city": "mohali",
        "closingFuelLevel": 72.3,
        "date": "2022-09-09T13:35:14.668Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.81,
        "fuelConsumed": 8.61,
        "fuelConsumedperRunHour": 4.33,
        "fuelDrained": 6.16,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 80.91,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 59 min 11 sec"
    },
    {
        "_id": "631b41126164e143766128b1",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-07-11 23:52:31",
        "city": "mohali",
        "closingFuelLevel": 94.07,
        "date": "2022-09-09T13:35:14.644Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.25,
        "fuelConsumed": 1.55,
        "fuelConsumedperRunHour": 3.72,
        "fuelDrained": 4.38,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 95.62,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 25 min 1 sec"
    },
    {
        "_id": "631b41126164e143766128b2",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-07-13 23:39:06",
        "city": "mohali",
        "closingFuelLevel": 40.12,
        "date": "2022-09-09T13:35:14.681Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.39,
        "fuelConsumed": 0.69,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 5.08,
        "fuelLessThanThreshold": 40.12,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 40.81,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143766128b3",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-06-23 23:53:10",
        "city": "mohali",
        "closingFuelLevel": 61.23,
        "date": "2022-09-09T13:35:14.655Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.62,
        "fuelConsumed": 13.12,
        "fuelConsumedperRunHour": 4.07,
        "fuelDrained": 6.7,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 74.35,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "3 hr 13 min 38 sec"
    },
    {
        "_id": "631b41136164e143766128b4",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-07-13 23:41:27",
        "city": "mohali",
        "closingFuelLevel": 82.56,
        "date": "2022-09-09T13:35:14.681Z",
        "deFuellingAmount": 0,
        "fuelAdded": 0.63,
        "fuelConsumed": 0.1,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 0.73,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 82.66,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143766128b5",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-06-29 23:37:12",
        "city": "mohali",
        "closingFuelLevel": 68.01,
        "date": "2022-09-09T13:35:14.684Z",
        "deFuellingAmount": 10.75,
        "fuelAdded": 0.89,
        "fuelConsumed": 3.88,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 15.52,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 82.64,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143766128b6",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-06-29 23:47:41",
        "city": "mohali",
        "closingFuelLevel": 71.55,
        "date": "2022-09-09T13:35:14.684Z",
        "deFuellingAmount": 0,
        "fuelAdded": 40.41,
        "fuelConsumed": 9.54,
        "fuelConsumedperRunHour": 4,
        "fuelDrained": 7.19,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 35.46,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 45.63,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "2 hr 23 min 6 sec"
    },
    {
        "_id": "631b41136164e143766128b7",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-06-29 23:47:32",
        "city": "mohali",
        "closingFuelLevel": 64.85,
        "date": "2022-09-09T13:35:14.684Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.01,
        "fuelConsumed": 1.16,
        "fuelConsumedperRunHour": 4.22,
        "fuelDrained": 4.7,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 66.01,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 16 min 29 sec"
    },
    {
        "_id": "631b41136164e143766128b8",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-06-29 23:56:15",
        "city": "mohali",
        "closingFuelLevel": 73.33,
        "date": "2022-09-09T13:35:14.684Z",
        "deFuellingAmount": 0,
        "fuelAdded": 2.72,
        "fuelConsumed": 0.73,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.45,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 74.06,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143766128b9",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-08-14 23:33:03",
        "city": "mohali",
        "closingFuelLevel": 99.94,
        "date": "2022-09-09T13:35:15.310Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.93,
        "fuelConsumed": 0.38,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 6.31,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 100.32,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e1467b52b500",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-07-21 23:33:28",
        "city": "mohali",
        "closingFuelLevel": 49.32,
        "date": "2022-09-09T13:35:14.691Z",
        "deFuellingAmount": 0,
        "fuelAdded": 21.99,
        "fuelConsumed": 19.95,
        "fuelConsumedperRunHour": 3.25,
        "fuelDrained": 5.06,
        "fuelLessThanThreshold": 49.32,
        "fuellingAmount": 16.45,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 52.82,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "6 hr 8 min 8 sec"
    },
    {
        "_id": "631b41126164e1467b52b501",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-05-22 23:46:33",
        "city": "mohali",
        "closingFuelLevel": 65.16,
        "date": "2022-09-09T13:35:14.648Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.48,
        "fuelConsumed": 0.27,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 6.75,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 65.43,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e1467b52b502",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-04-13 23:50:53",
        "city": "mohali",
        "closingFuelLevel": 233.11,
        "date": "2022-09-09T13:35:14.741Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.73,
        "fuelConsumed": -0.48,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.25,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 232.63,
        "pincode": "160055",
        "siteId": "",
        "siteName": "",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e1467b52b503",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-07-06 23:36:20",
        "city": "mohali",
        "closingFuelLevel": 61.59,
        "date": "2022-09-09T13:35:15.346Z",
        "deFuellingAmount": 6.35,
        "fuelAdded": 3.64,
        "fuelConsumed": 8.25,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 18.24,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 76.19,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e1467b52b504",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-07-06 23:56:17",
        "city": "mohali",
        "closingFuelLevel": 82.09,
        "date": "2022-09-09T13:35:15.346Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.37,
        "fuelConsumed": 6.41,
        "fuelConsumedperRunHour": 4.53,
        "fuelDrained": 3.4,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 88.5,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 24 min 55 sec"
    },
    {
        "_id": "631b41136164e1467b52b505",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-07-06 23:59:06",
        "city": "mohali",
        "closingFuelLevel": 40,
        "date": "2022-09-09T13:35:15.346Z",
        "deFuellingAmount": 0,
        "fuelAdded": 7.31,
        "fuelConsumed": 13.01,
        "fuelConsumedperRunHour": 3,
        "fuelDrained": 6.7,
        "fuelLessThanThreshold": 40,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 53.01,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "4 hr 19 min 56 sec"
    },
    {
        "_id": "631b41136164e1467b52b506",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-07-06 23:45:44",
        "city": "mohali",
        "closingFuelLevel": 66.31,
        "date": "2022-09-09T13:35:15.346Z",
        "deFuellingAmount": 0,
        "fuelAdded": 40.17,
        "fuelConsumed": 47.83,
        "fuelConsumedperRunHour": 4.08,
        "fuelDrained": 5.62,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 35.92,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 78.22,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "11 hr 42 min 47 sec"
    },
    {
        "_id": "631b41136164e1467b52b507",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-07-03 23:36:05",
        "city": "mohali",
        "closingFuelLevel": 88.17,
        "date": "2022-09-09T13:35:14.720Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.82,
        "fuelConsumed": -0.17,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.65,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 88,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e1467b52b508",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-07-03 22:22:27",
        "city": "mohali",
        "closingFuelLevel": 86.35,
        "date": "2022-09-09T13:35:14.720Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.03,
        "fuelConsumed": 0.59,
        "fuelConsumedperRunHour": 3.32,
        "fuelDrained": 5.74,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 86.94,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 10 min 39 sec"
    },
    {
        "_id": "631b41136164e1467b52b509",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-06-15 23:57:49",
        "city": "mohali",
        "closingFuelLevel": 45.09,
        "date": "2022-09-09T13:35:15.370Z",
        "deFuellingAmount": 0,
        "fuelAdded": 8.01,
        "fuelConsumed": 14.97,
        "fuelConsumedperRunHour": 3.67,
        "fuelDrained": 5.67,
        "fuelLessThanThreshold": 45.09,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 60.06,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "4 hr 4 min 31 sec"
    },
    {
        "_id": "631b41136164e1467b52b50a",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-05-26 23:51:08",
        "city": "mohali",
        "closingFuelLevel": 58.4,
        "date": "2022-09-09T13:35:14.743Z",
        "deFuellingAmount": 0,
        "fuelAdded": 7.97,
        "fuelConsumed": 7.61,
        "fuelConsumedperRunHour": 4.85,
        "fuelDrained": 8.89,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 66.01,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 34 min 4 sec"
    },
    {
        "_id": "631b41126164e154f92b215b",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-08-06 23:45:11",
        "city": "mohali",
        "closingFuelLevel": 75.71,
        "date": "2022-09-09T13:35:14.639Z",
        "deFuellingAmount": 0,
        "fuelAdded": 26.71,
        "fuelConsumed": 25.93,
        "fuelConsumedperRunHour": 6.4,
        "fuelDrained": 12.11,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 18.44,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 83.2,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "4 hr 3 min 16 sec"
    },
    {
        "_id": "631b41126164e154f92b215c",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-07-09 23:43:58",
        "city": "mohali",
        "closingFuelLevel": 55.57,
        "date": "2022-09-09T13:35:14.651Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.18,
        "fuelConsumed": 8.31,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 11.49,
        "fuelLessThanThreshold": 55.57,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 63.88,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e154f92b215d",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-07-21 22:44:18",
        "city": "mohali",
        "closingFuelLevel": 82.18,
        "date": "2022-09-09T13:35:14.691Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.96,
        "fuelConsumed": 3.47,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 8.43,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 85.65,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e154f92b215e",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-09-01 23:33:59",
        "city": "mohali",
        "closingFuelLevel": 100.77,
        "date": "2022-09-09T13:35:15.321Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.44,
        "fuelConsumed": -0.12,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.32,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 100.65,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e154f92b215f",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-09-01 23:47:16",
        "city": "mohali",
        "closingFuelLevel": 45,
        "date": "2022-09-09T13:35:15.321Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.83,
        "fuelConsumed": 1.15,
        "fuelConsumedperRunHour": 5.01,
        "fuelDrained": 4.89,
        "fuelLessThanThreshold": 45,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 46.15,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 13 min 47 sec"
    },
    {
        "_id": "631b41136164e154f92b2160",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-09-01 23:19:56",
        "city": "mohali",
        "closingFuelLevel": 105.6,
        "date": "2022-09-09T13:35:15.321Z",
        "deFuellingAmount": 0,
        "fuelAdded": 43.21,
        "fuelConsumed": -40.03,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.18,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 65.57,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e154f92b2161",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-04-27 17:23:10",
        "city": "mohali",
        "closingFuelLevel": 159.11,
        "date": "2022-09-09T13:35:15.320Z",
        "deFuellingAmount": 0,
        "fuelAdded": 0,
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 0,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 159.11,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e154f92b2162",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-06-24 23:47:30",
        "city": "mohali",
        "closingFuelLevel": 68.58,
        "date": "2022-09-09T13:35:15.407Z",
        "deFuellingAmount": 2.02,
        "fuelAdded": 5.24,
        "fuelConsumed": 3.75,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 11.01,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 74.35,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e154f92b2163",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-06-24 23:44:51",
        "city": "mohali",
        "closingFuelLevel": 10.38,
        "date": "2022-09-09T13:35:15.407Z",
        "deFuellingAmount": 0,
        "fuelAdded": 12.77,
        "fuelConsumed": 17.26,
        "fuelConsumedperRunHour": 4.96,
        "fuelDrained": 18.09,
        "fuelLessThanThreshold": 10.38,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 27.64,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "3 hr 28 min 38 sec"
    },
    {
        "_id": "631b41136164e154f92b2164",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-06-24 23:37:53",
        "city": "mohali",
        "closingFuelLevel": 101.4,
        "date": "2022-09-09T13:35:15.407Z",
        "deFuellingAmount": 0,
        "fuelAdded": 14.19,
        "fuelConsumed": -0.12,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 14.07,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 101.28,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e154f92b2165",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-05-10 23:45:01",
        "city": "mohali",
        "closingFuelLevel": 57.87,
        "date": "2022-09-09T13:35:14.722Z",
        "deFuellingAmount": 0,
        "fuelAdded": 7.77,
        "fuelConsumed": 1.38,
        "fuelConsumedperRunHour": 4.6,
        "fuelDrained": 7.82,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 59.25,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 18 min 1 sec"
    },
    {
        "_id": "631b41126164e146b23e9bd8",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-05-22 23:58:56",
        "city": "mohali",
        "closingFuelLevel": 78.39,
        "date": "2022-09-09T13:35:14.648Z",
        "deFuellingAmount": 0,
        "fuelAdded": 7.58,
        "fuelConsumed": 0.24,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 7.82,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 78.63,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e146b23e9bd9",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-04-09 23:40:32",
        "city": "mohali",
        "closingFuelLevel": 236.91,
        "date": "2022-09-09T13:35:14.658Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.87,
        "fuelConsumed": -0.15,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.72,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 236.76,
        "pincode": "140603",
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e146b23e9bda",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-04-09 23:59:22",
        "city": "mohali",
        "closingFuelLevel": 235.93,
        "date": "2022-09-09T13:35:14.658Z",
        "deFuellingAmount": 0,
        "fuelAdded": 17.12,
        "fuelConsumed": 21.13,
        "fuelConsumedperRunHour": 9.76,
        "fuelDrained": 2.94,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 12,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 245.06,
        "pincode": "160103",
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "2 hr 9 min 55 sec"
    },
    {
        "_id": "631b41126164e146b23e9bdb",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-07-11 23:55:10",
        "city": "mohali",
        "closingFuelLevel": 90.75,
        "date": "2022-09-09T13:35:14.644Z",
        "deFuellingAmount": 0,
        "fuelAdded": 44.91,
        "fuelConsumed": -0.67,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.41,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 39.83,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 50.25,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e146b23e9bdc",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-07-01 23:53:41",
        "city": "mohali",
        "closingFuelLevel": 44.94,
        "date": "2022-09-09T13:35:15.338Z",
        "deFuellingAmount": 3.59,
        "fuelAdded": 4.64,
        "fuelConsumed": 4.35,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 12.58,
        "fuelLessThanThreshold": 44.94,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 52.88,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e146b23e9bdd",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-07-01 23:33:38",
        "city": "mohali",
        "closingFuelLevel": 56.13,
        "date": "2022-09-09T13:35:15.338Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.23,
        "fuelConsumed": 0.37,
        "fuelConsumedperRunHour": 3.5,
        "fuelDrained": 3.74,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 56.5,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 6 min 21 sec"
    },
    {
        "_id": "631b41136164e146b23e9bde",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-06-20 19:52:37",
        "city": "mohali",
        "closingFuelLevel": 0,
        "date": "2022-09-09T13:35:15.306Z",
        "deFuellingAmount": 111.32,
        "fuelAdded": 0.85,
        "fuelConsumed": -0.58,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 111.59,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 110.74,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e146b23e9bdf",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-07-26 23:30:39",
        "city": "mohali",
        "closingFuelLevel": 101.4,
        "date": "2022-09-09T13:35:15.392Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.43,
        "fuelConsumed": 1.07,
        "fuelConsumedperRunHour": 4.82,
        "fuelDrained": 3.6,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 102.47,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 13 min 20 sec"
    },
    {
        "_id": "631b41136164e146b23e9be0",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-07-26 23:35:14",
        "city": "mohali",
        "closingFuelLevel": 72.86,
        "date": "2022-09-09T13:35:15.392Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.73,
        "fuelConsumed": 5.65,
        "fuelConsumedperRunHour": 3.77,
        "fuelDrained": 4.9,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 78.51,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 29 min 53 sec"
    },
    {
        "_id": "631b41136164e146b23e9be1",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-07-26 23:42:03",
        "city": "mohali",
        "closingFuelLevel": 69.09,
        "date": "2022-09-09T13:35:15.392Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.35,
        "fuelConsumed": 2.79,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 7.14,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 71.88,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e146b23e9be2",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-05-30 23:51:50",
        "city": "mohali",
        "closingFuelLevel": 65.86,
        "date": "2022-09-09T13:35:14.683Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.12,
        "fuelConsumed": 0.05,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 5.17,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 65.91,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e127db3d983c",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-05-04 23:36:27",
        "city": "mohali",
        "closingFuelLevel": 85.83,
        "date": "2022-09-09T13:35:14.671Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.54,
        "fuelConsumed": 27.85,
        "fuelConsumedperRunHour": 6.6,
        "fuelDrained": 4.17,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 113.68,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "4 hr 13 min 4 sec"
    },
    {
        "_id": "631b41126164e127db3d983d",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-07-08 23:10:03",
        "city": "mohali",
        "closingFuelLevel": 58.16,
        "date": "2022-09-09T13:35:14.659Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.1,
        "fuelConsumed": 1.9,
        "fuelConsumedperRunHour": 3.14,
        "fuelDrained": 5.17,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 60.06,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 36 min 21 sec"
    },
    {
        "_id": "631b41126164e127db3d983e",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-07-08 10:42:03",
        "city": "mohali",
        "closingFuelLevel": 0,
        "date": "2022-09-09T13:35:14.659Z",
        "deFuellingAmount": 0,
        "fuelAdded": 18.41,
        "fuelConsumed": 100.44,
        "fuelConsumedperRunHour": 24.78,
        "fuelDrained": 0.44,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 18.16,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 82.28,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "4 hr 3 min 13 sec"
    },
    {
        "_id": "631b41136164e127db3d983f",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-04-28 23:26:39",
        "city": "mohali",
        "closingFuelLevel": 79.25,
        "date": "2022-09-09T13:35:14.686Z",
        "deFuellingAmount": 0,
        "fuelAdded": 2.99,
        "fuelConsumed": 10.46,
        "fuelConsumedperRunHour": 2.01,
        "fuelDrained": 3.11,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 89.71,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "5 hr 11 min 47 sec"
    },
    {
        "_id": "631b41136164e127db3d9840",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-04-28 23:56:49",
        "city": "mohali",
        "closingFuelLevel": 45.6,
        "date": "2022-09-09T13:35:14.686Z",
        "deFuellingAmount": 2.08,
        "fuelAdded": 9.72,
        "fuelConsumed": 4.14,
        "fuelConsumedperRunHour": 4.55,
        "fuelDrained": 7.34,
        "fuelLessThanThreshold": 45.6,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 51.82,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 54 min 36 sec"
    },
    {
        "_id": "631b41136164e127db3d9841",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-04-28 23:44:05",
        "city": "mohali",
        "closingFuelLevel": 147.65,
        "date": "2022-09-09T13:35:14.686Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.29,
        "fuelConsumed": 1.01,
        "fuelConsumedperRunHour": 1.55,
        "fuelDrained": 3.96,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 148.66,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 39 min 11 sec"
    },
    {
        "_id": "631b41136164e127db3d9842",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-04-22 23:30:27",
        "city": "mohali",
        "closingFuelLevel": 188.85,
        "date": "2022-09-09T13:35:14.708Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.77,
        "fuelConsumed": 19.04,
        "fuelConsumedperRunHour": 5.27,
        "fuelDrained": 3.52,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 207.89,
        "pincode": "160055",
        "siteId": "",
        "siteName": "",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "3 hr 36 min 52 sec"
    },
    {
        "_id": "631b41136164e127db3d9843",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-04-22 23:38:43",
        "city": "mohali",
        "closingFuelLevel": 61.06,
        "date": "2022-09-09T13:35:14.708Z",
        "deFuellingAmount": 0,
        "fuelAdded": 11.11,
        "fuelConsumed": 4.39,
        "fuelConsumedperRunHour": 5.53,
        "fuelDrained": 8.3,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 65.45,
        "pincode": "160103",
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 47 min 38 sec"
    },
    {
        "_id": "631b41136164e127db3d9844",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-04-22 23:44:16",
        "city": "mohali",
        "closingFuelLevel": 172.19,
        "date": "2022-09-09T13:35:14.708Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.7,
        "fuelConsumed": 1.37,
        "fuelConsumedperRunHour": 4.39,
        "fuelDrained": 5.5,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 173.56,
        "pincode": "140307",
        "siteId": "",
        "siteName": "",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 18 min 43 sec"
    },
    {
        "_id": "631b41136164e127db3d9845",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-04-22 23:45:00",
        "city": "mohali",
        "closingFuelLevel": 172.24,
        "date": "2022-09-09T13:35:14.708Z",
        "deFuellingAmount": 0,
        "fuelAdded": 8.65,
        "fuelConsumed": 0.35,
        "fuelConsumedperRunHour": 1.83,
        "fuelDrained": 5.59,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 172.59,
        "pincode": "140603",
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 11 min 27 sec"
    },
    {
        "_id": "631b05906164e1396f29f337",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-09-08 23:51:51",
        "city": "mohali",
        "closingFuelLevel": 100.84,
        "date": "2022-09-09T13:35:15.184Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.63,
        "fuelConsumed": 0.13,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.76,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 100.97,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e143503d1fcd",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-04-30 23:33:00",
        "city": "mohali",
        "closingFuelLevel": 63.18,
        "date": "2022-09-09T13:35:14.637Z",
        "deFuellingAmount": 0,
        "fuelAdded": 18.43,
        "fuelConsumed": 20.44,
        "fuelConsumedperRunHour": 45.25,
        "fuelDrained": 34.21,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 83.62,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 27 min 6 sec"
    },
    {
        "_id": "631b41126164e143503d1fce",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-08-06 23:49:31",
        "city": "mohali",
        "closingFuelLevel": 81.04,
        "date": "2022-09-09T13:35:14.639Z",
        "deFuellingAmount": 0,
        "fuelAdded": 43.65,
        "fuelConsumed": 16.04,
        "fuelConsumedperRunHour": 4.95,
        "fuelDrained": 6.55,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 38.31,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 58.77,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "3 hr 14 min 30 sec"
    },
    {
        "_id": "631b41126164e143503d1fcf",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-08-06 23:29:02",
        "city": "mohali",
        "closingFuelLevel": 110.06,
        "date": "2022-09-09T13:35:14.639Z",
        "deFuellingAmount": 2.39,
        "fuelAdded": 1.8,
        "fuelConsumed": 2.32,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 6.51,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 114.77,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143503d1fd0",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-06-03 23:57:50",
        "city": "mohali",
        "closingFuelLevel": 55.8,
        "date": "2022-09-09T13:35:14.679Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.96,
        "fuelConsumed": 4.79,
        "fuelConsumedperRunHour": 4.48,
        "fuelDrained": 8.21,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 60.59,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 4 min 11 sec"
    },
    {
        "_id": "631b41136164e143503d1fd1",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-06-03 23:41:00",
        "city": "mohali",
        "closingFuelLevel": 78.98,
        "date": "2022-09-09T13:35:14.679Z",
        "deFuellingAmount": 30.53,
        "fuelAdded": 60.51,
        "fuelConsumed": 3.66,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 39.98,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 54.72,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 58.45,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143503d1fd2",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-06-03 23:50:54",
        "city": "mohali",
        "closingFuelLevel": 76.4,
        "date": "2022-09-09T13:35:14.679Z",
        "deFuellingAmount": 0,
        "fuelAdded": 9.01,
        "fuelConsumed": 0.87,
        "fuelConsumedperRunHour": 2.92,
        "fuelDrained": 8.32,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 77.27,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 17 min 51 sec"
    },
    {
        "_id": "631b41136164e143503d1fd3",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-06-03 18:03:23",
        "city": "mohali",
        "closingFuelLevel": 82.59,
        "date": "2022-09-09T13:35:14.679Z",
        "deFuellingAmount": 0,
        "fuelAdded": 1.01,
        "fuelConsumed": -0.34,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 0.67,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 82.25,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143503d1fd4",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-05-17 23:33:52",
        "city": "mohali",
        "closingFuelLevel": 65.65,
        "date": "2022-09-09T13:35:15.236Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.28,
        "fuelConsumed": -0.06,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 6.22,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 65.59,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143503d1fd5",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-05-17 23:52:23",
        "city": "mohali",
        "closingFuelLevel": 92.62,
        "date": "2022-09-09T13:35:15.236Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.25,
        "fuelConsumed": 5.58,
        "fuelConsumedperRunHour": 3.87,
        "fuelDrained": 6.63,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 98.2,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 26 min 32 sec"
    },
    {
        "_id": "631b41136164e143503d1fd6",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-05-17 23:57:07",
        "city": "mohali",
        "closingFuelLevel": 65.11,
        "date": "2022-09-09T13:35:15.236Z",
        "deFuellingAmount": 14.11,
        "fuelAdded": 66.92,
        "fuelConsumed": 4.17,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 24.68,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 60.52,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 22.87,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143503d1fd7",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-05-17 23:50:44",
        "city": "mohali",
        "closingFuelLevel": 74.47,
        "date": "2022-09-09T13:35:15.236Z",
        "deFuellingAmount": 0,
        "fuelAdded": 8.11,
        "fuelConsumed": 0.44,
        "fuelConsumedperRunHour": 2.17,
        "fuelDrained": 7.3,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 74.91,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 12 min 10 sec"
    },
    {
        "_id": "631b41126164e143047fea09",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-06-26 23:47:30",
        "city": "mohali",
        "closingFuelLevel": 113.86,
        "date": "2022-09-09T13:35:14.655Z",
        "deFuellingAmount": 5.91,
        "fuelAdded": 64.46,
        "fuelConsumed": 8.51,
        "fuelConsumedperRunHour": 33.85,
        "fuelDrained": 16.23,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 61.23,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 67.05,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 15 min 5 sec"
    },
    {
        "_id": "631b41126164e143047fea0a",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-06-26 23:52:34",
        "city": "mohali",
        "closingFuelLevel": 38.83,
        "date": "2022-09-09T13:35:14.655Z",
        "deFuellingAmount": 0,
        "fuelAdded": 7.39,
        "fuelConsumed": 19.35,
        "fuelConsumedperRunHour": 4.66,
        "fuelDrained": 6.8,
        "fuelLessThanThreshold": 38.83,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 58.18,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "4 hr 9 min 22 sec"
    },
    {
        "_id": "631b41126164e11ae51100b8",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-06-28 23:37:53",
        "city": "mohali",
        "closingFuelLevel": 65.77,
        "date": "2022-09-09T13:35:14.641Z",
        "deFuellingAmount": 0,
        "fuelAdded": 44.35,
        "fuelConsumed": 14.28,
        "fuelConsumedperRunHour": 3,
        "fuelDrained": 7.77,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 36.88,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 43.17,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "4 hr 46 min 0 sec"
    },
    {
        "_id": "631b41126164e1283f79e992",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-08-07 23:31:34",
        "city": "mohali",
        "closingFuelLevel": 106.31,
        "date": "2022-09-09T13:35:14.668Z",
        "deFuellingAmount": 0,
        "fuelAdded": 2.09,
        "fuelConsumed": 3.8,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 5.89,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 110.11,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41126164e146aa768c17",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-04-16 23:50:04",
        "city": "mohali",
        "closingFuelLevel": 218.69,
        "date": "2022-09-09T13:35:14.671Z",
        "deFuellingAmount": 0,
        "fuelAdded": 10.7,
        "fuelConsumed": 1.08,
        "fuelConsumedperRunHour": 3.72,
        "fuelDrained": 7.52,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 219.77,
        "pincode": "140603",
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 17 min 25 sec"
    },
    {
        "_id": "631b41136164e143047fea0b",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-07-17 23:49:15",
        "city": "mohali",
        "closingFuelLevel": 90.29,
        "date": "2022-09-09T13:35:14.654Z",
        "deFuellingAmount": 20.99,
        "fuelAdded": 25.56,
        "fuelConsumed": 3.43,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 28.32,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 21.66,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 93.05,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143047fea0c",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-07-17 23:47:33",
        "city": "mohali",
        "closingFuelLevel": 99.83,
        "date": "2022-09-09T13:35:14.654Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.14,
        "fuelConsumed": 26.78,
        "fuelConsumedperRunHour": 3.5,
        "fuelDrained": 6.83,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 126.61,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "7 hr 39 min 7 sec"
    },
    {
        "_id": "631b41136164e143047fea0d",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-07-02 23:39:34",
        "city": "mohali",
        "closingFuelLevel": 46.15,
        "date": "2022-09-09T13:35:14.647Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.39,
        "fuelConsumed": 9.98,
        "fuelConsumedperRunHour": 3.79,
        "fuelDrained": 5.83,
        "fuelLessThanThreshold": 46.15,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 56.13,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "2 hr 38 min 7 sec"
    },
    {
        "_id": "631b41136164e143047fea0e",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-07-02 23:56:14",
        "city": "mohali",
        "closingFuelLevel": 42.21,
        "date": "2022-09-09T13:35:14.647Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.09,
        "fuelConsumed": 3.03,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 7.12,
        "fuelLessThanThreshold": 42.21,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 45.24,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143047fea0f",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-07-02 23:34:42",
        "city": "mohali",
        "closingFuelLevel": 87,
        "date": "2022-09-09T13:35:14.647Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.54,
        "fuelConsumed": 8.06,
        "fuelConsumedperRunHour": 3.98,
        "fuelDrained": 5.1,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 95.06,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "2 hr 1 min 34 sec"
    },
    {
        "_id": "631b41136164e143047fea10",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-04-15 23:43:28",
        "city": "mohali",
        "closingFuelLevel": 220,
        "date": "2022-09-09T13:35:14.673Z",
        "deFuellingAmount": 0,
        "fuelAdded": 8.72,
        "fuelConsumed": 0.35,
        "fuelConsumedperRunHour": 3.68,
        "fuelDrained": 6.03,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 220.35,
        "pincode": "140603",
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 5 min 42 sec"
    },
    {
        "_id": "631b41136164e15da67e862f",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-06-02 23:57:35",
        "city": "mohali",
        "closingFuelLevel": 77.45,
        "date": "2022-09-09T13:35:14.470Z",
        "deFuellingAmount": 0,
        "fuelAdded": 8.24,
        "fuelConsumed": 0.81,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 9.05,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 78.26,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e15da67e8630",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-07-17 23:36:26",
        "city": "mohali",
        "closingFuelLevel": 57.79,
        "date": "2022-09-09T13:35:14.654Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.03,
        "fuelConsumed": 17.05,
        "fuelConsumedperRunHour": 3.69,
        "fuelDrained": 6.09,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 74.84,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "4 hr 37 min 32 sec"
    },
    {
        "_id": "631b41136164e15da67e8631",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-07-17 23:53:03",
        "city": "mohali",
        "closingFuelLevel": 66.18,
        "date": "2022-09-09T13:35:14.654Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.27,
        "fuelConsumed": 6.63,
        "fuelConsumedperRunHour": 5.97,
        "fuelDrained": 4.53,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 72.81,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 6 min 36 sec"
    },
    {
        "_id": "631b41136164e15da67e8632",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-08-16 23:38:10",
        "city": "mohali",
        "closingFuelLevel": 100.06,
        "date": "2022-09-09T13:35:15.282Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.56,
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.56,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 100.06,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e15da67e8633",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-08-16 23:51:38",
        "city": "mohali",
        "closingFuelLevel": 69.55,
        "date": "2022-09-09T13:35:15.282Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.02,
        "fuelConsumed": 4.13,
        "fuelConsumedperRunHour": 1,
        "fuelDrained": 4.13,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 73.68,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "4 hr 6 min 53 sec"
    },
    {
        "_id": "631b41136164e15da67e8634",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-08-16 23:39:25",
        "city": "mohali",
        "closingFuelLevel": 71.62,
        "date": "2022-09-09T13:35:15.282Z",
        "deFuellingAmount": 0,
        "fuelAdded": 2.93,
        "fuelConsumed": 0.52,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.45,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 72.14,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e15da67e8635",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-08-03 23:45:11",
        "city": "mohali",
        "closingFuelLevel": 62.08,
        "date": "2022-09-09T13:35:15.322Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.63,
        "fuelConsumed": 0.06,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.69,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 62.14,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e15da67e8636",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-05-12 23:51:12",
        "city": "mohali",
        "closingFuelLevel": 27.09,
        "date": "2022-09-09T13:35:15.327Z",
        "deFuellingAmount": 0,
        "fuelAdded": 11.95,
        "fuelConsumed": 11.66,
        "fuelConsumedperRunHour": 2.75,
        "fuelDrained": 8.2,
        "fuelLessThanThreshold": 27.09,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 38.75,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "4 hr 14 min 5 sec"
    },
    {
        "_id": "631b41136164e16bc02c3200",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-05-08 23:36:32",
        "city": "mohali",
        "closingFuelLevel": 64.18,
        "date": "2022-09-09T13:35:14.663Z",
        "deFuellingAmount": 0,
        "fuelAdded": 8.12,
        "fuelConsumed": 0.88,
        "fuelConsumedperRunHour": 3.22,
        "fuelDrained": 6.93,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 65.06,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 16 min 25 sec"
    },
    {
        "_id": "631b41136164e16bc02c3201",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-05-08 23:46:38",
        "city": "mohali",
        "closingFuelLevel": 86.5,
        "date": "2022-09-09T13:35:14.663Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.85,
        "fuelConsumed": -0.17,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 6.68,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 86.33,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e16bc02c3202",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-05-08 23:42:26",
        "city": "mohali",
        "closingFuelLevel": 58.65,
        "date": "2022-09-09T13:35:14.663Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.94,
        "fuelConsumed": -0.31,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.63,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 58.34,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e16bc02c3203",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-04-28 11:30:57",
        "city": "mohali",
        "closingFuelLevel": 158.68,
        "date": "2022-09-09T13:35:14.686Z",
        "deFuellingAmount": 0,
        "fuelAdded": 0.1,
        "fuelConsumed": -0.1,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 0,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 158.58,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e16bc02c3204",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-08-24 23:55:43",
        "city": "mohali",
        "closingFuelLevel": 81.8,
        "date": "2022-09-09T13:35:15.268Z",
        "deFuellingAmount": 0,
        "fuelAdded": 41.36,
        "fuelConsumed": 6.17,
        "fuelConsumedperRunHour": 3.76,
        "fuelDrained": 5.42,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 35.84,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 52.13,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 38 min 34 sec"
    },
    {
        "_id": "631b41136164e16bc02c3205",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-06-20 23:38:09",
        "city": "mohali",
        "closingFuelLevel": 74.35,
        "date": "2022-09-09T13:35:15.306Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.98,
        "fuelConsumed": 3.1,
        "fuelConsumedperRunHour": 3.74,
        "fuelDrained": 5.84,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 77.45,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 49 min 43 sec"
    },
    {
        "_id": "631b41136164e16bc02c3206",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-06-20 23:39:52",
        "city": "mohali",
        "closingFuelLevel": 52.64,
        "date": "2022-09-09T13:35:15.306Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.66,
        "fuelConsumed": 8.83,
        "fuelConsumedperRunHour": 2.29,
        "fuelDrained": 3.46,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 61.47,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "3 hr 51 min 26 sec"
    },
    {
        "_id": "631b41136164e16bc02c3207",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-07-01 23:31:18",
        "city": "mohali",
        "closingFuelLevel": 73.7,
        "date": "2022-09-09T13:35:15.338Z",
        "deFuellingAmount": 0,
        "fuelAdded": 2.88,
        "fuelConsumed": -0.42,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 2.46,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 73.28,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e16bc02c3208",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-07-01 23:41:36",
        "city": "mohali",
        "closingFuelLevel": 95.17,
        "date": "2022-09-09T13:35:15.338Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.14,
        "fuelConsumed": 0.34,
        "fuelConsumedperRunHour": 1.72,
        "fuelDrained": 2.81,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 95.51,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 11 min 52 sec"
    },
    {
        "_id": "631b41136164e16bc02c3209",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-09-02 23:57:44",
        "city": "mohali",
        "closingFuelLevel": 44.37,
        "date": "2022-09-09T13:35:15.332Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.82,
        "fuelConsumed": 0.86,
        "fuelConsumedperRunHour": 22.27,
        "fuelDrained": 5.68,
        "fuelLessThanThreshold": 44.37,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 45.23,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 2 min 19 sec"
    },
    {
        "_id": "631b41136164e16bc02c320a",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-09-02 23:36:31",
        "city": "mohali",
        "closingFuelLevel": 100.97,
        "date": "2022-09-09T13:35:15.332Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.44,
        "fuelConsumed": -0.26,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.18,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 100.71,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143047fea11",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-04-15 23:59:57",
        "city": "mohali",
        "closingFuelLevel": 141.88,
        "date": "2022-09-09T13:35:14.673Z",
        "deFuellingAmount": 0,
        "fuelAdded": 7.77,
        "fuelConsumed": 2.47,
        "fuelConsumedperRunHour": 3.28,
        "fuelDrained": 6.53,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 144.35,
        "pincode": "160103",
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 45 min 15 sec"
    },
    {
        "_id": "631b41136164e143047fea12",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-04-15 23:55:58",
        "city": "mohali",
        "closingFuelLevel": 233.05,
        "date": "2022-09-09T13:35:14.673Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.46,
        "fuelConsumed": 0.16,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.62,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 233.21,
        "pincode": "160055",
        "siteId": "",
        "siteName": "",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143047fea13",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-04-15 23:36:09",
        "city": "mohali",
        "closingFuelLevel": 252.38,
        "date": "2022-09-09T13:35:14.673Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.39,
        "fuelConsumed": 0.56,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.95,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 252.94,
        "pincode": "140307",
        "siteId": "",
        "siteName": "",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143047fea14",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-08-11 23:50:44",
        "city": "mohali",
        "closingFuelLevel": 102.52,
        "date": "2022-09-09T13:35:15.178Z",
        "deFuellingAmount": 0,
        "fuelAdded": 2.95,
        "fuelConsumed": 0.19,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.14,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 102.71,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143047fea15",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-08-11 23:41:44",
        "city": "mohali",
        "closingFuelLevel": 76.93,
        "date": "2022-09-09T13:35:15.178Z",
        "deFuellingAmount": 2.58,
        "fuelAdded": 1.6,
        "fuelConsumed": 2.56,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 6.74,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 82.07,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143047fea16",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-08-11 23:34:06",
        "city": "mohali",
        "closingFuelLevel": 64.35,
        "date": "2022-09-09T13:35:15.178Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.31,
        "fuelConsumed": 5.25,
        "fuelConsumedperRunHour": 1.47,
        "fuelDrained": 6.57,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 69.6,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "3 hr 35 min 0 sec"
    },
    {
        "_id": "631b41136164e143047fea17",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-04-18 23:31:20",
        "city": "mohali",
        "closingFuelLevel": 112.67,
        "date": "2022-09-09T13:35:15.192Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.89,
        "fuelConsumed": 1.82,
        "fuelConsumedperRunHour": 3.32,
        "fuelDrained": 6.83,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 114.49,
        "pincode": "160103",
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 32 min 56 sec"
    },
    {
        "_id": "631b41136164e143047fea18",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-04-18 23:54:38",
        "city": "mohali",
        "closingFuelLevel": 208.4,
        "date": "2022-09-09T13:35:15.192Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.61,
        "fuelConsumed": -0.15,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 5.46,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 208.25,
        "pincode": "160055",
        "siteId": "",
        "siteName": "",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143047fea19",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-04-18 23:32:04",
        "city": "mohali",
        "closingFuelLevel": 218.35,
        "date": "2022-09-09T13:35:15.192Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.64,
        "fuelConsumed": -0.22,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 5.42,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 218.13,
        "pincode": "140603",
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143047fea1a",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-04-18 23:42:38",
        "city": "mohali",
        "closingFuelLevel": 180.93,
        "date": "2022-09-09T13:35:15.192Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.18,
        "fuelConsumed": 39.19,
        "fuelConsumedperRunHour": 5.19,
        "fuelDrained": 4.66,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 220.12,
        "pincode": "140307",
        "siteId": "",
        "siteName": "",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "7 hr 32 min 53 sec"
    },
    {
        "_id": "631b41136164e143047fea1b",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-08-18 23:38:13",
        "city": "mohali",
        "closingFuelLevel": 63.65,
        "date": "2022-09-09T13:35:15.267Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.08,
        "fuelConsumed": 4.02,
        "fuelConsumedperRunHour": 4.36,
        "fuelDrained": 6.02,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 67.67,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 55 min 17 sec"
    },
    {
        "_id": "631b05906164e1396f29f338",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-09-08 23:39:19",
        "city": "mohali",
        "closingFuelLevel": 57.76,
        "date": "2022-09-09T13:35:15.184Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.89,
        "fuelConsumed": 0.21,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 5.1,
        "fuelLessThanThreshold": 57.76,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 57.97,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e127db3d9846",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-08-08 23:19:05",
        "city": "mohali",
        "closingFuelLevel": 100.57,
        "date": "2022-09-09T13:35:15.231Z",
        "deFuellingAmount": 3.24,
        "fuelAdded": 2.4,
        "fuelConsumed": 2.74,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 8.38,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 106.55,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e127db3d9847",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-08-08 23:44:03",
        "city": "mohali",
        "closingFuelLevel": 63.82,
        "date": "2022-09-09T13:35:15.231Z",
        "deFuellingAmount": 0,
        "fuelAdded": 8.96,
        "fuelConsumed": 0.94,
        "fuelConsumedperRunHour": 2.37,
        "fuelDrained": 6.83,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 64.76,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 23 min 50 sec"
    },
    {
        "_id": "631b41136164e127db3d9848",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-08-08 23:56:16",
        "city": "mohali",
        "closingFuelLevel": 64.79,
        "date": "2022-09-09T13:35:15.231Z",
        "deFuellingAmount": 0,
        "fuelAdded": 7.34,
        "fuelConsumed": 7.32,
        "fuelConsumedperRunHour": 3.67,
        "fuelDrained": 6.47,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 72.11,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 59 min 41 sec"
    },
    {
        "_id": "631b41136164e127db3d9849",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-08-08 23:39:56",
        "city": "mohali",
        "closingFuelLevel": 57.03,
        "date": "2022-09-09T13:35:15.231Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.27,
        "fuelConsumed": 14.48,
        "fuelConsumedperRunHour": 5.11,
        "fuelDrained": 4.1,
        "fuelLessThanThreshold": 57.03,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 71.51,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "2 hr 49 min 56 sec"
    },
    {
        "_id": "631b41136164e127db3d984a",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-05-28 23:55:19",
        "city": "mohali",
        "closingFuelLevel": 72.13,
        "date": "2022-09-09T13:35:15.257Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.26,
        "fuelConsumed": 3.67,
        "fuelConsumedperRunHour": 4.2,
        "fuelDrained": 5.73,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 75.8,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 52 min 25 sec"
    },
    {
        "_id": "631b41136164e127db3d984b",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-05-28 23:34:56",
        "city": "mohali",
        "closingFuelLevel": 48.76,
        "date": "2022-09-09T13:35:15.257Z",
        "deFuellingAmount": 58.4,
        "fuelAdded": 64.65,
        "fuelConsumed": 10.57,
        "fuelConsumedperRunHour": 4.69,
        "fuelDrained": 64.72,
        "fuelLessThanThreshold": 48.76,
        "fuellingAmount": 58.77,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 58.96,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "2 hr 15 min 20 sec"
    },
    {
        "_id": "631b41136164e127db3d984c",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-05-28 23:58:08",
        "city": "mohali",
        "closingFuelLevel": 35.13,
        "date": "2022-09-09T13:35:15.257Z",
        "deFuellingAmount": 2.79,
        "fuelAdded": 6.83,
        "fuelConsumed": 1.37,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 10.99,
        "fuelLessThanThreshold": 35.13,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 39.29,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e127db3d984d",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-05-28 23:46:45",
        "city": "mohali",
        "closingFuelLevel": 65.43,
        "date": "2022-09-09T13:35:15.257Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.81,
        "fuelConsumed": 0.22,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 5.03,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 65.65,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e127db3d984e",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-08-28 23:38:47",
        "city": "mohali",
        "closingFuelLevel": 100.84,
        "date": "2022-09-09T13:35:15.267Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.24,
        "fuelConsumed": -0.26,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.98,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 100.58,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143047fea1c",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-08-18 23:43:17",
        "city": "mohali",
        "closingFuelLevel": 100.45,
        "date": "2022-09-09T13:35:15.267Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.95,
        "fuelConsumed": -0.45,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.5,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 100,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143047fea1d",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-08-18 23:44:30",
        "city": "mohali",
        "closingFuelLevel": 55.46,
        "date": "2022-09-09T13:35:15.267Z",
        "deFuellingAmount": 0,
        "fuelAdded": 2.92,
        "fuelConsumed": 1.95,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.87,
        "fuelLessThanThreshold": 55.46,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 57.41,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143047fea1e",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-05-06 23:43:54",
        "city": "mohali",
        "closingFuelLevel": 81.35,
        "date": "2022-09-09T13:35:15.305Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.74,
        "fuelConsumed": 0.9,
        "fuelConsumedperRunHour": 5.51,
        "fuelDrained": 5.69,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 82.25,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 9 min 48 sec"
    },
    {
        "_id": "631b41136164e143047fea1f",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-05-06 23:41:32",
        "city": "mohali",
        "closingFuelLevel": 86.28,
        "date": "2022-09-09T13:35:15.305Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.51,
        "fuelConsumed": -0.34,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 6.17,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 85.94,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143047fea20",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-05-06 23:41:52",
        "city": "mohali",
        "closingFuelLevel": 79.09,
        "date": "2022-09-09T13:35:15.305Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.36,
        "fuelConsumed": 2.12,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 7.48,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 81.21,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143047fea21",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-05-06 22:33:10",
        "city": "mohali",
        "closingFuelLevel": 58.53,
        "date": "2022-09-09T13:35:15.305Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.82,
        "fuelConsumed": -0.37,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.45,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 58.16,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143047fea22",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-08-30 23:34:24",
        "city": "mohali",
        "closingFuelLevel": 53.28,
        "date": "2022-09-09T13:35:15.371Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.82,
        "fuelConsumed": 0.4,
        "fuelConsumedperRunHour": 0.22,
        "fuelDrained": 4.47,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 53.68,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 48 min 27 sec"
    },
    {
        "_id": "631b41136164e143047fea23",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-08-30 23:58:55",
        "city": "mohali",
        "closingFuelLevel": 100.9,
        "date": "2022-09-09T13:35:15.371Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.44,
        "fuelConsumed": -0.25,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.19,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 100.65,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143047fea24",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-07-22 23:51:04",
        "city": "mohali",
        "closingFuelLevel": 49.5,
        "date": "2022-09-09T13:35:15.407Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.46,
        "fuelConsumed": -0.31,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.15,
        "fuelLessThanThreshold": 49.5,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 49.19,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143047fea25",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-07-22 23:54:21",
        "city": "mohali",
        "closingFuelLevel": 45.37,
        "date": "2022-09-09T13:35:15.407Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.73,
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.73,
        "fuelLessThanThreshold": 45.37,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 45.37,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143047fea26",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-07-22 23:46:56",
        "city": "mohali",
        "closingFuelLevel": 81.09,
        "date": "2022-09-09T13:35:15.407Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.08,
        "fuelConsumed": 0.69,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.77,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 81.78,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e127db3d984f",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-08-28 23:39:50",
        "city": "mohali",
        "closingFuelLevel": 77.5,
        "date": "2022-09-09T13:35:15.267Z",
        "deFuellingAmount": 0,
        "fuelAdded": 2.89,
        "fuelConsumed": 0.91,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.8,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 78.41,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e127db3d9850",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-08-28 23:45:19",
        "city": "mohali",
        "closingFuelLevel": 53.91,
        "date": "2022-09-09T13:35:15.267Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.98,
        "fuelConsumed": 4.6,
        "fuelConsumedperRunHour": 4.15,
        "fuelDrained": 7.24,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 58.51,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 6 min 30 sec"
    },
    {
        "_id": "631b41136164e127db3d9851",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-05-11 23:45:43",
        "city": "mohali",
        "closingFuelLevel": 115.79,
        "date": "2022-09-09T13:35:15.268Z",
        "deFuellingAmount": 0,
        "fuelAdded": 65.49,
        "fuelConsumed": 1.88,
        "fuelConsumedperRunHour": 3.12,
        "fuelDrained": 5.9,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 59.85,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 57.82,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 36 min 8 sec"
    },
    {
        "_id": "631b41136164e127db3d9852",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-05-11 23:54:02",
        "city": "mohali",
        "closingFuelLevel": 47.86,
        "date": "2022-09-09T13:35:15.268Z",
        "deFuellingAmount": 6.29,
        "fuelAdded": 6.39,
        "fuelConsumed": 6.61,
        "fuelConsumedperRunHour": 12.09,
        "fuelDrained": 16.98,
        "fuelLessThanThreshold": 47.86,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 60.76,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 32 min 48 sec"
    },
    {
        "_id": "631b41136164e127db3d9853",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-05-11 23:43:19",
        "city": "mohali",
        "closingFuelLevel": 39.14,
        "date": "2022-09-09T13:35:15.268Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.95,
        "fuelConsumed": 47.36,
        "fuelConsumedperRunHour": 5.02,
        "fuelDrained": 4.74,
        "fuelLessThanThreshold": 39.14,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 86.5,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "9 hr 25 min 53 sec"
    },
    {
        "_id": "631b41136164e127db3d9854",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-05-11 23:36:21",
        "city": "mohali",
        "closingFuelLevel": 51.53,
        "date": "2022-09-09T13:35:15.268Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.6,
        "fuelConsumed": 5.89,
        "fuelConsumedperRunHour": 4.5,
        "fuelDrained": 6.28,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 57.42,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 18 min 32 sec"
    },
    {
        "_id": "631b41136164e127db3d9855",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-08-01 23:16:19",
        "city": "mohali",
        "closingFuelLevel": 63.41,
        "date": "2022-09-09T13:35:15.355Z",
        "deFuellingAmount": 5.01,
        "fuelAdded": 2.01,
        "fuelConsumed": 1.71,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 8.73,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 70.13,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e127db3d9856",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-07-25 23:42:29",
        "city": "mohali",
        "closingFuelLevel": 81.55,
        "date": "2022-09-09T13:35:15.340Z",
        "deFuellingAmount": 0,
        "fuelAdded": 41.35,
        "fuelConsumed": 0.45,
        "fuelConsumedperRunHour": 3.21,
        "fuelDrained": 3.71,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 37.71,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 44.29,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 8 min 24 sec"
    },
    {
        "_id": "631b41136164e127db3d9857",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-07-25 23:52:43",
        "city": "mohali",
        "closingFuelLevel": 78.14,
        "date": "2022-09-09T13:35:15.340Z",
        "deFuellingAmount": 0,
        "fuelAdded": 42.58,
        "fuelConsumed": 1.61,
        "fuelConsumedperRunHour": 6.13,
        "fuelDrained": 6.05,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 37.33,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 42.42,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 15 min 46 sec"
    },
    {
        "_id": "631b41136164e127db3d9858",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-09-02 23:37:30",
        "city": "mohali",
        "closingFuelLevel": 104.83,
        "date": "2022-09-09T13:35:15.332Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.52,
        "fuelConsumed": 0.47,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.99,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 105.3,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e1436931cf72",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-07-27 23:49:56",
        "city": "mohali",
        "closingFuelLevel": 78.04,
        "date": "2022-09-09T13:35:14.719Z",
        "deFuellingAmount": 0,
        "fuelAdded": 16.29,
        "fuelConsumed": 0.71,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 17,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 78.75,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e1430143979c",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-04-26 17:46:06",
        "city": "mohali",
        "closingFuelLevel": 56.9,
        "date": "2022-09-09T13:35:15.255Z",
        "deFuellingAmount": 0,
        "fuelAdded": 12.31,
        "fuelConsumed": 5.22,
        "fuelConsumedperRunHour": 2.22,
        "fuelDrained": 5.44,
        "fuelLessThanThreshold": 56.9,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 62.12,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "2 hr 21 min 7 sec"
    },
    {
        "_id": "631b41136164e1430143979d",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-04-26 16:24:57",
        "city": "mohali",
        "closingFuelLevel": 149.4,
        "date": "2022-09-09T13:35:15.255Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.2,
        "fuelConsumed": 0.9,
        "fuelConsumedperRunHour": 2.7,
        "fuelDrained": 2.82,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 150.3,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 19 min 59 sec"
    },
    {
        "_id": "631b41136164e1430143979e",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-09-06 23:46:45",
        "city": "mohali",
        "closingFuelLevel": 100.84,
        "date": "2022-09-09T13:35:15.299Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.42,
        "fuelConsumed": -0.13,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 5.29,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 100.71,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e1430143979f",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-09-06 23:35:24",
        "city": "mohali",
        "closingFuelLevel": 64.53,
        "date": "2022-09-09T13:35:15.299Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.65,
        "fuelConsumed": -0.21,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.44,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 64.32,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143014397a0",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-09-06 23:53:56",
        "city": "mohali",
        "closingFuelLevel": 34.71,
        "date": "2022-09-09T13:35:15.299Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.67,
        "fuelConsumed": 7.99,
        "fuelConsumedperRunHour": 3.69,
        "fuelDrained": 7.37,
        "fuelLessThanThreshold": 34.71,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 42.7,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "2 hr 9 min 52 sec"
    },
    {
        "_id": "631b41136164e143014397a1",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-06-17 23:56:15",
        "city": "mohali",
        "closingFuelLevel": 77.7,
        "date": "2022-09-09T13:35:15.337Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.65,
        "fuelConsumed": 3.46,
        "fuelConsumedperRunHour": 3.78,
        "fuelDrained": 5.91,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 81.16,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 54 min 51 sec"
    },
    {
        "_id": "631b41136164e143014397a2",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-06-17 23:46:31",
        "city": "mohali",
        "closingFuelLevel": 127.06,
        "date": "2022-09-09T13:35:15.337Z",
        "deFuellingAmount": 21.44,
        "fuelAdded": 24.09,
        "fuelConsumed": 5.93,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 31.86,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 19.6,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 134.83,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143503d1fd8",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-09-06 23:17:36",
        "city": "mohali",
        "closingFuelLevel": 102.16,
        "date": "2022-09-09T13:35:15.299Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.4,
        "fuelConsumed": 0.45,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.85,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 102.61,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143503d1fd9",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-04-26 19:37:44",
        "city": "mohali",
        "closingFuelLevel": 125.11,
        "date": "2022-09-09T13:35:15.255Z",
        "deFuellingAmount": 0,
        "fuelAdded": 22.09,
        "fuelConsumed": 31.62,
        "fuelConsumedperRunHour": 3.9,
        "fuelDrained": 5.58,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 156.73,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "8 hr 6 min 7 sec"
    },
    {
        "_id": "631b41136164e143503d1fda",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-04-26 00:55:51",
        "city": "mohali",
        "closingFuelLevel": 158.79,
        "date": "2022-09-09T13:35:15.255Z",
        "deFuellingAmount": 0,
        "fuelAdded": 0,
        "fuelConsumed": 0.79,
        "fuelConsumedperRunHour": 5.63,
        "fuelDrained": 0.1,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 159.58,
        "pincode": "160055",
        "siteId": "",
        "siteName": "",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 8 min 25 sec"
    },
    {
        "_id": "631b41136164e143503d1fdb",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-08-05 23:35:15",
        "city": "mohali",
        "closingFuelLevel": 83.23,
        "date": "2022-09-09T13:35:15.370Z",
        "deFuellingAmount": 0,
        "fuelAdded": 23.8,
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 2.6,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 21.2,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 62.03,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143503d1fdc",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-08-05 23:38:08",
        "city": "mohali",
        "closingFuelLevel": 58.83,
        "date": "2022-09-09T13:35:15.370Z",
        "deFuellingAmount": 0,
        "fuelAdded": 2.96,
        "fuelConsumed": -0.12,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 2.84,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 58.71,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143503d1fdd",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-08-05 23:48:20",
        "city": "mohali",
        "closingFuelLevel": 87.47,
        "date": "2022-09-09T13:35:15.370Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.74,
        "fuelConsumed": 2.35,
        "fuelConsumedperRunHour": 5.95,
        "fuelDrained": 4.85,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 89.82,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 23 min 42 sec"
    },
    {
        "_id": "631b41136164e143503d1fde",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-06-14 23:44:38",
        "city": "mohali",
        "closingFuelLevel": 60.25,
        "date": "2022-09-09T13:35:15.390Z",
        "deFuellingAmount": 0,
        "fuelAdded": 7.96,
        "fuelConsumed": 1.41,
        "fuelConsumedperRunHour": 4.08,
        "fuelDrained": 8.01,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 61.66,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 20 min 45 sec"
    },
    {
        "_id": "631b41136164e143503d1fdf",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-06-14 23:38:54",
        "city": "mohali",
        "closingFuelLevel": 135.59,
        "date": "2022-09-09T13:35:15.390Z",
        "deFuellingAmount": 13.46,
        "fuelAdded": 4.67,
        "fuelConsumed": 4.35,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 22.48,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 153.4,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143503d1fe0",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-06-14 23:48:08",
        "city": "mohali",
        "closingFuelLevel": 75.98,
        "date": "2022-09-09T13:35:15.390Z",
        "deFuellingAmount": 0,
        "fuelAdded": 44.52,
        "fuelConsumed": 0.8,
        "fuelConsumedperRunHour": 0.33,
        "fuelDrained": 5.78,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 38.45,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 38.33,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "2 hr 26 min 26 sec"
    },
    {
        "_id": "631b41136164e143503d1fe1",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-06-14 23:17:54",
        "city": "mohali",
        "closingFuelLevel": 118.01,
        "date": "2022-09-09T13:35:15.390Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.26,
        "fuelConsumed": -0.11,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.15,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 117.9,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143503d1fe2",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-05-14 23:34:29",
        "city": "mohali",
        "closingFuelLevel": 77.95,
        "date": "2022-09-09T13:35:15.361Z",
        "deFuellingAmount": 0,
        "fuelAdded": 8.1,
        "fuelConsumed": -0.25,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 7.85,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 77.7,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143766128ba",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-08-14 23:34:46",
        "city": "mohali",
        "closingFuelLevel": 42.59,
        "date": "2022-09-09T13:35:15.310Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.8,
        "fuelConsumed": 16.89,
        "fuelConsumedperRunHour": 2.81,
        "fuelDrained": 5.94,
        "fuelLessThanThreshold": 42.59,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 59.48,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "6 hr 0 min 32 sec"
    },
    {
        "_id": "631b41136164e143766128bb",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-08-14 23:19:18",
        "city": "mohali",
        "closingFuelLevel": 73.7,
        "date": "2022-09-09T13:35:15.310Z",
        "deFuellingAmount": 0,
        "fuelAdded": 1.79,
        "fuelConsumed": 3.12,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.91,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 76.82,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143766128bc",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-08-20 23:48:24",
        "city": "mohali",
        "closingFuelLevel": 100.06,
        "date": "2022-09-09T13:35:15.388Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.03,
        "fuelConsumed": 0.07,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.1,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 100.13,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143766128bd",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-08-20 23:19:31",
        "city": "mohali",
        "closingFuelLevel": 92.07,
        "date": "2022-09-09T13:35:15.388Z",
        "deFuellingAmount": 0,
        "fuelAdded": 44.88,
        "fuelConsumed": 2.79,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 5.28,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 42.39,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 52.47,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143766128be",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-08-20 23:43:33",
        "city": "mohali",
        "closingFuelLevel": 55.46,
        "date": "2022-09-09T13:35:15.388Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.92,
        "fuelConsumed": 2.47,
        "fuelConsumedperRunHour": 6.94,
        "fuelDrained": 5.3,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 57.93,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 21 min 22 sec"
    },
    {
        "_id": "631b41136164e143766128bf",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-06-27 23:55:07",
        "city": "mohali",
        "closingFuelLevel": 79.29,
        "date": "2022-09-09T13:35:15.389Z",
        "deFuellingAmount": 0,
        "fuelAdded": 47.69,
        "fuelConsumed": 1,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 7.7,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 40.99,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 39.3,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143766128c0",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-08-04 23:32:43",
        "city": "mohali",
        "closingFuelLevel": 62.08,
        "date": "2022-09-09T13:35:15.435Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.85,
        "fuelConsumed": -0.05,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.8,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 62.03,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143766128c1",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-05-05 23:39:20",
        "city": "mohali",
        "closingFuelLevel": 81.44,
        "date": "2022-09-09T13:35:14.718Z",
        "deFuellingAmount": 2.61,
        "fuelAdded": 12.83,
        "fuelConsumed": 6.93,
        "fuelConsumedperRunHour": 4.22,
        "fuelDrained": 9.51,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 90.98,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 38 min 28 sec"
    },
    {
        "_id": "631b41136164e143766128c2",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-05-05 23:39:00",
        "city": "mohali",
        "closingFuelLevel": 86.11,
        "date": "2022-09-09T13:35:14.718Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.44,
        "fuelConsumed": -0.17,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 6.27,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 85.94,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143766128c3",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-05-05 23:47:17",
        "city": "mohali",
        "closingFuelLevel": 82.36,
        "date": "2022-09-09T13:35:14.718Z",
        "deFuellingAmount": 0,
        "fuelAdded": 70.91,
        "fuelConsumed": 15.24,
        "fuelConsumedperRunHour": 3.64,
        "fuelDrained": 6.91,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 56.97,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 40.63,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "4 hr 11 min 8 sec"
    },
    {
        "_id": "631b41146164e143766128c4",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-05-05 23:44:03",
        "city": "mohali",
        "closingFuelLevel": 58.34,
        "date": "2022-09-09T13:35:14.718Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.55,
        "fuelConsumed": 1.05,
        "fuelConsumedperRunHour": 3.85,
        "fuelDrained": 4.07,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 59.39,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 16 min 21 sec"
    },
    {
        "_id": "631b41136164e10a67277d70",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-08-13 23:45:31",
        "city": "mohali",
        "closingFuelLevel": 100.39,
        "date": "2022-09-09T13:35:15.288Z",
        "deFuellingAmount": 0,
        "fuelAdded": 7.12,
        "fuelConsumed": 2.26,
        "fuelConsumedperRunHour": 4.68,
        "fuelDrained": 6.36,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 102.65,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 28 min 58 sec"
    },
    {
        "_id": "631b41136164e10a67277d71",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-08-13 23:16:45",
        "city": "mohali",
        "closingFuelLevel": 77.1,
        "date": "2022-09-09T13:35:15.288Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.28,
        "fuelConsumed": -0.05,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.23,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 77.05,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e10a67277d72",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-06-27 23:42:38",
        "city": "mohali",
        "closingFuelLevel": 42.61,
        "date": "2022-09-09T13:35:15.389Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.76,
        "fuelConsumed": 0.56,
        "fuelConsumedperRunHour": 2.42,
        "fuelDrained": 4.16,
        "fuelLessThanThreshold": 42.61,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 43.17,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 13 min 53 sec"
    },
    {
        "_id": "631b41136164e10a67277d73",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-06-27 23:57:26",
        "city": "mohali",
        "closingFuelLevel": 67.67,
        "date": "2022-09-09T13:35:15.389Z",
        "deFuellingAmount": 0,
        "fuelAdded": 62.3,
        "fuelConsumed": 26.7,
        "fuelConsumedperRunHour": 3.77,
        "fuelDrained": 8.86,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 54.37,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 40,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "7 hr 5 min 5 sec"
    },
    {
        "_id": "631b41136164e10a67277d74",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-06-27 23:50:03",
        "city": "mohali",
        "closingFuelLevel": 97.82,
        "date": "2022-09-09T13:35:15.389Z",
        "deFuellingAmount": 13.47,
        "fuelAdded": 3.54,
        "fuelConsumed": 2.4,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 19.41,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 113.69,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e10a67277d75",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-06-12 23:48:51",
        "city": "mohali",
        "closingFuelLevel": 97.47,
        "date": "2022-09-09T13:35:14.758Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.65,
        "fuelConsumed": 2.24,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 8.89,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 99.71,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e10a67277d76",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-07-25 23:54:33",
        "city": "mohali",
        "closingFuelLevel": 71.88,
        "date": "2022-09-09T13:35:15.340Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.56,
        "fuelConsumed": 1.43,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 7.99,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 73.31,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e10a67277d77",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-07-25 23:47:45",
        "city": "mohali",
        "closingFuelLevel": 102.58,
        "date": "2022-09-09T13:35:15.340Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.48,
        "fuelConsumed": 5.56,
        "fuelConsumedperRunHour": 3.98,
        "fuelDrained": 6.79,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 108.14,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 23 min 50 sec"
    },
    {
        "_id": "631b41136164e143613895b7",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-08-24 23:58:36",
        "city": "mohali",
        "closingFuelLevel": 100.52,
        "date": "2022-09-09T13:35:15.268Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.82,
        "fuelConsumed": -0.13,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.69,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 100.39,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e15b612096c8",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-07-20 23:38:17",
        "city": "mohali",
        "closingFuelLevel": 124.6,
        "date": "2022-09-09T13:35:15.346Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.48,
        "fuelConsumed": 4.94,
        "fuelConsumedperRunHour": 4.62,
        "fuelDrained": 5.63,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 129.54,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 4 min 6 sec"
    },
    {
        "_id": "631b41136164e143613895b8",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-08-24 23:44:42",
        "city": "mohali",
        "closingFuelLevel": 81.84,
        "date": "2022-09-09T13:35:15.268Z",
        "deFuellingAmount": 0,
        "fuelAdded": 2.26,
        "fuelConsumed": 4.57,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 6.83,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 86.41,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e15b612096c9",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-07-20 23:41:52",
        "city": "mohali",
        "closingFuelLevel": 85.76,
        "date": "2022-09-09T13:35:15.346Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.83,
        "fuelConsumed": 1.89,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 5.72,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 87.65,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143613895b9",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-07-28 23:35:08",
        "city": "mohali",
        "closingFuelLevel": 99.94,
        "date": "2022-09-09T13:35:15.357Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.43,
        "fuelConsumed": 1.3,
        "fuelConsumedperRunHour": 3.95,
        "fuelDrained": 4.72,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 101.24,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 19 min 46 sec"
    },
    {
        "_id": "631b41136164e15b612096ca",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-07-20 23:45:39",
        "city": "mohali",
        "closingFuelLevel": 66.56,
        "date": "2022-09-09T13:35:15.346Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.44,
        "fuelConsumed": 0.11,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.55,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 66.67,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143613895ba",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-07-28 23:52:28",
        "city": "mohali",
        "closingFuelLevel": 78.75,
        "date": "2022-09-09T13:35:15.357Z",
        "deFuellingAmount": 0,
        "fuelAdded": 12.16,
        "fuelConsumed": -0.71,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 11.45,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 78.04,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e15b612096cb",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-06-15 23:58:25",
        "city": "mohali",
        "closingFuelLevel": 49.77,
        "date": "2022-09-09T13:35:15.370Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.48,
        "fuelConsumed": 26.03,
        "fuelConsumedperRunHour": 4.04,
        "fuelDrained": 3.92,
        "fuelLessThanThreshold": 49.77,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 75.8,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "6 hr 26 min 11 sec"
    },
    {
        "_id": "631b41136164e15b612096cc",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-06-15 23:41:26",
        "city": "mohali",
        "closingFuelLevel": 135.18,
        "date": "2022-09-09T13:35:15.370Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.9,
        "fuelConsumed": 0.58,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 6.48,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 135.76,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e15b612096cd",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-05-31 23:54:41",
        "city": "mohali",
        "closingFuelLevel": 66.76,
        "date": "2022-09-09T13:35:14.685Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.57,
        "fuelConsumed": 3.7,
        "fuelConsumedperRunHour": 4.14,
        "fuelDrained": 6.97,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 70.46,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 53 min 36 sec"
    },
    {
        "_id": "631b41136164e15b612096ce",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-05-31 23:51:55",
        "city": "mohali",
        "closingFuelLevel": 45.53,
        "date": "2022-09-09T13:35:14.685Z",
        "deFuellingAmount": 0,
        "fuelAdded": 8.02,
        "fuelConsumed": 1.99,
        "fuelConsumedperRunHour": 5.1,
        "fuelDrained": 7.46,
        "fuelLessThanThreshold": 45.53,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 47.52,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 23 min 25 sec"
    },
    {
        "_id": "631b41136164e11ae51100b9",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-08-01 23:23:17",
        "city": "mohali",
        "closingFuelLevel": 65.7,
        "date": "2022-09-09T13:35:15.355Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.7,
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.7,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 65.7,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e11ae51100ba",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-08-01 23:57:17",
        "city": "mohali",
        "closingFuelLevel": 61.29,
        "date": "2022-09-09T13:35:15.355Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.19,
        "fuelConsumed": -0.12,
        "fuelConsumedperRunHour": -1.59,
        "fuelDrained": 3.83,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 61.17,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 4 min 32 sec"
    },
    {
        "_id": "631b41136164e11ae51100bb",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-04-27 15:59:01",
        "city": "mohali",
        "closingFuelLevel": 149.06,
        "date": "2022-09-09T13:35:15.320Z",
        "deFuellingAmount": 0,
        "fuelAdded": 0,
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 0,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 149.06,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e11ae51100bc",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-08-29 23:41:20",
        "city": "mohali",
        "closingFuelLevel": 100.65,
        "date": "2022-09-09T13:35:15.397Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.46,
        "fuelConsumed": 0.12,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.58,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 100.77,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e11ae51100bd",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-08-29 23:42:22",
        "city": "mohali",
        "closingFuelLevel": 72.21,
        "date": "2022-09-09T13:35:15.397Z",
        "deFuellingAmount": 21.36,
        "fuelAdded": 20.31,
        "fuelConsumed": 2.34,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 25.54,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 18.47,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 77.44,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e11ae51100be",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-07-15 23:44:10",
        "city": "mohali",
        "closingFuelLevel": 103.24,
        "date": "2022-09-09T13:35:14.673Z",
        "deFuellingAmount": 0,
        "fuelAdded": 45.08,
        "fuelConsumed": 1.81,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 5.9,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 40.99,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 64.06,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e11ae51100bf",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-07-15 23:55:49",
        "city": "mohali",
        "closingFuelLevel": 132.25,
        "date": "2022-09-09T13:35:14.673Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.88,
        "fuelConsumed": 0.39,
        "fuelConsumedperRunHour": 2.2,
        "fuelDrained": 3.26,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 132.64,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 10 min 39 sec"
    },
    {
        "_id": "631b41136164e11ae51100c0",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-07-04 23:53:40",
        "city": "mohali",
        "closingFuelLevel": 88.56,
        "date": "2022-09-09T13:35:14.695Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.76,
        "fuelConsumed": -0.45,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.31,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 88.11,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e11ae51100c1",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-07-04 23:59:04",
        "city": "mohali",
        "closingFuelLevel": 39.71,
        "date": "2022-09-09T13:35:14.695Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.56,
        "fuelConsumed": 1.1,
        "fuelConsumedperRunHour": 2.16,
        "fuelDrained": 4.51,
        "fuelLessThanThreshold": 39.71,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 40.81,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 30 min 37 sec"
    },
    {
        "_id": "631b41146164e11ae51100c2",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-07-04 23:31:15",
        "city": "mohali",
        "closingFuelLevel": 91.72,
        "date": "2022-09-09T13:35:14.695Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.61,
        "fuelConsumed": 7.16,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 10.77,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 98.88,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e1283f79e993",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-05-03 23:35:22",
        "city": "mohali",
        "closingFuelLevel": 52.59,
        "date": "2022-09-09T13:35:15.335Z",
        "deFuellingAmount": 2.31,
        "fuelAdded": 1.74,
        "fuelConsumed": 2.63,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 6.68,
        "fuelLessThanThreshold": 52.59,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 57.53,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e1283f79e994",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-05-03 23:44:46",
        "city": "mohali",
        "closingFuelLevel": 51.95,
        "date": "2022-09-09T13:35:15.335Z",
        "deFuellingAmount": 0,
        "fuelAdded": 7.26,
        "fuelConsumed": 1.21,
        "fuelConsumedperRunHour": 3.49,
        "fuelDrained": 5.93,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 53.16,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 20 min 47 sec"
    },
    {
        "_id": "631b41136164e1283f79e995",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-05-03 23:34:44",
        "city": "mohali",
        "closingFuelLevel": 113.58,
        "date": "2022-09-09T13:35:15.335Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.8,
        "fuelConsumed": 37.41,
        "fuelConsumedperRunHour": 4.58,
        "fuelDrained": 3.31,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 150.99,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "8 hr 9 min 55 sec"
    },
    {
        "_id": "631b41136164e1283f79e996",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-05-03 23:32:53",
        "city": "mohali",
        "closingFuelLevel": 70.25,
        "date": "2022-09-09T13:35:15.335Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.15,
        "fuelConsumed": 30.78,
        "fuelConsumedperRunHour": 5.27,
        "fuelDrained": 6.43,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 101.03,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "5 hr 50 min 22 sec"
    },
    {
        "_id": "631b41136164e146aa768c18",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-04-25 23:33:23",
        "city": "mohali",
        "closingFuelLevel": 62.41,
        "date": "2022-09-09T13:35:15.332Z",
        "deFuellingAmount": 0,
        "fuelAdded": 18.01,
        "fuelConsumed": 11.03,
        "fuelConsumedperRunHour": 5.26,
        "fuelDrained": 13.62,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 73.44,
        "pincode": "160103",
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "2 hr 5 min 48 sec"
    },
    {
        "_id": "631b41136164e146aa768c19",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-04-25 23:59:27",
        "city": "mohali",
        "closingFuelLevel": 156.79,
        "date": "2022-09-09T13:35:15.332Z",
        "deFuellingAmount": 0,
        "fuelAdded": 16.87,
        "fuelConsumed": 7.6,
        "fuelConsumedperRunHour": 3.58,
        "fuelDrained": 7.41,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 164.39,
        "pincode": "140603",
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "2 hr 7 min 16 sec"
    },
    {
        "_id": "631b41136164e146aa768c1a",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-04-25 23:35:58",
        "city": "mohali",
        "closingFuelLevel": 150.06,
        "date": "2022-09-09T13:35:15.332Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.21,
        "fuelConsumed": 0.89,
        "fuelConsumedperRunHour": 3.26,
        "fuelDrained": 4.96,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 150.95,
        "pincode": "140307",
        "siteId": "",
        "siteName": "",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 16 min 22 sec"
    },
    {
        "_id": "631b41136164e146aa768c1b",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-04-25 19:14:37",
        "city": "mohali",
        "closingFuelLevel": 189.01,
        "date": "2022-09-09T13:35:15.332Z",
        "deFuellingAmount": 0,
        "fuelAdded": 0.42,
        "fuelConsumed": -0.16,
        "fuelConsumedperRunHour": -2.87,
        "fuelDrained": 0,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 188.85,
        "pincode": "160055",
        "siteId": "",
        "siteName": "",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 3 min 21 sec"
    },
    {
        "_id": "631b41136164e146aa768c1c",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-09-03 23:39:05",
        "city": "mohali",
        "closingFuelLevel": 100.77,
        "date": "2022-09-09T13:35:15.390Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.87,
        "fuelConsumed": 0.13,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 100.9,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e146aa768c1d",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-09-03 23:40:03",
        "city": "mohali",
        "closingFuelLevel": 104.38,
        "date": "2022-09-09T13:35:15.390Z",
        "deFuellingAmount": 0,
        "fuelAdded": 2.99,
        "fuelConsumed": 0.34,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.33,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 104.72,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e146aa768c1e",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-08-19 23:45:50",
        "city": "mohali",
        "closingFuelLevel": 100.13,
        "date": "2022-09-09T13:35:14.725Z",
        "deFuellingAmount": 0,
        "fuelAdded": 2.91,
        "fuelConsumed": 0.19,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.1,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 100.32,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e146aa768c1f",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-08-19 23:32:01",
        "city": "mohali",
        "closingFuelLevel": 52.29,
        "date": "2022-09-09T13:35:14.725Z",
        "deFuellingAmount": 0,
        "fuelAdded": 2.29,
        "fuelConsumed": 3.05,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 5.34,
        "fuelLessThanThreshold": 52.29,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 55.34,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e1469f0693db",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-08-21 23:50:57",
        "city": "mohali",
        "closingFuelLevel": 100.45,
        "date": "2022-09-09T13:35:15.320Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.52,
        "fuelConsumed": -0.39,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.13,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 100.06,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e1469f0693dc",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-08-21 21:38:45",
        "city": "mohali",
        "closingFuelLevel": 54.14,
        "date": "2022-09-09T13:35:15.320Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.42,
        "fuelConsumed": 1.26,
        "fuelConsumedperRunHour": 3.23,
        "fuelDrained": 6.58,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 55.4,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 23 min 24 sec"
    },
    {
        "_id": "631b41136164e1469f0693dd",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-08-21 23:22:03",
        "city": "mohali",
        "closingFuelLevel": 91.78,
        "date": "2022-09-09T13:35:15.320Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.46,
        "fuelConsumed": 0.35,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.81,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 92.13,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e15c0e0abb92",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-08-01 23:39:05",
        "city": "mohali",
        "closingFuelLevel": 97.19,
        "date": "2022-09-09T13:35:15.355Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.38,
        "fuelConsumed": 9.5,
        "fuelConsumedperRunHour": 3.52,
        "fuelDrained": 3.23,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 106.69,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "2 hr 42 min 9 sec"
    },
    {
        "_id": "631b41136164e15c0e0abb93",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-06-17 20:48:02",
        "city": "mohali",
        "closingFuelLevel": 110.47,
        "date": "2022-09-09T13:35:15.337Z",
        "deFuellingAmount": 0,
        "fuelAdded": 1.48,
        "fuelConsumed": 3.48,
        "fuelConsumedperRunHour": 5.58,
        "fuelDrained": 1.23,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 113.95,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 37 min 24 sec"
    },
    {
        "_id": "631b41136164e15c0e0abb94",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-08-27 23:39:35",
        "city": "mohali",
        "closingFuelLevel": 58.74,
        "date": "2022-09-09T13:35:15.381Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.52,
        "fuelConsumed": 7.85,
        "fuelConsumedperRunHour": 4.21,
        "fuelDrained": 6.82,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 66.59,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 51 min 47 sec"
    },
    {
        "_id": "631b41136164e15c0e0abb95",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-08-27 23:36:13",
        "city": "mohali",
        "closingFuelLevel": 100.71,
        "date": "2022-09-09T13:35:15.381Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.81,
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.81,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 100.71,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e15c0e0abb96",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-08-27 23:37:17",
        "city": "mohali",
        "closingFuelLevel": 78.18,
        "date": "2022-09-09T13:35:15.381Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.03,
        "fuelConsumed": 0.85,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.88,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 79.03,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e15c0e0abb97",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-08-04 23:35:34",
        "city": "mohali",
        "closingFuelLevel": 58.83,
        "date": "2022-09-09T13:35:15.435Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.56,
        "fuelConsumed": 0.25,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.81,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 59.08,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e15c0e0abb98",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-08-04 23:53:34",
        "city": "mohali",
        "closingFuelLevel": 89.94,
        "date": "2022-09-09T13:35:15.435Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6,
        "fuelConsumed": 1.98,
        "fuelConsumedperRunHour": 3.15,
        "fuelDrained": 5.31,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 91.92,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 37 min 45 sec"
    },
    {
        "_id": "631b41146164e15c0e0abb99",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-08-04 23:23:56",
        "city": "mohali",
        "closingFuelLevel": 115.58,
        "date": "2022-09-09T13:35:15.435Z",
        "deFuellingAmount": 0,
        "fuelAdded": 64.51,
        "fuelConsumed": 0.88,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.93,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 61.46,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 55,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e146ad4532ad",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-08-12 23:44:17",
        "city": "mohali",
        "closingFuelLevel": 77.22,
        "date": "2022-09-09T13:35:15.339Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.24,
        "fuelConsumed": -0.34,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 2.9,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 76.88,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e146ad4532ae",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-08-05 23:41:31",
        "city": "mohali",
        "closingFuelLevel": 114.89,
        "date": "2022-09-09T13:35:15.370Z",
        "deFuellingAmount": 0,
        "fuelAdded": 1.56,
        "fuelConsumed": 0.87,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 2.43,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 115.76,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e146ad4532af",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-07-22 23:40:38",
        "city": "mohali",
        "closingFuelLevel": 114.16,
        "date": "2022-09-09T13:35:15.407Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.22,
        "fuelConsumed": 0.32,
        "fuelConsumedperRunHour": 1.72,
        "fuelDrained": 3.9,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 114.48,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 11 min 10 sec"
    },
    {
        "_id": "631b41136164e146ad4532b0",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-08-03 23:35:11",
        "city": "mohali",
        "closingFuelLevel": 92.03,
        "date": "2022-09-09T13:35:15.322Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.83,
        "fuelConsumed": 1.17,
        "fuelConsumedperRunHour": 3.7,
        "fuelDrained": 4.72,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 93.2,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 18 min 59 sec"
    },
    {
        "_id": "631b41136164e146ad4532b1",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-04-08 23:38:00",
        "city": "mohali",
        "closingFuelLevel": 237.02,
        "date": "2022-09-09T13:35:14.703Z",
        "deFuellingAmount": 0,
        "fuelAdded": 12.64,
        "fuelConsumed": 12.89,
        "fuelConsumedperRunHour": 20.83,
        "fuelDrained": 3.63,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 10.39,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 239.52,
        "pincode": "140603",
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 37 min 8 sec"
    },
    {
        "_id": "631b41136164e146ad4532b2",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-05-19 23:49:41",
        "city": "mohali",
        "closingFuelLevel": 80.17,
        "date": "2022-09-09T13:35:14.709Z",
        "deFuellingAmount": 0,
        "fuelAdded": 8.23,
        "fuelConsumed": 1.12,
        "fuelConsumedperRunHour": 3.46,
        "fuelDrained": 8.67,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 81.29,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 19 min 26 sec"
    },
    {
        "_id": "631b41136164e146ad4532b3",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-05-19 23:47:19",
        "city": "mohali",
        "closingFuelLevel": 71.37,
        "date": "2022-09-09T13:35:14.709Z",
        "deFuellingAmount": 0,
        "fuelAdded": 7.86,
        "fuelConsumed": 1.3,
        "fuelConsumedperRunHour": 3.34,
        "fuelDrained": 6.67,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 72.67,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 23 min 22 sec"
    },
    {
        "_id": "631b41136164e146ac337770",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-07-14 23:58:05",
        "city": "mohali",
        "closingFuelLevel": 60.52,
        "date": "2022-09-09T13:35:15.301Z",
        "deFuellingAmount": 0,
        "fuelAdded": 1.36,
        "fuelConsumed": 22.01,
        "fuelConsumedperRunHour": 5.67,
        "fuelDrained": 1.26,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 82.53,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "3 hr 52 min 47 sec"
    },
    {
        "_id": "631b41136164e1430360a03e",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-04-19 23:17:14",
        "city": "mohali",
        "closingFuelLevel": 178.19,
        "date": "2022-09-09T13:35:15.340Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.13,
        "fuelConsumed": 2.67,
        "fuelConsumedperRunHour": 5.1,
        "fuelDrained": 4.42,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 180.86,
        "pincode": "140307",
        "siteId": "",
        "siteName": "",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 31 min 25 sec"
    },
    {
        "_id": "631b41136164e142e070fb33",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-07-20 23:34:16",
        "city": "mohali",
        "closingFuelLevel": 52.94,
        "date": "2022-09-09T13:35:15.346Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.48,
        "fuelConsumed": 1.17,
        "fuelConsumedperRunHour": 3.39,
        "fuelDrained": 4.75,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 54.11,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 20 min 42 sec"
    },
    {
        "_id": "631b41136164e142df16c791",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-07-29 23:53:34",
        "city": "mohali",
        "closingFuelLevel": 113.05,
        "date": "2022-09-09T13:35:15.351Z",
        "deFuellingAmount": 0,
        "fuelAdded": 22.9,
        "fuelConsumed": 3.82,
        "fuelConsumedperRunHour": 3.76,
        "fuelDrained": 5.02,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 17.04,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 99.83,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 0 min 58 sec"
    },
    {
        "_id": "631b41136164e1469b5e6c79",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-07-28 23:40:18",
        "city": "mohali",
        "closingFuelLevel": 72.42,
        "date": "2022-09-09T13:35:15.357Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.41,
        "fuelConsumed": -0.31,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.1,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 72.11,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e146ac337771",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-04-12 23:59:50",
        "city": "mohali",
        "closingFuelLevel": 180,
        "date": "2022-09-09T13:35:15.446Z",
        "deFuellingAmount": 0,
        "fuelAdded": 7.96,
        "fuelConsumed": 29.07,
        "fuelConsumedperRunHour": 4.14,
        "fuelDrained": 7.16,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 209.07,
        "pincode": "160103",
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "7 hr 0 min 49 sec"
    },
    {
        "_id": "631b41136164e1469b5e6c7a",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-07-28 23:36:12",
        "city": "mohali",
        "closingFuelLevel": 93.68,
        "date": "2022-09-09T13:35:15.357Z",
        "deFuellingAmount": 11.06,
        "fuelAdded": 42.99,
        "fuelConsumed": 3.3,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 17.32,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 40.03,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 68.01,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e142e070fb34",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-08-25 23:37:58",
        "city": "mohali",
        "closingFuelLevel": 75.17,
        "date": "2022-09-09T13:35:15.428Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.33,
        "fuelConsumed": 6.57,
        "fuelConsumedperRunHour": 4.26,
        "fuelDrained": 6,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 81.74,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 32 min 36 sec"
    },
    {
        "_id": "631b41136164e142df16c792",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-07-29 23:38:45",
        "city": "mohali",
        "closingFuelLevel": 75.06,
        "date": "2022-09-09T13:35:15.351Z",
        "deFuellingAmount": 16.45,
        "fuelAdded": 1.38,
        "fuelConsumed": 2,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 19.83,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 93.51,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e146ac337772",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-04-12 23:48:21",
        "city": "mohali",
        "closingFuelLevel": 232.68,
        "date": "2022-09-09T13:35:15.446Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.94,
        "fuelConsumed": -0.05,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.89,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 232.63,
        "pincode": "160055",
        "siteId": "",
        "siteName": "",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e1469b5e6c7b",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-05-10 23:36:41",
        "city": "mohali",
        "closingFuelLevel": 86.67,
        "date": "2022-09-09T13:35:14.722Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.1,
        "fuelConsumed": -0.17,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 5.93,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 86.5,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e146ae5acf31",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-07-15 23:36:11",
        "city": "mohali",
        "closingFuelLevel": 81.4,
        "date": "2022-09-09T13:35:14.673Z",
        "deFuellingAmount": 0,
        "fuelAdded": 44.96,
        "fuelConsumed": 36.29,
        "fuelConsumedperRunHour": 4.69,
        "fuelDrained": 6.15,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 40.67,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 77.02,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "7 hr 44 min 38 sec"
    },
    {
        "_id": "631b41136164e14683239f43",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-04-08 23:50:51",
        "city": "mohali",
        "closingFuelLevel": 268.81,
        "date": "2022-09-09T13:35:14.703Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.42,
        "fuelConsumed": -0.32,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.1,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 268.49,
        "pincode": "140307",
        "siteId": "",
        "siteName": "",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e146ae5acf32",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-07-15 23:54:18",
        "city": "mohali",
        "closingFuelLevel": 72.71,
        "date": "2022-09-09T13:35:14.673Z",
        "deFuellingAmount": 0,
        "fuelAdded": 42.56,
        "fuelConsumed": 25.34,
        "fuelConsumedperRunHour": 4.95,
        "fuelDrained": 4.08,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 37.69,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 60.36,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "5 hr 7 min 4 sec"
    },
    {
        "_id": "631b41136164e14683239f44",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-04-08 23:36:05",
        "city": "mohali",
        "closingFuelLevel": 232.84,
        "date": "2022-09-09T13:35:14.703Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.75,
        "fuelConsumed": 7,
        "fuelConsumedperRunHour": 2.15,
        "fuelDrained": 4.21,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 239.84,
        "pincode": "160055",
        "siteId": "",
        "siteName": "",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "3 hr 15 min 9 sec"
    },
    {
        "_id": "631b41136164e146ae5acf33",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-09-07 23:39:52",
        "city": "mohali",
        "closingFuelLevel": 34.3,
        "date": "2022-09-09T13:35:15.395Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.66,
        "fuelConsumed": 0.35,
        "fuelConsumedperRunHour": 2.99,
        "fuelDrained": 5.89,
        "fuelLessThanThreshold": 34.3,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 34.65,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 7 min 2 sec"
    },
    {
        "_id": "631b41136164e14683239f45",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-07-03 23:41:16",
        "city": "mohali",
        "closingFuelLevel": 40.93,
        "date": "2022-09-09T13:35:14.720Z",
        "deFuellingAmount": 0,
        "fuelAdded": 7,
        "fuelConsumed": 5.09,
        "fuelConsumedperRunHour": 2.28,
        "fuelDrained": 6.5,
        "fuelLessThanThreshold": 40.93,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 46.02,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "2 hr 14 min 1 sec"
    },
    {
        "_id": "631b41136164e146ae5acf34",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-09-07 23:20:09",
        "city": "mohali",
        "closingFuelLevel": 102.22,
        "date": "2022-09-09T13:35:15.395Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.59,
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.59,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 102.22,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e14683239f46",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-07-03 23:58:46",
        "city": "mohali",
        "closingFuelLevel": 99.06,
        "date": "2022-09-09T13:35:14.720Z",
        "deFuellingAmount": 0,
        "fuelAdded": 66.04,
        "fuelConsumed": 3.09,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 9.25,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 59.88,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 42.27,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e146ae5acf35",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-06-19 23:37:04",
        "city": "mohali",
        "closingFuelLevel": 77.52,
        "date": "2022-09-09T13:35:14.740Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.51,
        "fuelConsumed": -0.19,
        "fuelConsumedperRunHour": -2.31,
        "fuelDrained": 4.01,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 77.33,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 4 min 56 sec"
    },
    {
        "_id": "631b41146164e146ae5acf36",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-06-08 23:38:41",
        "city": "mohali",
        "closingFuelLevel": 68.92,
        "date": "2022-09-09T13:35:14.726Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.98,
        "fuelConsumed": 0.4,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 7.38,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 69.32,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41146164e146ae5acf37",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-06-08 23:43:31",
        "city": "mohali",
        "closingFuelLevel": 81.93,
        "date": "2022-09-09T13:35:14.726Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.25,
        "fuelConsumed": 0.03,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.28,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 81.96,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e1473476328d",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-06-05 23:58:11",
        "city": "mohali",
        "closingFuelLevel": 37.07,
        "date": "2022-09-09T13:35:14.748Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.33,
        "fuelConsumed": 11.55,
        "fuelConsumedperRunHour": 4.26,
        "fuelDrained": 9.71,
        "fuelLessThanThreshold": 37.07,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 48.62,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "2 hr 42 min 33 sec"
    },
    {
        "_id": "631b41136164e146801944f9",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-09-07 23:36:47",
        "city": "mohali",
        "closingFuelLevel": 57.81,
        "date": "2022-09-09T13:35:15.395Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.59,
        "fuelConsumed": 6.62,
        "fuelConsumedperRunHour": 5.33,
        "fuelDrained": 4.33,
        "fuelLessThanThreshold": 57.81,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 64.43,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 14 min 28 sec"
    },
    {
        "_id": "631b41136164e1473476328e",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-06-05 23:49:29",
        "city": "mohali",
        "closingFuelLevel": 74.04,
        "date": "2022-09-09T13:35:14.748Z",
        "deFuellingAmount": 0,
        "fuelAdded": 8.06,
        "fuelConsumed": 0.49,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 8.55,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 74.53,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e1473476328f",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-06-05 23:46:05",
        "city": "mohali",
        "closingFuelLevel": 76.76,
        "date": "2022-09-09T13:35:14.748Z",
        "deFuellingAmount": 2.85,
        "fuelAdded": 9.78,
        "fuelConsumed": -1.14,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 11.49,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 78.47,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e14734763290",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-05-30 23:58:35",
        "city": "mohali",
        "closingFuelLevel": 47.58,
        "date": "2022-09-09T13:35:14.683Z",
        "deFuellingAmount": 0,
        "fuelAdded": 7.47,
        "fuelConsumed": 0.74,
        "fuelConsumedperRunHour": 3.01,
        "fuelDrained": 6.85,
        "fuelLessThanThreshold": 47.58,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 48.32,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 14 min 44 sec"
    },
    {
        "_id": "631b41136164e14734763291",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-05-30 23:55:08",
        "city": "mohali",
        "closingFuelLevel": 70.57,
        "date": "2022-09-09T13:35:14.683Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.39,
        "fuelConsumed": 0.46,
        "fuelConsumedperRunHour": 13.69,
        "fuelDrained": 5.85,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 71.03,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 2 min 1 sec"
    },
    {
        "_id": "631b41136164e142da57cdf0",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-08-19 23:39:08",
        "city": "mohali",
        "closingFuelLevel": 57.99,
        "date": "2022-09-09T13:35:14.725Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.78,
        "fuelConsumed": 5.83,
        "fuelConsumedperRunHour": 4.09,
        "fuelDrained": 5.31,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 63.82,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 25 min 32 sec"
    },
    {
        "_id": "631b41136164e142da57cdf1",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-04-07 23:31:30",
        "city": "mohali",
        "closingFuelLevel": 268.68,
        "date": "2022-09-09T13:35:14.703Z",
        "deFuellingAmount": 269.87,
        "fuelAdded": 273.77,
        "fuelConsumed": 0.88,
        "fuelConsumedperRunHour": 10.6,
        "fuelDrained": 274.27,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 269.56,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 269.87,
        "pincode": "140307",
        "siteId": "",
        "siteName": "",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 4 min 59 sec"
    },
    {
        "_id": "631b41136164e142da57cdf2",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-04-07 23:39:13",
        "city": "mohali",
        "closingFuelLevel": 240.01,
        "date": "2022-09-09T13:35:14.703Z",
        "deFuellingAmount": 0,
        "fuelAdded": 240.72,
        "fuelConsumed": 0.17,
        "fuelConsumedperRunHour": 0.54,
        "fuelDrained": 0.53,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 240.18,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 0,
        "pincode": "160055",
        "siteId": "",
        "siteName": "",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 18 min 59 sec"
    },
    {
        "_id": "631b41136164e143711ab3df",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-05-31 15:23:29",
        "city": "mohali",
        "closingFuelLevel": 0,
        "date": "2022-09-09T13:35:14.685Z",
        "deFuellingAmount": 66.34,
        "fuelAdded": 3.54,
        "fuelConsumed": -0.53,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 69.35,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 65.81,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143711ab3e0",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-05-10 23:55:57",
        "city": "mohali",
        "closingFuelLevel": 57.55,
        "date": "2022-09-09T13:35:14.722Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.96,
        "fuelConsumed": 0.36,
        "fuelConsumedperRunHour": 4.14,
        "fuelDrained": 4.58,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 57.91,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 5 min 13 sec"
    },
    {
        "_id": "631b41136164e143711ab3e1",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-06-24 23:43:05",
        "city": "mohali",
        "closingFuelLevel": 57.67,
        "date": "2022-09-09T13:35:15.407Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.87,
        "fuelConsumed": 3.43,
        "fuelConsumedperRunHour": 3.08,
        "fuelDrained": 6.38,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 61.1,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 6 min 52 sec"
    },
    {
        "_id": "631b41136164e143711ab3e2",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-05-16 23:54:36",
        "city": "mohali",
        "closingFuelLevel": 22.96,
        "date": "2022-09-09T13:35:14.708Z",
        "deFuellingAmount": 7.49,
        "fuelAdded": 8.39,
        "fuelConsumed": 3.75,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 19.63,
        "fuelLessThanThreshold": 22.96,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 34.2,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41146164e143711ab3e3",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-05-16 23:34:51",
        "city": "mohali",
        "closingFuelLevel": 98.43,
        "date": "2022-09-09T13:35:14.708Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.47,
        "fuelConsumed": 1.23,
        "fuelConsumedperRunHour": 4.25,
        "fuelDrained": 6.52,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 99.66,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 17 min 23 sec"
    },
    {
        "_id": "631b41146164e143711ab3e4",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-06-19 23:59:24",
        "city": "mohali",
        "closingFuelLevel": 61.47,
        "date": "2022-09-09T13:35:14.740Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.8,
        "fuelConsumed": 0.35,
        "fuelConsumedperRunHour": 3.02,
        "fuelDrained": 4.68,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 61.82,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 6 min 57 sec"
    },
    {
        "_id": "631b41136164e1469247cfa9",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-08-30 23:14:53",
        "city": "mohali",
        "closingFuelLevel": 65.74,
        "date": "2022-09-09T13:35:15.371Z",
        "deFuellingAmount": 0,
        "fuelAdded": 1.95,
        "fuelConsumed": 6.21,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 8.16,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 71.95,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e1469247cfaa",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-06-05 19:04:59",
        "city": "mohali",
        "closingFuelLevel": 82.59,
        "date": "2022-09-09T13:35:14.748Z",
        "deFuellingAmount": 0,
        "fuelAdded": 1.77,
        "fuelConsumed": -0.69,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 1.08,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 81.9,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e1469247cfab",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-09-04 23:40:29",
        "city": "mohali",
        "closingFuelLevel": 43.68,
        "date": "2022-09-09T13:35:15.423Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.51,
        "fuelConsumed": 0.29,
        "fuelConsumedperRunHour": 2.17,
        "fuelDrained": 5.12,
        "fuelLessThanThreshold": 43.68,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 43.97,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 8 min 0 sec"
    },
    {
        "_id": "631b41146164e1469247cfac",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-09-04 23:41:38",
        "city": "mohali",
        "closingFuelLevel": 100.9,
        "date": "2022-09-09T13:35:15.423Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.62,
        "fuelConsumed": -0.19,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.43,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 100.71,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41146164e1469247cfad",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-09-04 23:27:34",
        "city": "mohali",
        "closingFuelLevel": 104.26,
        "date": "2022-09-09T13:35:15.423Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.14,
        "fuelConsumed": 0.4,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.54,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 104.66,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41146164e1469247cfae",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-09-04 23:30:20",
        "city": "mohali",
        "closingFuelLevel": 64.64,
        "date": "2022-09-09T13:35:15.423Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.52,
        "fuelConsumed": -0.42,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.1,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 64.22,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e142e070fb35",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-08-25 23:46:08",
        "city": "mohali",
        "closingFuelLevel": 100.71,
        "date": "2022-09-09T13:35:15.428Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.24,
        "fuelConsumed": -0.26,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.98,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 100.45,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e142e070fb36",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-08-25 23:17:12",
        "city": "mohali",
        "closingFuelLevel": 79.49,
        "date": "2022-09-09T13:35:15.428Z",
        "deFuellingAmount": 0,
        "fuelAdded": 2.74,
        "fuelConsumed": 2.52,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 5.26,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 82.01,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e142e070fb37",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-06-30 23:56:11",
        "city": "mohali",
        "closingFuelLevel": 56.2,
        "date": "2022-09-09T13:35:14.729Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.28,
        "fuelConsumed": 8.59,
        "fuelConsumedperRunHour": 3.99,
        "fuelDrained": 4.98,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 64.79,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "2 hr 9 min 19 sec"
    },
    {
        "_id": "631b41136164e142e070fb38",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-06-30 23:58:55",
        "city": "mohali",
        "closingFuelLevel": 95.45,
        "date": "2022-09-09T13:35:14.729Z",
        "deFuellingAmount": 0,
        "fuelAdded": 41.26,
        "fuelConsumed": 14.64,
        "fuelConsumedperRunHour": 4.1,
        "fuelDrained": 3.69,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 38.54,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 71.55,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "3 hr 34 min 6 sec"
    },
    {
        "_id": "631b41146164e142e070fb39",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-05-26 23:47:34",
        "city": "mohali",
        "closingFuelLevel": 76.84,
        "date": "2022-09-09T13:35:14.743Z",
        "deFuellingAmount": 0,
        "fuelAdded": 46.13,
        "fuelConsumed": 1.49,
        "fuelConsumedperRunHour": 16.97,
        "fuelDrained": 5.38,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 41.84,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 36.49,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 5 min 16 sec"
    },
    {
        "_id": "631b41136164e142df16c793",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-07-29 23:39:58",
        "city": "mohali",
        "closingFuelLevel": 78.39,
        "date": "2022-09-09T13:35:15.351Z",
        "deFuellingAmount": 0,
        "fuelAdded": 8.94,
        "fuelConsumed": 0.36,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 9.3,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 78.75,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e1558b34ae6c",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-07-05 23:56:12",
        "city": "mohali",
        "closingFuelLevel": 88.56,
        "date": "2022-09-09T13:35:15.394Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.28,
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.28,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 88.56,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e1558b34ae6d",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-05-29 23:34:15",
        "city": "mohali",
        "closingFuelLevel": 65.7,
        "date": "2022-09-09T13:35:14.745Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.01,
        "fuelConsumed": -0.16,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 5.85,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 65.54,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e142df16c794",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-07-29 23:42:51",
        "city": "mohali",
        "closingFuelLevel": 72.42,
        "date": "2022-09-09T13:35:15.351Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.29,
        "fuelConsumed": -0.12,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.17,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 72.3,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e1558b34ae6e",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-05-18 23:59:40",
        "city": "mohali",
        "closingFuelLevel": 62.65,
        "date": "2022-09-09T13:35:14.414Z",
        "deFuellingAmount": 0,
        "fuelAdded": 7.06,
        "fuelConsumed": 2.63,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 9.69,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 65.28,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e142df16c795",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-08-26 23:33:39",
        "city": "mohali",
        "closingFuelLevel": 100.84,
        "date": "2022-09-09T13:35:15.440Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.32,
        "fuelConsumed": -0.13,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.19,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 100.71,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e1558b34ae6f",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-06-08 23:46:02",
        "city": "mohali",
        "closingFuelLevel": 64.72,
        "date": "2022-09-09T13:35:14.726Z",
        "deFuellingAmount": 0,
        "fuelAdded": 10.76,
        "fuelConsumed": 4.97,
        "fuelConsumedperRunHour": 4.14,
        "fuelDrained": 8.92,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 69.69,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 12 min 4 sec"
    },
    {
        "_id": "631b41136164e142df16c796",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-08-26 23:46:08",
        "city": "mohali",
        "closingFuelLevel": 66.76,
        "date": "2022-09-09T13:35:15.440Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.21,
        "fuelConsumed": 8.76,
        "fuelConsumedperRunHour": 4.48,
        "fuelDrained": 7.26,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 75.52,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 57 min 19 sec"
    },
    {
        "_id": "631b41146164e1558b34ae70",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-06-19 11:03:05",
        "city": "mohali",
        "closingFuelLevel": 110.53,
        "date": "2022-09-09T13:35:14.740Z",
        "deFuellingAmount": 0,
        "fuelAdded": 0.73,
        "fuelConsumed": 0.1,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 0.83,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 110.63,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41146164e142df16c797",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-05-19 23:38:55",
        "city": "mohali",
        "closingFuelLevel": 65.7,
        "date": "2022-09-09T13:35:14.709Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.21,
        "fuelConsumed": -0.11,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 6.1,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 65.59,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41146164e142df16c798",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-06-18 23:45:49",
        "city": "mohali",
        "closingFuelLevel": 77.33,
        "date": "2022-09-09T13:35:14.727Z",
        "deFuellingAmount": 0,
        "fuelAdded": 2.46,
        "fuelConsumed": 0.31,
        "fuelConsumedperRunHour": 4.15,
        "fuelDrained": 2.46,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 77.64,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 4 min 29 sec"
    },
    {
        "_id": "631b41136164e146ac337773",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-04-12 23:44:51",
        "city": "mohali",
        "closingFuelLevel": 229.36,
        "date": "2022-09-09T13:35:15.446Z",
        "deFuellingAmount": 0,
        "fuelAdded": 14.17,
        "fuelConsumed": 5.2,
        "fuelConsumedperRunHour": 4.17,
        "fuelDrained": 5.85,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 234.56,
        "pincode": "140603",
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 14 min 53 sec"
    },
    {
        "_id": "631b41136164e146ac337774",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-04-12 23:55:17",
        "city": "mohali",
        "closingFuelLevel": 257.63,
        "date": "2022-09-09T13:35:15.446Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.21,
        "fuelConsumed": 7.09,
        "fuelConsumedperRunHour": 4.63,
        "fuelDrained": 5.02,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 264.72,
        "pincode": "140307",
        "siteId": "",
        "siteName": "",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 31 min 52 sec"
    },
    {
        "_id": "631b41136164e16bc02c320b",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-06-12 23:57:36",
        "city": "mohali",
        "closingFuelLevel": 72.61,
        "date": "2022-09-09T13:35:14.758Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.97,
        "fuelConsumed": 2.11,
        "fuelConsumedperRunHour": 3.6,
        "fuelDrained": 6.54,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 74.72,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 35 min 12 sec"
    },
    {
        "_id": "631b41136164e16bc02c320c",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-06-12 23:45:31",
        "city": "mohali",
        "closingFuelLevel": 47.76,
        "date": "2022-09-09T13:35:14.758Z",
        "deFuellingAmount": 0,
        "fuelAdded": 9.04,
        "fuelConsumed": 24.65,
        "fuelConsumedperRunHour": 4.11,
        "fuelDrained": 10.29,
        "fuelLessThanThreshold": 47.76,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 72.41,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "5 hr 59 min 39 sec"
    },
    {
        "_id": "631b41136164e16bc02c320d",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-06-12 15:01:03",
        "city": "mohali",
        "closingFuelLevel": 118.17,
        "date": "2022-09-09T13:35:14.758Z",
        "deFuellingAmount": 0,
        "fuelAdded": 0.8,
        "fuelConsumed": -0.48,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 0.32,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 117.69,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41146164e16bc02c320e",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-08-09 23:41:53",
        "city": "mohali",
        "closingFuelLevel": 55.4,
        "date": "2022-09-09T13:35:14.698Z",
        "deFuellingAmount": 0,
        "fuelAdded": 7.58,
        "fuelConsumed": 8.25,
        "fuelConsumedperRunHour": 2.37,
        "fuelDrained": 6.97,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 63.65,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "3 hr 28 min 50 sec"
    },
    {
        "_id": "631b41146164e16bc02c320f",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-08-09 23:21:37",
        "city": "mohali",
        "closingFuelLevel": 95.47,
        "date": "2022-09-09T13:35:14.698Z",
        "deFuellingAmount": 5.21,
        "fuelAdded": 2.6,
        "fuelConsumed": 0.06,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 7.87,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 100.74,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41146164e16bc02c3210",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-07-04 23:48:30",
        "city": "mohali",
        "closingFuelLevel": 101.46,
        "date": "2022-09-09T13:35:14.695Z",
        "deFuellingAmount": 0,
        "fuelAdded": 56.19,
        "fuelConsumed": 37.66,
        "fuelConsumedperRunHour": 3.87,
        "fuelDrained": 3.77,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 53.71,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 85.41,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "9 hr 44 min 0 sec"
    },
    {
        "_id": "631b41136164e1469b5e6c7c",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-09-03 23:42:50",
        "city": "mohali",
        "closingFuelLevel": 64.32,
        "date": "2022-09-09T13:35:15.390Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.2,
        "fuelConsumed": 0.06,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.26,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 64.38,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e1469b5e6c7d",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-09-03 23:45:43",
        "city": "mohali",
        "closingFuelLevel": 44.02,
        "date": "2022-09-09T13:35:15.390Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.78,
        "fuelConsumed": 0.35,
        "fuelConsumedperRunHour": 4.45,
        "fuelDrained": 4.61,
        "fuelLessThanThreshold": 44.02,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 44.37,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 4 min 43 sec"
    },
    {
        "_id": "631b41136164e127db3d9859",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-09-02 23:40:17",
        "city": "mohali",
        "closingFuelLevel": 64.43,
        "date": "2022-09-09T13:35:15.332Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.51,
        "fuelConsumed": -0.21,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.3,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 64.22,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e127db3d985a",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-05-02 23:33:34",
        "city": "mohali",
        "closingFuelLevel": 53.16,
        "date": "2022-09-09T13:35:14.687Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.19,
        "fuelConsumed": 0.46,
        "fuelConsumedperRunHour": 7.39,
        "fuelDrained": 5.24,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 53.62,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 3 min 44 sec"
    },
    {
        "_id": "631b41146164e127db3d985b",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-05-02 23:39:22",
        "city": "mohali",
        "closingFuelLevel": 151.1,
        "date": "2022-09-09T13:35:14.687Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.34,
        "fuelConsumed": 0.38,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.72,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 151.48,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41146164e127db3d985c",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-05-02 23:24:37",
        "city": "mohali",
        "closingFuelLevel": 101.29,
        "date": "2022-09-09T13:35:14.687Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.55,
        "fuelConsumed": 24.42,
        "fuelConsumedperRunHour": 4.99,
        "fuelDrained": 5.39,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 125.71,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "4 hr 53 min 37 sec"
    },
    {
        "_id": "631b41146164e127db3d985d",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-05-02 23:32:49",
        "city": "mohali",
        "closingFuelLevel": 57.7,
        "date": "2022-09-09T13:35:14.687Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.92,
        "fuelConsumed": 2.54,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 6.46,
        "fuelLessThanThreshold": 57.7,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 60.24,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e146b23e9be3",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-06-15 22:58:07",
        "city": "mohali",
        "closingFuelLevel": 114.42,
        "date": "2022-09-09T13:35:15.370Z",
        "deFuellingAmount": 0,
        "fuelAdded": 2.99,
        "fuelConsumed": 3.7,
        "fuelConsumedperRunHour": 5.95,
        "fuelDrained": 2.93,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 118.12,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 37 min 20 sec"
    },
    {
        "_id": "631b41136164e1467b52b50b",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-05-26 23:56:42",
        "city": "mohali",
        "closingFuelLevel": 65.32,
        "date": "2022-09-09T13:35:14.743Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.66,
        "fuelConsumed": -0.21,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 5.45,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 65.11,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41146164e1467b52b50c",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-06-30 23:51:08",
        "city": "mohali",
        "closingFuelLevel": 53.06,
        "date": "2022-09-09T13:35:14.729Z",
        "deFuellingAmount": 12.27,
        "fuelAdded": 3.99,
        "fuelConsumed": 2.8,
        "fuelConsumedperRunHour": 54.19,
        "fuelDrained": 19,
        "fuelLessThanThreshold": 53.06,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 68.13,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 3 min 6 sec"
    },
    {
        "_id": "631b41146164e1467b52b50d",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-05-14 23:49:31",
        "city": "mohali",
        "closingFuelLevel": 35.06,
        "date": "2022-09-09T13:35:15.361Z",
        "deFuellingAmount": 2.2,
        "fuelAdded": 9.87,
        "fuelConsumed": 2.61,
        "fuelConsumedperRunHour": 9.88,
        "fuelDrained": 13.57,
        "fuelLessThanThreshold": 35.06,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 39.87,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 15 min 51 sec"
    },
    {
        "_id": "631b41136164e11f1c7ba051",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-09-05 23:44:12",
        "city": "mohali",
        "closingFuelLevel": 100.84,
        "date": "2022-09-09T13:35:15.375Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.21,
        "fuelConsumed": -0.07,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 5.14,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 100.77,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41146164e11f1c7ba052",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-09-05 23:30:05",
        "city": "mohali",
        "closingFuelLevel": 102.84,
        "date": "2022-09-09T13:35:15.375Z",
        "deFuellingAmount": 0,
        "fuelAdded": 2.32,
        "fuelConsumed": 1.48,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.8,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 104.32,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e143503d1fe3",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-05-14 23:56:17",
        "city": "mohali",
        "closingFuelLevel": 65.81,
        "date": "2022-09-09T13:35:15.361Z",
        "deFuellingAmount": 0,
        "fuelAdded": 7.85,
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 7.85,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 65.81,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41146164e143503d1fe4",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-05-14 23:35:36",
        "city": "mohali",
        "closingFuelLevel": 100.67,
        "date": "2022-09-09T13:35:15.361Z",
        "deFuellingAmount": 0,
        "fuelAdded": 7.48,
        "fuelConsumed": 6.6,
        "fuelConsumedperRunHour": 3.77,
        "fuelDrained": 7.18,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 107.27,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 44 min 56 sec"
    },
    {
        "_id": "631b41146164e143503d1fe5",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-06-08 23:44:24",
        "city": "mohali",
        "closingFuelLevel": 57.3,
        "date": "2022-09-09T13:35:14.726Z",
        "deFuellingAmount": 0,
        "fuelAdded": 10.58,
        "fuelConsumed": 6.11,
        "fuelConsumedperRunHour": 4.18,
        "fuelDrained": 10.04,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 63.41,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 27 min 37 sec"
    },
    {
        "_id": "631b41146164e143503d1fe6",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-06-19 23:47:23",
        "city": "mohali",
        "closingFuelLevel": 106.13,
        "date": "2022-09-09T13:35:14.740Z",
        "deFuellingAmount": 7.92,
        "fuelAdded": 6.81,
        "fuelConsumed": 7.67,
        "fuelConsumedperRunHour": 5.78,
        "fuelDrained": 17.12,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 121.72,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 19 min 36 sec"
    },
    {
        "_id": "631b41136164e143047fea27",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-06-16 23:59:34",
        "city": "mohali",
        "closingFuelLevel": 36.26,
        "date": "2022-09-09T13:35:14.730Z",
        "deFuellingAmount": 0,
        "fuelAdded": 61.9,
        "fuelConsumed": 69.96,
        "fuelConsumedperRunHour": 3.96,
        "fuelDrained": 4.56,
        "fuelLessThanThreshold": 36.26,
        "fuellingAmount": 56.68,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 49.54,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "17 hr 40 min 49 sec"
    },
    {
        "_id": "631b41146164e143047fea28",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-06-16 23:43:59",
        "city": "mohali",
        "closingFuelLevel": 135,
        "date": "2022-09-09T13:35:14.730Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.55,
        "fuelConsumed": -0.06,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 5.49,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 134.94,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41146164e143047fea29",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-06-16 23:35:51",
        "city": "mohali",
        "closingFuelLevel": 81.4,
        "date": "2022-09-09T13:35:14.730Z",
        "deFuellingAmount": 0,
        "fuelAdded": 48.35,
        "fuelConsumed": 3.04,
        "fuelConsumedperRunHour": 4.09,
        "fuelDrained": 8.38,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 39.6,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 44.84,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 44 min 35 sec"
    },
    {
        "_id": "631b41146164e143047fea2a",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-06-18 22:46:47",
        "city": "mohali",
        "closingFuelLevel": 110.74,
        "date": "2022-09-09T13:35:14.727Z",
        "deFuellingAmount": 0,
        "fuelAdded": 1.22,
        "fuelConsumed": -0.11,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 1.11,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 110.63,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e15b612096cf",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-05-27 23:45:24",
        "city": "mohali",
        "closingFuelLevel": 75.92,
        "date": "2022-09-09T13:35:14.727Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.26,
        "fuelConsumed": 1.15,
        "fuelConsumedperRunHour": 2.74,
        "fuelDrained": 5.74,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 77.07,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 25 min 12 sec"
    },
    {
        "_id": "631b41146164e15b612096d0",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-05-27 23:53:41",
        "city": "mohali",
        "closingFuelLevel": 58.59,
        "date": "2022-09-09T13:35:14.727Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.04,
        "fuelConsumed": 0.12,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 6.16,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 58.71,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41146164e15b612096d1",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-05-27 23:55:36",
        "city": "mohali",
        "closingFuelLevel": 39.42,
        "date": "2022-09-09T13:35:14.727Z",
        "deFuellingAmount": 5.63,
        "fuelAdded": 5.22,
        "fuelConsumed": 4.24,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 15.09,
        "fuelLessThanThreshold": 39.42,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 49.29,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41146164e15b612096d2",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-05-27 23:59:14",
        "city": "mohali",
        "closingFuelLevel": 65.65,
        "date": "2022-09-09T13:35:14.727Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.72,
        "fuelConsumed": -0.38,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 5.34,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 65.27,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e1436931cf73",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-07-27 23:37:44",
        "city": "mohali",
        "closingFuelLevel": 72.17,
        "date": "2022-09-09T13:35:14.719Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.12,
        "fuelConsumed": 0.5,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 5.62,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 72.67,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41146164e1436931cf74",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-07-27 23:44:37",
        "city": "mohali",
        "closingFuelLevel": 68.13,
        "date": "2022-09-09T13:35:14.719Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.52,
        "fuelConsumed": 0.9,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.42,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 69.03,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41146164e1436931cf75",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-07-27 23:33:11",
        "city": "mohali",
        "closingFuelLevel": 100.96,
        "date": "2022-09-09T13:35:14.719Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.16,
        "fuelConsumed": 0.16,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.32,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 101.12,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41136164e1468140a9ab",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-05-29 23:39:01",
        "city": "mohali",
        "closingFuelLevel": 70.86,
        "date": "2022-09-09T13:35:14.745Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.93,
        "fuelConsumed": 1.09,
        "fuelConsumedperRunHour": 3.09,
        "fuelDrained": 6.81,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 71.95,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 21 min 9 sec"
    },
    {
        "_id": "631b41146164e1468140a9ac",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-05-29 23:45:38",
        "city": "mohali",
        "closingFuelLevel": 70.52,
        "date": "2022-09-09T13:35:14.745Z",
        "deFuellingAmount": 2.14,
        "fuelAdded": 47.4,
        "fuelConsumed": 0.19,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 11.65,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 38.08,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 34.77,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41146164e1468140a9ad",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-05-26 23:53:04",
        "city": "mohali",
        "closingFuelLevel": 49.4,
        "date": "2022-09-09T13:35:14.743Z",
        "deFuellingAmount": 8.52,
        "fuelAdded": 5.35,
        "fuelConsumed": 5.2,
        "fuelConsumedperRunHour": 6.52,
        "fuelDrained": 16.42,
        "fuelLessThanThreshold": 49.4,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 63.12,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 47 min 50 sec"
    },
    {
        "_id": "631b41146164e154f92b2166",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-05-10 23:46:04",
        "city": "mohali",
        "closingFuelLevel": 60.53,
        "date": "2022-09-09T13:35:14.722Z",
        "deFuellingAmount": 0,
        "fuelAdded": 7.39,
        "fuelConsumed": 3.65,
        "fuelConsumedperRunHour": 18.91,
        "fuelDrained": 9.64,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 64.18,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 11 min 35 sec"
    },
    {
        "_id": "631b41146164e154f92b2167",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-08-26 23:34:45",
        "city": "mohali",
        "closingFuelLevel": 79.32,
        "date": "2022-09-09T13:35:15.440Z",
        "deFuellingAmount": 0,
        "fuelAdded": 2.83,
        "fuelConsumed": 0.28,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.11,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 79.6,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41146164e1467f61e880",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-06-18 23:52:53",
        "city": "mohali",
        "closingFuelLevel": 61.65,
        "date": "2022-09-09T13:35:14.727Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.92,
        "fuelConsumed": 22.79,
        "fuelConsumedperRunHour": 4.41,
        "fuelDrained": 6.54,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 84.44,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "5 hr 10 min 0 sec"
    },
    {
        "_id": "631b41146164e1467f61e881",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-05-19 23:47:11",
        "city": "mohali",
        "closingFuelLevel": 59.31,
        "date": "2022-09-09T13:35:14.709Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.43,
        "fuelConsumed": 3.22,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 8.65,
        "fuelLessThanThreshold": 59.31,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 62.53,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41146164e146ae5acf38",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-06-30 23:58:48",
        "city": "mohali",
        "closingFuelLevel": 73.33,
        "date": "2022-09-09T13:35:14.729Z",
        "deFuellingAmount": 0,
        "fuelAdded": 1.91,
        "fuelConsumed": -0.05,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 1.86,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 73.28,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41146164e1558b34ae71",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-05-16 23:43:36",
        "city": "mohali",
        "closingFuelLevel": 75.03,
        "date": "2022-09-09T13:35:14.708Z",
        "deFuellingAmount": 0,
        "fuelAdded": 8.43,
        "fuelConsumed": 2.24,
        "fuelConsumedperRunHour": 4.54,
        "fuelDrained": 8.09,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 77.27,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 29 min 37 sec"
    },
    {
        "_id": "631b41146164e1558b34ae72",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-05-16 23:46:20",
        "city": "mohali",
        "closingFuelLevel": 65.65,
        "date": "2022-09-09T13:35:14.708Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.12,
        "fuelConsumed": 0.26,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 6.38,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 65.91,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41146164e1558b34ae73",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-06-25 23:35:02",
        "city": "mohali",
        "closingFuelLevel": 66.88,
        "date": "2022-09-09T13:35:15.455Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.65,
        "fuelConsumed": 1.87,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 8.52,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 68.75,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b41146164e1558b34ae74",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-06-25 23:56:44",
        "city": "mohali",
        "closingFuelLevel": 57.81,
        "date": "2022-09-09T13:35:15.455Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.06,
        "fuelConsumed": 43.12,
        "fuelConsumedperRunHour": 5.32,
        "fuelDrained": 2.63,
        "fuelLessThanThreshold": 57.81,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 100.93,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "8 hr 6 min 26 sec"
    },
    {
        "_id": "631b41146164e1558b34ae75",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-06-25 23:59:51",
        "city": "mohali",
        "closingFuelLevel": 50.12,
        "date": "2022-09-09T13:35:15.455Z",
        "deFuellingAmount": 0,
        "fuelAdded": 7.8,
        "fuelConsumed": 7.3,
        "fuelConsumedperRunHour": 3.85,
        "fuelDrained": 7.49,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 57.42,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 53 min 43 sec"
    },
    {
        "_id": "631b41146164e1558b34ae76",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-06-25 23:45:57",
        "city": "mohali",
        "closingFuelLevel": 9.59,
        "date": "2022-09-09T13:35:15.455Z",
        "deFuellingAmount": 2.91,
        "fuelAdded": 10.37,
        "fuelConsumed": -2.21,
        "fuelConsumedperRunHour": -28.62,
        "fuelDrained": 10.97,
        "fuelLessThanThreshold": 9.59,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 10.29,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 4 min 38 sec"
    },
    {
        "_id": "631b41146164e142df16c799",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-06-16 20:04:31",
        "city": "mohali",
        "closingFuelLevel": 114.21,
        "date": "2022-09-09T13:35:14.730Z",
        "deFuellingAmount": 0,
        "fuelAdded": 1.1,
        "fuelConsumed": -0.21,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 0.89,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 114,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631b97e96164e142df16c7ae",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-09-09 23:33:14",
        "city": "mohali",
        "closingFuelLevel": 99.75,
        "date": "2022-09-09T19:45:45.356Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6,
        "fuelConsumed": 1.02,
        "fuelConsumedperRunHour": 4.1,
        "fuelDrained": 5.35,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 100.77,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 14 min 55 sec"
    },
    {
        "_id": "631b97e96164e142df16c7af",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-09-09 23:33:58",
        "city": "mohali",
        "closingFuelLevel": 51.33,
        "date": "2022-09-09T19:45:45.356Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.95,
        "fuelConsumed": 6.27,
        "fuelConsumedperRunHour": 5.41,
        "fuelDrained": 5.8,
        "fuelLessThanThreshold": 51.33,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 57.6,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "1 hr 9 min 34 sec"
    },
    {
        "_id": "631b97e96164e142df16c7b0",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-09-09 23:46:25",
        "city": "mohali",
        "closingFuelLevel": 42.07,
        "date": "2022-09-09T19:45:45.356Z",
        "deFuellingAmount": 0,
        "fuelAdded": 20.69,
        "fuelConsumed": 1.95,
        "fuelConsumedperRunHour": 3.66,
        "fuelDrained": 7.23,
        "fuelLessThanThreshold": 42.07,
        "fuellingAmount": 13.38,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 30.64,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 31 min 58 sec"
    },
    {
        "_id": "631b97e96164e142df16c7b1",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-09-09 23:25:15",
        "city": "mohali",
        "closingFuelLevel": 98.24,
        "date": "2022-09-09T19:45:45.356Z",
        "deFuellingAmount": 2.84,
        "fuelAdded": 2.72,
        "fuelConsumed": 0.62,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 6.18,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 101.7,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631ce9686164e1430360a4d0",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-09-10 23:33:32",
        "city": "mohali",
        "closingFuelLevel": 41.55,
        "date": "2022-09-10T19:45:44.105Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.12,
        "fuelConsumed": 0.52,
        "fuelConsumedperRunHour": 14.63,
        "fuelDrained": 5.52,
        "fuelLessThanThreshold": 41.55,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 42.07,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 2 min 8 sec"
    },
    {
        "_id": "631ce9686164e1430360a4d1",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-09-10 23:35:48",
        "city": "mohali",
        "closingFuelLevel": 99.38,
        "date": "2022-09-10T19:45:44.105Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.18,
        "fuelConsumed": 0.19,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.37,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 99.57,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631ce9686164e1430360a4d2",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-09-10 23:36:29",
        "city": "mohali",
        "closingFuelLevel": 69.46,
        "date": "2022-09-10T19:45:44.105Z",
        "deFuellingAmount": 0,
        "fuelAdded": 23.36,
        "fuelConsumed": 0.49,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 5.02,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 18.83,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 51.12,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631ce9686164e1430360a4d3",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-09-10 23:12:45",
        "city": "mohali",
        "closingFuelLevel": 92.99,
        "date": "2022-09-10T19:45:44.105Z",
        "deFuellingAmount": 2.17,
        "fuelAdded": 1.64,
        "fuelConsumed": 2.84,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 6.65,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 98,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631e3ae66164e146961a1f9a",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-09-11 23:45:56",
        "city": "mohali",
        "closingFuelLevel": 39.54,
        "date": "2022-09-11T19:45:42.896Z",
        "deFuellingAmount": 0,
        "fuelAdded": 5.92,
        "fuelConsumed": 2.18,
        "fuelConsumedperRunHour": 5.76,
        "fuelDrained": 6.49,
        "fuelLessThanThreshold": 39.54,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 41.72,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 22 min 43 sec"
    },
    {
        "_id": "631e3ae66164e146961a1f9b",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-09-11 23:38:20",
        "city": "mohali",
        "closingFuelLevel": 99.69,
        "date": "2022-09-11T19:45:42.896Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.84,
        "fuelConsumed": -0.37,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.47,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 99.32,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631e3ae66164e146961a1f9c",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-09-11 23:39:02",
        "city": "mohali",
        "closingFuelLevel": 69.35,
        "date": "2022-09-11T19:45:42.896Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.47,
        "fuelConsumed": -0.05,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.42,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 69.3,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631e3ae66164e146961a1f9d",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-09-11 23:30:20",
        "city": "mohali",
        "closingFuelLevel": 92.59,
        "date": "2022-09-11T19:45:42.896Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.15,
        "fuelConsumed": 0.28,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.43,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 92.87,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631f8c686164e102f2336fba",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-09-12 23:17:50",
        "city": "mohali",
        "closingFuelLevel": 91.67,
        "date": "2022-09-12T19:45:44.112Z",
        "deFuellingAmount": 0,
        "fuelAdded": 2.53,
        "fuelConsumed": 1.2,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.73,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 92.87,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631f8c686164e102f2336fbb",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-09-12 23:52:39",
        "city": "mohali",
        "closingFuelLevel": 27.85,
        "date": "2022-09-12T19:45:44.112Z",
        "deFuellingAmount": 0,
        "fuelAdded": 6.22,
        "fuelConsumed": 11.58,
        "fuelConsumedperRunHour": 2.21,
        "fuelDrained": 6.61,
        "fuelLessThanThreshold": 27.85,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 39.43,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "5 hr 14 min 36 sec"
    },
    {
        "_id": "631f8c686164e102f2336fbc",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-09-12 23:40:54",
        "city": "mohali",
        "closingFuelLevel": 99.32,
        "date": "2022-09-12T19:45:44.112Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.66,
        "fuelConsumed": 0.31,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.97,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 99.63,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "631f8c686164e102f2336fbd",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-09-12 23:41:35",
        "city": "mohali",
        "closingFuelLevel": 69.41,
        "date": "2022-09-12T19:45:44.112Z",
        "deFuellingAmount": 0,
        "fuelAdded": 4.3,
        "fuelConsumed": -0.16,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 4.14,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 69.25,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "6320ddea6164e102f233702b",
        "thingName": "baltanatank1",
        "lastDataRecievingTime": "2022-09-13 23:45:08",
        "city": "mohali",
        "closingFuelLevel": 34.53,
        "date": "2022-09-13T19:45:46.170Z",
        "deFuellingAmount": 2.44,
        "fuelAdded": 37.67,
        "fuelConsumed": 18.51,
        "fuelConsumedperRunHour": 3.39,
        "fuelDrained": 10.84,
        "fuelLessThanThreshold": 34.53,
        "fuellingAmount": 27.35,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 28.13,
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "5 hr 27 min 48 sec"
    },
    {
        "_id": "6320ddea6164e102f233702c",
        "thingName": "landratank1",
        "lastDataRecievingTime": "2022-09-13 23:43:27",
        "city": "mohali",
        "closingFuelLevel": 99.44,
        "date": "2022-09-13T19:45:46.170Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.5,
        "fuelConsumed": -0.19,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.31,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "industowers",
        "model": "model1",
        "openingFuelLevel": 99.25,
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "size": "50kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "6320ddea6164e102f233702d",
        "thingName": "bhagomajrotank1",
        "lastDataRecievingTime": "2022-09-13 23:44:08",
        "city": "mohali",
        "closingFuelLevel": 69.3,
        "date": "2022-09-13T19:45:46.170Z",
        "deFuellingAmount": 0,
        "fuelAdded": 3.71,
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 3.71,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirlosker",
        "model": "model1",
        "openingFuelLevel": 69.3,
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "size": "40kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    },
    {
        "_id": "6320ddea6164e102f233702e",
        "thingName": "nayagaontank1",
        "lastDataRecievingTime": "2022-09-13 23:35:24",
        "city": "mohali",
        "closingFuelLevel": 78.92,
        "date": "2022-09-13T19:45:46.170Z",
        "deFuellingAmount": 8.74,
        "fuelAdded": 1.85,
        "fuelConsumed": 3.95,
        "fuelConsumedperRunHour": 0,
        "fuelDrained": 14.54,
        "fuelLessThanThreshold": 0,
        "fuellingAmount": 0,
        "make": "kirloskar",
        "model": "model1",
        "openingFuelLevel": 91.61,
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab",
        "totalRunDuration": "0 hr 0 min 0 sec"
    }
];

export const sessionData = [
    {
        "_id": "631b20626164e12f34409608",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-28 10:02:32",
        "ignitionOffTime": "2022-04-28 10:05:39",
        "city": "mohali",
        "date": "2022-09-09T11:15:45.475Z",
        "fuelConsumed": 0.8799999999999955,
        "fuelConsumedperRunHour": 16.941176470588147,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 187,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409609",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-28 12:04:56",
        "ignitionOffTime": "2022-04-28 12:06:05",
        "city": "mohali",
        "date": "2022-09-09T11:15:45.475Z",
        "fuelConsumed": 0.10999999999999943,
        "fuelConsumedperRunHour": 5.739130434782579,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 69,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f3440960a",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-28 12:06:07",
        "ignitionOffTime": "2022-04-28 12:06:13",
        "city": "mohali",
        "date": "2022-09-09T11:15:45.475Z",
        "fuelConsumed": 0.060000000000002274,
        "fuelConsumedperRunHour": 36.000000000001364,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 6,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f3440960b",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-28 13:02:09",
        "ignitionOffTime": "2022-04-28 13:20:20",
        "city": "mohali",
        "date": "2022-09-09T11:15:45.475Z",
        "fuelConsumed": 1.4200000000000017,
        "fuelConsumedperRunHour": 4.68560953253896,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 1091,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f3440960c",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-28 13:20:31",
        "ignitionOffTime": "2022-04-28 13:20:54",
        "city": "mohali",
        "date": "2022-09-09T11:15:45.475Z",
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 23,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f3440960d",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-28 13:20:58",
        "ignitionOffTime": "2022-04-28 13:21:22",
        "city": "mohali",
        "date": "2022-09-09T11:15:45.475Z",
        "fuelConsumed": 0.060000000000002274,
        "fuelConsumedperRunHour": 9.000000000000341,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 24,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f3440960e",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-28 13:21:25",
        "ignitionOffTime": "2022-04-28 13:21:31",
        "city": "mohali",
        "date": "2022-09-09T11:15:45.475Z",
        "fuelConsumed": -0.11999999999999744,
        "fuelConsumedperRunHour": -71.99999999999847,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 6,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f3440960f",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-28 13:21:36",
        "ignitionOffTime": "2022-04-28 13:21:50",
        "city": "mohali",
        "date": "2022-09-09T11:15:45.475Z",
        "fuelConsumed": -0.060000000000002274,
        "fuelConsumedperRunHour": -15.428571428572013,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 14,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409610",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-28 13:21:54",
        "ignitionOffTime": "2022-04-28 13:23:46",
        "city": "mohali",
        "date": "2022-09-09T11:15:45.475Z",
        "fuelConsumed": 0.240000000000002,
        "fuelConsumedperRunHour": 7.714285714285778,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 112,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409611",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-28 13:23:50",
        "ignitionOffTime": "2022-04-28 13:26:41",
        "city": "mohali",
        "date": "2022-09-09T11:15:45.475Z",
        "fuelConsumed": -0.240000000000002,
        "fuelConsumedperRunHour": -5.05263157894741,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 171,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409612",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-28 13:26:44",
        "ignitionOffTime": "2022-04-28 13:27:46",
        "city": "mohali",
        "date": "2022-09-09T11:15:45.475Z",
        "fuelConsumed": 0.4099999999999966,
        "fuelConsumedperRunHour": 23.80645161290303,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 62,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409613",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-28 13:27:55",
        "ignitionOffTime": "2022-04-28 13:27:59",
        "city": "mohali",
        "date": "2022-09-09T11:15:45.475Z",
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 4,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409614",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-28 13:28:21",
        "ignitionOffTime": "2022-04-28 13:29:18",
        "city": "mohali",
        "date": "2022-09-09T11:15:45.475Z",
        "fuelConsumed": -0.060000000000002274,
        "fuelConsumedperRunHour": -3.7894736842106695,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 57,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409615",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-28 13:29:24",
        "ignitionOffTime": "2022-04-28 13:29:29",
        "city": "mohali",
        "date": "2022-09-09T11:15:45.475Z",
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 5,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409616",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-28 13:29:35",
        "ignitionOffTime": "2022-04-28 13:30:10",
        "city": "mohali",
        "date": "2022-09-09T11:15:45.475Z",
        "fuelConsumed": 0.060000000000002274,
        "fuelConsumedperRunHour": 6.171428571428805,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 35,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409617",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-28 13:30:26",
        "ignitionOffTime": "2022-04-28 13:31:35",
        "city": "mohali",
        "date": "2022-09-09T11:15:45.475Z",
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 69,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409618",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-28 13:31:39",
        "ignitionOffTime": "2022-04-28 13:32:02",
        "city": "mohali",
        "date": "2022-09-09T11:15:45.475Z",
        "fuelConsumed": 0.1699999999999946,
        "fuelConsumedperRunHour": 26.608695652173065,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 23,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409619",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-28 13:32:06",
        "ignitionOffTime": "2022-04-28 13:32:17",
        "city": "mohali",
        "date": "2022-09-09T11:15:45.475Z",
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 11,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f3440961a",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-28 13:32:23",
        "ignitionOffTime": "2022-04-28 13:32:39",
        "city": "mohali",
        "date": "2022-09-09T11:15:45.475Z",
        "fuelConsumed": -0.1699999999999946,
        "fuelConsumedperRunHour": -38.249999999998785,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 16,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f3440961b",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-28 13:32:45",
        "ignitionOffTime": "2022-04-28 13:32:48",
        "city": "mohali",
        "date": "2022-09-09T11:15:45.475Z",
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 3,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f3440961c",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-28 13:32:55",
        "ignitionOffTime": "2022-04-28 13:33:04",
        "city": "mohali",
        "date": "2022-09-09T11:15:45.475Z",
        "fuelConsumed": -0.060000000000002274,
        "fuelConsumedperRunHour": -24.00000000000091,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 9,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f3440961d",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-28 13:33:08",
        "ignitionOffTime": "2022-04-28 13:33:44",
        "city": "mohali",
        "date": "2022-09-09T11:15:45.475Z",
        "fuelConsumed": 0.060000000000002274,
        "fuelConsumedperRunHour": 6.000000000000227,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 36,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f3440961e",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-28 13:34:05",
        "ignitionOffTime": "2022-04-28 13:34:12",
        "city": "mohali",
        "date": "2022-09-09T11:15:45.475Z",
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 7,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f3440961f",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-28 13:34:15",
        "ignitionOffTime": "2022-04-28 13:45:06",
        "city": "mohali",
        "date": "2022-09-09T11:15:45.475Z",
        "fuelConsumed": 0.6499999999999986,
        "fuelConsumedperRunHour": 3.5944700460829413,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 651,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409620",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-28 13:45:10",
        "ignitionOffTime": "2022-04-28 13:45:38",
        "city": "mohali",
        "date": "2022-09-09T11:15:45.475Z",
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 28,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409621",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-28 13:45:47",
        "ignitionOffTime": "2022-04-28 13:46:11",
        "city": "mohali",
        "date": "2022-09-09T11:15:45.475Z",
        "fuelConsumed": 0.05999999999999517,
        "fuelConsumedperRunHour": 8.999999999999275,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 24,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409622",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-28 13:46:17",
        "ignitionOffTime": "2022-04-28 13:46:22",
        "city": "mohali",
        "date": "2022-09-09T11:15:45.475Z",
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 5,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409623",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-28 13:46:41",
        "ignitionOffTime": "2022-04-28 13:46:45",
        "city": "mohali",
        "date": "2022-09-09T11:15:45.475Z",
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 4,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409624",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-28 13:46:49",
        "ignitionOffTime": "2022-04-28 13:46:52",
        "city": "mohali",
        "date": "2022-09-09T11:15:45.475Z",
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 3,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409625",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-28 13:46:56",
        "ignitionOffTime": "2022-04-28 13:47:12",
        "city": "mohali",
        "date": "2022-09-09T11:15:45.475Z",
        "fuelConsumed": -0.17999999999999972,
        "fuelConsumedperRunHour": -40.499999999999936,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 16,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409626",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-28 13:47:15",
        "ignitionOffTime": "2022-04-28 13:47:26",
        "city": "mohali",
        "date": "2022-09-09T11:15:45.475Z",
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 11,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409627",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-28 13:47:30",
        "ignitionOffTime": "2022-04-28 13:54:08",
        "city": "mohali",
        "date": "2022-09-09T11:15:45.475Z",
        "fuelConsumed": 0.7100000000000009,
        "fuelConsumedperRunHour": 6.422110552763827,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 398,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409628",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-28 13:54:08",
        "ignitionOffTime": "2022-04-28 13:54:15",
        "city": "mohali",
        "date": "2022-09-09T11:15:45.475Z",
        "fuelConsumed": 0.11999999999999744,
        "fuelConsumedperRunHour": 61.7142857142844,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 7,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409629",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-28 14:49:33",
        "ignitionOffTime": "2022-04-28 14:50:58",
        "city": "mohali",
        "date": "2022-09-09T11:15:45.475Z",
        "fuelConsumed": -0.35999999999999943,
        "fuelConsumedperRunHour": -15.247058823529388,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 85,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f3440962a",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-28 14:51:15",
        "ignitionOffTime": "2022-04-28 14:51:19",
        "city": "mohali",
        "date": "2022-09-09T11:15:45.475Z",
        "fuelConsumed": 0.060000000000002274,
        "fuelConsumedperRunHour": 54.000000000002046,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 4,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f3440963c",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-23 10:44:34",
        "ignitionOffTime": "2022-04-23 11:20:23",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.437Z",
        "fuelConsumed": 1.759999999999998,
        "fuelConsumedperRunHour": 2.948348068869238,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 2149,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f3440963d",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-23 14:19:15",
        "ignitionOffTime": "2022-04-23 15:39:07",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.437Z",
        "fuelConsumed": 4.200000000000003,
        "fuelConsumedperRunHour": 3.1552587646076815,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 4792,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f3440963e",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-23 16:44:34",
        "ignitionOffTime": "2022-04-23 17:27:31",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.437Z",
        "fuelConsumed": 3,
        "fuelConsumedperRunHour": 4.190919674039581,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 2577,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f3440963f",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-23 18:39:00",
        "ignitionOffTime": "2022-04-23 18:51:18",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.437Z",
        "fuelConsumed": 0.17999999999999972,
        "fuelConsumedperRunHour": 0.8780487804878035,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 738,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409640",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-23 18:51:21",
        "ignitionOffTime": "2022-04-23 18:53:55",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.437Z",
        "fuelConsumed": 0.060000000000002274,
        "fuelConsumedperRunHour": 1.402597402597456,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 154,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409641",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-23 18:54:01",
        "ignitionOffTime": "2022-04-23 18:59:10",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.437Z",
        "fuelConsumed": 0.46999999999999886,
        "fuelConsumedperRunHour": 5.475728155339793,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 309,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409642",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-23 19:39:07",
        "ignitionOffTime": "2022-04-23 19:51:29",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.437Z",
        "fuelConsumed": 0.6599999999999966,
        "fuelConsumedperRunHour": 3.2021563342317894,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 742,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409649",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-08 06:05:00",
        "ignitionOffTime": "2022-05-08 06:13:25",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.585Z",
        "fuelConsumed": -0.7900000000000063,
        "fuelConsumedperRunHour": -5.631683168316876,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 505,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f3440964a",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-08 20:33:32",
        "ignitionOffTime": "2022-05-08 20:38:49",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.585Z",
        "fuelConsumed": -1.3700000000000045,
        "fuelConsumedperRunHour": -15.558359621451157,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 317,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f3440964e",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-01 12:53:20",
        "ignitionOffTime": "2022-05-01 12:53:28",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.586Z",
        "fuelConsumed": 0.5200000000000031,
        "fuelConsumedperRunHour": 234.00000000000142,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 8,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f3440964f",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-01 12:53:47",
        "ignitionOffTime": "2022-05-01 12:53:49",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.586Z",
        "fuelConsumed": -0.060000000000002274,
        "fuelConsumedperRunHour": -108.00000000000409,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 2,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409650",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-01 12:53:56",
        "ignitionOffTime": "2022-05-01 12:54:10",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.586Z",
        "fuelConsumed": -0.39000000000000057,
        "fuelConsumedperRunHour": -100.28571428571443,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 14,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409651",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-01 12:54:15",
        "ignitionOffTime": "2022-05-01 12:55:57",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.586Z",
        "fuelConsumed": -0.5899999999999963,
        "fuelConsumedperRunHour": -20.823529411764575,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 102,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409652",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-01 12:56:02",
        "ignitionOffTime": "2022-05-01 12:56:06",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.586Z",
        "fuelConsumed": 0.45999999999999375,
        "fuelConsumedperRunHour": 413.9999999999944,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 4,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409653",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-01 12:56:09",
        "ignitionOffTime": "2022-05-01 12:56:14",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.586Z",
        "fuelConsumed": 0.519999999999996,
        "fuelConsumedperRunHour": 374.39999999999714,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 5,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409654",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-01 12:56:28",
        "ignitionOffTime": "2022-05-01 12:56:28",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.586Z",
        "fuelConsumed": 0.07000000000000028,
        "fuelConsumedperRunHour": 0,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 0,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409655",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-01 12:56:37",
        "ignitionOffTime": "2022-05-01 12:56:43",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.586Z",
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 6,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409656",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-01 12:56:55",
        "ignitionOffTime": "2022-05-01 12:57:17",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.586Z",
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 22,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409657",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-01 12:57:21",
        "ignitionOffTime": "2022-05-01 12:57:25",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.586Z",
        "fuelConsumed": 0.13000000000000256,
        "fuelConsumedperRunHour": 117.0000000000023,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 4,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409658",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-01 12:57:34",
        "ignitionOffTime": "2022-05-01 12:57:46",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.586Z",
        "fuelConsumed": -0.5899999999999963,
        "fuelConsumedperRunHour": -176.9999999999989,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 12,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409659",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-01 12:57:55",
        "ignitionOffTime": "2022-05-01 12:58:03",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.586Z",
        "fuelConsumed": -0.45000000000000284,
        "fuelConsumedperRunHour": -202.50000000000128,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 8,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f3440965a",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-01 12:58:33",
        "ignitionOffTime": "2022-05-01 12:58:58",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.586Z",
        "fuelConsumed": 0.13000000000000256,
        "fuelConsumedperRunHour": 18.72000000000037,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 25,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f3440965b",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-01 12:59:36",
        "ignitionOffTime": "2022-05-01 12:59:39",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.586Z",
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 3,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f3440965c",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-01 13:00:01",
        "ignitionOffTime": "2022-05-01 13:00:04",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.586Z",
        "fuelConsumed": -0.259999999999998,
        "fuelConsumedperRunHour": -311.9999999999976,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 3,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f3440965d",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-01 13:00:29",
        "ignitionOffTime": "2022-05-01 13:00:58",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.586Z",
        "fuelConsumed": 0.07000000000000028,
        "fuelConsumedperRunHour": 8.68965517241383,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 29,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f3440965e",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-01 13:01:13",
        "ignitionOffTime": "2022-05-01 13:01:18",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.586Z",
        "fuelConsumed": -0.13000000000000256,
        "fuelConsumedperRunHour": -93.60000000000184,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 5,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f3440965f",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-01 13:01:30",
        "ignitionOffTime": "2022-05-01 13:01:42",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.586Z",
        "fuelConsumed": 0.22999999999999687,
        "fuelConsumedperRunHour": 68.99999999999906,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 12,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409660",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-01 13:01:47",
        "ignitionOffTime": "2022-05-01 13:02:02",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.586Z",
        "fuelConsumed": 0.9799999999999969,
        "fuelConsumedperRunHour": 235.19999999999925,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 15,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409661",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-01 13:02:02",
        "ignitionOffTime": "2022-05-01 13:02:13",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.586Z",
        "fuelConsumed": -0.13000000000000256,
        "fuelConsumedperRunHour": -42.54545454545538,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 11,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409662",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-01 13:02:17",
        "ignitionOffTime": "2022-05-01 13:02:26",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.586Z",
        "fuelConsumed": -0.06999999999999318,
        "fuelConsumedperRunHour": -27.99999999999727,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 9,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409663",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-01 13:02:38",
        "ignitionOffTime": "2022-05-01 13:02:41",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.586Z",
        "fuelConsumed": 0.07000000000000028,
        "fuelConsumedperRunHour": 84.00000000000034,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 3,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409664",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-01 13:02:45",
        "ignitionOffTime": "2022-05-01 13:03:16",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.586Z",
        "fuelConsumed": -0.07000000000000028,
        "fuelConsumedperRunHour": -8.12903225806455,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 31,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409665",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-01 13:03:20",
        "ignitionOffTime": "2022-05-01 13:03:23",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.586Z",
        "fuelConsumed": 0.060000000000002274,
        "fuelConsumedperRunHour": 72.00000000000273,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 3,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409666",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-01 13:03:26",
        "ignitionOffTime": "2022-05-01 13:03:36",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.586Z",
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 10,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409667",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-01 13:03:46",
        "ignitionOffTime": "2022-05-01 13:03:50",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.586Z",
        "fuelConsumed": 0.5200000000000031,
        "fuelConsumedperRunHour": 468.00000000000284,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 4,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409668",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-01 13:03:56",
        "ignitionOffTime": "2022-05-01 13:03:59",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.586Z",
        "fuelConsumed": 0.13000000000000256,
        "fuelConsumedperRunHour": 156.00000000000307,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 3,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f34409669",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-01 13:04:24",
        "ignitionOffTime": "2022-05-01 13:04:39",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.586Z",
        "fuelConsumed": -0.45000000000000284,
        "fuelConsumedperRunHour": -108.00000000000068,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 15,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f3440966a",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-01 13:05:09",
        "ignitionOffTime": "2022-05-01 13:05:17",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.586Z",
        "fuelConsumed": -0.12999999999999545,
        "fuelConsumedperRunHour": -58.499999999997954,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 8,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f3440966b",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-01 13:38:15",
        "ignitionOffTime": "2022-05-01 13:38:18",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.586Z",
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 3,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f3440966c",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-01 13:47:09",
        "ignitionOffTime": "2022-05-01 13:47:43",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.586Z",
        "fuelConsumed": 0.44999999999999574,
        "fuelConsumedperRunHour": 47.64705882352896,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 34,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f3440966d",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-01 13:51:56",
        "ignitionOffTime": "2022-05-01 13:52:05",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.586Z",
        "fuelConsumed": 0.07000000000000028,
        "fuelConsumedperRunHour": 28.000000000000114,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 9,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f3440966e",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-01 13:52:13",
        "ignitionOffTime": "2022-05-01 13:59:13",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.586Z",
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 420,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20626164e12f3440966f",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-01 13:59:13",
        "ignitionOffTime": "2022-05-01 13:59:24",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.586Z",
        "fuelConsumed": 0.2600000000000051,
        "fuelConsumedperRunHour": 85.09090909091076,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 11,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20636164e12ee016a496",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-13 10:34:29",
        "ignitionOffTime": "2022-04-13 10:44:37",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.489Z",
        "fuelConsumed": 1,
        "fuelConsumedperRunHour": 5.921052631578947,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 608,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20636164e12ee016a497",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-13 10:59:30",
        "ignitionOffTime": "2022-04-13 11:04:36",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.489Z",
        "fuelConsumed": 0.30000000000001137,
        "fuelConsumedperRunHour": 3.529411764706016,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 306,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20636164e12ee016a498",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-13 13:08:35",
        "ignitionOffTime": "2022-04-13 13:25:13",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.489Z",
        "fuelConsumed": 0.9399999999999977,
        "fuelConsumedperRunHour": 3.3907815631262443,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 998,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20636164e12ee016a499",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-13 14:47:12",
        "ignitionOffTime": "2022-04-13 17:17:34",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.489Z",
        "fuelConsumed": 9.370000000000005,
        "fuelConsumedperRunHour": 3.7388605630680574,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 9022,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20636164e12ee016a49a",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-13 18:16:43",
        "ignitionOffTime": "2022-04-13 18:26:05",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.489Z",
        "fuelConsumed": 0.6800000000000068,
        "fuelConsumedperRunHour": 4.35587188612104,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 562,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20636164e12ee016a49b",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-13 22:11:59",
        "ignitionOffTime": "2022-04-13 22:17:34",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.489Z",
        "fuelConsumed": 0.28999999999999204,
        "fuelConsumedperRunHour": 3.1164179104476757,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 335,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20636164e12ee016a49c",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-13 23:14:38",
        "ignitionOffTime": "2022-04-13 23:21:55",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.489Z",
        "fuelConsumed": 0.5300000000000011,
        "fuelConsumedperRunHour": 4.366132723112138,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 437,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20636164e12826148171",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-07 13:12:13",
        "ignitionOffTime": "2022-05-07 13:15:24",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.516Z",
        "fuelConsumed": 0.05999999999998806,
        "fuelConsumedperRunHour": 1.1308900523557959,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 191,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20646164e16d7363c7f0",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-11 07:48:33",
        "ignitionOffTime": "2022-05-11 08:14:41",
        "city": "mohali",
        "date": "2022-09-09T11:16:54.046Z",
        "fuelConsumed": 1.0899999999999963,
        "fuelConsumedperRunHour": 2.502551020408155,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 1568,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20636164e12ede00e2f1",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-06-20 14:06:17",
        "ignitionOffTime": "2022-06-20 14:21:23",
        "city": "mohali",
        "date": "2022-09-09T11:15:46.564Z",
        "fuelConsumed": 0.1700000000000017,
        "fuelConsumedperRunHour": 0.6754966887417286,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 906,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20646164e12ede00e2f2",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-19 10:17:57",
        "ignitionOffTime": "2022-04-19 10:40:34",
        "city": "mohali",
        "date": "2022-09-09T11:16:47.028Z",
        "fuelConsumed": 0.5100000000000051,
        "fuelConsumedperRunHour": 1.3529845246868226,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 1357,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20646164e12eb1376780",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-19 15:51:05",
        "ignitionOffTime": "2022-04-19 15:52:41",
        "city": "mohali",
        "date": "2022-09-09T11:16:47.028Z",
        "fuelConsumed": -0.4000000000000057,
        "fuelConsumedperRunHour": -15.000000000000211,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 96,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20646164e12eb1376781",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-19 16:38:23",
        "ignitionOffTime": "2022-04-19 16:44:59",
        "city": "mohali",
        "date": "2022-09-09T11:16:47.028Z",
        "fuelConsumed": 0.8100000000000023,
        "fuelConsumedperRunHour": 7.363636363636385,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 396,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e12eb1376785",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-19 22:58:05",
        "ignitionOffTime": "2022-04-19 23:51:13",
        "city": "mohali",
        "date": "2022-09-09T11:16:47.028Z",
        "fuelConsumed": 2.269999999999996,
        "fuelConsumedperRunHour": 2.5633626097866955,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 3188,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20646164e12ede00e2f3",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-19 11:08:08",
        "ignitionOffTime": "2022-04-19 13:34:17",
        "city": "mohali",
        "date": "2022-09-09T11:16:47.028Z",
        "fuelConsumed": 8.349999999999994,
        "fuelConsumedperRunHour": 3.4279849469722863,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 8769,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20646164e12ede00e2f4",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-19 13:56:43",
        "ignitionOffTime": "2022-04-19 14:13:19",
        "city": "mohali",
        "date": "2022-09-09T11:16:47.028Z",
        "fuelConsumed": 0.6299999999999955,
        "fuelConsumedperRunHour": 2.277108433734923,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 996,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20646164e12ede00e2f5",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-19 15:12:28",
        "ignitionOffTime": "2022-04-19 15:16:36",
        "city": "mohali",
        "date": "2022-09-09T11:16:47.028Z",
        "fuelConsumed": -0.3400000000000034,
        "fuelConsumedperRunHour": -4.935483870967792,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 248,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20646164e1280b0f03a3",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-30 16:14:44",
        "ignitionOffTime": "2022-04-30 16:36:46",
        "city": "mohali",
        "date": "2022-09-09T11:16:49.045Z",
        "fuelConsumed": 2.0400000000000063,
        "fuelConsumedperRunHour": 5.555219364599109,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 1322,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20646164e12eb2762686",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-11 08:14:44",
        "ignitionOffTime": "2022-05-11 08:21:24",
        "city": "mohali",
        "date": "2022-09-09T11:16:54.046Z",
        "fuelConsumed": 0.29000000000000625,
        "fuelConsumedperRunHour": 2.6100000000000563,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 400,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20646164e1285e443c22",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-20 08:47:35",
        "ignitionOffTime": "2022-04-20 08:50:45",
        "city": "mohali",
        "date": "2022-09-09T11:16:47.015Z",
        "fuelConsumed": -0.6300000000000097,
        "fuelConsumedperRunHour": -11.936842105263342,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 190,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20646164e1285e443c23",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-20 10:41:51",
        "ignitionOffTime": "2022-04-20 10:43:20",
        "city": "mohali",
        "date": "2022-09-09T11:16:47.015Z",
        "fuelConsumed": -0.46000000000000796,
        "fuelConsumedperRunHour": -18.60674157303403,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 89,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20646164e1280b0f03a7",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-20 12:26:10",
        "ignitionOffTime": "2022-04-20 12:29:26",
        "city": "mohali",
        "date": "2022-09-09T11:16:47.015Z",
        "fuelConsumed": 0.11999999999999034,
        "fuelConsumedperRunHour": 2.204081632652884,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 196,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e1280b0f03ab",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-20 17:20:51",
        "ignitionOffTime": "2022-04-20 19:01:43",
        "city": "mohali",
        "date": "2022-09-09T11:16:47.015Z",
        "fuelConsumed": 5.200000000000003,
        "fuelConsumedperRunHour": 3.093192333113022,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 6052,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20646164e1284e1f9545",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-05 08:33:19",
        "ignitionOffTime": "2022-05-05 09:07:06",
        "city": "mohali",
        "date": "2022-09-09T11:16:43.095Z",
        "fuelConsumed": 1.1200000000000045,
        "fuelConsumedperRunHour": 1.9891465219536342,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 2027,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20646164e1284e1f9543",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-30 16:38:57",
        "ignitionOffTime": "2022-04-30 16:40:09",
        "city": "mohali",
        "date": "2022-09-09T11:16:49.045Z",
        "fuelConsumed": 0.12000000000000455,
        "fuelConsumedperRunHour": 6.000000000000227,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 72,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20646164e1284e1f9544",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-30 16:40:12",
        "ignitionOffTime": "2022-04-30 16:40:48",
        "city": "mohali",
        "date": "2022-09-09T11:16:49.045Z",
        "fuelConsumed": -0.1700000000000017,
        "fuelConsumedperRunHour": -17.00000000000017,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 36,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20646164e1288c153931",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-05 07:52:53",
        "ignitionOffTime": "2022-05-05 07:59:08",
        "city": "mohali",
        "date": "2022-09-09T11:16:43.095Z",
        "fuelConsumed": -0.45999999999999375,
        "fuelConsumedperRunHour": -4.41599999999994,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 375,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20646164e1288c153932",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-05 08:19:34",
        "ignitionOffTime": "2022-05-05 08:24:49",
        "city": "mohali",
        "date": "2022-09-09T11:16:43.095Z",
        "fuelConsumed": -0.1700000000000017,
        "fuelConsumedperRunHour": -1.9428571428571624,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 315,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e128a526ca03",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-05 09:07:10",
        "ignitionOffTime": "2022-05-05 09:07:10",
        "city": "mohali",
        "date": "2022-09-09T11:16:43.095Z",
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 0,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20646164e1288c153933",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-19 22:32:23",
        "ignitionOffTime": "2022-04-19 22:44:05",
        "city": "mohali",
        "date": "2022-09-09T11:16:47.028Z",
        "fuelConsumed": 0.12000000000000455,
        "fuelConsumedperRunHour": 0.6153846153846387,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 702,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e128a526ca04",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-20 14:56:20",
        "ignitionOffTime": "2022-04-20 14:58:07",
        "city": "mohali",
        "date": "2022-09-09T11:16:47.015Z",
        "fuelConsumed": -0.1799999999999926,
        "fuelConsumedperRunHour": -6.056074766354891,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 107,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e1396f29f351",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-25 17:26:07",
        "ignitionOffTime": "2022-04-25 17:31:44",
        "city": "mohali",
        "date": "2022-09-09T11:16:55.153Z",
        "fuelConsumed": 0.10999999999999943,
        "fuelConsumedperRunHour": 1.175074183976255,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 337,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e128b117fb43",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-05 09:07:23",
        "ignitionOffTime": "2022-05-05 09:59:08",
        "city": "mohali",
        "date": "2022-09-09T11:16:43.095Z",
        "fuelConsumed": 3.509999999999991,
        "fuelConsumedperRunHour": 4.069565217391293,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 3105,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e128b117fb44",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-05 17:06:12",
        "ignitionOffTime": "2022-05-05 17:07:38",
        "city": "mohali",
        "date": "2022-09-09T11:16:43.095Z",
        "fuelConsumed": -0.6899999999999977,
        "fuelConsumedperRunHour": -28.88372093023246,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 86,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20646164e1390f1dc33d",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-19 18:09:16",
        "ignitionOffTime": "2022-04-19 18:15:26",
        "city": "mohali",
        "date": "2022-09-09T11:16:47.028Z",
        "fuelConsumed": -0.46999999999999886,
        "fuelConsumedperRunHour": -4.572972972972962,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 370,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20646164e1390f1dc33e",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-19 19:17:14",
        "ignitionOffTime": "2022-04-19 19:26:41",
        "city": "mohali",
        "date": "2022-09-09T11:16:47.028Z",
        "fuelConsumed": 0.12000000000000455,
        "fuelConsumedperRunHour": 0.7619047619047907,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 567,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e128b117fb42",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-20 12:29:26",
        "ignitionOffTime": "2022-04-20 12:29:32",
        "city": "mohali",
        "date": "2022-09-09T11:16:47.015Z",
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 6,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e1390f1dc343",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-20 15:39:33",
        "ignitionOffTime": "2022-04-20 15:41:13",
        "city": "mohali",
        "date": "2022-09-09T11:16:47.015Z",
        "fuelConsumed": -0.3999999999999915,
        "fuelConsumedperRunHour": -14.399999999999693,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 100,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e1390f1dc345",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-20 19:44:59",
        "ignitionOffTime": "2022-04-20 19:46:51",
        "city": "mohali",
        "date": "2022-09-09T11:16:47.015Z",
        "fuelConsumed": -1.4199999999999875,
        "fuelConsumedperRunHour": -45.64285714285674,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 112,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e128b009d733",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-14 07:24:42",
        "ignitionOffTime": "2022-05-14 07:35:26",
        "city": "mohali",
        "date": "2022-09-09T11:16:45.094Z",
        "fuelConsumed": 0.6499999999999986,
        "fuelConsumedperRunHour": 3.6335403726708,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 644,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e128b009d734",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-14 11:12:04",
        "ignitionOffTime": "2022-05-14 11:17:11",
        "city": "mohali",
        "date": "2022-09-09T11:16:45.094Z",
        "fuelConsumed": -0.259999999999998,
        "fuelConsumedperRunHour": -3.048859934853397,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 307,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20646164e16d7363c7f5",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-30 16:40:53",
        "ignitionOffTime": "2022-04-30 16:40:58",
        "city": "mohali",
        "date": "2022-09-09T11:16:49.045Z",
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 5,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20646164e16d7363c7f6",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-30 16:41:03",
        "ignitionOffTime": "2022-04-30 16:41:14",
        "city": "mohali",
        "date": "2022-09-09T11:16:49.045Z",
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 11,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e16d7363c7f7",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-30 16:41:26",
        "ignitionOffTime": "2022-04-30 16:41:29",
        "city": "mohali",
        "date": "2022-09-09T11:16:49.045Z",
        "fuelConsumed": -0.060000000000002274,
        "fuelConsumedperRunHour": -72.00000000000273,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 3,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e16d7363c7f8",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-30 16:41:38",
        "ignitionOffTime": "2022-04-30 16:41:48",
        "city": "mohali",
        "date": "2022-09-09T11:16:49.045Z",
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 10,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e16d7363c7f9",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-30 16:41:52",
        "ignitionOffTime": "2022-04-30 16:41:56",
        "city": "mohali",
        "date": "2022-09-09T11:16:49.045Z",
        "fuelConsumed": -0.04999999999999716,
        "fuelConsumedperRunHour": -44.99999999999744,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 4,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e16d7363c7fa",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-30 16:42:02",
        "ignitionOffTime": "2022-04-30 16:42:36",
        "city": "mohali",
        "date": "2022-09-09T11:16:49.045Z",
        "fuelConsumed": -0.060000000000002274,
        "fuelConsumedperRunHour": -6.352941176470829,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 34,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e16d7363c7fb",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-30 16:59:20",
        "ignitionOffTime": "2022-04-30 16:59:27",
        "city": "mohali",
        "date": "2022-09-09T11:16:49.045Z",
        "fuelConsumed": -0.05999999999998806,
        "fuelConsumedperRunHour": -30.85714285713672,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 7,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e16d7363c7fc",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-30 16:59:36",
        "ignitionOffTime": "2022-04-30 16:59:39",
        "city": "mohali",
        "date": "2022-09-09T11:16:49.045Z",
        "fuelConsumed": -0.05999999999998806,
        "fuelConsumedperRunHour": -71.99999999998568,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 3,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e128615fecd6",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-11 22:41:10",
        "ignitionOffTime": "2022-04-11 23:55:31",
        "city": "mohali",
        "date": "2022-09-09T11:16:48.041Z",
        "fuelConsumed": 5.010000000000019,
        "fuelConsumedperRunHour": 4.043039677202437,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 4461,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e128b117fb54",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-09 15:02:00",
        "ignitionOffTime": "2022-04-09 15:16:29",
        "city": "mohali",
        "date": "2022-09-09T11:16:55.078Z",
        "fuelConsumed": 12.430000000000007,
        "fuelConsumedperRunHour": 51.49367088607598,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 869,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e12eb137678d",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-11 14:31:01",
        "ignitionOffTime": "2022-04-11 15:14:26",
        "city": "mohali",
        "date": "2022-09-09T11:16:48.041Z",
        "fuelConsumed": 1.6899999999999977,
        "fuelConsumedperRunHour": 2.335508637236081,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 2605,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e12eb137678e",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-11 16:24:22",
        "ignitionOffTime": "2022-04-11 16:34:29",
        "city": "mohali",
        "date": "2022-09-09T11:16:48.041Z",
        "fuelConsumed": 0.4199999999999875,
        "fuelConsumedperRunHour": 2.4909390444809802,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 607,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e12eb137678f",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-11 18:01:56",
        "ignitionOffTime": "2022-04-11 18:08:09",
        "city": "mohali",
        "date": "2022-09-09T11:16:48.041Z",
        "fuelConsumed": 0.12000000000000455,
        "fuelConsumedperRunHour": 1.1581769436997758,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 373,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e16d7363c801",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-11 18:32:39",
        "ignitionOffTime": "2022-04-11 19:43:48",
        "city": "mohali",
        "date": "2022-09-09T11:16:48.041Z",
        "fuelConsumed": 4.0800000000000125,
        "fuelConsumedperRunHour": 3.4406184118060543,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 4269,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e12eb13767a3",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-25 07:18:11",
        "ignitionOffTime": "2022-04-25 07:22:22",
        "city": "mohali",
        "date": "2022-09-09T11:16:55.153Z",
        "fuelConsumed": 0.710000000000008,
        "fuelConsumedperRunHour": 10.18326693227103,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 251,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e128871572f1",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-06-26 13:46:24",
        "ignitionOffTime": "2022-06-26 14:01:29",
        "city": "mohali",
        "date": "2022-09-09T11:16:47.125Z",
        "fuelConsumed": 1.4200000000000017,
        "fuelConsumedperRunHour": 5.6486187845303935,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 905,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e128871572f7",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-09 16:16:52",
        "ignitionOffTime": "2022-04-09 16:34:09",
        "city": "mohali",
        "date": "2022-09-09T11:16:55.078Z",
        "fuelConsumed": 1.6099999999999852,
        "fuelConsumedperRunHour": 5.5891996142718865,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 1037,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e12f344096a1",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-06-28 15:16:51",
        "ignitionOffTime": "2022-06-28 15:16:57",
        "city": "mohali",
        "date": "2022-09-09T11:16:54.078Z",
        "fuelConsumed": -0.060000000000002274,
        "fuelConsumedperRunHour": -36.000000000001364,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 6,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e12f34409698",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-25 07:28:52",
        "ignitionOffTime": "2022-04-25 07:34:27",
        "city": "mohali",
        "date": "2022-09-09T11:16:55.153Z",
        "fuelConsumed": -0.18999999999999773,
        "fuelConsumedperRunHour": -2.0417910447760947,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 335,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e12f34409699",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-25 09:06:18",
        "ignitionOffTime": "2022-04-25 09:25:14",
        "city": "mohali",
        "date": "2022-09-09T11:16:55.153Z",
        "fuelConsumed": 0.12999999999999545,
        "fuelConsumedperRunHour": 0.4119718309859011,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 1136,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e12f3440969a",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-25 13:46:41",
        "ignitionOffTime": "2022-04-25 14:43:08",
        "city": "mohali",
        "date": "2022-09-09T11:16:55.153Z",
        "fuelConsumed": 2.3900000000000006,
        "fuelConsumedperRunHour": 2.540301151461471,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 3387,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e128b009d74c",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-24 00:09:45",
        "ignitionOffTime": "2022-04-24 00:10:45",
        "city": "mohali",
        "date": "2022-09-09T11:16:52.044Z",
        "fuelConsumed": 0.6400000000000006,
        "fuelConsumedperRunHour": 38.400000000000034,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 60,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e128b009d74d",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-24 07:18:23",
        "ignitionOffTime": "2022-04-24 07:24:57",
        "city": "mohali",
        "date": "2022-09-09T11:16:52.044Z",
        "fuelConsumed": -0.05999999999999517,
        "fuelConsumedperRunHour": -0.548223350253763,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 394,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e128b009d74e",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-24 11:01:26",
        "ignitionOffTime": "2022-04-24 11:08:45",
        "city": "mohali",
        "date": "2022-09-09T11:16:52.044Z",
        "fuelConsumed": 0.11999999999999744,
        "fuelConsumedperRunHour": 0.9840546697038515,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 439,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e128b009d74f",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-24 13:31:07",
        "ignitionOffTime": "2022-04-24 13:36:12",
        "city": "mohali",
        "date": "2022-09-09T11:16:52.044Z",
        "fuelConsumed": 0.060000000000002274,
        "fuelConsumedperRunHour": 0.7081967213115022,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 305,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e128b009d750",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-24 16:14:21",
        "ignitionOffTime": "2022-04-24 17:06:30",
        "city": "mohali",
        "date": "2022-09-09T11:16:52.044Z",
        "fuelConsumed": 2.530000000000001,
        "fuelConsumedperRunHour": 2.9108341323106437,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 3129,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e128b009d751",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-24 17:06:42",
        "ignitionOffTime": "2022-04-24 17:33:54",
        "city": "mohali",
        "date": "2022-09-09T11:16:52.044Z",
        "fuelConsumed": 0.9099999999999966,
        "fuelConsumedperRunHour": 2.007352941176463,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 1632,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e12887157305",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-26 13:00:26",
        "ignitionOffTime": "2022-04-26 13:00:41",
        "city": "mohali",
        "date": "2022-09-09T11:16:54.034Z",
        "fuelConsumed": -0.28999999999999915,
        "fuelConsumedperRunHour": -69.5999999999998,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 15,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e12887157306",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-26 13:00:45",
        "ignitionOffTime": "2022-04-26 13:09:45",
        "city": "mohali",
        "date": "2022-09-09T11:16:54.034Z",
        "fuelConsumed": 0.6500000000000057,
        "fuelConsumedperRunHour": 4.333333333333371,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 540,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e128871572f8",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-09 21:50:09",
        "ignitionOffTime": "2022-04-09 23:28:18",
        "city": "mohali",
        "date": "2022-09-09T11:16:55.078Z",
        "fuelConsumed": 6.510000000000019,
        "fuelConsumedperRunHour": 3.9796230259806538,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 5889,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e128b117fb56",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-26 05:34:23",
        "ignitionOffTime": "2022-05-26 06:20:12",
        "city": "mohali",
        "date": "2022-09-09T11:16:42.538Z",
        "fuelConsumed": 1.8900000000000006,
        "fuelConsumedperRunHour": 2.4750818479447076,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 2749,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e1287e655c4c",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-16 00:00:09",
        "ignitionOffTime": "2022-04-16 00:22:53",
        "city": "mohali",
        "date": "2022-09-09T11:16:54.078Z",
        "fuelConsumed": 1.4699999999999989,
        "fuelConsumedperRunHour": 3.879765395894425,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 1364,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e1287e655c4d",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-16 02:13:27",
        "ignitionOffTime": "2022-04-16 02:19:40",
        "city": "mohali",
        "date": "2022-09-09T11:16:54.078Z",
        "fuelConsumed": 0.3499999999999943,
        "fuelConsumedperRunHour": 3.3780160857908297,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 373,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e12eb13767ac",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-06-28 15:16:42",
        "ignitionOffTime": "2022-06-28 15:16:47",
        "city": "mohali",
        "date": "2022-09-09T11:16:54.078Z",
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 5,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e12eb13767af",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-06-28 15:34:55",
        "ignitionOffTime": "2022-06-28 15:35:06",
        "city": "mohali",
        "date": "2022-09-09T11:16:54.078Z",
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 11,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e12eb13767b0",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-06-28 15:35:10",
        "ignitionOffTime": "2022-06-28 15:35:13",
        "city": "mohali",
        "date": "2022-09-09T11:16:54.078Z",
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 3,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e12eb13767a7",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-25 16:48:02",
        "ignitionOffTime": "2022-04-25 17:23:04",
        "city": "mohali",
        "date": "2022-09-09T11:16:55.153Z",
        "fuelConsumed": 1.5300000000000011,
        "fuelConsumedperRunHour": 2.620361560418651,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 2102,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e128b009d752",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-24 18:40:12",
        "ignitionOffTime": "2022-04-24 18:51:49",
        "city": "mohali",
        "date": "2022-09-09T11:16:52.044Z",
        "fuelConsumed": -0.060000000000002274,
        "fuelConsumedperRunHour": -0.3098995695839429,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 697,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e128b009d753",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-24 21:11:43",
        "ignitionOffTime": "2022-04-24 21:45:50",
        "city": "mohali",
        "date": "2022-09-09T11:16:52.044Z",
        "fuelConsumed": -24.580000000000005,
        "fuelConsumedperRunHour": -43.22813873961897,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 2047,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e1280b0f03cb",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-10 11:49:34",
        "ignitionOffTime": "2022-05-10 11:56:24",
        "city": "mohali",
        "date": "2022-09-09T11:16:52.114Z",
        "fuelConsumed": -0.4100000000000037,
        "fuelConsumedperRunHour": -3.6000000000000325,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 410,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e1280b0f03cc",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-10 15:24:27",
        "ignitionOffTime": "2022-05-10 15:29:12",
        "city": "mohali",
        "date": "2022-09-09T11:16:52.114Z",
        "fuelConsumed": -0.759999999999998,
        "fuelConsumedperRunHour": -9.599999999999975,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 285,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e1280b0f03cf",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-26 17:22:59",
        "ignitionOffTime": "2022-04-26 17:41:14",
        "city": "mohali",
        "date": "2022-09-09T11:16:54.034Z",
        "fuelConsumed": 0.3400000000000034,
        "fuelConsumedperRunHour": 1.1178082191780936,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 1095,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e1280b0f03d0",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-26 17:44:15",
        "ignitionOffTime": "2022-04-26 17:44:44",
        "city": "mohali",
        "date": "2022-09-09T11:16:54.034Z",
        "fuelConsumed": 0.519999999999996,
        "fuelConsumedperRunHour": 64.55172413793055,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 29,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e12887157307",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-26 13:17:28",
        "ignitionOffTime": "2022-04-26 13:26:27",
        "city": "mohali",
        "date": "2022-09-09T11:16:54.034Z",
        "fuelConsumed": 0.5300000000000011,
        "fuelConsumedperRunHour": 3.539888682745833,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 539,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e12887157308",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-26 13:32:32",
        "ignitionOffTime": "2022-04-26 13:47:12",
        "city": "mohali",
        "date": "2022-09-09T11:16:54.034Z",
        "fuelConsumed": -0.5900000000000034,
        "fuelConsumedperRunHour": -2.413636363636378,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 880,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e12887157309",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-26 13:47:14",
        "ignitionOffTime": "2022-04-26 14:08:26",
        "city": "mohali",
        "date": "2022-09-09T11:16:54.034Z",
        "fuelConsumed": 0.6499999999999986,
        "fuelConsumedperRunHour": 1.83962264150943,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 1272,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e1288715730a",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-26 14:08:29",
        "ignitionOffTime": "2022-04-26 14:23:16",
        "city": "mohali",
        "date": "2022-09-09T11:16:54.034Z",
        "fuelConsumed": 1.230000000000004,
        "fuelConsumedperRunHour": 4.992108229988743,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 887,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e1288715730b",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-26 14:23:30",
        "ignitionOffTime": "2022-04-26 14:24:51",
        "city": "mohali",
        "date": "2022-09-09T11:16:54.034Z",
        "fuelConsumed": 0.11999999999999744,
        "fuelConsumedperRunHour": 5.33333333333322,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 81,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e1288715730c",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-26 14:24:51",
        "ignitionOffTime": "2022-04-26 14:25:22",
        "city": "mohali",
        "date": "2022-09-09T11:16:54.034Z",
        "fuelConsumed": -0.060000000000002274,
        "fuelConsumedperRunHour": -6.967741935484135,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 31,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e1288715730e",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-26 14:25:45",
        "ignitionOffTime": "2022-04-26 14:26:40",
        "city": "mohali",
        "date": "2022-09-09T11:16:54.034Z",
        "fuelConsumed": 0.45999999999999375,
        "fuelConsumedperRunHour": 30.1090909090905,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 55,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e1288715730f",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-26 15:14:11",
        "ignitionOffTime": "2022-04-26 15:16:33",
        "city": "mohali",
        "date": "2022-09-09T11:16:54.034Z",
        "fuelConsumed": -0.10999999999999943,
        "fuelConsumedperRunHour": -2.788732394366183,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 142,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e12887157310",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-26 16:34:37",
        "ignitionOffTime": "2022-04-26 17:22:42",
        "city": "mohali",
        "date": "2022-09-09T11:16:54.034Z",
        "fuelConsumed": 0.3499999999999943,
        "fuelConsumedperRunHour": 0.43674176776429097,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 2885,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e128615feced",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-06-28 15:18:13",
        "ignitionOffTime": "2022-06-28 15:18:16",
        "city": "mohali",
        "date": "2022-09-09T11:16:54.078Z",
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 3,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e1396f29f35f",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-06-19 08:58:45",
        "ignitionOffTime": "2022-06-19 09:27:35",
        "city": "mohali",
        "date": "2022-09-09T11:16:46.068Z",
        "fuelConsumed": 1.2199999999999989,
        "fuelConsumedperRunHour": 2.5387283236994196,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 1730,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e1396f29f361",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-06-19 10:58:57",
        "ignitionOffTime": "2022-06-19 11:01:35",
        "city": "mohali",
        "date": "2022-09-09T11:16:46.068Z",
        "fuelConsumed": 0.23000000000000398,
        "fuelConsumedperRunHour": 5.240506329114015,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 158,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e12eb13767b3",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-06-28 15:50:23",
        "ignitionOffTime": "2022-06-28 15:50:24",
        "city": "mohali",
        "date": "2022-09-09T11:16:54.078Z",
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 1,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e12eb13767b4",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-06-28 15:50:34",
        "ignitionOffTime": "2022-06-28 15:50:43",
        "city": "mohali",
        "date": "2022-09-09T11:16:54.078Z",
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 9,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e12eb13767b5",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-06-28 15:50:47",
        "ignitionOffTime": "2022-06-28 15:50:52",
        "city": "mohali",
        "date": "2022-09-09T11:16:54.078Z",
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 5,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e12eb13767b6",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-06-28 15:50:57",
        "ignitionOffTime": "2022-06-28 15:50:58",
        "city": "mohali",
        "date": "2022-09-09T11:16:54.078Z",
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 1,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e128b009d762",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-06-19 07:35:20",
        "ignitionOffTime": "2022-06-19 07:42:08",
        "city": "mohali",
        "date": "2022-09-09T11:16:46.068Z",
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 408,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e128b009d769",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-06-19 12:45:23",
        "ignitionOffTime": "2022-06-19 12:45:53",
        "city": "mohali",
        "date": "2022-09-09T11:16:46.068Z",
        "fuelConsumed": -0.11999999999999034,
        "fuelConsumedperRunHour": -14.39999999999884,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 30,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e128b009d76a",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-06-19 12:46:03",
        "ignitionOffTime": "2022-06-19 13:15:16",
        "city": "mohali",
        "date": "2022-09-09T11:16:46.068Z",
        "fuelConsumed": 1.6999999999999886,
        "fuelConsumedperRunHour": 3.4911580148316936,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 1753,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e12eb13767c9",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-06-02 15:37:35",
        "ignitionOffTime": "2022-06-02 15:52:39",
        "city": "mohali",
        "date": "2022-09-09T11:16:45.356Z",
        "fuelConsumed": 1.4899999999999949,
        "fuelConsumedperRunHour": 5.93362831858405,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 904,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e128b009d773",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-10 02:13:18",
        "ignitionOffTime": "2022-04-10 03:27:43",
        "city": "mohali",
        "date": "2022-09-09T11:16:49.344Z",
        "fuelConsumed": 3.4399999999999977,
        "fuelConsumedperRunHour": 2.773572228443447,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 4465,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e128b009d774",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-10 08:20:42",
        "ignitionOffTime": "2022-04-10 08:32:33",
        "city": "mohali",
        "date": "2022-09-09T11:16:49.344Z",
        "fuelConsumed": 0.7299999999999898,
        "fuelConsumedperRunHour": 3.696202531645518,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 711,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e128b009d775",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-10 21:19:56",
        "ignitionOffTime": "2022-04-10 22:32:09",
        "city": "mohali",
        "date": "2022-09-09T11:16:49.344Z",
        "fuelConsumed": 4.380000000000024,
        "fuelConsumedperRunHour": 3.6390491576275292,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 4333,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e128b009d776",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-10 23:20:58",
        "ignitionOffTime": "2022-04-10 23:39:21",
        "city": "mohali",
        "date": "2022-09-09T11:16:49.344Z",
        "fuelConsumed": 1.0900000000000034,
        "fuelConsumedperRunHour": 3.5575702629193224,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 1103,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e128b009d777",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-10 23:53:50",
        "ignitionOffTime": "2022-04-10 23:55:29",
        "city": "mohali",
        "date": "2022-09-09T11:16:49.344Z",
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 99,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e1396f29f371",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-21 09:36:58",
        "ignitionOffTime": "2022-04-21 09:38:47",
        "city": "mohali",
        "date": "2022-09-09T11:16:47.033Z",
        "fuelConsumed": -1.2999999999999972,
        "fuelConsumedperRunHour": -42.935779816513666,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 109,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e1396f29f372",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-21 11:03:20",
        "ignitionOffTime": "2022-04-21 11:22:02",
        "city": "mohali",
        "date": "2022-09-09T11:16:47.033Z",
        "fuelConsumed": 0.04999999999999716,
        "fuelConsumedperRunHour": 0.16042780748662192,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 1122,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e1396f29f373",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-21 12:07:21",
        "ignitionOffTime": "2022-04-21 12:12:46",
        "city": "mohali",
        "date": "2022-09-09T11:16:47.033Z",
        "fuelConsumed": -1.1299999999999955,
        "fuelConsumedperRunHour": -12.516923076923026,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 325,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e1396f29f374",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-21 15:45:27",
        "ignitionOffTime": "2022-04-21 16:25:15",
        "city": "mohali",
        "date": "2022-09-09T11:16:47.033Z",
        "fuelConsumed": 1.6600000000000108,
        "fuelConsumedperRunHour": 2.5025125628140867,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 2388,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e1396f29f369",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-06-30 10:46:40",
        "ignitionOffTime": "2022-06-30 10:49:46",
        "city": "mohali",
        "date": "2022-09-09T11:16:54.246Z",
        "fuelConsumed": -0.060000000000002274,
        "fuelConsumedperRunHour": -1.1612903225806892,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 186,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e1280b0f03eb",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-18 17:26:49",
        "ignitionOffTime": "2022-04-18 17:59:45",
        "city": "mohali",
        "date": "2022-09-09T11:16:50.167Z",
        "fuelConsumed": 1.4299999999999926,
        "fuelConsumedperRunHour": 2.6052631578947234,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 1976,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e12f344096c5",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-21 09:22:11",
        "ignitionOffTime": "2022-04-21 09:25:31",
        "city": "mohali",
        "date": "2022-09-09T11:16:47.033Z",
        "fuelConsumed": -1.4200000000000017,
        "fuelConsumedperRunHour": -25.56000000000003,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 200,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e12f344096ca",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-21 18:04:41",
        "ignitionOffTime": "2022-04-21 18:23:01",
        "city": "mohali",
        "date": "2022-09-09T11:16:47.033Z",
        "fuelConsumed": 0.5200000000000102,
        "fuelConsumedperRunHour": 1.7018181818182152,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 1100,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e12f344096cb",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-21 18:23:01",
        "ignitionOffTime": "2022-04-21 18:23:05",
        "city": "mohali",
        "date": "2022-09-09T11:16:47.033Z",
        "fuelConsumed": 0.12999999999999545,
        "fuelConsumedperRunHour": 116.99999999999591,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 4,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e12f344096cc",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-21 21:23:01",
        "ignitionOffTime": "2022-04-21 21:41:20",
        "city": "mohali",
        "date": "2022-09-09T11:16:47.033Z",
        "fuelConsumed": 0.5700000000000074,
        "fuelConsumedperRunHour": 1.8671519563239551,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 1099,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e12f344096cd",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-21 22:18:36",
        "ignitionOffTime": "2022-04-21 22:32:43",
        "city": "mohali",
        "date": "2022-09-09T11:16:47.033Z",
        "fuelConsumed": 0.4000000000000057,
        "fuelConsumedperRunHour": 1.7001180637544515,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 847,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e128615fed13",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-21 17:17:48",
        "ignitionOffTime": "2022-04-21 17:29:30",
        "city": "mohali",
        "date": "2022-09-09T11:16:47.033Z",
        "fuelConsumed": -0.7199999999999989,
        "fuelConsumedperRunHour": -3.6923076923076863,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 702,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e128615fed16",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-22 05:23:22",
        "ignitionOffTime": "2022-04-22 05:34:44",
        "city": "mohali",
        "date": "2022-09-09T11:16:55.012Z",
        "fuelConsumed": 0.060000000000002274,
        "fuelConsumedperRunHour": 0.3167155425220061,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 682,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e128615fed17",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-22 14:37:04",
        "ignitionOffTime": "2022-04-22 14:38:55",
        "city": "mohali",
        "date": "2022-09-09T11:16:55.012Z",
        "fuelConsumed": 0.060000000000002274,
        "fuelConsumedperRunHour": 1.9459459459460196,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 111,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e128615fed18",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-22 18:47:50",
        "ignitionOffTime": "2022-04-22 19:22:15",
        "city": "mohali",
        "date": "2022-09-09T11:16:55.012Z",
        "fuelConsumed": 1.8299999999999912,
        "fuelConsumedperRunHour": 3.190314769975772,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 2065,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e128622f0b70",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-04 09:36:11",
        "ignitionOffTime": "2022-05-04 09:37:21",
        "city": "mohali",
        "date": "2022-09-09T11:16:56.037Z",
        "fuelConsumed": -1.1799999999999997,
        "fuelConsumedperRunHour": -60.68571428571427,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 70,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e128622f0b71",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-04 11:35:01",
        "ignitionOffTime": "2022-05-04 11:46:31",
        "city": "mohali",
        "date": "2022-09-09T11:16:56.037Z",
        "fuelConsumed": 0.4200000000000017,
        "fuelConsumedperRunHour": 2.1913043478260956,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 690,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e1288715732c",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-12 00:00:10",
        "ignitionOffTime": "2022-04-12 00:55:38",
        "city": "mohali",
        "date": "2022-09-09T11:16:45.084Z",
        "fuelConsumed": 4.009999999999991,
        "fuelConsumedperRunHour": 4.337740384615375,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 3328,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e1280b0f03f7",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-12 21:36:51",
        "ignitionOffTime": "2022-04-12 21:54:31",
        "city": "mohali",
        "date": "2022-09-09T11:16:45.084Z",
        "fuelConsumed": 1.1200000000000045,
        "fuelConsumedperRunHour": 3.8037735849056755,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 1060,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e1280b0f03f8",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-12 22:29:03",
        "ignitionOffTime": "2022-04-12 22:30:55",
        "city": "mohali",
        "date": "2022-09-09T11:16:45.084Z",
        "fuelConsumed": 0.05000000000001137,
        "fuelConsumedperRunHour": 1.6071428571432227,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 112,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e1280b0f03f9",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-12 22:38:50",
        "ignitionOffTime": "2022-04-12 23:58:50",
        "city": "mohali",
        "date": "2022-09-09T11:16:45.084Z",
        "fuelConsumed": 4.779999999999973,
        "fuelConsumedperRunHour": 3.5849999999999795,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 4800,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e1396f29f37f",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-14 00:15:42",
        "ignitionOffTime": "2022-04-14 00:20:09",
        "city": "mohali",
        "date": "2022-09-09T11:16:55.098Z",
        "fuelConsumed": 0.46999999999999886,
        "fuelConsumedperRunHour": 6.337078651685378,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 267,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e1396f29f380",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-14 08:29:38",
        "ignitionOffTime": "2022-04-14 10:57:43",
        "city": "mohali",
        "date": "2022-09-09T11:16:55.098Z",
        "fuelConsumed": 7.530000000000001,
        "fuelConsumedperRunHour": 3.0509848058525613,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 8885,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e1396f29f381",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-14 18:57:47",
        "ignitionOffTime": "2022-04-14 20:39:41",
        "city": "mohali",
        "date": "2022-09-09T11:16:55.098Z",
        "fuelConsumed": 6.910000000000025,
        "fuelConsumedperRunHour": 4.068694798822389,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 6114,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e1396f29f382",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-14 21:04:46",
        "ignitionOffTime": "2022-04-14 21:06:47",
        "city": "mohali",
        "date": "2022-09-09T11:16:55.098Z",
        "fuelConsumed": 0.05000000000001137,
        "fuelConsumedperRunHour": 1.487603305785462,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 121,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e1396f29f383",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-14 21:18:45",
        "ignitionOffTime": "2022-04-14 21:21:20",
        "city": "mohali",
        "date": "2022-09-09T11:16:55.098Z",
        "fuelConsumed": 0.1599999999999966,
        "fuelConsumedperRunHour": 3.716129032257985,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 155,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e1396f29f37a",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-04 11:56:01",
        "ignitionOffTime": "2022-05-04 12:11:08",
        "city": "mohali",
        "date": "2022-09-09T11:16:56.037Z",
        "fuelConsumed": 0.4200000000000017,
        "fuelConsumedperRunHour": 1.6670341786108114,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 907,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e1396f29f37b",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-04 12:45:21",
        "ignitionOffTime": "2022-05-04 12:47:31",
        "city": "mohali",
        "date": "2022-09-09T11:16:56.037Z",
        "fuelConsumed": -0.35999999999999943,
        "fuelConsumedperRunHour": -9.969230769230755,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 130,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e1396f29f37d",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-04 14:10:22",
        "ignitionOffTime": "2022-05-04 14:38:07",
        "city": "mohali",
        "date": "2022-09-09T11:16:56.037Z",
        "fuelConsumed": 1.3999999999999986,
        "fuelConsumedperRunHour": 3.0270270270270236,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 1665,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e1286453a001",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-21 22:35:33",
        "ignitionOffTime": "2022-04-21 22:49:19",
        "city": "mohali",
        "date": "2022-09-09T11:16:47.033Z",
        "fuelConsumed": 0.1700000000000017,
        "fuelConsumedperRunHour": 0.7409200968523076,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 826,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20676164e1288715733f",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-25 10:07:36",
        "ignitionOffTime": "2022-05-25 10:09:17",
        "city": "mohali",
        "date": "2022-09-09T11:16:51.028Z",
        "fuelConsumed": -0.3500000000000014,
        "fuelConsumedperRunHour": -12.475247524752525,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 101,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e12f344096e2",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-06-18 21:11:47",
        "ignitionOffTime": "2022-06-18 21:20:28",
        "city": "mohali",
        "date": "2022-09-09T11:16:54.253Z",
        "fuelConsumed": 0.23000000000000398,
        "fuelConsumedperRunHour": 1.5892514395393749,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 521,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e12f344096e3",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-06-18 21:20:44",
        "ignitionOffTime": "2022-06-18 21:24:52",
        "city": "mohali",
        "date": "2022-09-09T11:16:54.253Z",
        "fuelConsumed": 0.3400000000000034,
        "fuelConsumedperRunHour": 4.935483870967792,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 248,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e128b117fb55",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-26 00:31:57",
        "ignitionOffTime": "2022-05-26 00:33:58",
        "city": "mohali",
        "date": "2022-09-09T11:16:42.538Z",
        "fuelConsumed": -0.5899999999999963,
        "fuelConsumedperRunHour": -17.553719008264352,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 121,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e1286453a002",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-12 09:04:05",
        "ignitionOffTime": "2022-04-12 09:06:13",
        "city": "mohali",
        "date": "2022-09-09T11:16:45.084Z",
        "fuelConsumed": 0.060000000000002274,
        "fuelConsumedperRunHour": 1.687500000000064,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 128,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e1286453a003",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-12 16:37:31",
        "ignitionOffTime": "2022-04-12 17:33:16",
        "city": "mohali",
        "date": "2022-09-09T11:16:45.084Z",
        "fuelConsumed": 3.1200000000000045,
        "fuelConsumedperRunHour": 3.3578475336322917,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 3345,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e1280b0f03f6",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-12 18:24:11",
        "ignitionOffTime": "2022-04-12 20:26:54",
        "city": "mohali",
        "date": "2022-09-09T11:16:45.084Z",
        "fuelConsumed": 7.590000000000003,
        "fuelConsumedperRunHour": 3.710987369278828,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 7363,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e1288715732d",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-12 06:15:27",
        "ignitionOffTime": "2022-04-12 07:40:40",
        "city": "mohali",
        "date": "2022-09-09T11:16:45.084Z",
        "fuelConsumed": 4.530000000000001,
        "fuelConsumedperRunHour": 3.1895169176608653,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 5113,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e12887157313",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-06-19 09:27:38",
        "ignitionOffTime": "2022-06-19 09:27:42",
        "city": "mohali",
        "date": "2022-09-09T11:16:46.068Z",
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 4,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e1396f29f362",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-06-19 12:38:53",
        "ignitionOffTime": "2022-06-19 12:41:34",
        "city": "mohali",
        "date": "2022-09-09T11:16:46.068Z",
        "fuelConsumed": -0.28999999999999204,
        "fuelConsumedperRunHour": -6.484472049689264,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 161,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e1390f1dc346",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-20 22:24:38",
        "ignitionOffTime": "2022-04-20 23:50:23",
        "city": "mohali",
        "date": "2022-09-09T11:16:47.015Z",
        "fuelConsumed": 4.140000000000001,
        "fuelConsumedperRunHour": 2.896793002915452,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 5145,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e1396f29f35e",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-06-19 08:39:02",
        "ignitionOffTime": "2022-06-19 08:47:54",
        "city": "mohali",
        "date": "2022-09-09T11:16:46.068Z",
        "fuelConsumed": -0.29000000000000625,
        "fuelConsumedperRunHour": -1.9624060150376361,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 532,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20636164e12ef978da71",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-19 10:07:11",
        "ignitionOffTime": "2022-04-19 10:15:55",
        "city": "mohali",
        "date": "2022-09-09T11:16:47.028Z",
        "fuelConsumed": 0.5699999999999932,
        "fuelConsumedperRunHour": 3.916030534351098,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 524,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e12887157304",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-06-28 15:16:05",
        "ignitionOffTime": "2022-06-28 15:16:09",
        "city": "mohali",
        "date": "2022-09-09T11:16:54.078Z",
        "fuelConsumed": -0.060000000000002274,
        "fuelConsumedperRunHour": -54.000000000002046,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 4,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e12f3440969e",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-06-28 15:16:19",
        "ignitionOffTime": "2022-06-28 15:16:24",
        "city": "mohali",
        "date": "2022-09-09T11:16:54.078Z",
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 5,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20646164e1396f29f34a",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-30 16:36:55",
        "ignitionOffTime": "2022-04-30 16:38:54",
        "city": "mohali",
        "date": "2022-09-09T11:16:49.045Z",
        "fuelConsumed": 0.28999999999999204,
        "fuelConsumedperRunHour": 8.773109243697238,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 119,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e128b009d74b",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-24 00:00:00",
        "ignitionOffTime": "2022-04-24 00:09:45",
        "city": "mohali",
        "date": "2022-09-09T11:16:52.044Z",
        "fuelConsumed": 1.7700000000000031,
        "fuelConsumedperRunHour": 10.89230769230771,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 585,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e1396f29f358",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-16 09:50:55",
        "ignitionOffTime": "2022-04-16 15:14:56",
        "city": "mohali",
        "date": "2022-09-09T11:16:54.078Z",
        "fuelConsumed": 20.799999999999997,
        "fuelConsumedperRunHour": 3.851653721516382,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 19441,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e12eb13767b1",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-06-28 15:49:18",
        "ignitionOffTime": "2022-06-28 15:49:30",
        "city": "mohali",
        "date": "2022-09-09T11:16:54.078Z",
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 12,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e12eb13767b2",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-06-28 15:50:02",
        "ignitionOffTime": "2022-06-28 15:50:20",
        "city": "mohali",
        "date": "2022-09-09T11:16:54.078Z",
        "fuelConsumed": 0,
        "fuelConsumedperRunHour": 0,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 18,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20656164e1288715730d",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-04-26 14:25:26",
        "ignitionOffTime": "2022-04-26 14:25:42",
        "city": "mohali",
        "date": "2022-09-09T11:16:54.034Z",
        "fuelConsumed": 0.28999999999999915,
        "fuelConsumedperRunHour": 65.24999999999982,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 16,
        "siteId": "",
        "siteName": "",
        "size": "25kva",
        "state": "punjab"
    },
    {
        "_id": "631b20666164e1396f29f37c",
        "thingName": "nayagaontank1",
        "ignitionOnTime": "2022-05-04 12:47:53",
        "ignitionOffTime": "2022-05-04 12:55:28",
        "city": "mohali",
        "date": "2022-09-09T11:16:56.037Z",
        "fuelConsumed": 0.6000000000000014,
        "fuelConsumedperRunHour": 4.747252747252759,
        "make": "kirloskar",
        "model": "model1",
        "pincode": "160103",
        "runDuration": 455,
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "size": "25kva",
        "state": "punjab"
    }
];

export const fuelingDefueling = [
    {
        "_id": "631c6c566164e17f277cb0e2",
        "thingName": "baltanatank1",
        "time": "2022-05-05 19:01:47",
        "volume": 56.97,
        "city": "mohali",
        "date": "2022-09-10T10:52:35.649Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "state": "punjab"
    },
    {
        "_id": "631c6c566164e17f241008c2",
        "thingName": "nayagaontank1",
        "time": "2022-06-24 16:01:41",
        "volume": 2.02,
        "city": "mohali",
        "date": "2022-09-10T10:52:38.648Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c566164e17f256836a2",
        "thingName": "nayagaontank1",
        "time": "2022-05-16 13:08:28",
        "volume": 7.489999999999998,
        "city": "mohali",
        "date": "2022-09-10T10:52:38.690Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c566164e179f110bbe2",
        "thingName": "nayagaontank1",
        "time": "2022-05-27 11:39:18",
        "volume": 5.63,
        "city": "mohali",
        "date": "2022-09-10T10:52:38.756Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c566164e17f282b3463",
        "thingName": "bhagomajrotank1",
        "time": "2022-05-13 20:53:25",
        "volume": 38.66,
        "city": "mohali",
        "date": "2022-09-10T10:52:41.692Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "state": "punjab"
    },
    {
        "_id": "631c6c566164e17f282b3464",
        "thingName": "landratank1",
        "time": "2022-05-13 21:16:40",
        "volume": 37.58,
        "city": "mohali",
        "date": "2022-09-10T10:52:41.692Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "state": "punjab"
    },
    {
        "_id": "631c6c4f6164e17f237cc821",
        "thingName": "baltanatank1",
        "time": "2022-06-06 13:44:34",
        "volume": 36.21,
        "city": "mohali",
        "date": "2022-09-10T10:52:41.770Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "state": "punjab"
    },
    {
        "_id": "631c6c506164e17f237cc822",
        "thingName": "nayagaontank1",
        "time": "2022-06-06 14:17:36",
        "volume": 2.18,
        "city": "mohali",
        "date": "2022-09-10T10:52:41.770Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c506164e17f29002b01",
        "thingName": "landratank1",
        "time": "2022-05-25 18:07:11",
        "volume": 19.84,
        "city": "mohali",
        "date": "2022-09-10T10:52:42.694Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "state": "punjab"
    },
    {
        "_id": "631c6c4f6164e17f26492b51",
        "thingName": "nayagaontank1",
        "time": "2022-04-28 20:11:29",
        "volume": 2.08,
        "city": "mohali",
        "date": "2022-09-10T10:52:45.613Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c516164e17f282b3461",
        "thingName": "baltanatank1",
        "time": "2022-05-26 13:31:29",
        "volume": 41.84,
        "city": "mohali",
        "date": "2022-09-10T10:54:34.447Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "state": "punjab"
    },
    {
        "_id": "631c6c516164e17f282b3462",
        "thingName": "nayagaontank1",
        "time": "2022-05-26 09:21:31",
        "volume": 8.52,
        "city": "mohali",
        "date": "2022-09-10T10:54:34.447Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c506164e17f241008c1",
        "thingName": "baltanatank1",
        "time": "2022-07-09 18:26:48",
        "volume": 53.52,
        "city": "mohali",
        "date": "2022-09-10T10:54:52.846Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "state": "punjab"
    },
    {
        "_id": "631c6c566164e146942e6776",
        "thingName": "nayagaontank1",
        "time": "2022-06-19 10:43:49",
        "volume": 3.9499999999999997,
        "city": "mohali",
        "date": "2022-09-10T10:54:54.435Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c566164e146942e6777",
        "thingName": "nayagaontank1",
        "time": "2022-06-19 14:31:24",
        "volume": 3.97,
        "city": "mohali",
        "date": "2022-09-10T10:54:54.435Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c566164e17f256836a5",
        "thingName": "nayagaontank1",
        "time": "2022-05-12 22:26:26",
        "volume": 2.73,
        "city": "mohali",
        "date": "2022-09-10T10:52:34.095Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c566164e17f256836a3",
        "thingName": "nayagaontank1",
        "time": "2022-04-24 21:36:34",
        "volume": 19.66,
        "city": "mohali",
        "date": "2022-09-10T10:52:34.638Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160103",
        "siteId": "",
        "siteName": "",
        "state": "punjab"
    },
    {
        "_id": "631c6c576164e102b12ca703",
        "thingName": "bhagomajrotank1",
        "time": "2022-04-09 17:52:35",
        "volume": 10.49,
        "city": "mohali",
        "date": "2022-09-10T10:52:38.599Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160055",
        "siteId": "",
        "siteName": "",
        "state": "punjab"
    },
    {
        "_id": "631c6c576164e102b12ca704",
        "thingName": "landratank1",
        "time": "2022-04-09 17:20:14",
        "volume": 9.54,
        "city": "mohali",
        "date": "2022-09-10T10:52:38.599Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140307",
        "siteId": "",
        "siteName": "",
        "state": "punjab"
    },
    {
        "_id": "631c6c576164e102b12ca705",
        "thingName": "nayagaontank1",
        "time": "2022-04-09 15:14:01",
        "volume": 12,
        "city": "mohali",
        "date": "2022-09-10T10:52:38.599Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160103",
        "siteId": "",
        "siteName": "",
        "state": "punjab"
    },
    {
        "_id": "631c6c566164e152f10c747f",
        "thingName": "baltanatank1",
        "time": "2022-06-25 21:45:44",
        "volume": 2.91,
        "city": "mohali",
        "date": "2022-09-10T10:52:38.748Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "state": "punjab"
    },
    {
        "_id": "631c6c766164e102b12ca706",
        "thingName": "baltanatank1",
        "time": "2022-06-26 23:41:26",
        "volume": 33.97,
        "city": "mohali",
        "date": "2022-09-10T10:52:38.807Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "state": "punjab"
    },
    {
        "_id": "631c6c566164e152f10c7480",
        "thingName": "landratank1",
        "time": "2022-06-28 09:14:33",
        "volume": 36.88,
        "city": "mohali",
        "date": "2022-09-10T10:52:42.665Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "state": "punjab"
    },
    {
        "_id": "631c6c566164e17f256836a4",
        "thingName": "bhagomajrotank1",
        "time": "2022-07-02 19:33:07",
        "volume": 18.41,
        "city": "mohali",
        "date": "2022-09-10T10:52:44.658Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "state": "punjab"
    },
    {
        "_id": "631c6c566164e102b12ca701",
        "thingName": "landratank1",
        "time": "2022-07-08 07:55:48",
        "volume": 15.16,
        "city": "mohali",
        "date": "2022-09-10T10:52:44.687Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "state": "punjab"
    },
    {
        "_id": "631c6c566164e102b12ca702",
        "thingName": "landratank1",
        "time": "2022-07-08 08:01:48",
        "volume": 20.48,
        "city": "mohali",
        "date": "2022-09-10T10:52:44.687Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "state": "punjab"
    },
    {
        "_id": "631c6c566164e152f10c7481",
        "thingName": "nayagaontank1",
        "time": "2022-07-17 18:03:39",
        "volume": 21.66,
        "city": "mohali",
        "date": "2022-09-10T10:52:46.252Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c576164e152f10c7482",
        "thingName": "nayagaontank1",
        "time": "2022-07-17 14:03:13",
        "volume": 20.99,
        "city": "mohali",
        "date": "2022-09-10T10:52:46.252Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c566164e17f241008c3",
        "thingName": "nayagaontank1",
        "time": "2022-07-28 15:05:19",
        "volume": 40.03,
        "city": "mohali",
        "date": "2022-09-10T10:52:33.673Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c566164e17f241008c4",
        "thingName": "nayagaontank1",
        "time": "2022-07-28 14:35:15",
        "volume": 11.06,
        "city": "mohali",
        "date": "2022-09-10T10:52:33.673Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c566164e17f241008c5",
        "thingName": "baltanatank1",
        "time": "2022-06-17 09:24:54",
        "volume": 16.5,
        "city": "mohali",
        "date": "2022-09-10T10:52:42.715Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "state": "punjab"
    },
    {
        "_id": "631c6c576164e17f241008c6",
        "thingName": "baltanatank1",
        "time": "2022-06-17 14:23:30",
        "volume": 27.66,
        "city": "mohali",
        "date": "2022-09-10T10:52:42.715Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "state": "punjab"
    },
    {
        "_id": "631c6c576164e17f241008c7",
        "thingName": "baltanatank1",
        "time": "2022-06-17 18:46:06",
        "volume": 58.42,
        "city": "mohali",
        "date": "2022-09-10T10:52:42.715Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "state": "punjab"
    },
    {
        "_id": "631c6c576164e17f241008c8",
        "thingName": "nayagaontank1",
        "time": "2022-06-17 12:00:17",
        "volume": 19.6,
        "city": "mohali",
        "date": "2022-09-10T10:52:42.715Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c576164e17f241008c9",
        "thingName": "nayagaontank1",
        "time": "2022-06-17 04:29:29",
        "volume": 13.230000000000002,
        "city": "mohali",
        "date": "2022-09-10T10:52:42.715Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c576164e17f241008ca",
        "thingName": "nayagaontank1",
        "time": "2022-06-17 06:29:42",
        "volume": 8.21,
        "city": "mohali",
        "date": "2022-09-10T10:52:42.715Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c576164e146942e677b",
        "thingName": "bhagomajrotank1",
        "time": "2022-08-06 16:14:24",
        "volume": 18.44,
        "city": "mohali",
        "date": "2022-09-10T10:52:47.636Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "state": "punjab"
    },
    {
        "_id": "631c6c576164e146942e677c",
        "thingName": "landratank1",
        "time": "2022-08-06 15:44:26",
        "volume": 38.31,
        "city": "mohali",
        "date": "2022-09-10T10:52:47.636Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "state": "punjab"
    },
    {
        "_id": "631c6c566164e146942e6778",
        "thingName": "nayagaontank1",
        "time": "2022-07-07 07:37:11",
        "volume": 2.36,
        "city": "mohali",
        "date": "2022-09-10T10:52:47.186Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c576164e146942e677d",
        "thingName": "nayagaontank1",
        "time": "2022-08-06 12:57:55",
        "volume": 2.3899999999999997,
        "city": "mohali",
        "date": "2022-09-10T10:52:47.636Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c566164e146942e6779",
        "thingName": "nayagaontank1",
        "time": "2022-07-07 13:07:46",
        "volume": 8.44,
        "city": "mohali",
        "date": "2022-09-10T10:52:47.186Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c566164e146942e677a",
        "thingName": "nayagaontank1",
        "time": "2022-07-07 14:52:57",
        "volume": 2.0199999999999996,
        "city": "mohali",
        "date": "2022-09-10T10:52:47.186Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c566164e17f277cb0e5",
        "thingName": "nayagaontank1",
        "time": "2022-05-21 16:51:32",
        "volume": 20.04,
        "city": "mohali",
        "date": "2022-09-10T10:52:33.631Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c576164e17f277cb0e6",
        "thingName": "landratank1",
        "time": "2022-05-21 18:10:47",
        "volume": 20.92,
        "city": "mohali",
        "date": "2022-09-10T10:52:33.631Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "state": "punjab"
    },
    {
        "_id": "631c6c576164e17f277cb0e7",
        "thingName": "nayagaontank1",
        "time": "2022-05-21 11:51:00",
        "volume": 2.5300000000000002,
        "city": "mohali",
        "date": "2022-09-10T10:52:33.631Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c566164e102b3737b31",
        "thingName": "nayagaontank1",
        "time": "2022-05-04 16:24:19",
        "volume": 58.77,
        "city": "mohali",
        "date": "2022-09-10T10:52:38.664Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c566164e102b3737b32",
        "thingName": "nayagaontank1",
        "time": "2022-05-04 09:52:23",
        "volume": 2.4800000000000004,
        "city": "mohali",
        "date": "2022-09-10T10:52:38.664Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c766164e102b2238471",
        "thingName": "landratank1",
        "time": "2022-07-19 23:29:09",
        "volume": 54.23,
        "city": "mohali",
        "date": "2022-09-10T10:52:38.685Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "state": "punjab"
    },
    {
        "_id": "631c6c566164e102b3737b33",
        "thingName": "nayagaontank1",
        "time": "2022-05-04 12:26:10",
        "volume": 2.1399999999999997,
        "city": "mohali",
        "date": "2022-09-10T10:52:38.664Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c766164e102b2238472",
        "thingName": "baltanatank1",
        "time": "2022-07-19 15:12:35",
        "volume": 36.35,
        "city": "mohali",
        "date": "2022-09-10T10:52:38.685Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "state": "punjab"
    },
    {
        "_id": "631c6c576164e102b3737b34",
        "thingName": "nayagaontank1",
        "time": "2022-05-04 16:09:18",
        "volume": 5.61,
        "city": "mohali",
        "date": "2022-09-10T10:52:38.664Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c766164e102b2238473",
        "thingName": "landratank1",
        "time": "2022-07-19 22:59:06",
        "volume": 54.23,
        "city": "mohali",
        "date": "2022-09-10T10:52:38.685Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "state": "punjab"
    },
    {
        "_id": "631c6c576164e102b3737b35",
        "thingName": "nayagaontank1",
        "time": "2022-05-04 17:24:25",
        "volume": 3.03,
        "city": "mohali",
        "date": "2022-09-10T10:52:38.664Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c576164e102b3737b36",
        "thingName": "nayagaontank1",
        "time": "2022-05-04 22:02:09",
        "volume": 2.53,
        "city": "mohali",
        "date": "2022-09-10T10:52:38.664Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c776164e102b2238474",
        "thingName": "nayagaontank1",
        "time": "2022-07-23 15:18:35",
        "volume": 3.16,
        "city": "mohali",
        "date": "2022-09-10T10:52:39.440Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c566164e17f282b3465",
        "thingName": "nayagaontank1",
        "time": "2022-05-13 06:12:16",
        "volume": 2.15,
        "city": "mohali",
        "date": "2022-09-10T10:52:41.692Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c566164e102b2238462",
        "thingName": "nayagaontank1",
        "time": "2022-07-16 21:01:26",
        "volume": 2.18,
        "city": "mohali",
        "date": "2022-09-10T10:52:47.691Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c566164e102b2238461",
        "thingName": "landratank1",
        "time": "2022-07-21 13:24:11",
        "volume": 16.45,
        "city": "mohali",
        "date": "2022-09-10T10:54:54.846Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "state": "punjab"
    },
    {
        "_id": "631c6c576164e102f61f9c11",
        "thingName": "nayagaontank1",
        "time": "2022-05-31 17:20:01",
        "volume": 6.95,
        "city": "mohali",
        "date": "2022-09-10T10:52:37.671Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c576164e102f2336e21",
        "thingName": "nayagaontank1",
        "time": "2022-06-05 19:15:36",
        "volume": 2.8499999999999996,
        "city": "mohali",
        "date": "2022-09-10T10:52:37.662Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c576164e102f61f9c12",
        "thingName": "nayagaontank1",
        "time": "2022-05-31 19:35:16",
        "volume": 7.609999999999999,
        "city": "mohali",
        "date": "2022-09-10T10:52:37.671Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c576164e102f61f9c13",
        "thingName": "bhagomajrotank1",
        "time": "2022-05-31 13:53:19",
        "volume": 66.33999999999999,
        "city": "mohali",
        "date": "2022-09-10T10:52:37.671Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "state": "punjab"
    },
    {
        "_id": "631c6c576164e102f05a1761",
        "thingName": "nayagaontank1",
        "time": "2022-05-22 19:39:21",
        "volume": 2.24,
        "city": "mohali",
        "date": "2022-09-10T10:52:41.697Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c576164e102f33eb4d1",
        "thingName": "landratank1",
        "time": "2022-04-07 10:09:01",
        "volume": 269.56,
        "city": "mohali",
        "date": "2022-09-10T10:52:42.603Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140307",
        "siteId": "",
        "siteName": "",
        "state": "punjab"
    },
    {
        "_id": "631c6c576164e102f33eb4d2",
        "thingName": "bhagomajrotank1",
        "time": "2022-04-07 15:56:03",
        "volume": 240.18,
        "city": "mohali",
        "date": "2022-09-10T10:52:42.603Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160055",
        "siteId": "",
        "siteName": "",
        "state": "punjab"
    },
    {
        "_id": "631c6c576164e102f33eb4d3",
        "thingName": "landratank1",
        "time": "2022-04-07 01:33:07",
        "volume": 269.87,
        "city": "mohali",
        "date": "2022-09-10T10:52:42.603Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "140307",
        "siteId": "",
        "siteName": "",
        "state": "punjab"
    },
    {
        "_id": "631c6c576164e102ef60fca1",
        "thingName": "nayagaontank1",
        "time": "2022-07-08 14:10:24",
        "volume": 45.25,
        "city": "mohali",
        "date": "2022-09-10T10:52:44.687Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c576164e102ef60fca2",
        "thingName": "bhagomajrotank1",
        "time": "2022-07-08 08:53:19",
        "volume": 18.16,
        "city": "mohali",
        "date": "2022-09-10T10:52:44.687Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "state": "punjab"
    },
    {
        "_id": "631c6c576164e102f55c84c1",
        "thingName": "landratank1",
        "time": "2022-05-28 09:24:41",
        "volume": 58.77,
        "city": "mohali",
        "date": "2022-09-10T10:52:46.315Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "state": "punjab"
    },
    {
        "_id": "631c6c576164e102f55c84c2",
        "thingName": "landratank1",
        "time": "2022-05-28 08:54:38",
        "volume": 58.4,
        "city": "mohali",
        "date": "2022-09-10T10:52:46.315Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "state": "punjab"
    },
    {
        "_id": "631c6c576164e102f55c84c3",
        "thingName": "nayagaontank1",
        "time": "2022-05-28 20:42:47",
        "volume": 2.7899999999999996,
        "city": "mohali",
        "date": "2022-09-10T10:52:46.315Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c506164e17f277cb0e1",
        "thingName": "nayagaontank1",
        "time": "2022-05-03 11:04:02",
        "volume": 2.31,
        "city": "mohali",
        "date": "2022-09-10T10:52:32.812Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c716164e14690427444",
        "thingName": "landratank1",
        "time": "2022-07-15 03:25:14",
        "volume": 20.19,
        "city": "mohali",
        "date": "2022-09-10T10:52:33.176Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "state": "punjab"
    },
    {
        "_id": "631c6c716164e150052cfbec",
        "thingName": "baltanatank1",
        "time": "2022-06-29 13:33:31",
        "volume": 35.46,
        "city": "mohali",
        "date": "2022-09-10T10:52:33.231Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "state": "punjab"
    },
    {
        "_id": "631c6c716164e1594b292c66",
        "thingName": "baltanatank1",
        "time": "2022-06-14 17:48:52",
        "volume": 38.45,
        "city": "mohali",
        "date": "2022-09-10T10:52:33.291Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "state": "punjab"
    },
    {
        "_id": "631c6c726164e14690427445",
        "thingName": "landratank1",
        "time": "2022-07-15 20:05:49",
        "volume": 20.48,
        "city": "mohali",
        "date": "2022-09-10T10:52:33.176Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "state": "punjab"
    },
    {
        "_id": "631c6c726164e14c440c377b",
        "thingName": "baltanatank1",
        "time": "2022-07-14 16:39:44",
        "volume": 35.68,
        "city": "mohali",
        "date": "2022-09-10T10:52:33.945Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "state": "punjab"
    },
    {
        "_id": "631c6c726164e14690427446",
        "thingName": "bhagomajrotank1",
        "time": "2022-07-15 03:48:13",
        "volume": 37.69,
        "city": "mohali",
        "date": "2022-09-10T10:52:33.176Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "state": "punjab"
    },
    {
        "_id": "631c6c726164e150052cfbed",
        "thingName": "nayagaontank1",
        "time": "2022-06-29 09:05:39",
        "volume": 10.75,
        "city": "mohali",
        "date": "2022-09-10T10:52:33.231Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c726164e1594b292c67",
        "thingName": "nayagaontank1",
        "time": "2022-06-14 09:07:22",
        "volume": 11.17,
        "city": "mohali",
        "date": "2022-09-10T10:52:33.291Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c726164e14c440c377c",
        "thingName": "nayagaontank1",
        "time": "2022-07-14 22:11:28",
        "volume": 35.61,
        "city": "mohali",
        "date": "2022-09-10T10:52:33.945Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c726164e14690427447",
        "thingName": "nayagaontank1",
        "time": "2022-07-15 16:58:27",
        "volume": 40.99,
        "city": "mohali",
        "date": "2022-09-10T10:52:33.176Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c726164e1594b292c68",
        "thingName": "nayagaontank1",
        "time": "2022-06-14 13:07:48",
        "volume": 2.29,
        "city": "mohali",
        "date": "2022-09-10T10:52:33.291Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c726164e14c440c377d",
        "thingName": "nayagaontank1",
        "time": "2022-07-14 11:25:21",
        "volume": 2.18,
        "city": "mohali",
        "date": "2022-09-10T10:52:33.945Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c726164e150052cfbee",
        "thingName": "nayagaontank1",
        "time": "2022-08-01 17:45:44",
        "volume": 5.01,
        "city": "mohali",
        "date": "2022-09-10T10:52:34.138Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c576164e102f4061981",
        "thingName": "nayagaontank1",
        "time": "2022-06-03 09:09:28",
        "volume": 54.72,
        "city": "mohali",
        "date": "2022-09-10T10:52:34.238Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c726164e14c440c377e",
        "thingName": "nayagaontank1",
        "time": "2022-07-14 21:56:27",
        "volume": 5.570000000000001,
        "city": "mohali",
        "date": "2022-09-10T10:52:33.945Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c726164e1594b292c69",
        "thingName": "nayagaontank1",
        "time": "2022-05-23 07:55:39",
        "volume": 40.85,
        "city": "mohali",
        "date": "2022-09-10T10:52:34.229Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c726164e143711ab5d7",
        "thingName": "nayagaontank1",
        "time": "2022-06-13 16:50:38",
        "volume": 62.63,
        "city": "mohali",
        "date": "2022-09-10T10:52:34.364Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c576164e102f4061982",
        "thingName": "nayagaontank1",
        "time": "2022-06-03 06:39:13",
        "volume": 19.38,
        "city": "mohali",
        "date": "2022-09-10T10:52:34.238Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c726164e1594b292c6a",
        "thingName": "nayagaontank1",
        "time": "2022-05-23 04:40:18",
        "volume": 16.68,
        "city": "mohali",
        "date": "2022-09-10T10:52:34.229Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c726164e14c440c377f",
        "thingName": "landratank1",
        "time": "2022-07-05 19:26:07",
        "volume": 16.98,
        "city": "mohali",
        "date": "2022-09-10T10:52:34.474Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "state": "punjab"
    },
    {
        "_id": "631c6c726164e150052cfbef",
        "thingName": "nayagaontank1",
        "time": "2022-08-20 15:48:44",
        "volume": 42.39,
        "city": "mohali",
        "date": "2022-09-10T10:52:33.866Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c576164e102f4061983",
        "thingName": "nayagaontank1",
        "time": "2022-06-03 07:39:19",
        "volume": 2.9899999999999998,
        "city": "mohali",
        "date": "2022-09-10T10:52:34.238Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c576164e102f4061984",
        "thingName": "nayagaontank1",
        "time": "2022-06-03 11:54:46",
        "volume": 8.159999999999998,
        "city": "mohali",
        "date": "2022-09-10T10:52:34.238Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c766164e14690427448",
        "thingName": "nayagaontank1",
        "time": "2022-05-30 17:02:29",
        "volume": 2.3200000000000003,
        "city": "mohali",
        "date": "2022-09-10T10:52:38.783Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c726164e143711ab5d8",
        "thingName": "nayagaontank1",
        "time": "2022-06-13 13:20:17",
        "volume": 4.77,
        "city": "mohali",
        "date": "2022-09-10T10:52:34.364Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c796164e143711ab5d9",
        "thingName": "baltanatank1",
        "time": "2022-05-11 20:15:20",
        "volume": 59.85,
        "city": "mohali",
        "date": "2022-09-10T10:52:41.666Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "state": "punjab"
    },
    {
        "_id": "631c6c796164e143711ab5da",
        "thingName": "nayagaontank1",
        "time": "2022-05-11 09:07:29",
        "volume": 2.53,
        "city": "mohali",
        "date": "2022-09-10T10:52:41.666Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c796164e143711ab5db",
        "thingName": "nayagaontank1",
        "time": "2022-05-11 15:38:10",
        "volume": 3.76,
        "city": "mohali",
        "date": "2022-09-10T10:52:41.666Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c7a6164e143711ab5dc",
        "thingName": "baltanatank1",
        "time": "2022-08-10 20:02:53",
        "volume": 17.41,
        "city": "mohali",
        "date": "2022-09-10T10:52:42.503Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "state": "punjab"
    },
    {
        "_id": "631c6c726164e1594b292c6b",
        "thingName": "nayagaontank1",
        "time": "2022-05-23 06:40:31",
        "volume": 2.47,
        "city": "mohali",
        "date": "2022-09-10T10:52:34.229Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c726164e1594b292c6c",
        "thingName": "nayagaontank1",
        "time": "2022-08-11 04:39:43",
        "volume": 2.58,
        "city": "mohali",
        "date": "2022-09-10T10:52:34.791Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c736164e1594b292c6d",
        "thingName": "baltanatank1",
        "time": "2022-09-09 13:15:56",
        "volume": 13.38,
        "city": "mohali",
        "date": "2022-09-10T10:52:35.451Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "state": "punjab"
    },
    {
        "_id": "631c6c736164e1594b292c6e",
        "thingName": "nayagaontank1",
        "time": "2022-09-09 22:25:08",
        "volume": 2.84,
        "city": "mohali",
        "date": "2022-09-10T10:52:35.451Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c566164e17f277cb0e3",
        "thingName": "nayagaontank1",
        "time": "2022-05-05 10:30:11",
        "volume": 2.61,
        "city": "mohali",
        "date": "2022-09-10T10:52:35.649Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c746164e1594b292c6f",
        "thingName": "nayagaontank1",
        "time": "2022-05-14 14:48:33",
        "volume": 2.2,
        "city": "mohali",
        "date": "2022-09-10T10:52:36.329Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c746164e1594b292c70",
        "thingName": "nayagaontank1",
        "time": "2022-05-20 13:33:40",
        "volume": 2.19,
        "city": "mohali",
        "date": "2022-09-10T10:52:36.636Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c746164e1594b292c71",
        "thingName": "nayagaontank1",
        "time": "2022-07-01 01:06:16",
        "volume": 3.59,
        "city": "mohali",
        "date": "2022-09-10T10:52:36.665Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c516164e17f2a305741",
        "thingName": "landratank1",
        "time": "2022-07-11 19:24:41",
        "volume": 39.83,
        "city": "mohali",
        "date": "2022-09-10T10:52:36.691Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "state": "punjab"
    },
    {
        "_id": "631c6c506164e17f256836a1",
        "thingName": "baltanatank1",
        "time": "2022-04-08 18:22:25",
        "volume": 10.39,
        "city": "mohali",
        "date": "2022-09-10T10:52:38.875Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140603",
        "siteId": "",
        "siteName": "",
        "state": "punjab"
    },
    {
        "_id": "631c6c776164e1594b292c72",
        "thingName": "baltanatank1",
        "time": "2022-08-24 17:53:22",
        "volume": 35.84,
        "city": "mohali",
        "date": "2022-09-10T10:52:39.451Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "state": "punjab"
    },
    {
        "_id": "631c6c506164e179f110bbdf",
        "thingName": "nayagaontank1",
        "time": "2022-05-01 15:14:25",
        "volume": 26.76,
        "city": "mohali",
        "date": "2022-09-10T10:52:39.772Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c506164e179f110bbe0",
        "thingName": "nayagaontank1",
        "time": "2022-05-01 11:50:20",
        "volume": 2.34,
        "city": "mohali",
        "date": "2022-09-10T10:52:39.772Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c506164e179f110bbe1",
        "thingName": "nayagaontank1",
        "time": "2022-05-01 13:01:18",
        "volume": 2.16,
        "city": "mohali",
        "date": "2022-09-10T10:52:39.772Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c786164e1594b292c73",
        "thingName": "nayagaontank1",
        "time": "2022-06-11 20:45:58",
        "volume": 3.6399999999999997,
        "city": "mohali",
        "date": "2022-09-10T10:52:40.645Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c796164e1594b292c74",
        "thingName": "nayagaontank1",
        "time": "2022-08-03 18:20:52",
        "volume": 4.619999999999999,
        "city": "mohali",
        "date": "2022-09-10T10:52:41.686Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c796164e1594b292c75",
        "thingName": "bhagomajrotank1",
        "time": "2022-08-05 12:04:02",
        "volume": 21.2,
        "city": "mohali",
        "date": "2022-09-10T10:52:41.746Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "state": "punjab"
    },
    {
        "_id": "631c6c726164e14c440c3780",
        "thingName": "nayagaontank1",
        "time": "2022-07-05 13:47:46",
        "volume": 9.82,
        "city": "mohali",
        "date": "2022-09-10T10:52:34.474Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c566164e17f277cb0e4",
        "thingName": "baltanatank1",
        "time": "2022-07-13 16:46:36",
        "volume": 36.39,
        "city": "mohali",
        "date": "2022-09-10T10:52:34.186Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "state": "punjab"
    },
    {
        "_id": "631c6c736164e14c440c3781",
        "thingName": "bhagomajrotank1",
        "time": "2022-07-25 14:26:29",
        "volume": 37.71,
        "city": "mohali",
        "date": "2022-09-10T10:52:34.462Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "state": "punjab"
    },
    {
        "_id": "631c6c736164e14c440c3782",
        "thingName": "landratank1",
        "time": "2022-07-25 13:40:45",
        "volume": 37.33,
        "city": "mohali",
        "date": "2022-09-10T10:52:34.462Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "state": "punjab"
    },
    {
        "_id": "631c6c736164e14c440c3783",
        "thingName": "baltanatank1",
        "time": "2022-07-29 17:18:51",
        "volume": 17.04,
        "city": "mohali",
        "date": "2022-09-10T10:52:35.425Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "state": "punjab"
    },
    {
        "_id": "631c6c736164e14c440c3784",
        "thingName": "nayagaontank1",
        "time": "2022-07-29 20:08:23",
        "volume": 16.450000000000003,
        "city": "mohali",
        "date": "2022-09-10T10:52:35.425Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c746164e14c440c3785",
        "thingName": "nayagaontank1",
        "time": "2022-06-21 14:23:55",
        "volume": 3.1,
        "city": "mohali",
        "date": "2022-09-10T10:52:36.671Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c766164e14c440c3786",
        "thingName": "nayagaontank1",
        "time": "2022-08-04 17:53:21",
        "volume": 61.46,
        "city": "mohali",
        "date": "2022-09-10T10:52:38.431Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c726164e150052cfbf0",
        "thingName": "baltanatank1",
        "time": "2022-06-16 17:09:22",
        "volume": 56.68,
        "city": "mohali",
        "date": "2022-09-10T10:52:33.851Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "state": "punjab"
    },
    {
        "_id": "631c6c726164e150052cfbf1",
        "thingName": "landratank1",
        "time": "2022-06-16 21:35:39",
        "volume": 39.6,
        "city": "mohali",
        "date": "2022-09-10T10:52:33.851Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "state": "punjab"
    },
    {
        "_id": "631c6c566164e102b62b15c1",
        "thingName": "nayagaontank1",
        "time": "2022-04-24 21:37:47",
        "volume": 20.05,
        "city": "mohali",
        "date": "2022-09-10T10:52:34.638Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160103",
        "siteId": "",
        "siteName": "",
        "state": "punjab"
    },
    {
        "_id": "631c6c746164e102f4061985",
        "thingName": "nayagaontank1",
        "time": "2022-05-07 15:46:41",
        "volume": 7.57,
        "city": "mohali",
        "date": "2022-09-10T10:52:36.682Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c756164e102f4061986",
        "thingName": "baltanatank1",
        "time": "2022-06-10 17:16:45",
        "volume": 36.85,
        "city": "mohali",
        "date": "2022-09-10T10:52:37.447Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "state": "punjab"
    },
    {
        "_id": "631c6c756164e1316a3fddfb",
        "thingName": "baltanatank1",
        "time": "2022-06-27 11:53:16",
        "volume": 54.37,
        "city": "mohali",
        "date": "2022-09-10T10:52:37.761Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "state": "punjab"
    },
    {
        "_id": "631c6c756164e1316a3fddfc",
        "thingName": "bhagomajrotank1",
        "time": "2022-06-27 20:54:48",
        "volume": 40.99,
        "city": "mohali",
        "date": "2022-09-10T10:52:37.761Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "state": "punjab"
    },
    {
        "_id": "631c6c756164e1316a3fddfd",
        "thingName": "nayagaontank1",
        "time": "2022-06-27 08:03:23",
        "volume": 13.470000000000002,
        "city": "mohali",
        "date": "2022-09-10T10:52:37.761Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c766164e1316a3fddfe",
        "thingName": "baltanatank1",
        "time": "2022-07-06 19:17:40",
        "volume": 35.92,
        "city": "mohali",
        "date": "2022-09-10T10:52:38.317Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "state": "punjab"
    },
    {
        "_id": "631c6c766164e1316a3fddff",
        "thingName": "nayagaontank1",
        "time": "2022-07-06 10:19:56",
        "volume": 2.9999999999999996,
        "city": "mohali",
        "date": "2022-09-10T10:52:38.317Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c766164e1316a3fde00",
        "thingName": "nayagaontank1",
        "time": "2022-07-06 23:21:18",
        "volume": 3.3499999999999996,
        "city": "mohali",
        "date": "2022-09-10T10:52:38.317Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c766164e1316a3fde01",
        "thingName": "baltanatank1",
        "time": "2022-07-04 14:29:51",
        "volume": 53.71,
        "city": "mohali",
        "date": "2022-09-10T10:52:38.664Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "state": "punjab"
    },
    {
        "_id": "631c6c766164e152aa6684ad",
        "thingName": "nayagaontank1",
        "time": "2022-06-26 19:02:00",
        "volume": 61.23,
        "city": "mohali",
        "date": "2022-09-10T10:52:38.807Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c776164e152aa6684ae",
        "thingName": "baltanatank1",
        "time": "2022-06-26 22:15:32",
        "volume": 2.29,
        "city": "mohali",
        "date": "2022-09-10T10:52:38.807Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "state": "punjab"
    },
    {
        "_id": "631c6c776164e152aa6684af",
        "thingName": "nayagaontank1",
        "time": "2022-06-26 15:16:37",
        "volume": 5.91,
        "city": "mohali",
        "date": "2022-09-10T10:52:38.807Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c776164e102b2238475",
        "thingName": "nayagaontank1",
        "time": "2022-08-17 15:11:04",
        "volume": 9.230000000000002,
        "city": "mohali",
        "date": "2022-09-10T10:52:39.656Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c776164e102b2238476",
        "thingName": "nayagaontank1",
        "time": "2022-06-02 14:39:50",
        "volume": 55.81,
        "city": "mohali",
        "date": "2022-09-10T10:52:39.778Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c776164e102b2238477",
        "thingName": "bhagomajrotank1",
        "time": "2022-06-02 09:01:54",
        "volume": 37.85,
        "city": "mohali",
        "date": "2022-09-10T10:52:39.778Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "state": "punjab"
    },
    {
        "_id": "631c6c776164e102b2238478",
        "thingName": "nayagaontank1",
        "time": "2022-06-02 10:24:23",
        "volume": 2.2800000000000002,
        "city": "mohali",
        "date": "2022-09-10T10:52:39.778Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c786164e102b2238479",
        "thingName": "nayagaontank1",
        "time": "2022-06-02 14:24:49",
        "volume": 10.040000000000001,
        "city": "mohali",
        "date": "2022-09-10T10:52:39.778Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c786164e102b223847a",
        "thingName": "nayagaontank1",
        "time": "2022-06-02 19:38:02",
        "volume": 18.650000000000002,
        "city": "mohali",
        "date": "2022-09-10T10:52:39.778Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c786164e102b223847b",
        "thingName": "nayagaontank1",
        "time": "2022-06-02 21:38:15",
        "volume": 2.1199999999999997,
        "city": "mohali",
        "date": "2022-09-10T10:52:39.778Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c796164e102b223847c",
        "thingName": "baltanatank1",
        "time": "2022-06-30 17:45:26",
        "volume": 38.54,
        "city": "mohali",
        "date": "2022-09-10T10:52:41.338Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "state": "punjab"
    },
    {
        "_id": "631c6c796164e102b223847d",
        "thingName": "nayagaontank1",
        "time": "2022-06-30 14:05:07",
        "volume": 9.52,
        "city": "mohali",
        "date": "2022-09-10T10:52:41.338Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c796164e102b223847e",
        "thingName": "nayagaontank1",
        "time": "2022-06-30 23:36:07",
        "volume": 2.75,
        "city": "mohali",
        "date": "2022-09-10T10:52:41.338Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c7f6164e102b50b45f1",
        "thingName": "nayagaontank1",
        "time": "2022-08-22 16:53:55",
        "volume": 2.19,
        "city": "mohali",
        "date": "2022-09-10T10:52:45.473Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c7f6164e102b62b15c2",
        "thingName": "nayagaontank1",
        "time": "2022-08-29 17:11:41",
        "volume": 18.47,
        "city": "mohali",
        "date": "2022-09-10T10:52:46.134Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c7f6164e10a136ba711",
        "thingName": "nayagaontank1",
        "time": "2022-08-08 21:03:51",
        "volume": 3.2399999999999998,
        "city": "mohali",
        "date": "2022-09-10T10:52:45.366Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c7f6164e102b62b15c3",
        "thingName": "nayagaontank1",
        "time": "2022-08-29 16:41:38",
        "volume": 16.43,
        "city": "mohali",
        "date": "2022-09-10T10:52:46.134Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c7f6164e152f10c7483",
        "thingName": "nayagaontank1",
        "time": "2022-05-24 01:27:30",
        "volume": 3.25,
        "city": "mohali",
        "date": "2022-09-10T10:52:47.417Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c7f6164e10a184b61e1",
        "thingName": "nayagaontank1",
        "time": "2022-06-20 14:36:24",
        "volume": 4.07,
        "city": "mohali",
        "date": "2022-09-10T10:52:46.218Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c7f6164e10a28686401",
        "thingName": "bhagomajrotank1",
        "time": "2022-08-09 02:40:15",
        "volume": 22.44,
        "city": "mohali",
        "date": "2022-09-10T10:54:38.481Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "state": "punjab"
    },
    {
        "_id": "631c6c7f6164e10a1a035911",
        "thingName": "nayagaontank1",
        "time": "2022-07-03 21:28:31",
        "volume": 59.88,
        "city": "mohali",
        "date": "2022-09-10T10:54:38.829Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c7f6164e10a2472b9a1",
        "thingName": "landratank1",
        "time": "2022-06-09 19:12:33",
        "volume": 38.96,
        "city": "mohali",
        "date": "2022-09-10T10:52:46.298Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "state": "punjab"
    },
    {
        "_id": "631c6c7f6164e17f256836a6",
        "thingName": "bhagomajrotank1",
        "time": "2022-07-31 16:22:59",
        "volume": 33.2,
        "city": "mohali",
        "date": "2022-09-10T10:52:46.945Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "state": "punjab"
    },
    {
        "_id": "631c6c806164e17f256836a7",
        "thingName": "landratank1",
        "time": "2022-07-31 15:16:28",
        "volume": 19.03,
        "city": "mohali",
        "date": "2022-09-10T10:52:46.945Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "state": "punjab"
    },
    {
        "_id": "631c6c806164e10a2472b9a2",
        "thingName": "nayagaontank1",
        "time": "2022-06-09 18:10:39",
        "volume": 41.17,
        "city": "mohali",
        "date": "2022-09-10T10:52:46.298Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c806164e10a2472b9a3",
        "thingName": "bhagomajrotank1",
        "time": "2022-06-09 13:45:00",
        "volume": 39.7,
        "city": "mohali",
        "date": "2022-09-10T10:52:46.298Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "state": "punjab"
    },
    {
        "_id": "631c6c806164e17f256836a8",
        "thingName": "nayagaontank1",
        "time": "2022-07-31 19:28:23",
        "volume": 2.6699999999999995,
        "city": "mohali",
        "date": "2022-09-10T10:52:46.945Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c7f6164e152f10c7484",
        "thingName": "nayagaontank1",
        "time": "2022-05-24 14:28:53",
        "volume": 5.970000000000001,
        "city": "mohali",
        "date": "2022-09-10T10:52:47.417Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c806164e152f10c7485",
        "thingName": "nayagaontank1",
        "time": "2022-05-24 15:29:00",
        "volume": 2.94,
        "city": "mohali",
        "date": "2022-09-10T10:52:47.417Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c806164e152f10c7486",
        "thingName": "nayagaontank1",
        "time": "2022-05-24 17:59:15",
        "volume": 3.9799999999999995,
        "city": "mohali",
        "date": "2022-09-10T10:52:47.417Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c7f6164e102f05a1766",
        "thingName": "bhagomajrotank1",
        "time": "2022-06-01 13:20:31",
        "volume": 71.2,
        "city": "mohali",
        "date": "2022-09-10T10:54:36.486Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "state": "punjab"
    },
    {
        "_id": "631c6c806164e102f05a1767",
        "thingName": "bhagomajrotank1",
        "time": "2022-06-01 21:09:40",
        "volume": 36.9,
        "city": "mohali",
        "date": "2022-09-10T10:54:36.486Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "state": "punjab"
    },
    {
        "_id": "631c6c806164e102f05a1768",
        "thingName": "nayagaontank1",
        "time": "2022-06-01 00:35:47",
        "volume": 2.1399999999999997,
        "city": "mohali",
        "date": "2022-09-10T10:54:36.486Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c7f6164e102b62b15c4",
        "thingName": "nayagaontank1",
        "time": "2022-08-29 20:12:00",
        "volume": 4.930000000000001,
        "city": "mohali",
        "date": "2022-09-10T10:52:46.134Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c7f6164e10a2d325041",
        "thingName": "baltanatank1",
        "time": "2022-08-15 17:56:15",
        "volume": 34.31,
        "city": "mohali",
        "date": "2022-09-10T10:54:53.836Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "state": "punjab"
    },
    {
        "_id": "631c6c7f6164e10a2c2aa2c1",
        "thingName": "nayagaontank1",
        "time": "2022-05-17 08:25:28",
        "volume": 60.52,
        "city": "mohali",
        "date": "2022-09-10T10:54:56.605Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c806164e10a2c2aa2c2",
        "thingName": "nayagaontank1",
        "time": "2022-05-17 12:40:56",
        "volume": 4.96,
        "city": "mohali",
        "date": "2022-09-10T10:54:56.605Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c806164e10a184b61e2",
        "thingName": "nayagaontank1",
        "time": "2022-06-20 17:21:42",
        "volume": 3.5700000000000003,
        "city": "mohali",
        "date": "2022-09-10T10:52:46.218Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c806164e10a111fb2c1",
        "thingName": "nayagaontank1",
        "time": "2022-05-29 07:28:56",
        "volume": 38.08,
        "city": "mohali",
        "date": "2022-09-10T10:52:47.032Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c806164e10a184b61e3",
        "thingName": "bhagomajrotank1",
        "time": "2022-06-20 18:22:27",
        "volume": 111.32,
        "city": "mohali",
        "date": "2022-09-10T10:52:46.218Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "state": "punjab"
    },
    {
        "_id": "631c6c806164e10a111fb2c2",
        "thingName": "nayagaontank1",
        "time": "2022-05-29 16:14:51",
        "volume": 2.14,
        "city": "mohali",
        "date": "2022-09-10T10:52:47.032Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c7f6164e102f05a1762",
        "thingName": "landratank1",
        "time": "2022-06-01 12:02:47",
        "volume": 15.84,
        "city": "mohali",
        "date": "2022-09-10T10:54:36.486Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "state": "punjab"
    },
    {
        "_id": "631c6c7f6164e102f05a1763",
        "thingName": "landratank1",
        "time": "2022-06-01 12:19:49",
        "volume": 20.27,
        "city": "mohali",
        "date": "2022-09-10T10:54:36.486Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "state": "punjab"
    },
    {
        "_id": "631c6c7f6164e102f05a1764",
        "thingName": "landratank1",
        "time": "2022-06-01 20:44:51",
        "volume": 37.96,
        "city": "mohali",
        "date": "2022-09-10T10:54:36.486Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "state": "punjab"
    },
    {
        "_id": "631c6c7f6164e102f05a1765",
        "thingName": "bhagomajrotank1",
        "time": "2022-06-01 11:38:20",
        "volume": 22.2,
        "city": "mohali",
        "date": "2022-09-10T10:54:36.486Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "state": "punjab"
    },
    {
        "_id": "631c6c806164e10a28686402",
        "thingName": "landratank1",
        "time": "2022-08-09 01:54:50",
        "volume": 39.97,
        "city": "mohali",
        "date": "2022-09-10T10:54:38.481Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140307",
        "siteId": "in-1057567",
        "siteName": "landran-cgc",
        "state": "punjab"
    },
    {
        "_id": "631c6c806164e10a28686403",
        "thingName": "bhagomajrotank1",
        "time": "2022-08-09 18:07:01",
        "volume": 68.71,
        "city": "mohali",
        "date": "2022-09-10T10:54:38.481Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "state": "punjab"
    },
    {
        "_id": "631c6c806164e10a28686404",
        "thingName": "nayagaontank1",
        "time": "2022-08-09 16:20:53",
        "volume": 2.62,
        "city": "mohali",
        "date": "2022-09-10T10:54:38.481Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c806164e10a28686405",
        "thingName": "nayagaontank1",
        "time": "2022-08-09 21:21:24",
        "volume": 2.5900000000000003,
        "city": "mohali",
        "date": "2022-09-10T10:54:38.481Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c806164e10a236ee871",
        "thingName": "nayagaontank1",
        "time": "2022-05-17 15:26:13",
        "volume": 3.5400000000000005,
        "city": "mohali",
        "date": "2022-09-10T10:54:56.605Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631c6c806164e10a236ee872",
        "thingName": "nayagaontank1",
        "time": "2022-05-17 19:11:37",
        "volume": 5.61,
        "city": "mohali",
        "date": "2022-09-10T10:54:56.605Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "631ce9646164e1430360a4cd",
        "thingName": "bhagomajrotank1",
        "time": "2022-09-10 16:05:42",
        "volume": 18.83,
        "city": "mohali",
        "date": "2022-09-10T19:45:39.825Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160055",
        "siteId": "in-1086157",
        "siteName": "bhagomajra-landran",
        "state": "punjab"
    },
    {
        "_id": "631ce9646164e1430360a4ce",
        "thingName": "nayagaontank1",
        "time": "2022-09-10 12:11:36",
        "volume": 2.17,
        "city": "mohali",
        "date": "2022-09-10T19:45:39.825Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "6320dde76164e146aa76902c",
        "thingName": "baltanatank1",
        "time": "2022-09-13 11:00:18",
        "volume": 13.8,
        "city": "mohali",
        "date": "2022-09-13T19:45:42.674Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "state": "punjab"
    },
    {
        "_id": "6320dde76164e146aa76902d",
        "thingName": "baltanatank1",
        "time": "2022-09-13 11:09:33",
        "volume": 13.55,
        "city": "mohali",
        "date": "2022-09-13T19:45:42.674Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "state": "punjab"
    },
    {
        "_id": "6320dde76164e146aa76902e",
        "thingName": "baltanatank1",
        "time": "2022-09-13 09:25:22",
        "volume": 2.44,
        "city": "mohali",
        "date": "2022-09-13T19:45:42.674Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "140603",
        "siteId": "in-1058882",
        "siteName": "baltana",
        "state": "punjab"
    },
    {
        "_id": "6320dde76164e146aa76902f",
        "thingName": "nayagaontank1",
        "time": "2022-09-13 13:49:21",
        "volume": 8.74,
        "city": "mohali",
        "date": "2022-09-13T19:45:42.674Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "63222f666164e102b12ca7b8",
        "thingName": "nayagaontank1",
        "time": "2022-09-14 19:52:32",
        "volume": 18.2,
        "city": "mohali",
        "date": "2022-09-14T19:45:41.888Z",
        "fuelling/defuelling": "fuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    },
    {
        "_id": "63222f666164e102b12ca7b9",
        "thingName": "nayagaontank1",
        "time": "2022-09-14 19:22:30",
        "volume": 20.419999999999998,
        "city": "mohali",
        "date": "2022-09-14T19:45:41.888Z",
        "fuelling/defuelling": "defuelling",
        "pincode": "160103",
        "siteId": "in-1288611",
        "siteName": "nayagaon",
        "state": "punjab"
    }
];

export const vehicleListData = {
	"responseData": [
		{
			"thingName": "ltpk_1g_2584",
			"make": "mazda",
			"model": "mazda",
			"locations": [
				{
					"lat": 21.9155433,
					"lng": 96.1111783,
					"ts": "2022-09-23 00:10:59",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 21.9152016,
					"lng": 96.111085,
					"ts": "2022-09-23 06:51:10",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 21.8878316,
					"lng": 96.0927633,
					"ts": "2022-09-23 06:58:17",
					"speed": 18,
					"ignition": 1
				},
				{
					"lat": 21.8917633,
					"lng": 96.0948333,
					"ts": "2022-09-23 07:47:42",
					"speed": 27,
					"ignition": 1
				},
				{
					"lat": 21.9161083,
					"lng": 96.1107216,
					"ts": "2022-09-23 07:55:29",
					"speed": 14,
					"ignition": 1
				},
				{
					"lat": 21.9129666,
					"lng": 96.11399,
					"ts": "2022-09-23 09:20:18",
					"speed": 38,
					"ignition": 1
				},
				{
					"lat": 21.9152366,
					"lng": 96.13219,
					"ts": "2022-09-23 09:25:00",
					"speed": 10,
					"ignition": 1
				},
				{
					"lat": 21.9150199,
					"lng": 96.1327083,
					"ts": "2022-09-23 10:48:09",
					"speed": 7,
					"ignition": 1
				},
				{
					"lat": 21.9126899,
					"lng": 96.1286749,
					"ts": "2022-09-23 10:51:39",
					"speed": 30,
					"ignition": 1
				},
				{
					"lat": 21.8949816,
					"lng": 96.0957583,
					"ts": "2022-09-23 10:58:52",
					"speed": 33,
					"ignition": 1
				},
				{
					"lat": 21.8570216,
					"lng": 96.08761,
					"ts": "2022-09-23 11:05:26",
					"speed": 48,
					"ignition": 1
				},
				{
					"lat": 21.846165,
					"lng": 96.0684683,
					"ts": "2022-09-23 13:38:06",
					"speed": 17,
					"ignition": 1
				},
				{
					"lat": 21.8472033,
					"lng": 96.0683349,
					"ts": "2022-09-23 15:32:48",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 21.8513566,
					"lng": 96.0817633,
					"ts": "2022-09-23 15:39:14",
					"speed": 6,
					"ignition": 1
				},
				{
					"lat": 21.87397,
					"lng": 96.0913466,
					"ts": "2022-09-23 15:45:07",
					"speed": 45,
					"ignition": 1
				},
				{
					"lat": 21.9126199,
					"lng": 96.11019,
					"ts": "2022-09-23 15:52:05",
					"speed": 8,
					"ignition": 1
				},
				{
					"lat": 21.914645,
					"lng": 96.1313616,
					"ts": "2022-09-23 15:58:09",
					"speed": 9,
					"ignition": 1
				},
				{
					"lat": 21.9125999,
					"lng": 96.1340883,
					"ts": "2022-09-23 17:24:15",
					"speed": 10,
					"ignition": 1
				},
				{
					"lat": 21.91289,
					"lng": 96.1169816,
					"ts": "2022-09-23 17:30:21",
					"speed": 10,
					"ignition": 1
				}
			]
		},
		{
			"thingName": "ltpk_9h_2360",
			"make": "ford",
			"model": "ford-ranger",
			"locations": [
				{
					"lat": 22.0343216,
					"lng": 96.502545,
					"ts": "2022-09-23 05:00:10",
					"speed": 15,
					"ignition": 1
				},
				{
					"lat": 22.0181516,
					"lng": 96.5155883,
					"ts": "2022-09-23 05:06:08",
					"speed": 43,
					"ignition": 1
				},
				{
					"lat": 22.0030566,
					"lng": 96.55043,
					"ts": "2022-09-23 05:17:34",
					"speed": 19,
					"ignition": 1
				},
				{
					"lat": 22.0025516,
					"lng": 96.5521616,
					"ts": "2022-09-23 05:41:42",
					"speed": 11,
					"ignition": 1
				},
				{
					"lat": 22.0030133,
					"lng": 96.5376666,
					"ts": "2022-09-23 05:51:04",
					"speed": 38,
					"ignition": 1
				},
				{
					"lat": 22.0262733,
					"lng": 96.5040566,
					"ts": "2022-09-23 05:57:43",
					"speed": 36,
					"ignition": 1
				},
				{
					"lat": 22.039315,
					"lng": 96.5033583,
					"ts": "2022-09-23 06:02:32",
					"speed": 26,
					"ignition": 1
				},
				{
					"lat": 22.0571099,
					"lng": 96.5102116,
					"ts": "2022-09-23 08:14:56",
					"speed": 7,
					"ignition": 1
				},
				{
					"lat": 22.0399333,
					"lng": 96.4987833,
					"ts": "2022-09-23 10:01:22",
					"speed": 17,
					"ignition": 1
				},
				{
					"lat": 22.0185066,
					"lng": 96.492715,
					"ts": "2022-09-23 10:08:03",
					"speed": 15,
					"ignition": 1
				},
				{
					"lat": 22.01977,
					"lng": 96.4831466,
					"ts": "2022-09-23 10:25:49",
					"speed": 6,
					"ignition": 1
				},
				{
					"lat": 22.0233833,
					"lng": 96.464175,
					"ts": "2022-09-23 11:46:24",
					"speed": 23,
					"ignition": 1
				},
				{
					"lat": 22.0202816,
					"lng": 96.4633233,
					"ts": "2022-09-23 12:12:08",
					"speed": 26,
					"ignition": 1
				},
				{
					"lat": 22.0107266,
					"lng": 96.4691716,
					"ts": "2022-09-23 12:16:18",
					"speed": 17,
					"ignition": 1
				},
				{
					"lat": 22.0230349,
					"lng": 96.4767966,
					"ts": "2022-09-23 14:05:22",
					"speed": 30,
					"ignition": 1
				}
			]
		},
		{
			"thingName": "ltpk_8g_9972",
			"make": "toyota",
			"model": "sun-tun",
			"locations": [
				{
					"lat": 16.96246,
					"lng": 96.0674499,
					"ts": "2022-09-23 00:06:56",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 16.9625933,
					"lng": 96.0673716,
					"ts": "2022-09-23 07:43:31",
					"speed": 7,
					"ignition": 1
				},
				{
					"lat": 16.9561599,
					"lng": 96.0717783,
					"ts": "2022-09-23 07:49:56",
					"speed": 23,
					"ignition": 1
				},
				{
					"lat": 16.93211,
					"lng": 96.0784433,
					"ts": "2022-09-23 07:56:50",
					"speed": 30,
					"ignition": 1
				},
				{
					"lat": 16.92149,
					"lng": 96.0571583,
					"ts": "2022-09-23 08:02:51",
					"speed": 43,
					"ignition": 1
				},
				{
					"lat": 16.89767,
					"lng": 96.0450116,
					"ts": "2022-09-23 08:09:12",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 16.8845883,
					"lng": 96.0440583,
					"ts": "2022-09-23 08:59:44",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 16.8730483,
					"lng": 96.0363766,
					"ts": "2022-09-23 09:24:02",
					"speed": 38,
					"ignition": 1
				},
				{
					"lat": 16.8628466,
					"lng": 96.044365,
					"ts": "2022-09-23 10:37:42",
					"speed": 6,
					"ignition": 1
				},
				{
					"lat": 16.8700033,
					"lng": 96.0415166,
					"ts": "2022-09-23 10:59:49",
					"speed": 43,
					"ignition": 1
				},
				{
					"lat": 16.8845666,
					"lng": 96.0440433,
					"ts": "2022-09-23 11:09:15",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 16.8745083,
					"lng": 96.032895,
					"ts": "2022-09-23 11:15:46",
					"speed": 16,
					"ignition": 1
				},
				{
					"lat": 16.8631383,
					"lng": 96.0440216,
					"ts": "2022-09-23 11:35:18",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 16.8638216,
					"lng": 96.0433166,
					"ts": "2022-09-23 13:22:43",
					"speed": 10,
					"ignition": 1
				},
				{
					"lat": 16.8799949,
					"lng": 96.0401233,
					"ts": "2022-09-23 13:28:30",
					"speed": 30,
					"ignition": 1
				},
				{
					"lat": 16.8891349,
					"lng": 96.0393033,
					"ts": "2022-09-23 13:34:39",
					"speed": 47,
					"ignition": 1
				},
				{
					"lat": 16.9214716,
					"lng": 96.05678,
					"ts": "2022-09-23 13:41:30",
					"speed": 33,
					"ignition": 1
				},
				{
					"lat": 16.9303116,
					"lng": 96.0822333,
					"ts": "2022-09-23 13:47:10",
					"speed": 24,
					"ignition": 1
				},
				{
					"lat": 16.954875,
					"lng": 96.0755166,
					"ts": "2022-09-23 13:54:37",
					"speed": 9,
					"ignition": 1
				}
			]
		},
		{
			"thingName": "ltpk_2g_6332",
			"make": "ford",
			"model": "ford",
			"locations": [
				{
					"lat": 21.9650083,
					"lng": 96.0740749,
					"ts": "2022-09-23 00:07:15",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 21.9650116,
					"lng": 96.0741999,
					"ts": "2022-09-23 07:30:36",
					"speed": 6,
					"ignition": 1
				},
				{
					"lat": 21.9753816,
					"lng": 96.0823433,
					"ts": "2022-09-23 07:36:57",
					"speed": 24,
					"ignition": 1
				},
				{
					"lat": 21.992045,
					"lng": 96.08376,
					"ts": "2022-09-23 07:42:53",
					"speed": 7,
					"ignition": 1
				},
				{
					"lat": 21.98276,
					"lng": 96.0888049,
					"ts": "2022-09-23 07:58:56",
					"speed": 19,
					"ignition": 1
				},
				{
					"lat": 21.9799233,
					"lng": 96.0907533,
					"ts": "2022-09-23 08:20:40",
					"speed": 13,
					"ignition": 1
				},
				{
					"lat": 21.9805283,
					"lng": 96.0987399,
					"ts": "2022-09-23 08:37:40",
					"speed": 12,
					"ignition": 1
				},
				{
					"lat": 21.9727766,
					"lng": 96.1047966,
					"ts": "2022-09-23 08:58:44",
					"speed": 32,
					"ignition": 1
				},
				{
					"lat": 21.9677149,
					"lng": 96.10777,
					"ts": "2022-09-23 09:06:20",
					"speed": 6,
					"ignition": 1
				},
				{
					"lat": 21.9478966,
					"lng": 96.10795,
					"ts": "2022-09-23 09:11:00",
					"speed": 30,
					"ignition": 1
				},
				{
					"lat": 21.9329799,
					"lng": 96.1111383,
					"ts": "2022-09-23 09:15:04",
					"speed": 31,
					"ignition": 1
				},
				{
					"lat": 21.9262416,
					"lng": 96.1250483,
					"ts": "2022-09-23 09:19:05",
					"speed": 37,
					"ignition": 1
				},
				{
					"lat": 21.9195133,
					"lng": 96.138195,
					"ts": "2022-09-23 09:37:33",
					"speed": 61,
					"ignition": 1
				},
				{
					"lat": 21.946675,
					"lng": 96.1301099,
					"ts": "2022-09-23 09:42:00",
					"speed": 40,
					"ignition": 1
				},
				{
					"lat": 21.9880483,
					"lng": 96.1269166,
					"ts": "2022-09-23 09:48:03",
					"speed": 52,
					"ignition": 1
				},
				{
					"lat": 22.0210349,
					"lng": 96.1282933,
					"ts": "2022-09-23 09:54:40",
					"speed": 57,
					"ignition": 1
				},
				{
					"lat": 22.0363683,
					"lng": 96.1111216,
					"ts": "2022-09-23 09:59:24",
					"speed": 66,
					"ignition": 1
				},
				{
					"lat": 22.0945266,
					"lng": 96.1471166,
					"ts": "2022-09-23 10:08:05",
					"speed": 46,
					"ignition": 1
				},
				{
					"lat": 22.1237116,
					"lng": 96.1645933,
					"ts": "2022-09-23 10:12:40",
					"speed": 55,
					"ignition": 1
				},
				{
					"lat": 22.1667616,
					"lng": 96.17702,
					"ts": "2022-09-23 10:19:21",
					"speed": 23,
					"ignition": 1
				},
				{
					"lat": 22.2003083,
					"lng": 96.1752416,
					"ts": "2022-09-23 10:24:28",
					"speed": 65,
					"ignition": 1
				},
				{
					"lat": 22.2099016,
					"lng": 96.1258416,
					"ts": "2022-09-23 10:29:49",
					"speed": 47,
					"ignition": 1
				},
				{
					"lat": 22.2161816,
					"lng": 96.1179149,
					"ts": "2022-09-23 10:55:08",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 22.2156616,
					"lng": 96.1192466,
					"ts": "2022-09-23 11:56:37",
					"speed": 18,
					"ignition": 1
				},
				{
					"lat": 22.2127133,
					"lng": 96.1101166,
					"ts": "2022-09-23 12:01:53",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 22.2121133,
					"lng": 96.1103766,
					"ts": "2022-09-23 13:43:10",
					"speed": 7,
					"ignition": 1
				},
				{
					"lat": 22.2098983,
					"lng": 96.123555,
					"ts": "2022-09-23 13:47:18",
					"speed": 39,
					"ignition": 1
				},
				{
					"lat": 22.203725,
					"lng": 96.1674616,
					"ts": "2022-09-23 13:53:32",
					"speed": 46,
					"ignition": 1
				},
				{
					"lat": 22.1698933,
					"lng": 96.177455,
					"ts": "2022-09-23 13:59:12",
					"speed": 56,
					"ignition": 1
				},
				{
					"lat": 22.1276516,
					"lng": 96.1662283,
					"ts": "2022-09-23 14:05:56",
					"speed": 48,
					"ignition": 1
				},
				{
					"lat": 22.0964383,
					"lng": 96.1471733,
					"ts": "2022-09-23 14:10:44",
					"speed": 54,
					"ignition": 1
				},
				{
					"lat": 22.060815,
					"lng": 96.1238049,
					"ts": "2022-09-23 14:15:50",
					"speed": 62,
					"ignition": 1
				},
				{
					"lat": 22.0195033,
					"lng": 96.1018433,
					"ts": "2022-09-23 14:23:03",
					"speed": 50,
					"ignition": 1
				},
				{
					"lat": 22.0038333,
					"lng": 96.0856683,
					"ts": "2022-09-23 14:28:30",
					"speed": 22,
					"ignition": 1
				},
				{
					"lat": 21.9782483,
					"lng": 96.0841016,
					"ts": "2022-09-23 14:34:41",
					"speed": 31,
					"ignition": 1
				},
				{
					"lat": 21.96657,
					"lng": 96.08336,
					"ts": "2022-09-23 14:39:55",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 21.9666166,
					"lng": 96.07688,
					"ts": "2022-09-23 15:11:09",
					"speed": 8,
					"ignition": 1
				},
				{
					"lat": 21.964995,
					"lng": 96.073785,
					"ts": "2022-09-23 15:14:19",
					"speed": 16,
					"ignition": 1
				},
				{
					"lat": 21.9665883,
					"lng": 96.0768966,
					"ts": "2022-09-23 15:52:15",
					"speed": 9,
					"ignition": 1
				},
				{
					"lat": 21.96481,
					"lng": 96.0803666,
					"ts": "2022-09-23 15:57:37",
					"speed": 23,
					"ignition": 1
				},
				{
					"lat": 21.9543016,
					"lng": 96.0877733,
					"ts": "2022-09-23 16:03:10",
					"speed": 33,
					"ignition": 1
				},
				{
					"lat": 21.9487866,
					"lng": 96.0940349,
					"ts": "2022-09-23 16:06:43",
					"speed": 23,
					"ignition": 1
				},
				{
					"lat": 21.9526983,
					"lng": 96.0928533,
					"ts": "2022-09-23 16:12:26",
					"speed": 32,
					"ignition": 1
				},
				{
					"lat": 21.9646816,
					"lng": 96.0835283,
					"ts": "2022-09-23 16:17:46",
					"speed": 23,
					"ignition": 1
				},
				{
					"lat": 21.9647,
					"lng": 96.0816433,
					"ts": "2022-09-23 16:27:06",
					"speed": 6,
					"ignition": 1
				},
				{
					"lat": 21.9538633,
					"lng": 96.0878083,
					"ts": "2022-09-23 16:31:43",
					"speed": 17,
					"ignition": 1
				},
				{
					"lat": 21.9484483,
					"lng": 96.0939716,
					"ts": "2022-09-23 16:35:05",
					"speed": 20,
					"ignition": 1
				},
				{
					"lat": 21.9409066,
					"lng": 96.0935483,
					"ts": "2022-09-23 17:05:04",
					"speed": 16,
					"ignition": 1
				},
				{
					"lat": 21.9636883,
					"lng": 96.0860316,
					"ts": "2022-09-23 17:11:35",
					"speed": 6,
					"ignition": 1
				}
			]
		},
		{
			"thingName": "ltpk_7h_5745",
			"make": "ford",
			"model": "ford-ranger",
			"locations": [
				{
					"lat": 22.2710616,
					"lng": 96.128815,
					"ts": "2022-09-23 00:18:23",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 22.27681,
					"lng": 96.1330216,
					"ts": "2022-09-23 05:19:54",
					"speed": 46,
					"ignition": 1
				},
				{
					"lat": 22.3074283,
					"lng": 96.15066,
					"ts": "2022-09-23 05:25:18",
					"speed": 33,
					"ignition": 1
				},
				{
					"lat": 22.3296799,
					"lng": 96.1385966,
					"ts": "2022-09-23 05:29:38",
					"speed": 10,
					"ignition": 1
				},
				{
					"lat": 22.3317416,
					"lng": 96.1379783,
					"ts": "2022-09-23 05:45:11",
					"speed": 12,
					"ignition": 1
				},
				{
					"lat": 22.331415,
					"lng": 96.138055,
					"ts": "2022-09-23 05:51:20",
					"speed": 17,
					"ignition": 1
				},
				{
					"lat": 22.3305099,
					"lng": 96.13834,
					"ts": "2022-09-23 06:00:36",
					"speed": 11,
					"ignition": 1
				},
				{
					"lat": 22.3108633,
					"lng": 96.1509016,
					"ts": "2022-09-23 06:05:21",
					"speed": 47,
					"ignition": 1
				},
				{
					"lat": 22.2857733,
					"lng": 96.138735,
					"ts": "2022-09-23 06:10:26",
					"speed": 40,
					"ignition": 1
				},
				{
					"lat": 22.2635916,
					"lng": 96.125715,
					"ts": "2022-09-23 06:15:44",
					"speed": 29,
					"ignition": 1
				},
				{
					"lat": 22.235705,
					"lng": 96.105345,
					"ts": "2022-09-23 06:21:26",
					"speed": 54,
					"ignition": 1
				},
				{
					"lat": 22.2101883,
					"lng": 96.1196816,
					"ts": "2022-09-23 06:26:50",
					"speed": 19,
					"ignition": 1
				},
				{
					"lat": 22.20648,
					"lng": 96.1204399,
					"ts": "2022-09-23 06:40:07",
					"speed": 11,
					"ignition": 1
				},
				{
					"lat": 22.1974266,
					"lng": 96.1219566,
					"ts": "2022-09-23 06:45:43",
					"speed": 12,
					"ignition": 1
				},
				{
					"lat": 22.18217,
					"lng": 96.12778,
					"ts": "2022-09-23 06:53:42",
					"speed": 10,
					"ignition": 1
				},
				{
					"lat": 22.1759983,
					"lng": 96.1291116,
					"ts": "2022-09-23 07:46:26",
					"speed": 8,
					"ignition": 1
				},
				{
					"lat": 22.1815766,
					"lng": 96.1280916,
					"ts": "2022-09-23 07:51:11",
					"speed": 7,
					"ignition": 1
				},
				{
					"lat": 22.2027983,
					"lng": 96.1208633,
					"ts": "2022-09-23 07:59:48",
					"speed": 17,
					"ignition": 1
				},
				{
					"lat": 22.2102883,
					"lng": 96.11818,
					"ts": "2022-09-23 08:04:14",
					"speed": 16,
					"ignition": 1
				},
				{
					"lat": 22.2070916,
					"lng": 96.10254,
					"ts": "2022-09-23 08:28:33",
					"speed": 6,
					"ignition": 1
				},
				{
					"lat": 22.20996,
					"lng": 96.1092816,
					"ts": "2022-09-23 08:33:19",
					"speed": 35,
					"ignition": 1
				},
				{
					"lat": 22.225915,
					"lng": 96.1065549,
					"ts": "2022-09-23 08:39:29",
					"speed": 39,
					"ignition": 1
				},
				{
					"lat": 22.2525849,
					"lng": 96.1165166,
					"ts": "2022-09-23 08:44:51",
					"speed": 35,
					"ignition": 1
				},
				{
					"lat": 22.2713683,
					"lng": 96.131015,
					"ts": "2022-09-23 08:49:25",
					"speed": 9,
					"ignition": 1
				},
				{
					"lat": 22.2713633,
					"lng": 96.1308166,
					"ts": "2022-09-23 09:18:11",
					"speed": 11,
					"ignition": 1
				},
				{
					"lat": 22.2485616,
					"lng": 96.1134766,
					"ts": "2022-09-23 09:23:50",
					"speed": 43,
					"ignition": 1
				},
				{
					"lat": 22.2216816,
					"lng": 96.1116866,
					"ts": "2022-09-23 09:30:04",
					"speed": 18,
					"ignition": 1
				},
				{
					"lat": 22.21542,
					"lng": 96.1047483,
					"ts": "2022-09-23 09:33:57",
					"speed": 27,
					"ignition": 1
				},
				{
					"lat": 22.2069833,
					"lng": 96.102905,
					"ts": "2022-09-23 09:42:35",
					"speed": 9,
					"ignition": 1
				},
				{
					"lat": 22.2135116,
					"lng": 96.1039766,
					"ts": "2022-09-23 09:46:10",
					"speed": 24,
					"ignition": 1
				},
				{
					"lat": 22.2198066,
					"lng": 96.1066666,
					"ts": "2022-09-23 09:51:53",
					"speed": 12,
					"ignition": 1
				},
				{
					"lat": 22.2243083,
					"lng": 96.111625,
					"ts": "2022-09-23 09:56:07",
					"speed": 11,
					"ignition": 1
				},
				{
					"lat": 22.245965,
					"lng": 96.11151,
					"ts": "2022-09-23 10:01:42",
					"speed": 46,
					"ignition": 1
				},
				{
					"lat": 22.2411833,
					"lng": 96.10851,
					"ts": "2022-09-23 10:24:54",
					"speed": 40,
					"ignition": 1
				},
				{
					"lat": 22.217025,
					"lng": 96.1190516,
					"ts": "2022-09-23 10:31:46",
					"speed": 25,
					"ignition": 1
				},
				{
					"lat": 22.2166733,
					"lng": 96.1172966,
					"ts": "2022-09-23 11:55:24",
					"speed": 10,
					"ignition": 1
				},
				{
					"lat": 22.212205,
					"lng": 96.1117266,
					"ts": "2022-09-23 11:59:43",
					"speed": 9,
					"ignition": 1
				},
				{
					"lat": 22.2127083,
					"lng": 96.1102016,
					"ts": "2022-09-23 13:43:57",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 22.216535,
					"lng": 96.1053433,
					"ts": "2022-09-23 13:48:21",
					"speed": 17,
					"ignition": 1
				},
				{
					"lat": 22.2219616,
					"lng": 96.1127449,
					"ts": "2022-09-23 13:52:46",
					"speed": 7,
					"ignition": 1
				},
				{
					"lat": 22.2377466,
					"lng": 96.1065733,
					"ts": "2022-09-23 13:57:41",
					"speed": 19,
					"ignition": 1
				},
				{
					"lat": 22.2600116,
					"lng": 96.1223233,
					"ts": "2022-09-23 14:03:58",
					"speed": 34,
					"ignition": 1
				},
				{
					"lat": 22.2717183,
					"lng": 96.1297133,
					"ts": "2022-09-23 14:07:58",
					"speed": 8,
					"ignition": 1
				},
				{
					"lat": 22.2712766,
					"lng": 96.1310833,
					"ts": "2022-09-23 15:22:41",
					"speed": 12,
					"ignition": 1
				},
				{
					"lat": 22.2569533,
					"lng": 96.1199133,
					"ts": "2022-09-23 15:38:47",
					"speed": 22,
					"ignition": 1
				}
			]
		},
		{
			"thingName": "ltpk_2f_4001",
			"make": "subaru",
			"model": "subaru-sambar-tt1",
			"locations": [
				{
					"lat": 21.9230933,
					"lng": 96.1078916,
					"ts": "2022-09-23 00:02:04",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 21.9240033,
					"lng": 96.1064333,
					"ts": "2022-09-23 08:48:12",
					"speed": 18,
					"ignition": 1
				},
				{
					"lat": 21.93331,
					"lng": 96.1011266,
					"ts": "2022-09-23 08:52:29",
					"speed": 8,
					"ignition": 1
				},
				{
					"lat": 21.9384983,
					"lng": 96.09567,
					"ts": "2022-09-23 08:57:31",
					"speed": 7,
					"ignition": 1
				},
				{
					"lat": 21.9527233,
					"lng": 96.0906283,
					"ts": "2022-09-23 09:02:07",
					"speed": 37,
					"ignition": 1
				},
				{
					"lat": 21.9650166,
					"lng": 96.0770983,
					"ts": "2022-09-23 09:10:09",
					"speed": 27,
					"ignition": 1
				},
				{
					"lat": 21.970725,
					"lng": 96.0690316,
					"ts": "2022-09-23 09:28:11",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 21.9710033,
					"lng": 96.0634116,
					"ts": "2022-09-23 09:33:17",
					"speed": 14,
					"ignition": 1
				},
				{
					"lat": 21.97813,
					"lng": 96.0626766,
					"ts": "2022-09-23 09:56:21",
					"speed": 7,
					"ignition": 1
				},
				{
					"lat": 21.9838616,
					"lng": 96.0696899,
					"ts": "2022-09-23 10:00:47",
					"speed": 26,
					"ignition": 1
				},
				{
					"lat": 21.9869549,
					"lng": 96.0722066,
					"ts": "2022-09-23 10:11:32",
					"speed": 7,
					"ignition": 1
				},
				{
					"lat": 21.9725816,
					"lng": 96.0712316,
					"ts": "2022-09-23 10:17:22",
					"speed": 20,
					"ignition": 1
				},
				{
					"lat": 21.9699233,
					"lng": 96.0714783,
					"ts": "2022-09-23 10:25:18",
					"speed": 26,
					"ignition": 1
				},
				{
					"lat": 21.9699116,
					"lng": 96.0733633,
					"ts": "2022-09-23 11:42:06",
					"speed": 20,
					"ignition": 1
				},
				{
					"lat": 21.9634799,
					"lng": 96.0745083,
					"ts": "2022-09-23 12:02:01",
					"speed": 10,
					"ignition": 1
				},
				{
					"lat": 21.96943,
					"lng": 96.0685883,
					"ts": "2022-09-23 13:29:23",
					"speed": 28,
					"ignition": 1
				},
				{
					"lat": 21.9696416,
					"lng": 96.0768316,
					"ts": "2022-09-23 13:33:00",
					"speed": 4,
					"ignition": 1
				},
				{
					"lat": 21.9691933,
					"lng": 96.0861466,
					"ts": "2022-09-23 13:38:39",
					"speed": 7,
					"ignition": 1
				},
				{
					"lat": 21.981475,
					"lng": 96.0951616,
					"ts": "2022-09-23 13:45:09",
					"speed": 8,
					"ignition": 1
				},
				{
					"lat": 21.982855,
					"lng": 96.0872649,
					"ts": "2022-09-23 13:58:21",
					"speed": 8,
					"ignition": 1
				},
				{
					"lat": 21.9771333,
					"lng": 96.0807266,
					"ts": "2022-09-23 14:05:13",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 21.9708183,
					"lng": 96.0696783,
					"ts": "2022-09-23 14:13:36",
					"speed": 10,
					"ignition": 1
				},
				{
					"lat": 21.96204,
					"lng": 96.0745816,
					"ts": "2022-09-23 15:32:30",
					"speed": 14,
					"ignition": 1
				},
				{
					"lat": 21.9467116,
					"lng": 96.0873733,
					"ts": "2022-09-23 15:40:01",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 21.9186116,
					"lng": 96.0856483,
					"ts": "2022-09-23 15:48:21",
					"speed": 36,
					"ignition": 1
				},
				{
					"lat": 21.902395,
					"lng": 96.0929233,
					"ts": "2022-09-23 16:00:51",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 21.8988333,
					"lng": 96.091255,
					"ts": "2022-09-23 16:16:38",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 21.893445,
					"lng": 96.0984533,
					"ts": "2022-09-23 16:35:27",
					"speed": 10,
					"ignition": 1
				},
				{
					"lat": 21.8948616,
					"lng": 96.1092433,
					"ts": "2022-09-23 16:45:48",
					"speed": 19,
					"ignition": 1
				},
				{
					"lat": 21.9248033,
					"lng": 96.1103183,
					"ts": "2022-09-23 16:58:38",
					"speed": 21,
					"ignition": 1
				},
				{
					"lat": 21.9351783,
					"lng": 96.1073883,
					"ts": "2022-09-23 17:07:26",
					"speed": 27,
					"ignition": 1
				},
				{
					"lat": 21.9519183,
					"lng": 96.1042366,
					"ts": "2022-09-23 17:14:44",
					"speed": 37,
					"ignition": 1
				}
			]
		},
		{
			"thingName": "ltpk_8g_4613",
			"make": "toyota",
			"model": "shwe-gantawin-l-t-sgtwl-04",
			"locations": [
				{
					"lat": 17.1315316,
					"lng": 96.0035183,
					"ts": "2022-09-23 07:39:31",
					"speed": 40,
					"ignition": 1
				},
				{
					"lat": 17.110595,
					"lng": 96.0146216,
					"ts": "2022-09-23 07:47:35",
					"speed": 60,
					"ignition": 1
				},
				{
					"lat": 17.0982866,
					"lng": 96.038155,
					"ts": "2022-09-23 07:51:55",
					"speed": 6,
					"ignition": 1
				},
				{
					"lat": 17.1038216,
					"lng": 96.0231683,
					"ts": "2022-09-23 07:57:47",
					"speed": 52,
					"ignition": 1
				},
				{
					"lat": 17.1085183,
					"lng": 96.0164466,
					"ts": "2022-09-23 08:04:56",
					"speed": 24,
					"ignition": 1
				},
				{
					"lat": 17.1372366,
					"lng": 96.0003483,
					"ts": "2022-09-23 08:10:07",
					"speed": 31,
					"ignition": 1
				},
				{
					"lat": 17.1334033,
					"lng": 95.9678416,
					"ts": "2022-09-23 08:15:17",
					"speed": 51,
					"ignition": 1
				},
				{
					"lat": 17.154915,
					"lng": 95.937495,
					"ts": "2022-09-23 08:20:37",
					"speed": 54,
					"ignition": 1
				},
				{
					"lat": 17.158745,
					"lng": 95.8931633,
					"ts": "2022-09-23 08:25:54",
					"speed": 60,
					"ignition": 1
				},
				{
					"lat": 17.170365,
					"lng": 95.8679333,
					"ts": "2022-09-23 08:30:24",
					"speed": 31,
					"ignition": 1
				},
				{
					"lat": 17.1669383,
					"lng": 95.867385,
					"ts": "2022-09-23 09:01:37",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 17.17054,
					"lng": 95.87494,
					"ts": "2022-09-23 09:27:06",
					"speed": 32,
					"ignition": 1
				},
				{
					"lat": 17.1433816,
					"lng": 95.880335,
					"ts": "2022-09-23 09:31:30",
					"speed": 55,
					"ignition": 1
				},
				{
					"lat": 17.1057816,
					"lng": 95.8745283,
					"ts": "2022-09-23 09:36:41",
					"speed": 34,
					"ignition": 1
				},
				{
					"lat": 17.1126816,
					"lng": 95.86135,
					"ts": "2022-09-23 09:41:35",
					"speed": 7,
					"ignition": 1
				},
				{
					"lat": 17.1127033,
					"lng": 95.8612533,
					"ts": "2022-09-23 10:31:26",
					"speed": 10,
					"ignition": 1
				},
				{
					"lat": 17.1059533,
					"lng": 95.8758116,
					"ts": "2022-09-23 10:37:46",
					"speed": 23,
					"ignition": 1
				},
				{
					"lat": 17.1491449,
					"lng": 95.8801183,
					"ts": "2022-09-23 10:43:05",
					"speed": 64,
					"ignition": 1
				},
				{
					"lat": 17.1687766,
					"lng": 95.8798033,
					"ts": "2022-09-23 11:29:51",
					"speed": 12,
					"ignition": 1
				},
				{
					"lat": 17.1607883,
					"lng": 95.90294,
					"ts": "2022-09-23 11:43:08",
					"speed": 60,
					"ignition": 1
				},
				{
					"lat": 17.1493566,
					"lng": 95.9477749,
					"ts": "2022-09-23 11:48:39",
					"speed": 51,
					"ignition": 1
				},
				{
					"lat": 17.1330833,
					"lng": 95.97586,
					"ts": "2022-09-23 11:55:12",
					"speed": 50,
					"ignition": 1
				},
				{
					"lat": 17.1368933,
					"lng": 95.9959633,
					"ts": "2022-09-23 12:10:00",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 17.1319133,
					"lng": 96.0033266,
					"ts": "2022-09-23 14:13:41",
					"speed": 50,
					"ignition": 1
				},
				{
					"lat": 17.1001283,
					"lng": 96.030995,
					"ts": "2022-09-23 14:18:34",
					"speed": 71,
					"ignition": 1
				},
				{
					"lat": 17.1033533,
					"lng": 96.0498666,
					"ts": "2022-09-23 14:22:52",
					"speed": 7,
					"ignition": 1
				},
				{
					"lat": 17.1048316,
					"lng": 96.0787116,
					"ts": "2022-09-23 14:27:26",
					"speed": 69,
					"ignition": 1
				},
				{
					"lat": 17.073345,
					"lng": 96.1095633,
					"ts": "2022-09-23 14:32:21",
					"speed": 49,
					"ignition": 1
				},
				{
					"lat": 17.050355,
					"lng": 96.119,
					"ts": "2022-09-23 14:38:29",
					"speed": 54,
					"ignition": 1
				},
				{
					"lat": 17.02974,
					"lng": 96.1324283,
					"ts": "2022-09-23 14:42:59",
					"speed": 21,
					"ignition": 1
				},
				{
					"lat": 17.0166433,
					"lng": 96.1463749,
					"ts": "2022-09-23 14:48:29",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 17.0296583,
					"lng": 96.1325933,
					"ts": "2022-09-23 15:07:47",
					"speed": 11,
					"ignition": 1
				},
				{
					"lat": 17.0462,
					"lng": 96.1222566,
					"ts": "2022-09-23 15:12:40",
					"speed": 33,
					"ignition": 1
				},
				{
					"lat": 17.0753799,
					"lng": 96.1084883,
					"ts": "2022-09-23 15:19:00",
					"speed": 61,
					"ignition": 1
				},
				{
					"lat": 17.1089316,
					"lng": 96.0732349,
					"ts": "2022-09-23 15:25:06",
					"speed": 57,
					"ignition": 1
				},
				{
					"lat": 17.0969916,
					"lng": 96.0539966,
					"ts": "2022-09-23 15:29:58",
					"speed": 50,
					"ignition": 1
				},
				{
					"lat": 17.0753416,
					"lng": 96.0625616,
					"ts": "2022-09-23 15:54:12",
					"speed": 30,
					"ignition": 1
				},
				{
					"lat": 17.1000733,
					"lng": 96.0526566,
					"ts": "2022-09-23 16:00:33",
					"speed": 6,
					"ignition": 1
				},
				{
					"lat": 17.0989266,
					"lng": 96.0462583,
					"ts": "2022-09-23 16:07:56",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 17.0979033,
					"lng": 96.0359566,
					"ts": "2022-09-23 16:17:20",
					"speed": 29,
					"ignition": 1
				}
			]
		},
		{
			"thingName": "ltpk_1p_5753",
			"make": "isuzu",
			"model": "elf-nhr85",
			"locations": [
				{
					"lat": 16.7676366,
					"lng": 96.5272483,
					"ts": "2022-09-23 03:36:26",
					"speed": 6,
					"ignition": 1
				},
				{
					"lat": 16.780625,
					"lng": 96.5500633,
					"ts": "2022-09-23 03:42:03",
					"speed": 33,
					"ignition": 1
				},
				{
					"lat": 16.8009333,
					"lng": 96.5724516,
					"ts": "2022-09-23 03:49:33",
					"speed": 24,
					"ignition": 1
				},
				{
					"lat": 16.8126633,
					"lng": 96.5915816,
					"ts": "2022-09-23 03:57:40",
					"speed": 23,
					"ignition": 1
				},
				{
					"lat": 16.8191683,
					"lng": 96.6150166,
					"ts": "2022-09-23 04:11:08",
					"speed": 33,
					"ignition": 1
				},
				{
					"lat": 16.825175,
					"lng": 96.6492733,
					"ts": "2022-09-23 04:21:05",
					"speed": 22,
					"ignition": 1
				},
				{
					"lat": 16.8436866,
					"lng": 96.6529616,
					"ts": "2022-09-23 04:26:48",
					"speed": 11,
					"ignition": 1
				},
				{
					"lat": 16.8491333,
					"lng": 96.6567983,
					"ts": "2022-09-23 04:30:56",
					"speed": 19,
					"ignition": 1
				},
				{
					"lat": 16.854525,
					"lng": 96.6744516,
					"ts": "2022-09-23 04:37:11",
					"speed": 16,
					"ignition": 1
				},
				{
					"lat": 16.8544999,
					"lng": 96.6744533,
					"ts": "2022-09-23 05:10:57",
					"speed": 11,
					"ignition": 1
				},
				{
					"lat": 16.848205,
					"lng": 96.6559249,
					"ts": "2022-09-23 05:17:10",
					"speed": 23,
					"ignition": 1
				},
				{
					"lat": 16.84357,
					"lng": 96.6529333,
					"ts": "2022-09-23 05:21:47",
					"speed": 7,
					"ignition": 1
				},
				{
					"lat": 16.82534,
					"lng": 96.6445466,
					"ts": "2022-09-23 05:27:47",
					"speed": 44,
					"ignition": 1
				},
				{
					"lat": 16.8179583,
					"lng": 96.6087966,
					"ts": "2022-09-23 05:36:04",
					"speed": 18,
					"ignition": 1
				},
				{
					"lat": 16.8022666,
					"lng": 96.5744016,
					"ts": "2022-09-23 05:49:54",
					"speed": 17,
					"ignition": 1
				},
				{
					"lat": 16.77395,
					"lng": 96.5453516,
					"ts": "2022-09-23 06:00:29",
					"speed": 38,
					"ignition": 1
				},
				{
					"lat": 16.7666233,
					"lng": 96.52646,
					"ts": "2022-09-23 06:35:32",
					"speed": 26,
					"ignition": 1
				},
				{
					"lat": 16.7593933,
					"lng": 96.5320399,
					"ts": "2022-09-23 06:39:22",
					"speed": 24,
					"ignition": 1
				},
				{
					"lat": 16.7532366,
					"lng": 96.5501933,
					"ts": "2022-09-23 06:50:23",
					"speed": 17,
					"ignition": 1
				},
				{
					"lat": 16.7478916,
					"lng": 96.5574566,
					"ts": "2022-09-23 06:56:28",
					"speed": 12,
					"ignition": 1
				},
				{
					"lat": 16.7457016,
					"lng": 96.5640966,
					"ts": "2022-09-23 07:02:19",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 16.7549633,
					"lng": 96.5448066,
					"ts": "2022-09-23 11:38:01",
					"speed": 15,
					"ignition": 1
				},
				{
					"lat": 16.7587916,
					"lng": 96.5329399,
					"ts": "2022-09-23 11:44:22",
					"speed": 9,
					"ignition": 1
				},
				{
					"lat": 16.7631666,
					"lng": 96.5240233,
					"ts": "2022-09-23 11:49:42",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 16.7423433,
					"lng": 96.5210466,
					"ts": "2022-09-23 12:01:15",
					"speed": 36,
					"ignition": 1
				},
				{
					"lat": 16.7049633,
					"lng": 96.5350666,
					"ts": "2022-09-23 12:07:53",
					"speed": 43,
					"ignition": 1
				},
				{
					"lat": 16.6794233,
					"lng": 96.5378899,
					"ts": "2022-09-23 12:14:53",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 16.653235,
					"lng": 96.5407916,
					"ts": "2022-09-23 12:22:20",
					"speed": 21,
					"ignition": 1
				},
				{
					"lat": 16.6208966,
					"lng": 96.5280683,
					"ts": "2022-09-23 12:29:30",
					"speed": 46,
					"ignition": 1
				},
				{
					"lat": 16.5979383,
					"lng": 96.5092633,
					"ts": "2022-09-23 12:36:05",
					"speed": 4,
					"ignition": 1
				},
				{
					"lat": 16.5902349,
					"lng": 96.4903166,
					"ts": "2022-09-23 12:47:59",
					"speed": 49,
					"ignition": 1
				},
				{
					"lat": 16.5816633,
					"lng": 96.4597516,
					"ts": "2022-09-23 12:54:52",
					"speed": 19,
					"ignition": 1
				},
				{
					"lat": 16.558875,
					"lng": 96.4271766,
					"ts": "2022-09-23 13:02:10",
					"speed": 51,
					"ignition": 1
				},
				{
					"lat": 16.5802583,
					"lng": 96.4102833,
					"ts": "2022-09-23 13:08:13",
					"speed": 47,
					"ignition": 1
				},
				{
					"lat": 16.6001733,
					"lng": 96.3936633,
					"ts": "2022-09-23 13:17:23",
					"speed": 15,
					"ignition": 1
				},
				{
					"lat": 16.60813,
					"lng": 96.37879,
					"ts": "2022-09-23 13:25:02",
					"speed": 7,
					"ignition": 1
				},
				{
					"lat": 16.6153866,
					"lng": 96.36599,
					"ts": "2022-09-23 13:31:33",
					"speed": 35,
					"ignition": 1
				},
				{
					"lat": 16.6267066,
					"lng": 96.3351816,
					"ts": "2022-09-23 13:40:07",
					"speed": 37,
					"ignition": 1
				},
				{
					"lat": 16.61503,
					"lng": 96.3251533,
					"ts": "2022-09-23 14:12:01",
					"speed": 22,
					"ignition": 1
				},
				{
					"lat": 16.5973733,
					"lng": 96.3333216,
					"ts": "2022-09-23 14:19:23",
					"speed": 30,
					"ignition": 1
				},
				{
					"lat": 16.5970183,
					"lng": 96.33372,
					"ts": "2022-09-23 15:11:49",
					"speed": 33,
					"ignition": 1
				},
				{
					"lat": 16.6068233,
					"lng": 96.3140399,
					"ts": "2022-09-23 15:17:01",
					"speed": 29,
					"ignition": 1
				},
				{
					"lat": 16.5801,
					"lng": 96.3044633,
					"ts": "2022-09-23 15:23:57",
					"speed": 25,
					"ignition": 1
				},
				{
					"lat": 16.5528433,
					"lng": 96.3185699,
					"ts": "2022-09-23 15:30:44",
					"speed": 34,
					"ignition": 1
				},
				{
					"lat": 16.5395166,
					"lng": 96.3401,
					"ts": "2022-09-23 15:38:12",
					"speed": 24,
					"ignition": 1
				},
				{
					"lat": 16.5317616,
					"lng": 96.3577283,
					"ts": "2022-09-23 15:44:52",
					"speed": 31,
					"ignition": 1
				},
				{
					"lat": 16.550725,
					"lng": 96.3844516,
					"ts": "2022-09-23 15:52:11",
					"speed": 26,
					"ignition": 1
				},
				{
					"lat": 16.5495016,
					"lng": 96.4118616,
					"ts": "2022-09-23 16:01:50",
					"speed": 17,
					"ignition": 1
				},
				{
					"lat": 16.5559316,
					"lng": 96.42292,
					"ts": "2022-09-23 16:09:25",
					"speed": 10,
					"ignition": 1
				},
				{
					"lat": 16.5768,
					"lng": 96.4535766,
					"ts": "2022-09-23 16:15:25",
					"speed": 44,
					"ignition": 1
				},
				{
					"lat": 16.5856483,
					"lng": 96.4781616,
					"ts": "2022-09-23 16:21:49",
					"speed": 21,
					"ignition": 1
				},
				{
					"lat": 16.5958699,
					"lng": 96.5037683,
					"ts": "2022-09-23 16:28:08",
					"speed": 22,
					"ignition": 1
				},
				{
					"lat": 16.5990999,
					"lng": 96.512325,
					"ts": "2022-09-23 16:37:13",
					"speed": 28,
					"ignition": 1
				},
				{
					"lat": 16.6311066,
					"lng": 96.5335133,
					"ts": "2022-09-23 16:43:22",
					"speed": 54,
					"ignition": 1
				},
				{
					"lat": 16.63707,
					"lng": 96.5218316,
					"ts": "2022-09-23 16:50:10",
					"speed": 21,
					"ignition": 1
				},
				{
					"lat": 16.6376649,
					"lng": 96.5016733,
					"ts": "2022-09-23 16:57:29",
					"speed": 28,
					"ignition": 1
				},
				{
					"lat": 16.6421099,
					"lng": 96.4769116,
					"ts": "2022-09-23 17:04:52",
					"speed": 41,
					"ignition": 1
				},
				{
					"lat": 16.64863,
					"lng": 96.44177,
					"ts": "2022-09-23 17:11:39",
					"speed": 33,
					"ignition": 1
				},
				{
					"lat": 16.6521483,
					"lng": 96.4020549,
					"ts": "2022-09-23 17:19:19",
					"speed": 31,
					"ignition": 1
				},
				{
					"lat": 16.6485916,
					"lng": 96.35582,
					"ts": "2022-09-23 17:27:10",
					"speed": 41,
					"ignition": 1
				},
				{
					"lat": 16.6428,
					"lng": 96.3272949,
					"ts": "2022-09-23 17:33:56",
					"speed": 11,
					"ignition": 1
				}
			]
		},
		{
			"thingName": "ltpk_8l_4418",
			"make": "toyota",
			"model": "asia-maung-l-t-1-ton",
			"locations": [
				{
					"lat": 16.97074,
					"lng": 96.0924133,
					"ts": "2022-09-23 06:01:22",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 16.97074,
					"lng": 96.0924133,
					"ts": "2022-09-23 06:25:51",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 16.9672299,
					"lng": 96.09519,
					"ts": "2022-09-23 06:42:06",
					"speed": 37,
					"ignition": 1
				},
				{
					"lat": 16.9526699,
					"lng": 96.0819016,
					"ts": "2022-09-23 06:49:38",
					"speed": 18,
					"ignition": 1
				},
				{
					"lat": 16.9326749,
					"lng": 96.0823666,
					"ts": "2022-09-23 06:56:03",
					"speed": 38,
					"ignition": 1
				},
				{
					"lat": 16.9112116,
					"lng": 96.0687316,
					"ts": "2022-09-23 07:03:17",
					"speed": 18,
					"ignition": 1
				},
				{
					"lat": 16.8956416,
					"lng": 96.0543166,
					"ts": "2022-09-23 07:10:52",
					"speed": 9,
					"ignition": 1
				},
				{
					"lat": 16.88997,
					"lng": 96.054165,
					"ts": "2022-09-23 07:28:22",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 16.88997,
					"lng": 96.054165,
					"ts": "2022-09-23 07:52:52",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 16.887485,
					"lng": 96.06215,
					"ts": "2022-09-23 07:59:00",
					"speed": 16,
					"ignition": 1
				},
				{
					"lat": 16.89986,
					"lng": 96.06544,
					"ts": "2022-09-23 08:04:56",
					"speed": 19,
					"ignition": 1
				},
				{
					"lat": 16.923245,
					"lng": 96.07072,
					"ts": "2022-09-23 08:10:26",
					"speed": 55,
					"ignition": 1
				},
				{
					"lat": 16.94761,
					"lng": 96.0831816,
					"ts": "2022-09-23 08:16:01",
					"speed": 22,
					"ignition": 1
				},
				{
					"lat": 16.9690883,
					"lng": 96.0912183,
					"ts": "2022-09-23 08:22:43",
					"speed": 13,
					"ignition": 1
				},
				{
					"lat": 16.9707533,
					"lng": 96.0924783,
					"ts": "2022-09-23 08:35:52",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 16.9705799,
					"lng": 96.0925716,
					"ts": "2022-09-23 09:00:21",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 16.96441,
					"lng": 96.09225,
					"ts": "2022-09-23 09:12:32",
					"speed": 10,
					"ignition": 1
				},
				{
					"lat": 16.948555,
					"lng": 96.0829783,
					"ts": "2022-09-23 09:19:04",
					"speed": 25,
					"ignition": 1
				},
				{
					"lat": 16.9304733,
					"lng": 96.0826883,
					"ts": "2022-09-23 09:25:28",
					"speed": 39,
					"ignition": 1
				},
				{
					"lat": 16.9215833,
					"lng": 96.05488,
					"ts": "2022-09-23 09:30:19",
					"speed": 22,
					"ignition": 1
				},
				{
					"lat": 16.8945783,
					"lng": 96.0435416,
					"ts": "2022-09-23 09:40:26",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 16.87459,
					"lng": 96.0328983,
					"ts": "2022-09-23 09:48:51",
					"speed": 16,
					"ignition": 1
				},
				{
					"lat": 16.8473466,
					"lng": 96.036135,
					"ts": "2022-09-23 09:56:14",
					"speed": 37,
					"ignition": 1
				},
				{
					"lat": 16.8022766,
					"lng": 96.03166,
					"ts": "2022-09-23 10:02:28",
					"speed": 63,
					"ignition": 1
				},
				{
					"lat": 16.7553266,
					"lng": 96.0270883,
					"ts": "2022-09-23 10:09:04",
					"speed": 41,
					"ignition": 1
				},
				{
					"lat": 16.714305,
					"lng": 96.0321433,
					"ts": "2022-09-23 10:14:20",
					"speed": 5,
					"ignition": 1
				},
				{
					"lat": 16.7096,
					"lng": 96.01688,
					"ts": "2022-09-23 10:18:22",
					"speed": 7,
					"ignition": 1
				},
				{
					"lat": 16.7069299,
					"lng": 95.9858583,
					"ts": "2022-09-23 10:24:22",
					"speed": 70,
					"ignition": 1
				},
				{
					"lat": 16.6944566,
					"lng": 95.9671399,
					"ts": "2022-09-23 10:29:23",
					"speed": 42,
					"ignition": 1
				},
				{
					"lat": 16.6900433,
					"lng": 95.9697916,
					"ts": "2022-09-23 10:44:09",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 16.69011,
					"lng": 95.9699283,
					"ts": "2022-09-23 11:08:40",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 16.6996283,
					"lng": 95.9634733,
					"ts": "2022-09-23 11:23:00",
					"speed": 10,
					"ignition": 1
				},
				{
					"lat": 16.7087966,
					"lng": 95.99086,
					"ts": "2022-09-23 11:28:13",
					"speed": 46,
					"ignition": 1
				},
				{
					"lat": 16.711635,
					"lng": 96.0239299,
					"ts": "2022-09-23 11:34:59",
					"speed": 48,
					"ignition": 1
				},
				{
					"lat": 16.7390916,
					"lng": 96.0230066,
					"ts": "2022-09-23 11:40:04",
					"speed": 50,
					"ignition": 1
				},
				{
					"lat": 16.7691466,
					"lng": 96.0387633,
					"ts": "2022-09-23 11:45:17",
					"speed": 57,
					"ignition": 1
				},
				{
					"lat": 16.7620266,
					"lng": 96.0851266,
					"ts": "2022-09-23 11:51:01",
					"speed": 36,
					"ignition": 1
				},
				{
					"lat": 16.777445,
					"lng": 96.1132133,
					"ts": "2022-09-23 11:56:25",
					"speed": 55,
					"ignition": 1
				},
				{
					"lat": 16.7890083,
					"lng": 96.1105183,
					"ts": "2022-09-23 12:05:39",
					"speed": 6,
					"ignition": 1
				},
				{
					"lat": 16.78472,
					"lng": 96.1111383,
					"ts": "2022-09-23 12:19:53",
					"speed": 6,
					"ignition": 1
				},
				{
					"lat": 16.76592,
					"lng": 96.1078733,
					"ts": "2022-09-23 12:26:23",
					"speed": 36,
					"ignition": 1
				},
				{
					"lat": 16.7622966,
					"lng": 96.0739233,
					"ts": "2022-09-23 12:32:00",
					"speed": 52,
					"ignition": 1
				},
				{
					"lat": 16.7685616,
					"lng": 96.0296866,
					"ts": "2022-09-23 12:37:07",
					"speed": 20,
					"ignition": 1
				},
				{
					"lat": 16.8031133,
					"lng": 96.03164,
					"ts": "2022-09-23 13:03:10",
					"speed": 9,
					"ignition": 1
				},
				{
					"lat": 16.8467333,
					"lng": 96.0362399,
					"ts": "2022-09-23 13:09:41",
					"speed": 50,
					"ignition": 1
				},
				{
					"lat": 16.87314,
					"lng": 96.0466933,
					"ts": "2022-09-23 13:15:35",
					"speed": 53,
					"ignition": 1
				},
				{
					"lat": 16.8914666,
					"lng": 96.0526333,
					"ts": "2022-09-23 13:25:03",
					"speed": 6,
					"ignition": 1
				},
				{
					"lat": 16.9113516,
					"lng": 96.0693566,
					"ts": "2022-09-23 13:31:42",
					"speed": 34,
					"ignition": 1
				},
				{
					"lat": 16.93215,
					"lng": 96.0819516,
					"ts": "2022-09-23 13:36:34",
					"speed": 21,
					"ignition": 1
				},
				{
					"lat": 16.9465833,
					"lng": 96.0836083,
					"ts": "2022-09-23 13:42:38",
					"speed": 37,
					"ignition": 1
				},
				{
					"lat": 16.9562066,
					"lng": 96.078245,
					"ts": "2022-09-23 13:47:35",
					"speed": 6,
					"ignition": 1
				},
				{
					"lat": 16.9689233,
					"lng": 96.091225,
					"ts": "2022-09-23 14:05:58",
					"speed": 25,
					"ignition": 1
				},
				{
					"lat": 16.9703783,
					"lng": 96.0922433,
					"ts": "2022-09-23 14:22:23",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 16.9704666,
					"lng": 96.09221,
					"ts": "2022-09-23 14:46:54",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 16.9706866,
					"lng": 96.0924466,
					"ts": "2022-09-23 15:07:35",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 16.9707866,
					"lng": 96.0925016,
					"ts": "2022-09-23 15:32:05",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 16.9707583,
					"lng": 96.0924099,
					"ts": "2022-09-23 15:56:35",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 16.9706683,
					"lng": 96.0925183,
					"ts": "2022-09-23 16:21:05",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 16.970575,
					"lng": 96.0926883,
					"ts": "2022-09-23 16:45:35",
					"speed": 0,
					"ignition": 1
				},
				{
					"lat": 16.9705483,
					"lng": 96.09262,
					"ts": "2022-09-23 17:10:05",
					"speed": 0,
					"ignition": 1
				}
			]
		}
	],
	"message": "Successfull",
	"success": true,
	"responseCode": 200
};