import React, { useEffect, useState } from 'react';
import { RadioButton } from 'primereact/radiobutton';
import TenantService from "../service/tenant";
import { useHistory } from 'react-router-dom';

const ServiceList = () => {
    const history = useHistory();

    const [selService, setSelectedService] = useState(null);
    const [services, setServices] = useState(null);
    useEffect(() => {
        getPermission();
    }, []);

    const getPermission = async () => {
        let getPermissionResponse = await TenantService.getPermissions();
        if (getPermissionResponse && getPermissionResponse.length) {
            let filterdPermissions = getPermissionResponse.filter(p => p.serviceTypeName);
            sessionStorage.setItem('permissions', JSON.stringify(filterdPermissions));
            setServices(filterdPermissions);
        }
    }

    const removeDomainName = (url) => {
        const domain = (new URL(url).origin);
        return url.replace(domain, '');
    }

    const onServiceSelection = async (e, service) => {
        let menus = await TenantService.getMenuOptions();
        let selMenu = menus.find(m => m.serviceTypeName === e.value);
        if (selMenu) {
            if (selMenu.menu && selMenu.menu.items) {
                selMenu.menu.items = selMenu.menu.items.filter(m => {
                    if (m.icon) m.iconurl = process.env.REACT_APP_IMAGE_STATIC_URL + m.icon;
                    else m.icon = "pi pi-fw pi-circle"
                    if (m.url) {
                        m.to = removeDomainName(m.url);
                    }
                    delete m.url;
                    return m.hasOwnProperty('to');
                });
            }
            sessionStorage.setItem('serviceId', service.serviceId);
            sessionStorage.setItem('menu', JSON.stringify(selMenu.menu))
            setSelectedService(e.value);
            history.push("/");
        }
    }

    return (
        <div className="pages-body flex flex-column login-img">
            <div className="align-self-center mt-auto mb-auto">
                {services && services.length ?
                    <div className="card" style={{ borderRadius: "0px", padding: "0" }}>
                        <div className='select-service-heading'>Select Service</div>
                        <div style={{ maxHeight: "15rem", overflowY: "auto", padding: "1rem" }}>
                            {
                                services.map((s, i) => {
                                    return (
                                        <div className="col-12" key={i} style={{ paddingRight: "5rem" }}>
                                            <div className="field-radiobutton">
                                                <RadioButton inputId="option1" name="option" value={s.serviceTypeName} checked={selService === s.serviceTypeName} onChange={(e) => onServiceSelection(e, s)} />
                                                <label htmlFor="option1">{s.serviceTypeName}</label>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    : null
                }
            </div>


        </div>

    )
}

export default ServiceList;