import axios from 'axios';

const url = 'https://3yn5dmzne6.execute-api.ap-south-1.amazonaws.com/analytics/get-current-fueldata';
const doc_db_url = 'https://3yn5dmzne6.execute-api.ap-south-1.amazonaws.com/apigwdocdb';
const config_url = 'https://3yn5dmzne6.execute-api.ap-south-1.amazonaws.com/config';
export class FuelSrvice {
    getAll = async (serviceID) => {
        const res = await axios.post(url, { "serviceId": serviceID });
        return res.data;
    }

    getQuickSight = async (serviceID, payload) => {
        const res = await axios.post(doc_db_url + '/' + serviceID + '/nq-quicksight-history', payload);
        return res.data;
    }

    getSessionReport = async (serviceID, payload) => {
        const res = await axios.post(doc_db_url + '/' + serviceID + '/nq-fuelconsumption-history', payload);
        return res.data;
    }

    getFuelingEventReport = async (serviceID, payload) => {
        const res = await axios.post(doc_db_url + '/' + serviceID + '/nq-fuelling-history', payload);
        return res.data;
    }

    getThings = async (serviceID) => {
        // const token = sessionStorage.getItem("token");
        const token = 'eyJraWQiOiIxY3JqR0w1VDJSVHVsaUMwSFhhRFBQUWVETCtcL2ltS2FhaFRJdXU0RmFLMD0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIwMTg3NGNlMS03MTdhLTQzMTktOGNhMC1hZmQ1NGFmNjRmNTMiLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC5hcC1zb3V0aC0xLmFtYXpvbmF3cy5jb21cL2FwLXNvdXRoLTFfT1l0dGYyNEkxIiwicGhvbmVfbnVtYmVyX3ZlcmlmaWVkIjpmYWxzZSwiY29nbml0bzp1c2VybmFtZSI6IjAxODc0Y2UxLTcxN2EtNDMxOS04Y2EwLWFmZDU0YWY2NGY1MyIsImF1ZCI6IjcxYTlnaDJlYTIyYzBvZ29nM2p0bGxpb3NhIiwiZXZlbnRfaWQiOiJjYjBhOWRlYi0zYjcxLTQ5YTYtOGYyZi1lNTE5ZDg2MDY5YmUiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY2MzIyNzY3NiwibmFtZSI6Ik5leHRxb3JlIFN1cHBvcnQiLCJwaG9uZV9udW1iZXIiOiIrOTE5MzIxOTUyNDQyIiwiZXhwIjoxNjYzMjMxMjc2LCJpYXQiOjE2NjMyMjc2NzYsImVtYWlsIjoic3VwcG9ydEBuZXh0cW9yZS5jb20ifQ.GZPDAKWAp2YDsV_IEswa_713WEVfkUsxlm9o243XMtCAKKT9ptuxHEqkcNDkhAOjrxBOUw9dL9vFf-7IITMyL2xy2PO6cs3jAuRMNXWqqvkKMLV7VaMCQGcoomwbX79s5Ohv_90PfpB3Pl5pBgBhzMcq-D_4cxwWgsanosJ6CNOTw1h4j-m_peK1wlf7Qo0-wt48CjuHiFQMrszdGfSFLWbcInecjdkp6RF9e8Ch2vME7Nr3PBpUTohNTgXVeOwcC_vQb4zgfZzv_XYkyNn3FpOXhlgmwB2eIZ-Cn6-GR0mIadK_cxjhtLSPZK-U5UMfZrP96_Mg10psmkKZrwrI1g';
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        const res = await axios.post(config_url+'/get-things/'+serviceID, {}, { headers: headers });
        return res.data;
    }

    getFuelTrend = async (serviceID, thingName, toDate, fromDate) => {
        const payload = {
            "filter": {
                "thingName": thingName, 
                "ts":{"$gte": fromDate,"$lte": toDate}
            }
        };

        const res = await axios.post(doc_db_url + '/' + serviceID + '/nq-consolidated-history', payload);
        return res.data;
    } 
}