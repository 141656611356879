import React, { useEffect } from 'react';
import { Route, withRouter, useLocation } from 'react-router-dom';
import App from "./App";
import Login from "./pages/Login";
import Error from "./pages/Error";
import NotFound from "./pages/NotFound";
import Access from "./pages/Access";
import Landing from './pages/Landing';
import AuthorizedRoutes from './routes';
import ServiceList from './pages/serviceList';
const AppWrapper = (props) => {
	let location = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [location]);
    document.body.style.backgroundColor = '#f7f7f7';

	const params = new URLSearchParams(location.search);
	const token = params.get('token');
	const serviceId = params.get('serviceId');
	const fromMobile = params.get('mobile');

	if (token) sessionStorage.setItem("token", token);
	if (serviceId) sessionStorage.setItem('serviceId', serviceId);
	if(fromMobile === "true") sessionStorage.setItem('mobile',fromMobile);
	switch (props.location.pathname) {
		case "/login":
			return <Route path="/login" component={Login} />
		case "/error":
			return <Route path="/error" component={Error} />
		case "/notfound":
			return <Route path="/notfound" component={NotFound} />
		case "/access":
			return <Route path="/access" component={Access} />
		case "/landing":
			return <Route path="/landing" component={Landing} />
		case "/services":
			return <Route path="/services" component={ServiceList} />
		default:
			return sessionStorage.getItem('mobile') && sessionStorage.getItem('token') ? (
				<AuthorizedRoutes />
			) : <App />
	}

}

export default withRouter(AppWrapper);
