import React, { Component } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Chart } from 'primereact/chart';
import { Badge } from 'primereact/badge';
import moment from "moment";
import { FuelSrvice } from "../../service/FuelService";
import { checkIfInSameMinute } from "../../utilities/utillFunction";

const getLineChartOptions = () => {
    return {
        maintainAspectRatio: false,
        aspectRatio: .8,
        plugins: {
            legend: {
                position: "bottom",
                labels: {
                    usePointStyle: true,
                    pointStyle: "circle",
                    color: "#000000",
                },
            }
        },
        scales: {
            x: {
                ticks: {
                    color: "#000000",
                },
                grid: {
                    color: "#FFFFFF",
                },
            },
            y: {
                ticks: {
                    precision: 0,
                    color: "#000000",
                },
                grid: {
                    color: "#dedede",
                },
            }
        }
    }
}

class FuelLiveReport extends Component {
    constructor(props){
        super(props);
        this.state = {
            data: null,
            site: null, 
            lineData: null, 
            finalIgSet: null,
			sitename: ''
        }
        this.fuelService = new FuelSrvice()
        this.lineChartOptions = getLineChartOptions();
		this.handelDetails = this.handelDetails.bind();
        this.handleFuelLevel = this.handleFuelLevel.bind(this);
        this.handleLastTime = this.handleLastTime.bind(this);
        this.handleIgnition = this.handleIgnition.bind(this);
		this.handelIg = this.handelIg.bind(this);
		this.handelFromTime = this.handelFromTime.bind(this);
		this.handelToTime = this.handelToTime.bind(this);
        this.headerGroup = (<ColumnGroup>
                                <Row>
                                    <Column header="Site/Equipment Name" className="bg-primary"/>
                                    <Column header="Fuel Level" className="bg-primary"/>
                                    <Column header="As On" className="bg-primary"/>
                                    <Column header="Ignition" className="bg-primary"/>
                                </Row>
                            </ColumnGroup>);
		this.headerGroup2 = (<ColumnGroup>
			<Row>
				<Column header="Ignition" className="bg-primary"/>
				<Column header="Duration" className="bg-primary"/>
				<Column header="Consumption/hr" className="bg-primary"/>
				<Column header="From" className="bg-primary"/>
				<Column header="To" className="bg-primary"/>
			</Row>
		</ColumnGroup>);						
    }
    componentDidMount = () => {
        this.fuelService.getAll('3v5cgrvyo0').then(res => {
            let response = res.responseData;
            const reportData = response.map((item) => {
				const fuelLevelArr = [];
				const ignitionArr = [];

				// Sanitize data

				let maxFuelItemPerSecond = {
					x: item.data[0].ts,
					y: item.data[0].fuel,
				};
				let lastIgnitionItemPerSecond = {
					x: item.data[0].ts,
					y: item.data[0].ignition ? 10 : 0,
				};
				for (let i = 1; i < item.data.length; i++) {
					if (
						!checkIfInSameMinute(
							item.data[i - 1].ts,
							item.data[i].ts
						)
					) {
						if (maxFuelItemPerSecond && lastIgnitionItemPerSecond) {
							fuelLevelArr.push(maxFuelItemPerSecond);
							ignitionArr.push(lastIgnitionItemPerSecond);
							maxFuelItemPerSecond = null;
							lastIgnitionItemPerSecond = null;
						} else {
							maxFuelItemPerSecond = {
								x: item.data[i].ts,
								y: item.data[i].fuel,
							};
							lastIgnitionItemPerSecond = {
								x: item.data[i].ts,
								y: item.data[i].ignition ? 300 : 0,
							};
						}
					} else {
						if (maxFuelItemPerSecond && lastIgnitionItemPerSecond) {
							if (maxFuelItemPerSecond.y < item.data[i].fuel) {
								maxFuelItemPerSecond = {
									x: item.data[i].ts,
									y: item.data[i].fuel,
								};
								lastIgnitionItemPerSecond = {
									x: item.data[i].ts,
									y: item.data[i].ignition ? 300 : 0,
								};
							}
						} else {
							maxFuelItemPerSecond = {
								x: item.data[i].ts,
								y: item.data[i].fuel,
							};
							lastIgnitionItemPerSecond = {
								x: item.data[i].ts,
								y: item.data[i].ignition ? 300 : 0,
							};
						}
					}
				}

				if (maxFuelItemPerSecond && lastIgnitionItemPerSecond) {
					fuelLevelArr.push(maxFuelItemPerSecond);
					ignitionArr.push(lastIgnitionItemPerSecond);
					maxFuelItemPerSecond = null;
					lastIgnitionItemPerSecond = null;
				}

				const fuelEvents = [];
				const defuelEvents = [];

				// calculate fuel / de-fuel event
				// Note: fuel/de fuel event can not be more than two. for now showing only two
				let fuelEventArr = [];
				let fuelEvent = {};

				let defuelEventArr = [];
				let defuelEvent = {};

				for (let i = 1; i < item.data.length; i++) {
					if (
						item.data[i - 1].ignition === 0 &&
						item.data[i].ignition === 1
					) {
						if (
							Object.keys(defuelEvent).length > 0 &&
							defuelEvent.totalFuel >= 2
						) {
							defuelEvents.push(defuelEvent);
						}
					} else if (item.data[i].fuel - item.data[i - 1].fuel > 0) {
						if (
							Object.keys(defuelEvent).length > 0 &&
							defuelEvent.totalFuel >= 2
						) {
							defuelEvents.push(defuelEvent);
						}
					}

					if (item.data[i].fuel - item.data[i - 1].fuel > 0) {
						defuelEventArr = [];
						defuelEvent = {};

						fuelEventArr.push({
							ts: item.data[i - 1].ts,
							fuelDifference:
								item.data[i].fuel - item.data[i - 1].fuel,
						});

						const totalFuel = fuelEventArr.reduce(
							(accumulator, value) => {
								return accumulator + value.fuelDifference;
							},
							0
						);

						if (Object.keys(fuelEvent).length === 0) {
							fuelEvent = {
								ts: item.data[i - 1].ts,
								totalFuel: totalFuel,
							};
						} else {
							fuelEvent.totalFuel = totalFuel;
						}
					}
					// defueling Event fueling information
					else if (
						item.data[i - 1].fuel - item.data[i].fuel > 0 &&
						item.data[i].ignition === 0
					) {
						if (
							Object.keys(fuelEvent).length > 0 &&
							fuelEvent.totalFuel >= 5
						) {
							fuelEvents.push(fuelEvent);
						}
						fuelEventArr = [];
						fuelEvent = {};

						defuelEventArr.push({
							ts: item.data[i - 1].ts,
							fuelDifference:
								item.data[i - 1].fuel - item.data[i].fuel,
						});

						const totalFuel = defuelEventArr.reduce(
							(accumulator, value) => {
								return accumulator + value.fuelDifference;
							},
							0
						);

						if (Object.keys(defuelEvent).length === 0) {
							defuelEvent = {
								ts: item.data[i - 1].ts,
								totalFuel: totalFuel,
							};
						} else {
							defuelEvent.totalFuel = totalFuel;
						}
					}
				}

				const lastEntryInData = item.data[item.data.length - 1];
				return {
					equipmentName: item.thingName,
					siteId: item.siteid,
					siteName: item.sitename,
					fuelEvents: fuelEvents,
					defuelEvents: defuelEvents,
					data: [
						{
							id: 'FuelLevel',
							color: '#bc5090',
							data: fuelLevelArr,
						},
						{
							id: 'Ignition',
							color: '#ff6361',
							data: ignitionArr,
						},
					],
					lastknownFuelLevel: lastEntryInData.fuel,
					lastKnownIgnition: lastEntryInData.ignition ? 'ON' : 'OFF',
				};
			});
            const chartData = this.createChart(reportData[0]);
            
            this.setState({data: reportData, lineData: chartData});
        }).catch(reason =>{
            console.log(reason);
        })
    }

    createChart = (reportData) => {
        let times = [];
            let fuel = [];
            let ignition = [];

            

            reportData.data[0].data.forEach(item => {
                times.push(moment(item.x, "YYYY-MM-DD HH:mm:ss").format('HH'));
                fuel.push(item.y)
            });
            reportData.data[1].data.forEach(item => {
                ignition.push(item.y)
            })
            return {
                labels: times,
                datasets: [
                    {
                        label: 'Fuel Level',
                        data: fuel,
                        fill: false,
                        backgroundColor: '#00ACC1',
                        borderColor: '#00ACC1',
                        tension: .4
                    },
                    {
                        label: 'Ignition',
                        data: ignition,
                        fill: false,
                        backgroundColor: '#FF9800',
                        borderColor: '#FF9800',
                        tension: .4
                    }
                ]
            }
    }

	handelDetails = (item) => {
		return <span>{item.siteName}<br/>{item.equipmentName}</span>
		// return item.siteName +'<br>'+item.equipmentName;
	}

    handleFuelLevel = (item) => {
        return item.lastknownFuelLevel + ' ltr';
    }
    handleLastTime = (item) => {
        return moment(item.data[0].data[item.data[0].data.length - 1].x).format('Do MMM, HH:mm');
    }
    handleIgnition = (item) => {
        return item.lastKnownIgnition === 'ON' ? (
            <Badge value="ON" className="mr-2" severity="success"></Badge>
        ) : (<Badge value="OFF" className="mr-2" severity="danger"></Badge>);
    }
    handelSelection = (value) => {
        let fuelLevel = value.data[0].data;
		let ignition = value.data[1].data;
		
		let ignitionSet = [];
		ignition.map((item, index) => {
			if(ignitionSet.length === 0 && item.y === 300) {
				var fuleLevel
				var fuleIndex = fuelLevel.findIndex(elm => new Date(elm.x).getTime() === new Date(item.x).getTime());
				if(fuleIndex > -1) {
					fuleLevel = fuelLevel[fuleIndex].y;
				}
				ignitionSet.push({
					onFrom: item.x,
					onTo: null,
					fule: fuleLevel
				})
			} else if(item.y === 0 && ignitionSet.length > 0 && ignitionSet[ignitionSet.length-1].onTo === null) {
				let lastIndex = ignitionSet.length-1;
				var nextfuleLevel;
				var nextfuleIndex = fuelLevel.findIndex(elm => new Date(elm.x).getTime() === new Date(ignition[index - 1].x).getTime());
				if(nextfuleIndex > -1) {
					nextfuleLevel = fuelLevel[nextfuleIndex].y;
				}

				ignitionSet[lastIndex].onTo = ignition[index - 1].x;
				ignitionSet[lastIndex].fule = ignitionSet[lastIndex].fule - nextfuleLevel;
			} else if (item.y === 300 && ignitionSet.length > 0 && ignitionSet[ignitionSet.length-1].onTo !== null) {

				var fuleLevel
				var fuleIndex = fuelLevel.findIndex(elm => new Date(elm.x).getTime() === new Date(item.x).getTime());
				if(fuleIndex > -1) {
					fuleLevel = fuelLevel[fuleIndex].y;
				}

				ignitionSet.push({
					onFrom: item.x,
					onTo: null,
					fule: fuleLevel
				})
			} else if(item.y === 300 && index === ignition.length - 1) {

				var nextfuleLevel;
				var nextfuleIndex = fuelLevel.findIndex(elm => new Date(elm.x).getTime() === new Date(ignition[index].x).getTime());
				if(nextfuleIndex > -1) {
					nextfuleLevel = fuelLevel[nextfuleIndex].y;
				}
				ignitionSet[ignitionSet.length-1].onTo = ignition[index].x;
				ignitionSet[ignitionSet.length-1].fule = ignitionSet[ignitionSet.length-1].fule - nextfuleLevel;
			}
		});
		
		let finalIgSet = ignitionSet.map(item => {
			if(item.onTo !== null) {
				let timeDiff = (new Date(item.onTo).getTime() - new Date(item.onFrom).getTime()) / 1000;
				let tempTime = this.convertHMS(timeDiff)
				return {
					...item,
					duration: tempTime,
					fuleConsumption: (item.fule * 60) / (timeDiff * 60) ? ((item.fule * 60) / ((timeDiff / 3600).toFixed(2) * 60)).toFixed(2) + 'Ltr/hr' : 'No Consumption'
				}
			} else {
				return {
					...item,
					duration: 'DG is still on',
					fuleConsumption: '--'
				}
			}
			
		});
        const chartData = this.createChart(value);
        this.setState({lineData: chartData, finalIgSet, site: value, sitename: value.siteName});
    }

    convertHMS = (value) => {
		const sec = parseInt(value, 10);
		let hours   = Math.floor(sec / 3600);
		let minutes = Math.floor((sec - (hours * 3600)) / 60);
		let seconds = sec - (hours * 3600) - (minutes * 60);
		// add 0 if value < 10; Example: 2 => 02
		if (hours   < 10) {hours   = "0"+hours}
		if (minutes < 10) {minutes = "0"+minutes}
		if (seconds < 10) {seconds = "0"+seconds}
		// return hours+':'+minutes+':'+seconds;
		return hours+':'+minutes;
	}

	handelIg(item) {
		return <Badge value="ON" className="mr-2" severity="success"></Badge>;
	}
	handelFromTime(item) {
		return moment(item.onFrom, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
	}
	handelToTime(item) {
		return moment(item.onTo, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
	}

    render = () => this.state.data ? (
            <div className="grid">
                <div className="col-12 lg:col-5">
                    <div className="card">
                        <Chart style={{ position: 'relative', height: '300px' }} type="line" data={this.state.lineData} options={this.lineChartOptions}></Chart>
                    </div>
                </div>
                <div className="col-12 lg:col-7"> 
                    <div className="card mb-3 p-0">
                        <DataTable 
                            value={this.state.data} 
                            headerColumnGroup={this.headerGroup} 
                            selectionMode="single" 
                            selection={this.state.site} 
                            onSelectionChange={e => this.handelSelection(e.value)} 
                            dataKey="siteId"
                            responsiveLayout="scroll"
                        >
                            <Column body={this.handelDetails} className="font-semibold"></Column>
                            <Column body={this.handleFuelLevel} className="white-space-nowrap"></Column>
                            <Column body={this.handleLastTime} className="white-space-nowrap"></Column>
                            <Column body={this.handleIgnition} className="text-center"></Column>
                        </DataTable>
                    </div>
                    {
                        this.state.finalIgSet && this.state.finalIgSet.length > 0 ? (
                            <div className="card">
								<h5>Run Session of {this.state.sitename}</h5>
								<DataTable 
									headerColumnGroup={this.headerGroup2} 
									value={this.state.finalIgSet} 
									responsiveLayout="scroll"
								>
									<Column body={this.handelIg}></Column>
									<Column field="duration"></Column>
									<Column field="fuleConsumption"></Column>
									<Column field="onFrom" body={this.handelFromTime}></Column>
									<Column field="onTo" body={this.handelToTime}></Column>
								</DataTable>
							</div>
                        ) : null
                    }
                </div>
            </div>
    ) : null
}

export default FuelLiveReport;