import React, { Component } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import Report from "./components/Report";
import Dashboard from "./components/Dashboard";

class WaterQuality extends Component {
    constructor(props) {
        super(props);
    }

    render = () => {
        return (
            <TabView>
                <TabPanel header="Live" className="px-3">
                    <Report />
                </TabPanel>
                <TabPanel header="History" className="px-3">
                    <Dashboard />
                </TabPanel>
            </TabView>
        );
    };
}

export default WaterQuality;
